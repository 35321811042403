import { uid } from "uid";
import { sendErrorToChatbot } from "./utils";

//export const DBversion = 12;
export const DBversion = 19;

export const createStoreOrder = (order_id, dataList, total_amount,reference_order,order_index,selectPackage,payment_method) => {
  return new Promise((resolve, reject) => {
    var request = window.indexedDB.open("LuckyDoseDatabase", DBversion);
    request.onsuccess = () => {
      if (request.result.objectStoreNames.contains("customers")) {
        var tx = request.result.transaction("customers", "readwrite");
        var store = tx.objectStore("customers");
        var uidArrays = [];
        dataList.forEach((form, index) => {
          var customer_id = uid();
          store.put({
            psd_filename:form.psd_filename,
            name: form.name,
            surname: form.surname,
            birthDate: form.date,
            birthMonth: form.month,
            birthYear: form.year,
            zodiacId: parseInt(form.zodiac),
            //selectImage: form.id,
            showBirthDate: form.isShowDate,
            customer_id: customer_id,
            price: total_amount,
            //selectImageId:form.selectImageId,
            chinese_zodiac:form.chinese_zodiac,
            day_of_week:parseInt(form.day_of_week),
            moon_phase:parseInt(form.moon_phase),
            attribute:form.attribute?form.attribute:[]
          });
          uidArrays.push(customer_id);
        });
        if (request.result.objectStoreNames.contains("orders")) {
          var orderUID = uid();
          var tx_order = request.result.transaction("orders", "readwrite");
          var store_order = tx_order.objectStore("orders");
          store_order.put({
            orderID: order_id,
            customer_ids: uidArrays,
            uid: orderUID,
            payment_method: payment_method,
            total_amount: total_amount,
            reference_order:reference_order,
            order_index:order_index,
            payment_status:false,
            select_package:selectPackage
          });
          resolve(orderUID);
        }
        else{
          reject('database not contain orders when create')
        }
      }
      //reject('error')
      //return databaseID;
    };
    request.onerror = (e) => {
      reject(`Error when open database with DBVersion:${DBversion} ${request.error}`)
    };
  });
};

export const createSubscriberInfo = (subscriber_info,phone_number)=>{
  return new Promise((resolve,reject)=>{
    var request = window.indexedDB.open("LuckyDoseDatabase", DBversion);
    request.onsuccess = () => {
      if (request.result.objectStoreNames.contains("subscription")) {
        var tx_order = request.result.transaction("subscription", "readwrite");
        var store_order = tx_order.objectStore("subscription");
        store_order.put({
          name: subscriber_info.name,
          surname: subscriber_info.surname,
          birthDate: subscriber_info.birthDate,
          birthMonth: subscriber_info.birthMonth,
          birthYear: subscriber_info.birthYear,
          zodiacId:subscriber_info.zodiacId,
          chinese_zodiac:subscriber_info.chinese_zodiac,
          phone_number:phone_number,
          subscription_id:phone_number
        });
        resolve(phone_number);
      }
  }
    request.onerror= (e) =>{
      reject(`Error when open database with DBVersion:${DBversion} ${request.error}`)
    }
})
}

export const updateIndexedDBOrderId = (uid, order_id) => {
  return new Promise((resolve, reject) => {
    var request = window.indexedDB.open("LuckyDoseDatabase", DBversion);
    request.onsuccess = () => {
      var db = request.result;
      var tx = db.transaction(["orders"], "readwrite");
      const store = tx.objectStore("orders");
      var updateRequest = store.get(uid);
      updateRequest.onsuccess = () => {
        var data = updateRequest.result;
        data.orderID = order_id;
        var requestUpdate = store.put(data);
        resolve("update success");
      };
      updateRequest.onerror = () => {
        reject("update error");
      };
    };
    request.onerror = () => {
      reject("open databsae error");
    };
  });
};

export const checkHasPurchasedHistory = () => {
  return new Promise((resolve, reject) => {
    var request = window.indexedDB.open("LuckyDoseDatabase", DBversion);
    request.onupgradeneeded = (e) => {
      var store = request.result.objectStoreNames;

      if (!store.contains("orders")) {
        var objectStore = request.result.createObjectStore("orders", { keyPath: "uid" });
        const orderID = objectStore.createIndex("by_orderID", "orderID", { unique: true });
        const customer_ids = objectStore.createIndex("by_customerIDs", "customer_ids");
        const payment_method = objectStore.createIndex("by_payment_method", "payment_method");
        const total_amount = objectStore.createIndex("by_total_amount", "total_amount");
        const reference_order = objectStore.createIndex("by_reference_order",'reference_order')
        const payment_status = objectStore.createIndex('by_payment_status','payment_status')
        const order_index = objectStore.createIndex('by_order_index','order_index')
        const select_package = objectStore.createIndex('by_select_package','select_package');
      } else {
        var objectStore = e.target.transaction.objectStore("orders");
        //version 3 add payment_method
        //version 4 need to add total_amount
        //version 7 add reference_order
        if (!objectStore.indexNames.contains("by_payment_method")) {
          objectStore.createIndex("by_payment_method", "payment_method");
        }
        if (!objectStore.indexNames.contains("by_total_amount")) {
          objectStore.createIndex("by_total_amount", "total_amount");
        }
        if(!objectStore.indexNames.contains("by_reference_order")){
          objectStore.createIndex("by_reference_order",'reference_order')
        }
        //version 8 add payment_status and order_index
        if(!objectStore.indexNames.contains('by_payment_status')){
          objectStore.createIndex('by_payment_status','payment_status')
        }
        if(!objectStore.indexNames.contains('by_order_index')){
          objectStore.createIndex('by_order_index','order_index')
        }
        //version 9
        if(!objectStore.indexNames.contains('by_select_package')) {
          objectStore.createIndex('by_select_package','select_package');
        }
      }
      if (!store.contains("customers")) {
        var objectStore = request.result.createObjectStore("customers", { keyPath: "customer_id" });
        const name = objectStore.createIndex("by_name", "name");
        const surname = objectStore.createIndex("by_surname", "surname");
        const birthDate = objectStore.createIndex("by_birthDate", "birthDate");
        const birthMonth = objectStore.createIndex("by_birtMonth", "birthMonth");
        const birthYear = objectStore.createIndex("by_birthYear", "birthYear");
        const zodiacId = objectStore.createIndex("by_zodiacId", "zodiacId");
        //const selectImageId = objectStore.createIndex("by_selectImageId", "selectImageId");
        const selectImage = objectStore.createIndex("by_selectImage", "selectImage");
        const showBirthDate = objectStore.createIndex("by_showBirthDate", "showBirthDate");
        const price = objectStore.createIndex("by_price", "price");
        const selectImageId = objectStore.createIndex("by_selectImageId", "selectImageId");
        const chinese_zodiac = objectStore.createIndex("by_chinese_zodiac", "chinese_zodiac");
        const day_of_week = objectStore.createIndex('by_day_of_week','day_of_week');
        const moon_phase = objectStore.createIndex('by_moon_phase','moon_phase');
        const psd_filename = objectStore.createIndex('by_psd_filename','psd_filename');
        const attribute = objectStore.createIndex('by_attribute','attribute');
      } else {
        //version 4 need to add total_amount
        var objectStore = e.target.transaction.objectStore("customers");
        if (!objectStore.indexNames.contains("by_price")) {
          objectStore.createIndex("by_price", "price");
        }
        //version 9
        if (!objectStore.indexNames.contains("by_selectImageId")) {
          objectStore.createIndex("by_selectImageId", "selectImageId");
        }
        //version  10
        if (!objectStore.indexNames.contains("by_chinese_zodiac")) {
          objectStore.createIndex("by_chinese_zodiac", "chinese_zodiac");
        }
        //version 18 add day_of_week/moon_phase/psd_filename
        if (!objectStore.indexNames.contains("by_day_of_week")) {
          objectStore.createIndex("by_day_of_week", "day_of_week");
        }
        if (!objectStore.indexNames.contains("by_moon_phase")) {
          objectStore.createIndex("by_moon_phase", "moon_phase");
        }
        if (!objectStore.indexNames.contains("by_psd_filename")) {
          objectStore.createIndex("by_psd_filename", "psd_filename");
        }
        //version 19 add attribute
        if (!objectStore.indexNames.contains("by_attribute")) {
          objectStore.createIndex("by_attribute", "attribute");
        }
      }
      if (!store.contains("purchased")) {
        var objectStore = request.result.createObjectStore("purchased", {
          keyPath: "purchase_id",
          autoIncrement: true,
        });
      }
      //version 16
      if(!store.contains("subscription")){
        var objectStore = request.result.createObjectStore("subscription", { keyPath: "subscription_id" });
        const name = objectStore.createIndex("by_name", "name");
        const surname = objectStore.createIndex("by_surname", "surname");
        const birthDate = objectStore.createIndex("by_birthDate", "birthDate");
        const birthMonth = objectStore.createIndex("by_birtMonth", "birthMonth");
        const birthYear = objectStore.createIndex("by_birthYear", "birthYear");
        const zodiacId = objectStore.createIndex("by_zodiacId", "zodiacId");
        const chinese_zodiac = objectStore.createIndex("by_chinese_zodiac", "chinese_zodiac");
        const phone_number = objectStore.createIndex("by_phone_number","phone_number")
      }
    };
    request.onsuccess = () => {
      var store = request.result.objectStoreNames;
      if (!store.contains("purchased")) {
        reject(false);
      } else {
        var db = request.result;
        var tx = db.transaction(["purchased"], "readonly");
        var obj = tx.objectStore("purchased");
        if ("getAll" in obj) {
          var req = obj.getAll();
          req.onsuccess = (e) => {
            var result = e.target.result;
            if (result.length > 0) resolve(true);
            else resolve(false);
          };
        } else {
          reject("cant get all");
        }
      }
    };
    request.onerror = () => {
      reject("open databsae error");
    };
  });
};

export const addImageToIndexedDB = (blob, id) => {
  return new Promise((resolve, reject) => {
    var request = window.indexedDB.open("LuckyDoseDatabase", DBversion);
    request.onupgradeneeded = (e) => {
      var store = request.result.objectStoreNames;

      if (!store.contains("orders")) {
        var objectStore = request.result.createObjectStore("orders", { keyPath: "uid" });
        const orderID = objectStore.createIndex("by_orderID", "orderID", { unique: true });
        const customer_ids = objectStore.createIndex("by_customerIDs", "customer_ids");
        const payment_method = objectStore.createIndex("by_payment_method", "payment_method");
        const total_amount = objectStore.createIndex("by_total_amount", "total_amount");
        const reference_order = objectStore.createIndex("by_reference_order",'reference_order')
        const payment_status = objectStore.createIndex('by_payment_status','payment_status')
        const order_index = objectStore.createIndex('by_order_index','order_index')
      } else {
        var objectStore = e.target.transaction.objectStore("orders");
        //version 3 add payment_method
        if (!objectStore.indexNames.contains("by_payment_method")) {
          objectStore.createIndex("by_payment_method", "payment_method");
        }
        //version 7 add reference_order
        if(!objectStore.indexNames.contains("by_reference_order")){
          objectStore.createIndex("by_reference_order",'reference_order')
        }
        //version 8 add payment_status and order_index
        if(!objectStore.indexNames.contains('by_payment_status')){
          objectStore.createIndex('by_payment_status','payment_status')
        }
        if(!objectStore.indexNames.contains('by_order_index')){
          objectStore.createIndex('by_order_index','order_index')
        }  
      }
      if (!store.contains("customers")) {
        var objectStore = request.result.createObjectStore("customers", { keyPath: "customer_id" });
        const name = objectStore.createIndex("by_name", "name");
        const surname = objectStore.createIndex("by_surname", "surname");
        const birthDate = objectStore.createIndex("by_birthDate", "birthDate");
        const birthMonth = objectStore.createIndex("by_birtMonth", "birthMonth");
        const birthYear = objectStore.createIndex("by_birthYear", "birthYear");
        const zodiacId = objectStore.createIndex("by_zodiacId", "zodiacId");
        //const selectImageId = objectStore.createIndex("by_selectImageId", "selectImageId");
        const selectImage = objectStore.createIndex("by_selectImage", "selectImage");
        const showBirthDate = objectStore.createIndex("by_showBirthDate", "showBirthDate");
        const price = objectStore.createIndex("by_price", "price");
      }
      if (!store.contains("purchased")) {
        var objectStore = request.result.createObjectStore("purchased", {
          keyPath: "purchase_id",
          autoIncrement: true,
        });
      }
    };
    request.onsuccess = () => {
      var db = request.result;
      var tx = db.transaction(["purchased"], "readwrite");
      const addReq = tx.objectStore("purchased").put({ blob, purchase_id: id });
    };
    request.onerror = () => {
      reject("open databsae error");
    };
  });
};

export const updatePaymentMethodIndexedDB = (uid, payment) => {
  return new Promise((resolve, reject) => {
    var request = window.indexedDB.open("LuckyDoseDatabase", DBversion);
    request.onsuccess = () => {
      var db = request.result;
      var tx = db.transaction(["orders"], "readwrite");
      const store = tx.objectStore("orders");
      //var updateRequest = store.get(uid)
      store.openCursor().onsuccess = (e) => {
        const cursor = e.target.result;
        if (cursor) {
          //console.log(cursor.value)
          if (cursor.value.uid === uid) {
            const updateData = cursor.value;
            updateData.payment_method = payment;
            const cursorRequest = cursor.update(updateData);
            cursorRequest.onsuccess = () => {
              resolve("update succress");
            };
            cursorRequest.onerror = () => {
              reject("update  error");
            };
          }
          cursor.continue();
        } else {
          reject("no key");
        }
      };
      store.openCursor().onerror = () => {
        reject("open cursor errro");
      };
      /*updateRequest.onsuccess = () => {
                var data = updateRequest.result
                console.log(uid)
                data.payment_method = payment
                var requestUpdate = store.put(data)
                resolve('update success')
            }
            updateRequest.onerror = () => {
                reject('update error')
            }*/
    };
    request.onerror = () => {
      reject("open databsae error");
    };
  });
};

export const getPurchasedImages = () => {
  return new Promise((resolve, reject) => {
    var request = window.indexedDB.open("LuckyDoseDatabase", DBversion);
    var result;
    request.onsuccess = () => {
      var db = request.result;
      var tx = db.transaction(["purchased"], "readonly");
      var obj = tx.objectStore("purchased");
      if ("getAll" in obj) {
        var req = obj.getAll();
        req.onsuccess = (e) => {
          resolve(e.target.result);
        };
      }
    };
    request.onerror = () => {
      reject("open databsae error");
    };
  });
};

export function doImageTest() {
  new Promise((resolve, reject) => {
    var request = window.indexedDB.open("LuckyDoseDatabase", DBversion);
    request.onsuccess = () => {
      let image = document.querySelector("#testImage");
      var db = request.result;
      let trans = db.transaction(["purchased"], "readonly");
      //hard coded id
      let req = trans.objectStore("purchased").get(1);
      req.onsuccess = function (e) {
        let record = e.target.result;
        var imgURL = URL.createObjectURL(record);
        image.src = imgURL;
      };
    };
    request.onerror = () => {
      reject("open databsae error");
    };
  });
}

export const getIndexedStore = (uid) => {
  return new Promise((resolve, reject) => {
    var request = window.indexedDB.open("LuckyDoseDatabase", DBversion);
    request.onsuccess = () => {
      var db = request.result;
      var tx = db.transaction("orders");
      var store = tx.objectStore("orders");
      var getRequest = store.get(uid);
      getRequest.onsuccess = () => {
        resolve(getRequest.result);
      };
      getRequest.onerror = () => {
        reject("getRequest error");
      };
    };
    request.onerror = () => {
      reject("open request error");
    };
  });
};

export const getIndexedCustomer = (uid) => {
  return new Promise((resolve, reject) => {
    var request = window.indexedDB.open("LuckyDoseDatabase", DBversion);
    request.onsuccess = () => {
      var db = request.result;
      var tx = db.transaction("customers");
      var store = tx.objectStore("customers");
      var getRequest = store.get(uid);
      getRequest.onsuccess = () => {
        resolve(getRequest.result);
      };
      getRequest.onerror = () => {
        reject("getRequest error");
      };
    };
    request.onerror = () => {
      reject("open request error");
    };
  });
};

export const getSubscriberInfo = (subscription_id)=>{
  //sendErrorToChatbot(`get subscriber info:${subscription_id}`)
  return new Promise((resolve, reject) => {
    var request = window.indexedDB.open("LuckyDoseDatabase", DBversion);
    request.onerror = () => {
      //sendErrorToChatbot('open request error')
      reject(`open request error ${request.error}`);
    };
    request.onsuccess = () => {
      //sendErrorToChatbot('open database success')
      var db = request.result;
      var tx = db.transaction(["subscription"],"readonly");
      //sendErrorToChatbot('transaction complete')
      var store = tx.objectStore("subscription");
      //sendErrorToChatbot('objectStore complete')
      var getRequest = store.get(subscription_id);
      //sendErrorToChatbot('store.get complete')
      getRequest.onerror = () => {
        //sendErrorToChatbot('get request error')
        reject(`getRequest error ${getRequest.error}`);
      };
      getRequest.onsuccess = (e) => {
        //sendErrorToChatbot('onsuccess')
        if(e.target.result === undefined)
          resolve(false)
        else
          resolve(e.target.result);
      };
    };
  });
}

export const clearStoreByName = (name) => {
  return new Promise((resolve, reject) => {
    var request = window.indexedDB.open("LuckyDoseDatabase", DBversion);
    request.onsuccess = () => {
      if (request.result.objectStoreNames.contains(name)) {
        var tx = request.result.transaction(name, "readwrite");
        var store = tx.objectStore(name);

        store.clear();
      }

      resolve("delete complete");
    };
    request.onerror = () => {
      reject("delete error");
    };
  });
};

export const createStoreCustomer = (form) => {
  return new Promise((resolve, reject) => {
    var databaseID = uid();
    var request = window.indexedDB.open("LuckyDoseDatabase", DBversion);
    var isSetup = false;

    request.onsuccess = () => {
      if (!isSetup && request.result.objectStoreNames.contains("customers")) {
        var tx = request.result.transaction("customers", "readwrite");
        var store = tx.objectStore("customers");
        store.put({
          name: form.name,
          surname: form.surname,
          birthDate: form.birthDate,
          birthMonth: form.birthMonth,
          birthYear: form.birthYear,
          zodiacId: form.zodiacId,
          selectImageId: form.selectImageId,
          selectImage: form.selectImage,
          showBirthDate: form.showBirthDate,
          customer_id: databaseID,
        });
      } else if (!request.result.objectStoreNames.contains("customers")) {
        var objectStore = request.result.createObjectStore("customers", { keyPath: "customer_id" });
        const name = objectStore.createIndex("by_name", "name");
        const surname = objectStore.createIndex("by_surname", "surname");
        const birthDate = objectStore.createIndex("by_birthDate", "birthDate");
        const birthMonth = objectStore.createIndex("by_birtMonth", "birthMonth");
        const birthYear = objectStore.createIndex("by_birthYear", "birthYear");
        const zodiacId = objectStore.createIndex("by_zodiacId", "zodiacId");
        const selectImageId = objectStore.createIndex("by_selectImageId", "selectImageId");
        const selectImage = objectStore.createIndex("by_selectImage", "selectImage");
        const showBirthDate = objectStore.createIndex("by_showBirthDate", "showBirthDate");
        objectStore.put({
          name: form.name,
          surname: form.surname,
          birthDate: form.birthDate,
          birthMonth: form.birthMonth,
          birthYear: form.birthYear,
          zodiacId: form.zodiacId,
          selectImageId: form.selectImageId,
          selectImage: form.selectImage,
          showBirthDate: form.showBirthDate,
          customer_id: databaseID,
        });
      }
      resolve(databaseID);
      //return databaseID;
    };
    request.onerror = () => {
      reject("request error");
    };
  });
};

export const getMaxOrderIndex = ()=>{
  return new Promise((resolve,reject)=>{
    var request = window.indexedDB.open("LuckyDoseDatabase", DBversion);
    request.onupgradeneeded = (e) => {
      var store = request.result.objectStoreNames;

      if (!store.contains("orders")) {
        var objectStore = request.result.createObjectStore("orders", { keyPath: "uid" });
        const orderID = objectStore.createIndex("by_orderID", "orderID", { unique: true });
        const customer_ids = objectStore.createIndex("by_customerIDs", "customer_ids");
        const payment_method = objectStore.createIndex("by_payment_method", "payment_method");
        const total_amount = objectStore.createIndex("by_total_amount", "total_amount");
        const reference_order = objectStore.createIndex("by_reference_order",'reference_order')
        const payment_status = objectStore.createIndex('by_payment_status','payment_status')
        const order_index = objectStore.createIndex('by_order_index','order_index')
      } else {
        var objectStore = e.target.transaction.objectStore("orders");
        //version 3 add payment_method
        if (!objectStore.indexNames.contains("by_payment_method")) {
          objectStore.createIndex("by_payment_method", "payment_method");
        }
        //version 7 add reference_order
        if(!objectStore.indexNames.contains("by_reference_order")){
          objectStore.createIndex("by_reference_order",'reference_order')
        }
        //version 8 add payment_status and order_index
        if(!objectStore.indexNames.contains('by_payment_status')){
          objectStore.createIndex('by_payment_status','payment_status')
        }
        if(!objectStore.indexNames.contains('by_order_index')){
          objectStore.createIndex('by_order_index','order_index')
        }  
      }
      if (!store.contains("customers")) {
        var objectStore = request.result.createObjectStore("customers", { keyPath: "customer_id" });
        const name = objectStore.createIndex("by_name", "name");
        const surname = objectStore.createIndex("by_surname", "surname");
        const birthDate = objectStore.createIndex("by_birthDate", "birthDate");
        const birthMonth = objectStore.createIndex("by_birtMonth", "birthMonth");
        const birthYear = objectStore.createIndex("by_birthYear", "birthYear");
        const zodiacId = objectStore.createIndex("by_zodiacId", "zodiacId");
        //const selectImageId = objectStore.createIndex("by_selectImageId", "selectImageId");
        const selectImage = objectStore.createIndex("by_selectImage", "selectImage");
        const showBirthDate = objectStore.createIndex("by_showBirthDate", "showBirthDate");
        const price = objectStore.createIndex("by_price", "price");
      }
      if (!store.contains("purchased")) {
        var objectStore = request.result.createObjectStore("purchased", {
          keyPath: "purchase_id",
          autoIncrement: true,
        });
      }
    };

    request.onsuccess=()=>{
      var db = request.result;
      try{
        var transaction = db.transaction(["orders"],'readonly')
        var objectStore = transaction.objectStore('orders')
        var index = objectStore.index('by_order_index');
        var openCursorRequest = index.openCursor(null,'prev')
        var maxOrderIndexObject = null
        openCursorRequest.onsuccess = (event)=>{
          if(event.target.result){           
            maxOrderIndexObject = event.target.result.value
            resolve(maxOrderIndexObject)
          }
          else{
            resolve({ order_index:0})
          }
        }
        openCursorRequest.onerror = (e)=>{
          reject(`Error when opencursor :${DBversion} ${request.error}`)
        }
      }catch(error){
        //console.log(error)
        reject(error.message)
      }
    }
    request.onerror = (e)=>{
      reject(`Error when open database with DBVersion:${DBversion} ${request.error}`)
    }
  })
}

export const updateIndexedDBPaymentStatus = (uid) => {
  return new Promise((resolve, reject) => {
    var request = window.indexedDB.open("LuckyDoseDatabase", DBversion);
    request.onsuccess = () => {
      var db = request.result;
      var tx = db.transaction(["orders"], "readwrite");
      const store = tx.objectStore("orders");
      //var updateRequest = store.get(uid)
      store.openCursor().onsuccess = (e) => {
        const cursor = e.target.result;
        if (cursor) {
          //console.log(cursor.value)
          if (cursor.value.uid === uid) {
            const updateData = cursor.value;
            updateData.payment_status = true;
            const cursorRequest = cursor.update(updateData);
            cursorRequest.onsuccess = () => {
              resolve("update succress");
            };
            cursorRequest.onerror = () => {
              reject("update  error");
            };
          }
          cursor.continue();
        } else {
          reject("no key");
        }
      };
      store.openCursor().onerror = () => {
        reject("open cursor errro");
      };
    };
    request.onerror = () => {
      reject("open databsae error");
    };
  });
};

export const deleteOrderInIndexedDB = (uid)=>{
  return new Promise((resolve, reject) => {
    var request = window.indexedDB.open("LuckyDoseDatabase", DBversion);
    request.onsuccess = () => {
      var db = request.result;
      var tx = db.transaction(["orders"], "readwrite");
      var delete_req = tx.objectStore('orders').delete(uid);
      delete_req.onerror=(e)=>{
        console.log(e)
        reject('delete error')
      }
      tx.oncomplete=()=>{
        resolve('delete complete')
      }
    }
  })
}