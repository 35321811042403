import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import styled from 'styled-components';

const CircleProgressComponent = ({value})=>{
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex', margin:'auto' }}>
          <CircularProgress size={'70px'} variant="determinate" value={value} style={{'color': '#E4202A'}}/>
          <Box
            sx={{
              top: 0,
              left: 0,
              bottom: 0,
              right: 0,
              position: 'absolute',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              height:'72px'
            }}
          >
            <Typography variant="caption" component="div" style={{'color':'#000000','fontFamily':'Prompt,sans-serif','fontWeight':'500','fontSize':'1.0em'}}>
              {`${Math.round(value)}%`}
            </Typography>
          </Box>
        </Box>
      );
}

export default CircleProgressComponent;