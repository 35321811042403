import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import NotificationIcon from "../assets/cart/noti.svg";
import fluidText from "../utils/fluidText";
import { fluidRange } from "polished";
import luckyDoseLogo from "../assets/home/luckydose_logo_black.svg";
import emailLogo from "../assets/home/email.svg";
import lineLogo from "../assets/home/line.svg";
import telLogo from "../assets/home/tel.svg";
import facebookLogo from "../assets/home/facebookLogo.svg";
import { useTranslation } from "react-i18next";
import { handleFontFamily } from "../utils/utils";

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 600px) {
    width: 40vw;
    margin: auto;
    min-width: 500px;
  }
  //align-items:center;
`;

const NotificationImage = styled.img`
  width: 15.7vw;
  margin: 0vh auto;
  @media (min-width: 600px) {
    //width: 4.3vw;
    height: 6.5vh;
  }
`;

const HeadTextStyled = styled.div`
  //width:88.46vw;
  margin: 4vh auto 3vh auto;
  ${fluidText(22, 30)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 600;
  color: black;
  text-align: left;
  @media (min-width: 600px) {
    //width: 40vw;
    //min-width: 500px;
  }
`;

const TextStyled = styled.div`
  margin: 0vh auto;
  //width:82.3vw;
  ${fluidText(16, 24)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: center;
`;

const ContactTextStyledWrapper = styled.div`
  font-size: 0.6rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  z-index: 1;
  display: flex;
  //transform: translate(-70%, 0%);
  @media (min-width: 600px) {
    transform: translate(-70%, 0%);
    height: 4vh;
    align-items: center;
    font-size: 1rem;
  }
`;

const ContactTextStyled = styled.div`
  font-size: 0.5rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  z-index: 1;
  color: black;
  display: inline-block;
  margin-left: 1vw;
  margin-right: 1vw;
  @media (min-width: 600px) {
    font-size: 1rem;
  }
`;

const ContactLogo = styled.img`
  width: 3.5vh;
  height: 3.5vh;
  display: flex;
  cursor: pointer;
  z-index: 1;
  @media (min-width: 600px) {
    width: 4vh;
    height: 4vh;
  }
`;

const ContactLogoAndTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1.2vh;
  z-index: 1;
  @media (min-width: 600px) {
    margin-bottom: 0.5vh;
    margin-left: 1vw;
    margin-right: 0;
  }
`;

const ContactLogoWrapper = styled.div`
  //margin-right: 38.775vw;
  display: flex;
  z-index: 1;
  @media (min-width: 600px) {
    left: 50%;
    flex-direction: column;
    transform: translate(-30%, 0%);
  }
`;

const ContactLayout = styled.div`
  flex-direction: row;
  align-items: center;
  //justify-content: flex-end;
  margin: 2vh auto 4vh auto;
  display: flex;
  z-index: 1;
  // transform: translate(-22%,12vh);
  @media (min-width: 600px) {
    align-items: flex-start;
    //transform: translate(0,10vh);
  }
`;

const LuckyDoseLogo = styled.img`
  width: 61vw;
  margin: 4vh auto;
  height: auto;
  z-index: 1;
  //margin: 10vh auto 4vh auto;
  //margin: ${(props) => props.height * 0.1 + "px"} auto ${(props) =>
    props.height * 0.02 + "px"} auto;
  @media (min-width: 600px) {
    width: 20vw;
    min-width: 280px;
  }
`;

const MaintenancePage = () => {
  const { t } = useTranslation();

  const width = window.innerWidth;

  return (
    <ContentWrapper>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      >
        <NotificationImage src={NotificationIcon} />
        <HeadTextStyled>{t("ปิดปรับปรุงเซิร์ฟเวอร์")}</HeadTextStyled>
        {parseInt(width) < 600 ? (
          <TextStyled>
            {t("ต้องการสั่งซื้อ")}
            <br />
            {t("ติดต่อไลน์ @luckydose")}
          </TextStyled>
        ) : (
          <TextStyled>{t("ต้องการสั่งซื้อติดต่อไลน์ @luckydose")}</TextStyled>
        )}
        <LuckyDoseLogo src={luckyDoseLogo} />
        <ContactLayout>
          {parseInt(width) < 600 ? (
            <></>
          ) : (
            <ContactTextStyledWrapper>
              {t("ช่องทางติดต่อทีมงาน")}
            </ContactTextStyledWrapper>
          )}
          <ContactLogoWrapper>
            <ContactLogoAndTextWrapper>
              <a
                href="https://www.facebook.com/Luckydose.th"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ContactLogo src={facebookLogo} onClick={() => {}} />
              </a>
              {parseInt(width) < 600 ? (
                <></>
              ) : (
                <ContactTextStyled>
                  {t("facebook.com/Luckydose.th")}
                </ContactTextStyled>
              )}
            </ContactLogoAndTextWrapper>
            <ContactLogoAndTextWrapper>
              <ContactLogo
                src={lineLogo}
                onClick={() => {
                  window.location = "http://bit.ly/LineLuckyDose";
                }}
              />
              {parseInt(width) < 600 ? (
                <></>
              ) : (
                <ContactTextStyled>{t("@luckydose")}</ContactTextStyled>
              )}
            </ContactLogoAndTextWrapper>
            <ContactLogoAndTextWrapper>
              <ContactLogo
                src={emailLogo}
                onClick={() => {
                  window.location = "mailto:contact@luckydose.co";
                }}
              />
              {parseInt(width) < 600 ? (
                <></>
              ) : (
                <ContactTextStyled>
                  {t("contact@luckydose.co")}
                </ContactTextStyled>
              )}
            </ContactLogoAndTextWrapper>
          </ContactLogoWrapper>
        </ContactLayout>
        <TextStyled>{t("ขออภัยในความไม่สะดวก")}</TextStyled>
      </div>
    </ContentWrapper>
  );
};

export default MaintenancePage;
