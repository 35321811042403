import React, { useState } from "react";
import styled from "styled-components";

import { Default } from "react-spinners-css";
import CircleProgressComponent from "./CircularProgressbar";
import { useTranslation } from "react-i18next";
import { handleFontFamily } from "../utils/utils";

const TextStyled = styled.div`
  width: 71.84vw;
  margin: 1.5vh auto;
  font-size: 1.2rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: center;
`;

const ContentWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  z-index: 20;
`;

const SpinnerWrapper = styled(Default)`
  margin: 0 auto;
`;

const LoadingWrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const LoaderComponent = ({ value }) => {
  const { t } = useTranslation();
  return (
    <>
      {value ? (
        <ContentWrapper>
          <LoadingWrapper>
            <CircleProgressComponent value={value} />
            <TextStyled>{t("PaymentLoader.ระบบกำลังดำเนินการ")}</TextStyled>
          </LoadingWrapper>
        </ContentWrapper>
      ) : (
        <ContentWrapper>
          <LoadingWrapper>
            <SpinnerWrapper color="#E4202A" size={70} />
            <TextStyled>{t("PaymentLoader.กำลังดำเนินการ")}</TextStyled>
          </LoadingWrapper>
        </ContentWrapper>
      )}
    </>
  );
};

export default LoaderComponent;
