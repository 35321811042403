import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+SC:wght@100;300;400;500;700;900&display=swap');
  html {
    //height: ${(props) => (props.height ? props.height : "100%")};
    height: 100%;
    width: 100vw;
    overflow-x: hidden;
    //overflow-y:hidden;
    }
    body {
    font-family: ${(props) =>
      props.locale === "zh" ? "'Noto Sans SC', sans-serif" : "'Prompt', sans-serif"};
    font-style: normal;
    font-weight: 400;
    text-rendering: optimizeLegibility;
    min-height: 100%;
    width: 100%;
    height: 100%;
    //display: flex;
}
`;

export default GlobalStyle;
