import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { Default } from "react-spinners-css";
import {
  formList as formListAtom,
  state as stateAtom,
  contactID,
  artworkDatabase,
  referenceID as referenceIDAtom,
  orderID as orderIDAtom,
  dateDatabase as dateDatabaseAtom,
} from "../global/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import FooterComponent from "../components/FooterComponent";
import ContactLogoComponent from "../components/ContactLogoComponent";
import {
  sendMessageToCreateArtwork,
  getThaiMoonPhase,
  getTimeInQueue,
  handleFontFamily,
} from "../utils/utils";
import CartImg from "../assets/checkout/cart.svg";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import DoseartLogo from "../assets/checkout/doseartLogo_black.svg";
import { addCustomerToDatabase } from "../utils/utils";
import { format, utcToZonedTime } from "date-fns-tz";
import fluidText from "../utils/fluidText";
import { fluidRange } from "polished";
import { IS_IOS } from "../utils/support";
import luckyDoseLogo from "../assets/home/luckydose_logo_black.svg";
import demoVDO from "../assets/checkout/demoVDO.mp4";
import closeSVG from "../assets/gallery/cancelButton.svg";
import CircleProgressComponent from "../components/CircularProgressbar";
import { useSessionStorage } from "react-use";
import {
  sessionDateDatabase,
  sessionOrderID,
  sessionRefID,
  sessionState,
  sessionTotalAmount,
  sessionVoucher,
  session_indexUID,
} from "../utils/sessionStorage";
import { addImageToIndexedDB, doImageTest } from "../utils/IndexedDBStore";
import DownloadComponent from "../components/DownloadComponent";
import GalleryList from "../components/GalleryListComponent";
import { GalleryFilter, GalleryListWrapper } from "./GalleryPage/GalleryPage";
import { useContext } from "react";
import { UserContext } from "../utils/Context/userContext";
import { useTranslation } from "react-i18next";

const ContentWrapper = styled.div`
  //position: fixed;
  //top:0;
  //left:0;
  width: 100vw;
  height: 100%;
  /* background-color: rgb(255,255,255); */
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    width: 38vw;
    margin: 0 auto 20vh auto;
    min-width: 400px;
  }
`;

const LoaderTextStyled = styled.div`
  //width:71.84vw;
  margin: 1vh auto;
  font-size: 1.2em;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  @media (min-width: 600px) {
    margin: 1.5vh auto 4vh auto;
    font-size: 1.5rem;
  }
`;

const LoaderTextStyled2 = styled.div`
  //margin: 0 auto;
  font-size: 0.8em;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  @media (min-width: 600px) {
    font-size: 1.2rem;
  }
`;

const LoaderHighlightText = styled.div`
  color: #e4202a;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 600;
  font-size: 0.8em;
  @media (min-width: 600px) {
    font-size: 1.2rem;
  }
`;

const LoaderTextDescriptionWrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
`;

const SpinnerWrapper = styled(Default)`
  margin: 0 auto;
`;

const TipsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 88.46vw;
  margin: auto;
  margin-bottom: 2vh;
  @media (min-width: 600px) {
    width: 34vw;
    min-width: 450px;
    margin-bottom: 2vh;
  }
`;

const TipsTextStyled = styled.div`
  ${fluidText(12, 20)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: left;
  word-wrap: normal;
  @media (min-width: 600px) {
    //font-size: 1rem;
  }
  //margin-left : 5.1vw;
`;

export const GalleryCardWrapper = styled.div`
  width: 95%;
  margin: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  pointer-events: ${(props) => (props.isPreview ? "none" : "auto")};
  @media (min-width: 600px) {
    width: 100%;
    max-width: 450px;
    margin: ${(props) => (props.isCheckout ? "auto" : "3vh auto 5vh auto")};
  }
`;

export const GalleryWrapper = styled.div`
  display: grid;
  height: auto;
  /* grid-template-columns: repeat(auto-fit, minmax(25vw, 1fr)); */
  /* grid-template-columns: repeat(3, 12px); */
  grid-template-columns: auto auto;
  /* grid-auto-rows: 1px; */
  grid-row-end: span;
  justify-content: center;
  grid-gap: 0rem;
  /* grid-gap: 1em; */

  width: 100%;
  margin-bottom: 30%;
  @media (min-width: 600px) {
    width: 38vw;
    min-width: 430px;
    margin: 2vw auto 10vh auto;
  }
`;

const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  //margin: 0.75vw;
  opacity: ${(props) => (props.isSelected ? "30%" : "100%")};
  pointer-events: none;
  border-radius: 10px;
  @media (min-width: 600px) {
    width: 10vw;
    min-width: 118.42px;
    //margin: 0.25vw;
  }
`;

const PreviewImageWrapper = styled.div`
  position: relative;
  //height: 100%;
  display: inline-flex;
  cursor: pointer;
  margin-bottom: 4vw;
  margin-left: ${(props) => (props.position === "right" ? "0.5rem" : "4vw")};
  margin-right: ${(props) => (props.position === "right" ? "4vw" : "0.5rem")};
  @media (min-width: 600px) {
    margin-bottom: 2vw;
    margin-left: 2vw;
  }
`;

const LoaderContentWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  text-align: center;
`;

const LoadingWrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const HeadTextStyled = styled.div`
  //width:88.46vw;
  margin: 2vh auto 2vh auto;
  ${fluidText(24, 28)}
  //font-size: 1.5rem; 
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 300;
  color: black;
  text-align: center;
  @media (min-width: 600px) {
    //width: 38vw;
    margin: 3vh auto 3vh auto;
  }
`;

const DownloadImage = styled.img`
  //height: 55vh;
  width: 23vh;
  border-radius: 10px;
  margin: 0 auto 0 auto;
`;

const VideoWrapper = styled.video`
  width: 90vw;
  margin: auto;
`;

const VideoBGWrapper = styled.div`
  width: 100vw;
  height: 100%;
  //align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: 10;
`;

const CloseButton = styled.img`
  position: fixed;
  top: 4vh;
  right: 1vh;
  width: 5vh;
`;

const CheckoutPage = () => {
  const [isTransactionComplete, setTransaction] = useState(true);
  const [isLoading, updateLoading] = useState(true);
  const [state, updateState] = useRecoilState(stateAtom);
  //const [referenceID, updateRefID] = useSessionStorage(sessionRefID.key,sessionRefID.default);
  const [referenceID, updateRefID] = useState();
  const [orderID, updateOrderID] = useSessionStorage(
    sessionOrderID.key,
    sessionOrderID.default
  );
  const [indexUID] = useSessionStorage(
    session_indexUID.key,
    session_indexUID.default
  );
  const [total_amount, update_total_amount] = useSessionStorage(
    sessionTotalAmount.key,
    sessionTotalAmount.value
  );
  //const [,updateVoucher] = useSessionStorage(sessionVoucher.key,sessionVoucher.default)
  const { update_voucher, updateCouponCollection } = useContext(UserContext);
  const [isShowVDO, setShowVDO] = useState(false);
  const [isCanDownload, setCanDownload] = useState(false);
  const [dateDatabase, updateDateDatabase] = useRecoilState(dateDatabaseAtom);
  const [circularProgress, updateCircularProgress] = useState(0);
  const [autoDownload, updateAutoDownload] = useState(false);
  const [referenceIDs, updateArrayRefID] = useState([]);
  const [displayArray, updateDisplayArray] = useState([]); // for multiple ios download image
  const [previewImage, updatePreviewImage] = useState(false);
  const [previewIndex, updatePreviewIndex] = useState(0);
  const [width, setWidth] = useState(window.innerWidth);
  const [vh, updateVh] = useState(window.innerHeight);
  var isTouchmove = false;
  var currentTime = 0;
  var progressTime = 1;
  //const IS_IOS = true;
  var count = 0;

  var gCloudStorageURL = "https://storage.googleapis.com/doseart_asia_output/";
  var timeoutFunction;
  var intervalFunction;
  var loadingProgressRef = useRef();
  var delayShowRef = useRef();

  const { t } = useTranslation();

  useEffect(async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    updateRefID(urlParams.get("id"));
    updateState("WaitNonWaterMark");
    update_voucher("");
    updateCouponCollection([]);
    currentTime = 0;
    return () => {
      clearInterval(loadingProgressRef.current);
      if (delayShowRef.current) clearTimeout(delayShowRef);
    };
  }, []);

  useEffect(() => {
    if (referenceID != null) {
      var url = window.location.href;
      var idUrl = url.slice(url.indexOf("id=") + 3);
      var refIDs = idUrl.split("&");
      getTimeInQueue(refIDs.length).then((time) => {
        progressTime = time;
        loadingProgressRef.current = setInterval(countdownProgress, 100);
      });
      //saveCustomerdataToDatabase()

      if (refIDs.length > 1) {
        let colLeft = [],
          colRight = [];
        refIDs.map((value, index) => {
          if (index % 2 === 0) {
            colLeft = [
              ...colLeft,
              <PreviewImageWrapper position="left">
                <PreviewImage src={gCloudStorageURL + value} />
              </PreviewImageWrapper>,
            ];
          } else {
            colRight = [
              ...colRight,
              <PreviewImageWrapper position="right">
                <PreviewImage src={gCloudStorageURL + value} />
              </PreviewImageWrapper>,
            ];
          }
        });

        updateDisplayArray([colLeft, colRight]);
      }

      updateArrayRefID(refIDs);
      //timeoutFunction = setTimeout(checkIntervalURL, 5000)
    }
  }, [referenceID]);

  useEffect(() => {
    if (referenceIDs.length > 0)
      timeoutFunction = setTimeout(checkIntervalURL, 5000);
  }, [referenceIDs]);

  useEffect(async () => {
    if (state == "UploadSuccess" && !IS_IOS) {
      const dowloadTimer = setTimeout(downloadImage, 1000);
      for (var x = 0; x < referenceIDs.length; x++) {
        var url = gCloudStorageURL + referenceIDs[x];
        const res = await fetch(url);
        const blob = await res.blob();
        addImageToIndexedDB(blob, referenceIDs[x]);
      }

      return () => clearTimeout(dowloadTimer);
    } else if (state == "UploadSuccess" && IS_IOS) {
      for (var x = 0; x < referenceIDs.length; x++) {
        var url = gCloudStorageURL + referenceIDs[x];
        const res = await fetch(url);
        const blob = await res.blob();
        // addImageToIndexedDB(blob, referenceIDs[x]);
      }
      updateState("DownloadSuccess");
    }
    //don't know what make state change to payment at last
    if (state == "Payment") updateState("DownloadSuccess");
  }, [state]);

  const countdownProgress = () => {
    currentTime += 0.1;
    var progression = Math.round((currentTime / progressTime) * 100);
    if (progression >= 95) progression = 95;
    updateCircularProgress(progression);
  };

  function checkIntervalURL() {
    var fetchInterval = 1000;
    intervalFunction = setInterval(checkURLImages, fetchInterval);
  }

  function checkURLImage() {
    var http = new XMLHttpRequest();
    var previewImageURL = gCloudStorageURL + referenceID;
    http.onreadystatechange = function () {
      if (this.readyState == 4) {
        // Typical action to be performed when the document is ready:
        if (http.status != 404) {
          updateLoading(false);
          clearInterval(intervalFunction);
          clearInterval(loadingProgressRef.current);
          setCanDownload(true);
          updateState("UploadSuccess");
        }
      }
    };
    http.open("HEAD", previewImageURL, false);
    http.send();
  }

  function checkURLImages() {
    var promises = [];
    referenceIDs.forEach((element, index) => {
      promises.push(
        new Promise((resolve, reject) => {
          var http = new XMLHttpRequest();
          var previewImageURL = gCloudStorageURL + element;
          http.onreadystatechange = function () {
            if (this.readyState == 4) {
              // Typical action to be performed when the document is ready:
              if (http.status != 404) {
                resolve("true");
              } else reject("false");
            }
          };
          http.open("HEAD", previewImageURL, false);
          http.send();
        })
      );
    });
    Promise.all(promises)
      .then(() => {
        clearInterval(intervalFunction);
        clearInterval(loadingProgressRef.current);
        delayShowRef.current = setTimeout(showAllImage, 2000);
      })
      .catch(() => {});
  }

  const showAllImage = () => {
    updateLoading(false);
    setCanDownload(true);
    updateState("UploadSuccess");
  };

  function checkCanDownloadImage() {
    var http = new XMLHttpRequest();
    var previewImageURL = gCloudStorageURL + referenceID;
    http.onreadystatechange = function () {
      if (this.readyState == 4) {
        // Typical action to be performed when the document is ready:
        if (http.status != 404) {
          setCanDownload(true);
        }
      }
    };
    http.open("HEAD", previewImageURL, false);
    http.send();
  }

  const downloadImage = async () => {
    if (!isCanDownload) return;
    updateState("DownloadSuccess");
    for (var x = 0; x < referenceIDs.length; x++) {
      var url = gCloudStorageURL + referenceIDs[x];
      const res = await fetch(url);
      const blob = await res.blob();
      const downloadUrl = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = `${referenceIDs[x]}.jpg`;
      anchor.target = "_blank";
      anchor.click();
    }
  };

  const ShowVideo = () => {
    return (
      <VideoBGWrapper>
        <CloseButton
          src={closeSVG}
          onClick={() => {
            setShowVDO(false);
          }}
        />
        <VideoWrapper src={demoVDO} controls type="video/mp4" />
      </VideoBGWrapper>
    );
  };

  const Loading = () => {
    return (
      <LoaderContentWrapper>
        <LoadingWrapper>
          <CircleProgressComponent value={circularProgress} />

          <LoaderTextStyled>
            {t("CheckoutPage.รอรับภาพ")}
            <br />
            {t("CheckoutPage.กรุณาอย่าปิดหน้าต่างนี้")}
          </LoaderTextStyled>
          <LoaderTextDescriptionWrapper>
            <LoaderTextStyled2>
              {t("CheckoutPage.ระบบกำลังสร้างภาพพื้นหลังของคุณ")}
            </LoaderTextStyled2>
          </LoaderTextDescriptionWrapper>
        </LoadingWrapper>
      </LoaderContentWrapper>
    );
  };

  const imageList = referenceIDs.map((value, index) => (
    <PreviewImageWrapper>
      <PreviewImage src={gCloudStorageURL + value} />
    </PreviewImageWrapper>
  ));

  const isTouchEvent = (event) => {
    return "touches" in event;
  };

  const preventDefault = (event) => {
    if (!isTouchEvent(event)) return;

    if (event.touches.length < 2 && event.preventDefault) {
      event.preventDefault();
    }
  };

  const onClick = (i) => {
    if (!isTouchmove) {
      onClickItem(i);
    }
    isTouchmove = false;
  };

  const onClickRight = (i) => {
    if (!isTouchmove) {
      onClickItem(i * 2 + 1);
    }
    isTouchmove = false;
  };

  const onClickLeft = (i) => {
    if (!isTouchmove) {
      onClickItem(i * 2);
    }
    isTouchmove = false;
  };

  const onLongPress = (i) => {};

  const onClickClose = () => {
    updatePreviewImage(false);
    // window.removeEventListener("touchmove", preventDefault, {
    //   passive: true,
    // });
  };

  const onClickItem = React.useCallback((i) => {
    if (!isTouchmove) {
      updatePreviewIndex(i);
      updatePreviewImage(true);
      //setLoading(true);
      // window.addEventListener("touchmove", preventDefault, { passive: false });
    }
    isTouchmove = false;
  }, []);

  // start handle touchmove
  useEffect(() => {
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);
    return () => {
      window.removeEventListener("touchmove", preventDefault, {
        passive: true,
      });
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);

  const handleTouchMove = () => {
    isTouchmove = true;
  };
  const handleTouchEnd = () => {
    isTouchmove = false;
  };
  // end handle touchmove

  if (IS_IOS) {
    //if (true) {
    return (
      <div style={{ display: "flex", width: "100vw" }}>
        {isLoading ? (
          <Loading />
        ) : (
          <ContentWrapper>
            {isShowVDO ? <ShowVideo /> : <></>}
            <HeadTextStyled>
              {t("PreviewGenerate.กรุณาดาวน์โหลดภาพพื้นหลัง")}
            </HeadTextStyled>
            {referenceIDs.length > 1 ? (
              <>
                <LoaderTextStyled>
                  <span style={{ color: "#E4202A" }}>
                    {t("PreviewGenerate.กดภาพที่สั่งซื้อแต่ละภาพ")}
                  </span>{" "}
                </LoaderTextStyled>
                <div style={{ margin: "0 auto" }}>
                  <LoaderTextStyled2>
                    {t(
                      "PreviewGenerate.หากมีข้อสงสัยเกี่ยวกับวิธีดาวน์โหลดภาพ กด"
                    )}{" "}
                    <span
                      style={{ textDecoration: "underline" }}
                      onClick={() => {
                        setShowVDO(true);
                      }}
                    >
                      {t("PreviewGenerate.ที่นี่")}
                    </span>
                  </LoaderTextStyled2>
                </div>
                <GalleryCardWrapper>
                  <GalleryWrapper position="left">
                    <GalleryList
                      items={displayArray.length > 0 && displayArray[0]}
                      onLongPressItem={onLongPress}
                      onClickItem={onClickLeft}
                      isPreview={previewImage}
                      isCheckout={true}
                      position="left"
                    ></GalleryList>
                  </GalleryWrapper>
                  <GalleryWrapper position="right">
                    <GalleryList
                      items={displayArray.length > 0 && displayArray[1]}
                      onLongPressItem={onLongPress}
                      onClickItem={onClickRight}
                      isPreview={previewImage}
                      isCheckout={true}
                      position="right"
                    ></GalleryList>
                  </GalleryWrapper>
                </GalleryCardWrapper>
                <ContactLogoComponent />
                {previewImage ? (
                  //<LargePreviewImage />
                  <DownloadComponent
                    imgSrc={gCloudStorageURL + referenceIDs[previewIndex]}
                    onClickClose={onClickClose}
                    width={width}
                    vh={vh}
                  />
                ) : (
                  <FooterComponent />
                )}
              </>
            ) : (
              <>
                <LoaderTextStyled>
                  <span style={{ color: "#E4202A" }}>
                    {t("PreviewGenerate.กดค้างที่ภาพเพื่อดาวน์โหลด")}
                  </span>{" "}
                </LoaderTextStyled>
                <div style={{ margin: "0 auto 4vh auto" }}>
                  <LoaderTextStyled2>
                    {t(
                      "PreviewGenerate.หากมีข้อสงสัยเกี่ยวกับวิธีดาวน์โหลดภาพ กด"
                    )}{" "}
                    <span
                      style={{ textDecoration: "underline" }}
                      onClick={() => {
                        setShowVDO(true);
                      }}
                    >
                      {t("PreviewGenerate.ที่นี่")}
                    </span>
                  </LoaderTextStyled2>
                </div>
                <DownloadImage src={gCloudStorageURL + referenceID} />
                <ContactLogoComponent />
                <FooterComponent />
              </>
            )}
          </ContentWrapper>
        )}
      </div>
    );
  } else {
    return (
      <div style={{ display: "flex", width: "100vw" }}>
        {isLoading ? (
          <Loading />
        ) : (
          <ContentWrapper>
            <HeadTextStyled>
              {t("CheckoutPage.ยืนยันการชำระเงินสำเร็จ")}
            </HeadTextStyled>
            
            {referenceIDs.length > 1 ? (
              <>
                <GalleryCardWrapper isCheckout={true}>
                  <GalleryListWrapper position="left">
                    <GalleryList
                      items={displayArray.length > 0 && displayArray[0]}
                      onLongPressItem={onLongPress}
                      onClickItem={onClickLeft}
                      isPreview={previewImage}
                      isCheckout={true}
                      position="left"
                    ></GalleryList>
                  </GalleryListWrapper>
                  <GalleryListWrapper position="right">
                    <GalleryList
                      items={displayArray.length > 0 && displayArray[1]}
                      onLongPressItem={onLongPress}
                      onClickItem={onClickRight}
                      isPreview={previewImage}
                      isCheckout={true}
                      position="right"
                    ></GalleryList>
                  </GalleryListWrapper>
                </GalleryCardWrapper>
                <LoaderTextStyled>
                  <span style={{ color: "#E4202A" }}>
                    {t("PreviewGenerate.กดภาพที่สั่งซื้อแต่ละภาพ")}
                  </span>{" "}
                </LoaderTextStyled>
                <ContactLogoComponent />
                {previewImage ? (
                  //<LargePreviewImage />
                  <DownloadComponent
                    imgSrc={gCloudStorageURL + referenceIDs[previewIndex]}
                    onClickClose={onClickClose}
                    width={width}
                    vh={vh}
                  />
                ) : (
                  <FooterComponent
                    addOnClickNextFunction={() => {
                      downloadImage();
                    }}
                  />
                )}
              </>
            ) : (
              <>
                <DownloadImage src={gCloudStorageURL + referenceID} />
                <ContactLogoComponent />
                <FooterComponent
                  addOnClickNextFunction={() => {
                    downloadImage();
                  }}
                />
              </>
            )}
          </ContentWrapper>
        )}
      </div>
    );
  }
  /*else {
    return (
      <div style={{ display: "flex", width: "100vw" }}>
        {isLoading ? (
          <Loading />
        ) : (
          <ContentWrapper>
            <HeadTextStyled>ยืนยันการชำระเงินสำเร็จ</HeadTextStyled>
            <CardWrapper>
              <CartImage src={CartImg} />
              <HeaderCardTextStyled>ขอบคุณสำหรับการสั่งซื้อ</HeaderCardTextStyled>
              <CardTextStyled>สามารถดูรูปภาพของคุณได้ใน "คลังภาพ"</CardTextStyled>
              <CardTextStyled>
                หากภาพไม่ดาวน์โหลดอัตโนมัติ
                <br />
                สามารถกด "ดาวน์โหลดภาพ" ด้านล่างได้
              </CardTextStyled>
            </CardWrapper>
            <ContactLogoComponent/>
            <FooterComponent
              addOnClickNextFunction={() => {
                downloadImage();
              }}
            />
          </ContentWrapper>
        )}
      </div>
    );
  }*/
};

export default CheckoutPage;
