import React, { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { HeadTextStyled } from "../HomePage/styles";
import fluidText from "../../utils/fluidText";
import { useRecoilState } from "recoil";
import { state as stateAtom } from "../../global/atom";
import FooterComponent from "../../components/FooterComponent";
import TextField from "@material-ui/core/TextField";
import { fluidRange } from "polished";
import { useNavigate } from "react-router-dom";
import {
  session_subscription_selection_package,
  session_subscription_user,
  useSessionStorage,
} from "../../utils/sessionStorage";
import {
  getPackageDetail,
  getUserPackage,
  handleFontFamily,
  sendErrorToChatbot,
} from "../../utils/utils";
import { getSubscriberInfo } from "../../utils/IndexedDBStore";
import { UserContext } from "../../utils/Context/userContext";
import useNavigationHandling from "../../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";

const SelectionButton = styled.button`
  width: 90%;
  max-width: 360px;
  height: 6rem;

  margin: 0.3rem auto;

  border: none;
  border-radius: 16px;

  font-size: 32px;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: normal;
  color: white;

  background-color: #aa0e19;
  box-shadow: 0px 4px 16px rgba(92, 92, 92, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  div {
    width: 97%;
    height: 86%;
    border: white 1px solid;
    border-radius: 12px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    div {
      position: relative;
      width: 3rem;
      height: 3rem;
      margin: 1rem;
    }
    img {
      width: 3rem;
      height: auto;
      margin: 1rem;
    }
    h3 {
      margin: auto;
      font-size: 2rem;
      ${handleFontFamily()}
      font-style: normal;
      font-weight: normal;
      line-height: 100%;
    }
    span {
      width: auto;
      margin: 0;
      height: 85%;

      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      h3 {
        margin: 0;
        font-size: 1.8rem;
        ${handleFontFamily()}
        font-style: normal;
        font-weight: normal;
        line-height: 100%;
      }
      p {
        margin: 0;
        font-size: 1.2rem;
        ${handleFontFamily()}
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
      }
    }
  }

  :hover {
    /* background-color: black; */
    cursor: pointer;
    color: #c8b280;
  }
  /* transition: all 0.5s; */
`;

const SelectPackagePage = () => {
  const { navigateHandler } = useNavigationHandling();
  const [subscriptionUser] = useSessionStorage(
    session_subscription_user.key,
    session_subscription_user.default
  );
  const { subscriberInfo, updateSubscriberInfo } = useContext(UserContext);
  const [goToUserPage, updateGoToUserPage] = useState(false);
  const [selectPackageDetail, updateSelectedPackageDetail] = useSessionStorage(
    session_subscription_selection_package.key,
    session_subscription_selection_package.default
  );
  const { t } = useTranslation();
  const [debugText, updateDebugText] = useState("");
  const UserPackage = subscriptionUser?.active_package_ids.map((item) => {
    return (
      <SelectionButton
        onClick={() => onClickPackage(item.id)}
      >
        <div>
          <h3>
            แพ็กเลข
            {item.id == "1238"
              ? t("เศรษฐี 456")
              : item.id == "1239"
              ? t("มังกร 789")
              : t("เทวดา 999")}
          </h3>
        </div>
      </SelectionButton>
    );
  });

  useEffect(() => {
    if (!subscriptionUser || !subscriptionUser.phone_number)
      navigateHandler("/aispostpaid/login");
  }, []);

  useEffect(() => {
    if (goToUserPage && subscriberInfo && subscriberInfo.birthYear) {
      navigateHandler("/aispostpaid/user");
    }
  }, [subscriberInfo, goToUserPage]);

  const onClickPackage = async(item)=>{
    //sendErrorToChatbot(`${item}`)
    getUserPackage(item,subscriptionUser.phone_number).then((user_package)=>{
      //sendErrorToChatbot(`getUserPackage`)
      if(!user_package){
        sendErrorToChatbot(`${subscriptionUser.phone_number} has no package with item ${item}`)
        updateDebugText('no user package')
      }
      getPackageDetail(item).then((package_detail)=>{
        //sendErrorToChatbot(`getPackageDetail`)
        if(!package_detail){
          updateDebugText('no package detail')
          sendErrorToChatbot(`${subscriptionUser.phone_number} has no package_detail ${item}`)
        }
        var promotion_end_date = new Date()
        for(let i =0;i<subscriptionUser.active_package_ids.length;i++){
          if(subscriptionUser.active_package_ids[i].id==item)
          promotion_end_date = new Date(subscriptionUser.active_package_ids[i].promotion_end)
        }
        //console.log(subscriptionUser)
        //var start_date = new Date(2023,5,19)
        var today_date =new Date()
        var promotion_nMonth = promotion_end_date.getMonth()+promotion_end_date.getFullYear()*12
        var today_nMonth = today_date.getMonth()+today_date.getFullYear()*12
        var diff_month = (today_nMonth-promotion_nMonth+3).toString()
        var show_artwork = package_detail.artworks
        if(today_nMonth-promotion_nMonth+3 <3 && diff_month in package_detail.fix_aw){
          for(let i =0;i<package_detail.fix_aw[diff_month].length;i++){
            show_artwork[i] = package_detail.fix_aw[diff_month][i]
          }
        }
        var detail = package_detail
        detail.artworks = show_artwork
        detail.id = item
        detail.generated_id = user_package.generated_image_id
        detail.timestamp = user_package.timestamp
        detail.generated_timestamp = user_package.generated_timestamp
        updateSelectedPackageDetail(detail)
        //sendErrorToChatbot('updateSelectedPackageDetail')
        getSubscriberInfo(subscriptionUser.phone_number).then((result) => {
          //sendErrorToChatbot('getSubscriberInfo')
          if (result) {
            updateDebugText('go to user page')
            var sub_info = {
              name: result.name,
              surname: result.surname,
              zodiacId: result.zodiacId,
              birthDate: result.birthDate,
              birthMonth: result.birthMonth,
              birthYear: result.birthYear,
              chinese_zodiac: result.chinese_zodiac
            }
            updateSubscriberInfo({ ...sub_info })
            //sendErrorToChatbot('update subscriberInfo')s
            updateGoToUserPage(true)
          }
        else{
          //sendErrorToChatbot('go to information page')
          navigateHandler('/aispostpaid/information')
        }
      }).catch((e)=>{
        console.log('error')
        sendErrorToChatbot('getSubscriberInfo error')
        sendErrorToChatbot(e)
      })
      })
    }).catch((e)=>{
      sendErrorToChatbot(`${subscriptionUser.phone_number} has error when getPackage with item : ${item}`)
    })
  }


  return (
    <div
      style={{
        marginTop: "calc(3.2rem + 2vh)",
        height: "calc(100% - 3.2rem - 2vh)",
      }}
    >
      <HeadTextStyled>{t("เลือกเพื่อเข้าสู่หน้าแพ็กเกจ")}</HeadTextStyled>
      {UserPackage}
    </div>
  );
};

export default SelectPackagePage;
