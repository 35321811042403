import React, { useEffect, useState } from "react";
import {
  SectionWrapper,
  NewAlert,
  TitleWrapper,
  DescriptionWrapper,
  LinkWrapper,
  TitleContainer,
  CouponSectionWrapper,
  ContactLayout,
  ContactTextStyledWrapper,
  ContactLogoWrapper,
  ContactLogoAndTextWrapper,
  ContactLogo,
  ContactTextStyled,
} from "./styles";
import { useNavigate, useParams } from "react-router-dom";

import { useRecoilState, useResetRecoilState } from "recoil";
import {
  collections_atom,
  collection_selection_info as collection_selection_info_atom,
  selectEditCard,
  nProduct,
  bundleTagPrice,
  multple_attribute_selection,
  edit_card_state,
  selectedIndex,
  order_type_id,
  multiple_index_selection,
  multiple_url_selection,
  package_id_selection_atom,
  prevBundleTagPrice,
  artwork_show_birthdate,
  filterAW,
  state as stateAtom,
} from "../../global/atom";
import axios from "axios";
import NavLogo, { LUCKYDOSE_BLACK } from "../../components/NavLogo";
import {
  checkRefferURL,
  COLLECTION_API_URL,
  DEPLOY_VERSION,
  fetchAndHandleData,
  handleNewLine,
  sendEvent,
} from "../../utils/utils";
import TagManager from "react-gtm-module";
import {
  reffer_url,
  select_package,
  sessionVoucher,
  useSessionStorage,
  package_name_detail,
  filterCheck,
  session_select_edit_card,
  session_nProducts,
  session_bundle_tag_price,
  session_prev_bundle_tag_price,
  session_multple_attribute_selection,
  session_multiple_index_selection,
  session_multiple_url_selection,
  session_edit_card_state,
  session_select_index,
  session_order_type_id,
  session_filter_aw,
  session_package_id_selection_atom,
  session_artwork_show_birthdate,
  session_collection_selection_info,
  sessionRefID,
  session_prev_form_list,
  sessionReferenceOrder,
  sessionClientID,
} from "../../utils/sessionStorage";
import { Link } from "react-router-dom";

import emailLogo from "../../assets/home/email.svg";
import lineLogo from "../../assets/home/line.svg";
import telLogo from "../../assets/home/tel.svg";
import facebookLogo from "../../assets/home/facebookLogo.svg";
import ReactPixel from "react-facebook-pixel";
import gtag, { install } from "ga-gtag";
import NotificationCard from "../../components/NotificationCard";
import {
  checkHasPurchasedHistory,
  deleteOrderInIndexedDB,
  getMaxOrderIndex,
} from "../../utils/IndexedDBStore";
import { useContext } from "react";
import { UserContext } from "../../utils/Context/userContext";
import useNavigationHandling from "../../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";

const emptyFormFill = {
  fill: false,
  name: "",
  surname: "",
  zodiacId: "",
  birthDate: "",
  birthMonth: "",
  birthYear: "",
  showBirthDate: false,
  selectImageId: "",
  selectImage: "",
  id: "",
};

/**
 * read data
 * collection_selections
 * sort by package_sections_id
 * @returns
 */
const AisCollectionGroup = () => {
  const {navigateHandler} = useNavigationHandling();
  const { collection_tag } = useParams();
  const [collections, update_collections] = useState();
  const [state, updateState] = useRecoilState(stateAtom);
  const [, updateEditCard] = useSessionStorage(
    session_select_edit_card.key,
    session_select_edit_card.default
  );
  const [, updateNProducts] = useSessionStorage(
    session_nProducts.key,
    session_nProducts.default
  );
  const [collection_selection_info, update_collection_selection_info] =
    useSessionStorage(
      session_collection_selection_info.key,
      session_collection_selection_info.default
    );
  const [, updateBundleTagPrice] = useSessionStorage(
    session_bundle_tag_price.key,
    session_bundle_tag_price.default
  );
  const [, update_buffer_bundleTagPrice] = useSessionStorage(
    session_prev_bundle_tag_price.key,
    session_prev_bundle_tag_price.default
  );
  const [, update_select_package] = useSessionStorage(
    select_package.key,
    select_package.default
  );
  const [changePage_link, set_changePage_link] = useState("");
  const [refferURL, update_refferURL] = useState();
  const [session_reffer_url, update_session_reffer_url] = useSessionStorage(
    reffer_url.key,
    reffer_url.default
  );
  const [, updateMultipleAttribute] = useSessionStorage(
    session_multple_attribute_selection.key,
    session_multple_attribute_selection.default
  );
  const [, updateMutipleIndexSelection] = useSessionStorage(
    session_multiple_index_selection.key,
    session_multiple_index_selection.default
  );
  const [, updateURLSelection] = useSessionStorage(
    session_multiple_url_selection.key,
    session_multiple_url_selection.default
  );
  const [, updatePrevList] = useSessionStorage(
    session_prev_form_list.key,
    session_prev_form_list.default
  );
  const [, update_edit_card_state] = useSessionStorage(
    session_edit_card_state.key,
    session_edit_card_state.default
  );
  const [, updateSelectedIndex] = useSessionStorage(
    session_select_index.key,
    session_select_index.default
  );
  const [, update_oreder_type_id] = useSessionStorage(
    session_order_type_id.key,
    session_order_type_id.default
  );
  const [, updateFilterAW] = useSessionStorage(
    session_filter_aw.key,
    session_filter_aw.default
  );
  const [, update_package_id_selection] = useSessionStorage(
    session_package_id_selection_atom.key,
    session_package_id_selection_atom.default
  );
  const [, update_artwork_show_birthDate] = useSessionStorage(
    session_artwork_show_birthdate.key,
    session_artwork_show_birthdate.default
  );
  //const [, updateVoucher] = useSessionStorage(sessionVoucher.key, sessionVoucher.default);
  const { update_voucher, updateFormList } = useContext(UserContext);
  const [, updateFilter] = useSessionStorage(
    filterCheck.key,
    filterCheck.default
  );
  const [, updateRefID] = useSessionStorage(
    sessionRefID.key,
    sessionRefID.default
  );
  const [, updateReferenceOrder] = useSessionStorage(
    sessionReferenceOrder.key,
    sessionReferenceOrder.default
  );
  const [, update_package_name_detail] = useSessionStorage(
    package_name_detail.key,
    package_name_detail.default
  );
  const [, update_session_client_id] = useSessionStorage(
    sessionClientID.key,
    sessionClientID.default
  );

  const { t } = useTranslation();

  const width = window.innerWidth;

  const handleChangePage = (collection_obj) => {
    update_collection_selection_info(collection_obj);
    set_changePage_link(collection_obj.link);
  };
  useEffect(() => {
    if (changePage_link && collection_selection_info.collection_name) {
      navigateHandler(changePage_link);
    }
  }, [collection_selection_info]);

  useEffect(() => {
    window.scrollTo(0, 0);
    // clear state
    resetState();
    var MEASUREMENT_ID = "G-5TM9EK429P";
    if (DEPLOY_VERSION == "PROD") MEASUREMENT_ID = "G-2PCQ99MS9K";
    install(MEASUREMENT_ID);

    gtag("get", MEASUREMENT_ID, "client_id", (field) => {
      update_session_client_id(field);
    });
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("refID")) {
      var ref_url = urlParams.get("refID");
      checkRefferURL(ref_url).then((res) => {
        if (res.result) {
          if (session_reffer_url == "") {
            if (ref_url == "BbJ4VowBI3pLuupSP45n") {
              TagManager.dataLayer({
                dataLayer: {
                  event: "true_pageview",
                },
              });
            }
            sendEvent(ref_url, "page_view");
          }
          update_session_reffer_url(ref_url);
        }
      });
    }
    /*TagManager.dataLayer({
      dataLayer: {
        event: 'view_content',
        collection_id:collection_id,
        refID : session_reffer_url,
      },
    });*/
  }, []);

  const resetState = () => {
    // clear state
    updateState("group_collection");
    updateNProducts("");
    updateBundleTagPrice("");
    update_buffer_bundleTagPrice("");
    updateMultipleAttribute([]);
    update_edit_card_state("");
    updateSelectedIndex("");
    update_oreder_type_id("");
    update_package_id_selection("");
    updateMutipleIndexSelection([]);
    updateFormList([emptyFormFill]);
    updatePrevList([emptyFormFill]);
    updateURLSelection([]);
    updateMultipleAttribute([]);
    update_artwork_show_birthDate("");
    updateReferenceOrder("");
    update_package_name_detail({
      package_name: "",
      icon: "",
    });
    updateFilter("");
    updateFilterAW(new Set());
  };

  return (
    <div style={{ paddingTop: "3rem" }}>
      <SectionWrapper
        jpg_small={
          "https://storage.googleapis.com/doseart_banner/AIS_Subscription/ais_subscription_small.jpg"
        }
        jpg_med={
          "https://storage.googleapis.com/doseart_banner/AIS_Subscription/ais_subscription_medium.jpg"
        }
        jpg_large={
          "https://storage.googleapis.com/doseart_banner/AIS_Subscription/ais_subscription_large.jpg"
        }
      >
        <TitleContainer>
          <TitleWrapper font_theme="light">
            {t("สิทธิพิเศษ ! สำหรับลูกค้าเอไอเอส")}
          </TitleWrapper>
          <DescriptionWrapper font_theme="light">
            {t("ด้วยลัคกี้วอลเปเปอร์ ออกแบบเฉพาะคุณเท่านั้น!")}
          </DescriptionWrapper>
          {/* <div className="price-wrapper grey">From $999.</div> */}
          <LinkWrapper>
            <ul>
              <li onClick={() => navigateHandler("/ais/login")} className={"ais"}>
                <p>{t("คลิก")}</p>
              </li>
            </ul>
          </LinkWrapper>
        </TitleContainer>
      </SectionWrapper>
      {<SectionWrapper
        jpg_small={
          "https://storage.googleapis.com/doseart_banner/aismongkol/aismongkol_banner_small.jpg"
        }
        jpg_med={
          "https://storage.googleapis.com/doseart_banner/aismongkol/aismongkol_banner_medium.jpgg"
        }
        jpg_large={
          "https://storage.googleapis.com/doseart_banner/aismongkol/aismongkol_banner_large.jpg"
        }
      >
        <TitleContainer>
          <TitleWrapper font_theme="light">
            {t("แพ็กมหามงคล")}
          </TitleWrapper>
          <DescriptionWrapper font_theme="light">
            {t("เสริมพลังชีวิตแบบไร้ขีดจำกัด")}
          </DescriptionWrapper>
          <LinkWrapper>
            <ul>
              <li onClick={() => navigateHandler("/aispostpaid/login")} className={"ais"}>
                <p>{t("คลิก")}</p>
              </li>
            </ul>
          </LinkWrapper>
        </TitleContainer>
      </SectionWrapper>}
      {/*<SectionWrapper
        jpg_small={
          "https://storage.googleapis.com/doseart_banner/aisalc%20/aisalc_banner_small.jpg"
        }
        jpg_med={
          "https://storage.googleapis.com/doseart_banner/aisalc%20/aisalc_banner_medium.jpg"
        }
        jpg_large={
          "https://storage.googleapis.com/doseart_banner/aisalc%20/aisalc_banner_large.jpg"
        }
      >
        <TitleContainer>
          <TitleWrapper font_theme="light">
            {t("ราคาพิเศษสำหรับลูกค้าเอไอเอส")}
          </TitleWrapper>
          <DescriptionWrapper font_theme="light">
            {t("เป็นเจ้าของลัคกี้วอลเปเปอร์ได้ง่ายๆ")}
          </DescriptionWrapper>
          <LinkWrapper>
            <ul>
              <li onClick={() => navigateHandler("/aisalc")} className={"ais"}>
                <p>{t("คลิก")}</p>
              </li>
            </ul>
          </LinkWrapper>
        </TitleContainer>
      </SectionWrapper>*/}

      {collections && (
        <ContactLayout>
          <ContactTextStyledWrapper>{LUCKYDOSE_BLACK}</ContactTextStyledWrapper>
          <ContactLogoWrapper>
            <ContactLogoAndTextWrapper>
              <a
                href="https://www.facebook.com/Luckydose.th"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ContactLogo src={facebookLogo} onClick={() => {}} />
              </a>
              {parseInt(width) < 600 ? (
                <></>
              ) : (
                <ContactTextStyled>facebook.com/Luckydose.th</ContactTextStyled>
              )}
            </ContactLogoAndTextWrapper>
            <ContactLogoAndTextWrapper>
              <a
                href="https://line.me/R/ti/p/@609hffrs?from=page"
                target="_blank"
                rel="noopener noreferrer"
              >
                <ContactLogo
                  src={lineLogo}
                  onClick={() => {
                    //window.location = "https://line.me/R/ti/p/@609hffrs?from=page";
                  }}
                />
              </a>
              {parseInt(width) < 600 ? (
                <></>
              ) : (
                <ContactTextStyled>@luckydose</ContactTextStyled>
              )}
            </ContactLogoAndTextWrapper>
            <ContactLogoAndTextWrapper>
              <ContactLogo
                src={emailLogo}
                onClick={() => {
                  window.location = "mailto:contact@luckydose.co";
                }}
              />
              {parseInt(width) < 600 ? (
                <></>
              ) : (
                <ContactTextStyled>contact@luckydose.co</ContactTextStyled>
              )}
            </ContactLogoAndTextWrapper>
          </ContactLogoWrapper>
        </ContactLayout>
      )}
    </div>
  );
};

export default AisCollectionGroup;
