import React, { useEffect, useState } from "react";
import styled from "styled-components";
import DoseartButton from "./DoseartButton";
import NotificationIcon from "../assets/cart/noti.svg";
import { useRecoilValue } from "recoil";

import { use100vh } from "react-div-100vh";
import { IS_ANDROID, IS_IOS } from "../utils/support";
import fluidText from "../utils/fluidText";
import { fluidRange } from "polished";
import { useTranslation } from "react-i18next";
import { handleFontFamily } from "../utils/utils";
const ContentWrapper = styled.div`
  width: 100vw;
  height: 100%;
  //align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: 10;
`;

const HeadTextStyled = styled.div`
  //width:71.84vw;
  margin: 0vh auto 1vh auto;
  ${fluidText(20, 25)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 600;
  color: black;
  text-align: ${(props) => (props.isPayment ? "left" : "center")};
  @media (min-width: 600px) {
    width: 24vw;
    //font-size: 1.8rem;
    min-width: 400px;
  }
`;

const TextStyled = styled.div`
  //width:71.84vw;
  margin: 1vh auto 2vh auto;
  ${fluidText(16, 22)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: ${(props) => (props.isPayment ? "left" : "center")};
  @media (min-width: 600px) {
    width: 24vw;
    font-size: 1.4rem;
    min-width: 400px;
  }
`;

const CardWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  width: 90vw;
  height: min-content;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  bottom: 0;
  border-radius: 15px;
  //padding : 0 10px;
  flex-direction: column;
  display: flex;
  @media (min-width: 600px) {
    width: 24vw;
    min-width: 400px;
    //padding: 0 1vw;
  }
`;

const NotificationImage = styled.img`
  width: 15.7vw;
  margin: 3vh auto;
  @media (min-width: 600px) {
    //width: 4.3vw;
    height: 6.5vh;
    margin: 3.4vh auto;
  }
`;

const ButtonWrapper = styled.div`
  width: 90vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 1vh auto 3vw auto;
  @media (min-width: 600px) {
    width: 100%;
    margin: 1vh auto 1.5vw auto;
  }
`;

const PaymentNotiWrapper = styled.div`
  display: flex;
  padding: 1vh auto 1vh auto;
  flex-direction: column;
`;

const NotificationCard = ({
  onClickBack,
  onClickNext,
  height,
  state,
  payment,
}) => {
  const { t } = useTranslation();
  //const state = useRecoilValue(state)
  const width = window.innerWidth;

  return (
    <ContentWrapper>
      <CardWrapper>
        {state == "CartFillComplete" ? (
          <>
            <NotificationImage src={NotificationIcon} />
            <HeadTextStyled>{t("โปรดตรวจสอบความถูกต้อง")}</HeadTextStyled>
            <TextStyled>
              {t("สามารถกดที่การ์ดเพื่อแก้ไขข้อมูล")}
              <br />
              {t("ก่อนสรุปรายการสั่งซื้อ")}
            </TextStyled>
            <ButtonWrapper>
              <DoseartButton
                width={parseInt(width) < 600 ? "40vw" : "9.37vw"}
                isDisable={false}
                isPrimary={false}
                text={t("กลับไปแก้ไข")}
                isSetMargin={true}
                handleOnClick={onClickBack}
              />
              <DoseartButton
                width={parseInt(width) < 600 ? "40vw" : "9.37vw"}
                isDisable={false}
                isPrimary={true}
                text={t("ตรวจสอบแล้ว")}
                isSetMargin={true}
                handleOnClick={onClickNext}
              />
            </ButtonWrapper>
          </>
        ) : state == "PreviewGenerate" ? (
          <>
            <NotificationImage src={NotificationIcon} />
            <HeadTextStyled>{t("ยืนยันรายการสั่งซื้อ")}</HeadTextStyled>
            <TextStyled>
              {t("ขอสงวนสิทธิ์ในการแก้ไขภาพ")}
              <br />
              {t("หากชำระเงินเรียบร้อยแล้ว")}
            </TextStyled>
            <ButtonWrapper>
              <DoseartButton
                width={parseInt(width) < 600 ? "42vw" : "10vw"}
                isDisable={false}
                isPrimary={false}
                text={t("ยกเลิก")}
                handleOnClick={onClickBack}
              />
              <DoseartButton
                width={parseInt(width) < 600 ? "42vw" : "10vw"}
                isDisable={false}
                isPrimary={true}
                text={t("ยืนยันรายการสั่งซื้อ")}
                handleOnClick={onClickNext}
              />
            </ButtonWrapper>
          </>
        ) : state == "Payment" ? (
          <>
            <NotificationImage src={NotificationIcon} />
            <HeadTextStyled>{t("การชำระเงินไม่สำเร็จ")}</HeadTextStyled>
            <TextStyled>
              {payment == "card" || payment == "unionPay"
                ? t("ระบบจะนำท่านไปดำเนินการสั่งซื้ออีกครั้ง")
                : t("กรุณาลองใหม่อีกครั้ง")}
            </TextStyled>
            <ButtonWrapper>
              <DoseartButton
                width={parseInt(width) < 600 ? "85vw" : "20vw"}
                isDisable={false}
                isPrimary={true}
                text={t("FillInformationPage.ตกลง")}
                handleOnClick={onClickNext}
              />
            </ButtonWrapper>
          </>
        ) : state == "home" ? (
          <>
            <NotificationImage src={NotificationIcon} />
            <HeadTextStyled>
              {t("NotificationCard.คุณมีรายการสั่งซื้อค้างชำระอยู่")}
            </HeadTextStyled>
            <TextStyled>
              {t(
                "NotificationCard.ต้องการตรวจสอบสถานะรายการสั่งซื้อล่าสุดหรือไม่"
              )}
              <br />
              <br />
              {t(
                "NotificationCard.(หากชำระเงินแล้วระบบจะนำคุณไปยังหน้าดาวน์โหลดภาพเพื่อรับภาพพื้นหลัง)"
              )}
            </TextStyled>
            <ButtonWrapper>
              <DoseartButton
                width={parseInt(width) < 600 ? "42vw" : "10vw"}
                isDisable={false}
                isPrimary={false}
                text={t("NotificationCard.ยกเลิกคำสั่งซื้อ")}
                handleOnClick={onClickBack}
              />
              <DoseartButton
                width={parseInt(width) < 600 ? "42vw" : "10vw"}
                isDisable={false}
                isPrimary={true}
                text={t("NotificationCard.ตรวจสอบสถานะ")}
                handleOnClick={onClickNext}
              />
            </ButtonWrapper>
          </>
        ) : state == "SubscriptionPersonalInfo" ? (
          <>
            <NotificationImage src={NotificationIcon} />
            <HeadTextStyled>
              {t("โปรดตรวจสอบความถูกต้องของข้อมูล")}
            </HeadTextStyled>
            <ButtonWrapper>
              <DoseartButton
                width={parseInt(width) < 600 ? "40vw" : "9.37vw"}
                isDisable={false}
                isPrimary={false}
                text={t("กลับไปแก้ไข")}
                isSetMargin={true}
                handleOnClick={onClickBack}
              />
              <DoseartButton
                width={parseInt(width) < 600 ? "40vw" : "9.37vw"}
                isDisable={false}
                isPrimary={true}
                text={t("ตรวจสอบแล้ว")}
                isSetMargin={true}
                handleOnClick={onClickNext}
              />
            </ButtonWrapper>
          </>
        ) : (
          <></>
        )}
      </CardWrapper>
    </ContentWrapper>
  );
};

export default NotificationCard;
