import "./styles.css";
import React, { useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import RedeemLandingPage from "./RedeemLandingPage.";
import IntroPage from "../HomePage/IntroPage";
import PackagePage from "../HomePage/PackagePage";
import FillInformationPage from "../FillInformationPage";
import CartPage from "../CartPage";
import PreviewGeneratePage from "../PreviewGenerate";
import GalleryMultipleSelectionPage from "../GalleryMultipleSelectionPage";

//come with /redeem/:partner_id/:campaign
const RedeemPage =  ()=>{
    
    return(
    <Routes>
        <Route path="/:partner_id/:campaign_id" element={<RedeemLandingPage/>}/>
        <Route path="/intro/:collection_id" element={<IntroPage/>}/>
        <Route path = '/package' element={<PackagePage/>}/>
        <Route path = '/filled' element={<FillInformationPage/>}/>
        <Route path = '/cart' element={<CartPage/>}/>
        <Route path =  "/preview" element={<PreviewGeneratePage/>}/>
        <Route
                path="/gallery-selection/:topic_id/:aw_price?"
                element={<GalleryMultipleSelectionPage />}
              />
        <Route path="/home" element={<RedeemLandingPage/>}/>
    </Routes>
    )
}

export default RedeemPage;