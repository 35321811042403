import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from 'styled-components';
import fluidText from "../../utils/fluidText";
import ReactEditor from "../../components/ReactEditor";
import {  state as stateAtom,} from "../../global/atom";
import { useRecoilState } from "recoil";
import useNavigationHandling from "../../utils/useNavigationHandling";

const BlogsContentPage = ()=>{
    const [fetchData,updateData] = useState()
    const { content_id } = useParams();
    const { navigateHandler } = useNavigationHandling()
    const [state, updateState] = useRecoilState(stateAtom);

    useEffect(()=>{  
        updateState('blog_content')
        var file_location = window.location.href.split(window.location.pathname)
        fetch(file_location[0]+'/blogs/'+content_id+'.json').then((res)=>{
          return res.json()
        }).catch((e)=>{
            console.log(e)
            navigateHandler('/home')
        }).then((data)=>{
            if(data){
                updateData(data)
            }
        })
        window.scrollTo(0,0)
      },[])

    return(
        <div style={{marginTop:'3.2rem',height:'100%'}}>
        {fetchData&&<ReactEditor defaultValue={fetchData} readOnly={true}/>}
        </div>
    )
}

export default BlogsContentPage