import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import {
  formList as formListAtom,
  state as stateAtom,
  artworkDatabase,
  prevFormList as prevFormListAtom,
  referenceID as referenceIDAtom,
  orderID as orderIDAtom,
  dateDatabase as dateDatabaseAtom,
  previewIndex as previewIndexAtom,
  previewLoading as previewLoadingAtom,
  package_id_selection_atom,
  chinese_zodiac_list,
  day_of_week_list,
  previewLoadingProgress,
  fbc_cookies,
  fbp_cookies,
  phoneNumberState,
  checkPhoneNumberState,
} from "../global/atom";
import ReactPixel from "react-facebook-pixel";
import RectangleImg from "../assets/cart/Rectangle.svg";
import FooterComponent from "../components/FooterComponent";
import {
  createOrderID,
  createOrderIDInDatabase,
  createQROrder,
  DEPLOY_VERSION,
  getDateText,
  getThaiMoonPhase,
  getTimeInQueue,
  getZodiacName,
  handleFontFamily,
  randomId,
  sendConversionAPI,
  sendErrorToChatbot,
} from "../utils/utils";
import NotificationCard from "../components/NotificationCard";
import LoaderComponent from "../components/LoaderComponent";
import PreviewCard from "../components/PreviewCard";
import CancelIcon from "../assets/gallery/cancelButton.svg";
import { use100vh } from "react-div-100vh";
import fluidText from "../utils/fluidText";
import { Default } from "react-spinners-css";
import {
  session_package_id_selection_atom,
  select_package,
  sessionOrderID,
  sessionPrice,
  sessionRefID,
  sessionState,
  useSessionStorage,
  sessionShowKpayment,
  sessionVoucher,
  sessionTotalAmount,
  multiple_attribute,
  reffer_url,
  session_state,
  session_prev_form_list,
  session_chinese_zodiac_list,
  session_day_of_week_list,
  sessionReferenceOrder,
  sessionClientID,
  paymentMethod,
  session_collection_selection_info,
  session_artwork_database,
  session_fbc_cookies,
  session_fbp_cookies,
  session_phone_number,
} from "../utils/sessionStorage";
import {
  createStoreOrder,
  clearStoreByName,
  getMaxOrderIndex,
} from "../utils/IndexedDBStore";
import TagManager from "react-gtm-module";
import { useContext } from "react";
import { UserContext } from "../utils/Context/userContext";
import useNavigationHandling from "../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";
import { TextField } from "@material-ui/core";
import { TelCardWrapper, PriceNumber } from "./HomePage/styles";
import PreviewComponent from "../components/PreviewComponent";

const HeadTextStyled = styled.div`
  //width:88.46vw;
  padding-top: ${(props) => (props.pd ? props.pd : "0px")};
  margin: 2vh auto 2vh auto;
  ${fluidText(24, 28)}
  //font-size: 1.5rem; 
    ${handleFontFamily()}
  font-style: normal;
  font-weight: 300;
  color: black;
  text-align: center;
  @media (min-width: 600px) {
    //width: 38vw;
    margin: 3vh auto 3vh auto;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    width: 40vw;
    margin: auto;
    min-width: 500px;
  }
`;

const TipImage = styled.img`
  width: 9.2vw;
  //margin-left : 5.1vw;
  //margin-right: 5.1vw;
  margin-left: 2vw;
  margin-right: 4vw;
  pointer-events: none;
  @media (min-width: 600px) {
    width: 6vh;
    height: 6vh;
    margin-right: 1vw;
  }
`;

const TipsTextStyled = styled.div`
  //font-size: 0.9rem;
  ${fluidText(12, 20)}
  ${handleFontFamily()}
  margin:auto;
  font-style: normal;
  font-weight: 400;
  color: "black";
  text-align: left;
  align-items: center;
  display: flex;
  width: 90%;
  @media (min-width: 600px) {
    //height: 6vh;
    //font-size: 1rem;
  }
  //margin-left : 5.1vw;
`;

const TipsRedTextStyled = styled.div`
  ${fluidText(10, 16)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: #e4202a;
  text-align: left;
  word-wrap: normal;
  width: 90%;
  margin: auto;
  @media (min-width: 600px) {
    //font-size: 1rem;
  }
  //margin-left : 5.1vw;
`;

const TipsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 90vw;
  margin: 0 auto 2vh auto;
  @media (min-width: 600px) {
    //width: 38vw;
    min-width: 400px;
    margin: 0 auto 4vh auto;
  }
`;

const PriceHeaderText = styled.div`
  margin: 1vh auto 0 auto;
  font-size: 1.5rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: #5c5c5c;
  flex-direction: row;
  display: flex;
  @media (min-width: 600px) {
    margin: 0 auto 1vh auto;
  }
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    width: 90%;
    height: 5.33vh;
    margin: 1vh auto 1vh auto;
    ${handleFontFamily()}
    font-style: normal;
    font-weight: 400;
    @media (min-width: 600px) {
      min-width: 350px;
    }
    fieldset {
      border-color: #e5e5e5;
    }
    &.Mui-focused fieldset {
      border-color: #e5e5e5;
    }
    background-color: #ffffff;
  }
`;

const RedText = styled.div`
  color: #e4202a;
  margin-left: 0.5rem;
`;

const LargePreviewImageWrapper = styled.img`
  width: 100%;
  height: 100%;
  margin: auto;
  pointer-events: none;
  object-fit: contain;
`;

const LargePreviewWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: 10;
`;
const ImageAreaWrapper = styled.div`
  position: relative;
  height: ${(props) => `${props.vh}px`};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  //display: flex;
`;

const PositionImageWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.useWidth ? `90vw` : `${props.vh * 0.518}px`)};
  height: ${(props) =>
    props.useWidth ? `calc(90vw / 0.518)` : `${props.vh}px`};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CancelIconStyled = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 9vw;
  cursor: pointer;
  pointer-events: auto;
  @media (min-width: 600px) {
    width: 2.5vw;
  }
`;
const SpinnerWrapper = styled(Default)`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

var gCloudStorageURL = "https://storage.googleapis.com/doseart_asia_output/";

const PreviewGeneratePage = () => {
  const [state, updateState] = useRecoilState(stateAtom);
  const [showDatabaseLoader, setShowdatabaseLoader] = useState(false);
  const [prevFormList, updatePrevFormList] = useSessionStorage(
    session_prev_form_list.key,
    session_prev_form_list.default
  );
  const [chineseZodiacList] = useSessionStorage(
    session_chinese_zodiac_list.key,
    session_chinese_zodiac_list.default
  );
  const [dayOfWeekList] = useSessionStorage(
    session_day_of_week_list.key,
    session_day_of_week_list.default
  );
  const { navigateHandler } = useNavigationHandling();
  const { contextAWDb, voucher, formList, updateFormList } =
    useContext(UserContext);
  const [showNoti, updateShowNoti] = useState(false);
  const height = use100vh();
  const [vh, updateVh] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [useWidth, setUseWidth] = useState(true);
  const [, updateOrderID] = useSessionStorage(
    sessionOrderID.key,
    sessionOrderID.default
  );
  const [, update_phone_number] = useSessionStorage(
    session_phone_number.key,
    session_phone_number.default
  );
  //const [dateDatabase,updateDateDatabase] =useSessionStorage(sessionDateDatabase.key,sessionDateDatabase.default)
  const [dateDatabase] = useRecoilState(dateDatabaseAtom);
  const [clickID, updateClickID] = useState(1);
  const [productPrice] = useSessionStorage(
    sessionPrice.key,
    sessionPrice.default
  );
  //const [voucher,] = useSessionStorage(sessionVoucher.key, sessionVoucher.default)
  const [total_amount] = useSessionStorage(
    sessionTotalAmount.key,
    sessionTotalAmount.default
  );
  const [multipleAttribute] = useSessionStorage(
    multiple_attribute.key,
    multiple_attribute.default
  );
  const [selectPackage] = useSessionStorage(
    select_package.key,
    select_package.default
  );
  const [sales_partner] = useSessionStorage(reffer_url.key, reffer_url.default);
  const [package_id] = useSessionStorage(
    session_package_id_selection_atom.key,
    session_package_id_selection_atom.default
  );
  const [, updateReferenceOrder] = useSessionStorage(
    sessionReferenceOrder.key,
    sessionReferenceOrder.default
  );
  const [session_client_id] = useSessionStorage(
    sessionClientID.key,
    sessionClientID.default
  );
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useSessionStorage(
    paymentMethod.key,
    paymentMethod.default
  );
  const [circularProgress, updateCircularProgress] = useState(0);
  const [collection_selection_info, update_collection_selection_info] =
    useSessionStorage(
      session_collection_selection_info.key,
      session_collection_selection_info.default
    );
  const [fbc] = useSessionStorage(
    session_fbc_cookies.key,
    session_fbc_cookies.default
  );
  const [fbp] = useSessionStorage(
    session_fbp_cookies.key,
    session_fbp_cookies.default
  );

  var intervalFunction = useRef();
  var timeoutFunction = useRef(new Array());
  var intervalCircularProgressRef = useRef();

  const { t, i18n } = useTranslation();
  const previewStateRef = useRef();
  const phoneNumberRef = useRef();

  useEffect(() => {
    if (
      (width < 600 && 1.69 * width > window.innerHeight) ||
      (width > 600 && 0.25 * width > window.innerHeight)
    ) {
      setUseWidth(false);
    }
    updateState("PreviewGenerate");
    TagManager.dataLayer({
      dataLayer: {
        event: "preview_item",
        collection_id: collection_selection_info.collection_name
          ? collection_selection_info.collection_name
          : "",
        refID: sales_partner,
      },
    });
    return () => {
      clearInterval(intervalFunction.current);
      clearTimeout(timeoutFunction.current);
      clearInterval(intervalCircularProgressRef.current);
    };
  }, []);

  useEffect(() => {
    if (state == "Payment") updateState("PreviewGenerate");
  }, [state]);

  function setPrevFormList() {
    var prevForm = { ...prevFormList };
    prevForm.fill = true;
    prevForm.name = formList[0].name;
    prevForm.surname = formList[0].surname;
    prevForm.zodiacId = formList[0].zodiacId;
    prevForm.birthDate = formList[0].birthDate;
    prevForm.birthMonth = formList[0].birthMonth;
    prevForm.birthYear = formList[0].birthYear;
    prevForm.selectImage = formList[0].selectImage;
    prevForm.showBirthDate = formList[0].showBirthDate;
    updatePrevFormList(prevForm);
  }

  function clearSendingMessage() {
    clearInterval(intervalFunction.current);
    clearTimeout(timeoutFunction.current);
  }

  const getChristianYear = (year) => {
    return i18n.language === "th" ? year - 543 : year;
  };

  const goToPaymentPage = async () => {
    const phoneNumber = window.location.href.includes("redeem")?formList[0].phone_no:i18n.language==="th"?phoneNumberRef.current.getPhoneNumber():'';
    update_phone_number(phoneNumber);
    getMaxOrderIndex()
      .then(async (newest_order) => {
        setShowdatabaseLoader(true);
        var data_list = [];
        var product_list = [];
        var price = [];
        var formArray = formList;
        for (var x = 0; x < formList.length; x++) {
          var date = new Date(
            getChristianYear(formList[x].birthYear),
            formList[x].birthMonth,
            formList[x].birthDate
          );
          var dayOfWeek =
            formList[x].day_of_week && formList[x].day_of_week !== ""
              ? formList[x].day_of_week
              : date.getDay();
          var moonPhase = await getThaiMoonPhase(
            getChristianYear(formList[x].birthYear),
            formList[x].birthMonth + 1,
            formList[x].birthDate,
            dateDatabase
          );
          if (multipleAttribute.length > 0) {
            var form_data = {
              psd_filename: selectPackage,
              name: formList[x].name,
              surname: formList[x].surname,
              zodiac: formList[x].zodiacId.toString(),
              day_of_week: dayOfWeek.toString(),
              moon_phase: moonPhase.toString(),
              isShowDate: formList[x].showBirthDate,
              date: formList[x].birthDate,
              month: formList[x].birthMonth,
              year: formList[x].birthYear,
              chinese_zodiac: formList[x].chinese_zodiac.toString(),
              attribute: multipleAttribute,
              element_index:formList[x].element_index?.toString(),
              element_support_index:formList[x].element_support_index?.toString()
            };
            data_list.push(form_data);
            formArray[x].price = total_amount;
          } else {
            var form_data = {
              psd_filename: contextAWDb[formList[x].selectImageId].filename,
              name: formList[x].name,
              surname: formList[x].surname,
              zodiac: formList[x].zodiacId.toString(),
              day_of_week: dayOfWeek.toString(),
              moon_phase: moonPhase.toString(),
              isShowDate: formList[x].showBirthDate,
              date: formList[x].birthDate,
              month: formList[x].birthMonth,
              year: formList[x].birthYear,
              chinese_zodiac: formList[x].chinese_zodiac.toString(),
              element_index:formList[x].element_index?.toString(),
              element_support_index:formList[x].element_support_index?.toString()
            };
            data_list.push(form_data);
            product_list.push(contextAWDb[formList[x].selectImageId].id);
            formArray[x].price = total_amount / formList.length;
          }
        }
        updateFormList(formArray);
        //Ais payment
        if (window.location.href.includes("aisalc")) {
          var date = new Date(); // Replace this with your own date object
          const year = date.getFullYear().toString().padStart(4, "0");
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const day = date.getDate().toString().padStart(2, "0");
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");
          const seconds = date.getSeconds().toString().padStart(2, "0");
          const randomNum = Math.floor(Math.random() * 1000)
            .toString()
            .padStart(3, "0");
          const formattedDate = `${year}${month}${day}${hours}${minutes}${seconds}${randomNum}`;
          createOrderID(formattedDate)
            .then((getOrderID) => {
              if (getOrderID.status == "failed") {
                alert("Voucher is invalid");
                return;
              }
              updateOrderID(getOrderID.id);
              updateReferenceOrder(getOrderID.reference_order);
              setSelectedPaymentMethod("card");
              if (DEPLOY_VERSION == "PROD") {
                ReactPixel.init("973339143546259");
                ReactPixel.track("InitiateCheckout");
                sendConversionAPI("InitiateCheckout", fbc, fbp);
              }
              createStoreOrder(
                getOrderID.id,
                data_list,
                total_amount,
                getOrderID.reference_order,
                newest_order.order_index + 1,
                selectPackage,
                "ais"
              )
                .then((value) => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "begin_checkout",
                      ecommerce: {
                        currencyCode: "THB",
                        transaction_id: getOrderID.reference_order,
                        value: total_amount,
                      },
                    },
                  });
                  createOrderIDInDatabase(
                    data_list,
                    getOrderID.id,
                    package_id,
                    voucher,
                    multipleAttribute,
                    product_list,
                    sales_partner,
                    value,
                    getOrderID.reference_order,
                    session_client_id,
                    "ais",
                    fbp,
                    fbc
                  )
                    .then(() => {
                      clearSendingMessage();
                      updateShowNoti(false);
                      setShowdatabaseLoader(false);
                      navigateHandler("/aisalc/payment?order=" + value);
                    })
                    .catch((e) => {
                      sendErrorToChatbot(e);
                      setShowdatabaseLoader(false);
                    });
                })
                .catch((e) => {
                  console.log(e);
                  sendErrorToChatbot(e);
                  setShowdatabaseLoader(false);
                });
            })
            .catch((e) => {
              sendErrorToChatbot(e);
              setShowdatabaseLoader(false);
            });
        } 
        else if(window.location.href.includes("redeem")){
          const indexUID = randomId()
          const ref_order = formList[0].trans_id;
          updateOrderID('test_'+formList[0].trans_id);
          updateReferenceOrder(ref_order);
          setSelectedPaymentMethod("card");
          createOrderIDInDatabase(
            data_list,
            'test_'+formList[0].trans_id,
            package_id,
            voucher,
            multipleAttribute,
            product_list,
            sales_partner,
            indexUID,
            formList[0].trans_id,
            formList[0].phone_no,
            session_client_id,
            "redeem",
            fbp,
            fbc
          )
            .then(() => {
              clearSendingMessage();
              updateShowNoti(false);
              setShowdatabaseLoader(false);
              navigateHandler("/payment?order=" + indexUID);
            })
            .catch((e) => {
              sendErrorToChatbot(e);
              setShowdatabaseLoader(false);
            });
        }
        else {
          createQROrder(
            package_id,
            voucher,
            product_list,
            sales_partner,
            i18n.language === "th" ? "th" : "zh"
          )
            .then((getOrderID) => {
              if (getOrderID.status == "failed") {
                alert("Voucher is invalid");
                return;
              }
              //id : order's key kept in database
              //reference_order : map to kpayment order
              updateOrderID(getOrderID.id);
              updateReferenceOrder(getOrderID.reference_order);
              setSelectedPaymentMethod("card");
              if (DEPLOY_VERSION == "PROD") {
                ReactPixel.init("973339143546259");
                ReactPixel.track("InitiateCheckout");
                sendConversionAPI("InitiateCheckout", fbc, fbp);
              }
              createStoreOrder(
                getOrderID.id,
                data_list,
                total_amount,
                getOrderID.reference_order,
                newest_order.order_index + 1,
                selectPackage,
                "qr"
              )
                .then((value) => {
                  TagManager.dataLayer({
                    dataLayer: {
                      event: "begin_checkout",
                      ecommerce: {
                        currencyCode: "THB",
                        transaction_id: getOrderID.reference_order,
                        value: total_amount,
                      },
                    },
                  });
                  createOrderIDInDatabase(
                    data_list,
                    getOrderID.id,
                    package_id,
                    voucher,
                    multipleAttribute,
                    product_list,
                    sales_partner,
                    value,
                    getOrderID.reference_order,
                    phoneNumber,
                    session_client_id,
                    null,
                    fbp,
                    fbc
                  )
                    .then(() => {
                      clearSendingMessage();
                      updateShowNoti(false);
                      setShowdatabaseLoader(false);
                      navigateHandler("/payment?order=" + value);
                    })
                    .catch((e) => {
                      sendErrorToChatbot(e.message);
                      setShowdatabaseLoader(false);
                    });
                })
                .catch((e) => {
                  console.log(e);
                  sendErrorToChatbot(e);
                  setShowdatabaseLoader(false);
                });
            })
            .catch((e) => {
              sendErrorToChatbot(e);
              setShowdatabaseLoader(false);
            });
        }
      })
      .catch((e) => {
        console.log(e);
        sendErrorToChatbot(e);
      });
  };

  window.addEventListener("resize", () => {
    let vh = window.innerHeight;
    updateVh(vh);
    let vw = window.innerWidth;
    setWidth(vw);
  });

  const PreveiwGenerateImageState = forwardRef(({}, ref) => {
    //const [text, setText] = useState("");
    // const phone_number = useInput("");
    const telRef = useRef();
    const priceHeaderTextFormat = (isVoucher) => {
      let translatedPriceHeader = t(
        "PreviewGenerate.ภาพพื้นหลัง 1 ภาพ 199 บาท"
      );
      if (isVoucher)
        return translatedPriceHeader
          .replace(/1/g, formList.length)
          .replace(
            /199/g,
            `<div style="color: black; margin-left: 0.5rem; text-decoration: line-through">${productPrice}</div><div style="color: #e4202a; margin-left: 0.5rem;">${
              total_amount < 0 ? "0.00" : total_amount
            }</div>`
          );
      else
        return translatedPriceHeader
          .replace(/1/g, formList.length)
          .replace(/199/g, productPrice);
    };

    return (
      <>
        {voucher == "" && !window.location.href.includes("aisalc") ? (
          <PriceHeaderText>{priceHeaderTextFormat()}</PriceHeaderText>
        ) : (
          <PriceHeaderText
            dangerouslySetInnerHTML={{ __html: priceHeaderTextFormat(true) }}
          ></PriceHeaderText>
        )}
        <PreviewComponent />
        {showNoti ? (
          <NotificationCard
            onClickBack={() => {
              updateShowNoti(false);
            }}
            onClickNext={() => {
              goToPaymentPage();
            }}
            height={height}
            state={state}
          />
        ) : (
          <></>
        )}
        
      </>
    );
  });


  //var checkPhone = false
  const [checkphone,updatePhoneStatus] = useState(false)
  
  
  function isHaveText(t){
    updatePhoneStatus(t)
  }



  return (
    <ContentWrapper>
      {showDatabaseLoader ? (
        <LoaderComponent />
      ) : (
        <>
          <HeadTextStyled pd={"0"}>
            {t("PreviewGenerate.รายการสั่งซื้อ")}
          </HeadTextStyled>
          {i18n.language == "th" &&!window.location.href.includes("redeem")&& (
            <TelCardWrapper>
              <TipsTextStyled>
                {t("กรอกเบอร์โทรศัพท์มือถือเพื่อรับภาพทาง SMS")}
              </TipsTextStyled>
              <TipsRedTextStyled>{t("*โปรดระบุ")}</TipsRedTextStyled>
              <PhoneTextWrapper ref={phoneNumberRef} isHaveText={isHaveText}/>
            </TelCardWrapper>
          )}
          <PreveiwGenerateImageState ref={previewStateRef} />
          <FooterComponent
          isDisableNextButton={window.location.href.includes("redeem")?false:i18n.language=='th'&&!checkphone}
          addOnClickNextFunction={() => {
            goToPaymentPage();
          }}
          addonClickBackFunction={() => {
            setPrevFormList();
            clearSendingMessage();
          }}
        />
        </>
      )}
    </ContentWrapper>
  );
};

const useInput = (initialValue) => {
  const [value, setValue] = useState(initialValue);
  const [textCheck,updateTextCheck] = useState(false)

  useEffect(()=>{
    if(value.length>0) updateTextCheck(true)
    else updateTextCheck(false)
  },[value])

  const handleChange = (event) => {
    const isNumber = /^\d+$/.test(event.target.value);
    if (isNumber || event.target.value === "") setValue(event.target.value);
  };

  return {
    value,
    onChange: handleChange,
    textCheck
  };
};

const PhoneTextWrapper = forwardRef(({isHaveText}, ref) => {
  const phone_n = useInput("");

  useEffect(()=>{
    isHaveText(phone_n.textCheck)
  },[phone_n.textCheck])

  function getPhoneNumber() {
    return phone_n.value;
  }

  useImperativeHandle(ref, () => {
    return {
      getPhoneNumber: getPhoneNumber,
    };
  });

  return (
    <StyledTextField
      type="tel"
      //ref={inputEL}
      {...phone_n}
    />
  );
});

export default PreviewGeneratePage;
