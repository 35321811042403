import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useState,
  useMemo,
  useRef,
} from "react";
import {
  unstable_createMuiStrictModeTheme as createMuiTheme,
  ThemeProvider,
  makeStyles,
  withStyles,
} from "@material-ui/core/styles";
import DesktopDatePicker from "@material-ui/lab/DesktopDatePicker";

import "./TextFieldCardStyle.css";

import TextField from "@material-ui/core/TextField";
//import { MuiPickersUtilsProvider,DatePicker, KeyboardDatePicker } from '@material-ui/pickers';
import styled from "styled-components";

import thLocale from "date-fns/locale/th";
import enLocale from "date-fns/locale/en-US";

import CustomAdapter from "../utils/DateFnsAdapterCustom/date-fns";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";

import dayjs from "dayjs";
import { WatDatePicker } from "../utils/ThaiDate/index.js";

import LocalizationProvider from "@material-ui/lab/LocalizationProvider";

import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import { dateDatabase as dateDatabaseAtom } from "../global/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";

import fluidText from "../utils/fluidText";
import { fluidRange } from "polished";
import {
  checkAstrologyProfile,
  getDateText,
  getThaiMoonPhase,
  handleFontFamily,
} from "../utils/utils";
import { useSessionStorage } from "react-use";
import {
  multiple_attribute,
  select_package,
  session_artwork_database,
  session_chinese_zodiac_list,
  session_day_of_week_list,
  session_multple_attribute_selection,
  session_select_edit_card,
  session_select_index,
} from "../utils/sessionStorage";
import { useContext } from "react";
import { UserContext } from "../utils/Context/userContext";
import { useTranslation } from "react-i18next";

const StyledSelector = styled(Select)`
  .MuiSelect-filled {
    background-color: #e1e1e1;
    width: 71.3vw;
    //height:5.33vh;
    padding-top: 10px;
    ${handleFontFamily()}
    font-style: normal;
    font-weight: 400;
    border-radius: 8px;
    text-align: left;
    background-color: #ffffff;
    @media (min-width: 600px) {
      width: 17.9vw;
      min-width: 350px;
    }
  }
  margin: 1vh auto;
`;

const StyledMenu = withStyles((theme) => ({
  root: {
    fontFamily: "Prompt",
    paddingTop: "4px",
    paddingBottom: "4px",
    paddingRight: "4px",
    paddingLeft: "4px",
    margin: "4px",
    fontSize: "1.0rem",
    lineHeight: "1",
  },
  searchMenuItem: {
    paddingTop: "2px",
    paddingBottom: "2px",
    selectedTextColor: "#ffffff",
  },
}))(MenuItem);

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    width: 82.3vw;
    height: 5.33vh;
    border-radius: 8px;
    margin: 1vh auto 1vh auto;
    ${handleFontFamily()}
    font-style: normal;
    font-weight: 400;
    @media (min-width: 600px) {
      width: 18.9vw;
      min-width: 390px;
    }
    fieldset {
      border-color: #e1e1e1;
    }
    &.Mui-focused fieldset {
      border-color: #e1e1e1;
    }
    background-color: #ffffff;
  }
  .MuiFormHelperText-root {
    ${handleFontFamily()}
    font-style: normal;
    font-weight: 400;
  }
`;

const ChoiceTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    width: 82.3vw;
    ${handleFontFamily()}
    font-style: normal;
    font-weight: 400;
    @media (min-width: 600px) {
      width: 18.9vw;
      min-width: 390px;
    }
    background-color: #ffffff;
  }
  fieldset {
    border-color: #e1e1e1;
  }
  &.Mui-focused fieldset {
    border-color: #e1e1e1;
  }
  .MuiFormHelperText-root {
    ${handleFontFamily()}
    font-style: normal;
    font-weight: 400;
  }
`;

const StyledDatePicker = withStyles((theme) => ({
  root: {
    fontFamily: "Prompt",
  },
}))(DesktopDatePicker);

const DatePickerWrapper = styled.div`
  position: relative;
  width: 82.3vw;
  margin: 2vh auto;
  @media (min-width: 600px) {
    width: 18.9vw;
    min-width: 390px;
  }
`;

const HelperText = styled.p`
  width: 82.3vw;
  ${fluidRange(
    {
      prop: "font-size",
      fromSize: "12px",
      toSize: "16px",
    },

    "300px",
    "600px"
  )}
  ${handleFontFamily()}
  font-weight: 400;
  color: #5c5c5c;
  text-align: left;
  margin: auto;
  @media (min-width: 600px) {
    ${fluidRange(
      {
        prop: "font-size",
        fromSize: "16px",
        toSize: "18px",
      },

      "600px",
      "1400px"
    )}
    width: 18.9vw;
    min-width: 390px;
  }
`;

/*const StyledDatePicker = styled(DatePicker)`
  .MuiInputBase-root-MuiOutlinedInput-root {
    width: '70vw';
  }
`;*/

const CardWrapper = styled.div`
  width: 88.4vw;
  border-radius: 15px;
  box-shadow: 4px 4px 10px rgba(92, 92, 92, 0.15);
  flex-direction: column;
  background: #f5f5f5;
  display: flex;
  padding: 15px 0px;
  //margin-bottom: 12vh;
  @media (min-width: 600px) {
    width: 23.95vw;
    min-width: 450px;
    //margin: 0vh auto 20vh auto;
  }
`;

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#E4202A",
    },
    secondary: {
      main: "#E5E5E5",
    },
  },
});

const TextLabelWrapper = styled.div`
  width: 82.3vw;
  ${fluidRange(
    {
      prop: "font-size",
      fromSize: "12px",
      toSize: "20px",
    },

    "300px",
    "600px"
  )}
  ${handleFontFamily()}
  font-weight: 400;
  color: #5c5c5c;
  text-align: left;
  margin: auto;
  @media (min-width: 600px) {
    ${fluidRange(
      {
        prop: "font-size",
        fromSize: "16px",
        toSize: "18px",
      },

      "600px",
      "1400px"
    )}
    width: 18.9vw;
    min-width: 390px;
  }
`;
const DateTextLabelWrapper = styled.div`
  width: 82.3vw;
  ${fluidRange(
    {
      prop: "font-size",
      fromSize: "10px",
      toSize: "18px",
    },

    "300px",
    "600px"
  )}
  ${handleFontFamily()}
  color: #828282;
  text-align: left;
  margin: auto;
  @media (min-width: 600px) {
    width: 18.9vw;
    min-width: 390px;
    ${fluidRange(
      {
        prop: "font-size",
        fromSize: "12px",
        toSize: "16px",
      },

      "600px",
      "1600px"
    )}
  }
`;

const UnOrderedListStyled = styled.ul`
  width: 79.3vw;
  margin: auto;
  padding: 0 3vw;
  @media (min-width: 600px) {
    padding: 0 1vw;
    width: 18.9vw;
    min-width: 390px;
  }
`;

const localeMap = {
  th: thLocale,
  en: enLocale,
};

const maskMap = {
  th: "__/__/____",
  en: "__/__/____",
};

const TextOverPicker = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 95%;
  margin: auto;
  padding: 0.6rem;
  transform: translate(-50%, -50%);
  background: white;
  color: rgba(0, 0, 0, 0.87);
  user-select: none;

  cursor: pointer;

  border-radius: 8px;
  border: #e1e1e1 1px solid;
  ${handleFontFamily()}
  font-style: normal;
`;

const useToggleOnFocus = (initialState = false) => {
  const [show, toggle] = useState(initialState);

  const eventHandlers = useMemo(
    () => ({
      onFocus: () => toggle(true),
      onBlur: () => toggle(false),
    }),
    []
  );

  return [show, eventHandlers];
};

const monthString = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

const timeList = [
  "-- ไม่ระบุ --",
  "00.00 - 01.59 น.",
  "02.00 - 03.59 น.",
  "04.00 - 05.59 น.",
  "06.00 - 07.59 น.",
  "08.00 - 09.59 น.",
  "10.00 - 11.59 น.",
  "12.00 - 13.59 น.",
  "14.00 - 15.59 น.",
  "16.00 - 17.59 น.",
  "18.00 - 19.59 น.",
  "20.00 - 21.59 น.",
  "22.00 - 23.59 น.",
];

const monthThai = [];

const TextFieldCard = (
  {
    setDateError,
    setShowNoti,
    type,
    type_selector,
    select_artwork,
    state,
    setTransError,
    nameLength,
  },
  ref
) => {
  const {
    contextAWDb,
    setContextAWDb,
    subscriberInfo,
    updateSubscriberInfo,
    formList,
    updateFormList,
  } = useContext(UserContext);
  const [data] = useSessionStorage(
    session_artwork_database.key,
    session_artwork_database.default
  );
  //const data = useRecoilValue(artworkDatabase);

  /*const selectedIndex = useRecoilValue(selectedIndexAtom);
  const [multipleAttribute, ] = useRecoilState(multple_attribute_selection);
  const [chineseZodiacList, update_chinese_zoidac_list] = useRecoilState(chinese_zodiac_list);
  const [dayOfWeekList, update_day_of_week_list] = useRecoilState(day_of_week_list);
  const editCardNumber = useRecoilValue(selectEditCard);
*/
  const [selectedIndex] = useSessionStorage(
    session_select_index.key,
    session_select_index.default
  );
  const [multipleAttribute] = useSessionStorage(
    session_multple_attribute_selection.key,
    session_multple_attribute_selection.default
  );
  const [chineseZodiacList, update_chinese_zoidac_list] = useSessionStorage(
    session_chinese_zodiac_list.key,
    session_chinese_zodiac_list.default
  );
  const [dayOfWeekList, update_day_of_week_list] = useSessionStorage(
    session_day_of_week_list.key,
    session_day_of_week_list.default
  );
  const [editCardNumber] = useSessionStorage(
    session_select_edit_card.key,
    session_select_edit_card.default
  );
  const [name, changeName] = useState("");
  const [surname, changeSurname] = useState("");
  const [zodiacValue, setZodiacValue] = useState(0);
  const [timeValue, setTimeValue] = useState(0);
  const [selectedDate, handleDateChange] = useState(new Date());
  const [phone_no, updatePhoneNo] = useState("");
  const [trans_id, updateTransID] = useState("");
  const [isDateFormatCorrect, setDateFormat] = useState(true);
  const [, setHelplerText] = useState();
  const [dateDisplay, setDateDisplay] = useState("");
  const [chineseZodiac, update_chinese_zoidac] = useState(0);
  const [dayOfWeekValue, update_day_of_week] = useState(0);
  //const [ multipleAttribute,updateMultipleAttribute] = useSessionStorage(multiple_attribute.key,multiple_attribute.default)
  const [selectPackage] = useSessionStorage(
    select_package.key,
    select_package.default
  );
  const [isOpenDatePicker, updateOpenDatePicker] = useState(false);
  const [dateDatabase, updateDateDatabase] = useRecoilState(dateDatabaseAtom);
  const [dateBuffer, updateDateBuffer] = useState(new Date());
  const [transIdStatus, setTransIdStatus] = useState({
    status: false,
    result: "",
  });
  //type_6
  const [elementIndex, updateElementIndex] = useState(0);
  const [elementSupportIndex, updateElementSupportIndex] = useState(0);

  const { t, i18n } = useTranslation();

  useEffect(() => {
    if (selectedDate) {
      let selectedDate_string = selectedDate.toDateString();

      let date_arr = selectedDate_string.split(" ");
      let month_str = date_arr[1];
      let day_str = date_arr[2];
      let year_str = date_arr[3];

      let day = day_str;
      let month = monthString.indexOf(month_str) + 1;
      let year =
        i18n.language === "th"
          ? (parseInt(year_str) + 543).toString()
          : year_str;

      setDateDisplay([day, month, year].join("/"));
      if (isOpenDatePicker || !formList[editCardNumber].fill) {
        if (type == "type_1" || type == "type_2") {
          update_day_of_week(selectedDate.getDay());
        }
        if (type == "type_2" || type == "type_3") {
          update_chinese_zoidac((selectedDate.getFullYear() + 8) % 12);
        }
        updateOpenDatePicker(false);
      }
    }
  }, [selectedDate]);

  const dateUiRef = useRef(null);

  const handleWatDatePickerChange = (christDate, buddhistDate) => {
    let newValue = new Date(christDate);
    if (newValue === null) {
      setDateFormat(false);
      setDateError(true);
      setShowNoti(true);
      setHelplerText(t("*โปรดระบุ"));
      return;
    }

    const invalidDate = newValue.toString() === "Invalid Date";
    if (invalidDate) {
      setDateFormat(false);
      setDateError(true);
      setHelplerText(t("*โปรดระบุ"));
    } else {
      setDateFormat(true);
      setDateError(false);
      handleDateChange(newValue);
      setHelplerText(
        getDateText(
          newValue.getDate(),
          newValue.getMonth(),
          newValue.getFullYear()
        )
      );
      if (
        (newValue.getDate() < 13 &&
          (newValue.getMonth() == 3 || newValue.getMonth() == 1)) ||
        (newValue.getDate() < 15 &&
          (newValue.getMonth() == 4 ||
            newValue.getMonth() == 5 ||
            newValue.getMonth() == 6 ||
            newValue.getMonth() == 0 ||
            newValue.getMonth() == 2)) ||
        (newValue.getDate() < 16 &&
          (newValue.getMonth() == 7 ||
            newValue.getMonth() == 10 ||
            newValue.getMonth() == 11)) ||
        (newValue.getDate() < 17 &&
          (newValue.getMonth() == 8 || newValue.getMonth() == 9))
      )
        setZodiacValue((newValue.getMonth() + 8) % 12);
      else setZodiacValue((newValue.getMonth() + 9) % 12);
    }

    // handleDateChange(dateUpdate);
    setHelplerText(
      getDateText(
        newValue.getDate(),
        newValue.getMonth(),
        newValue.getFullYear()
      )
    );
    // setSelectedDate(christDate);
    // setSelectedThaiDate(buddhistDate);
  };

  useEffect(() => {
    let dropdownDateEl = document.getElementsByClassName(
      "react-datepicker__header--custom"
    );
  }, [dateUiRef]);

  const zodiacList = [
    t("FillInformationPage.ราศีเมษ (13 เม.ษ. - 14 พ.ค.)"),
    t("FillInformationPage.ราศีพฤษภ (15 พ.ค. - 14 มิ.ย.)"),
    t("FillInformationPage.ราศีเมถุน (15 มิ.ย. - 14 ก.ค.)"),
    t("FillInformationPage.ราศีกรกฎ (15 ก.ค. - 15 ส.ค.)"),
    t("FillInformationPage.ราศีสิงห์ (16 ส.ค. - 16 ก.ย.)"),
    t("FillInformationPage.ราศีกันย์ (17 ก.ย. - 16 ต.ค.)"),
    t("FillInformationPage.ราศีตุลย์ (17 ต.ค. - 15 พ.ย.)"),
    t("FillInformationPage.ราศีพิจิก (16 พ.ย. - 15 ธ.ค.)"),
    t("FillInformationPage.ราศีธนู (16 ธ.ค. - 14 ม.ค.)"),
    t("FillInformationPage.ราศีมังกร (15 ม.ค. - 12 ก.พ.)"),
    t("FillInformationPage.ราศีกุมภ์ (13 ก.พ. - 14 มี.ค.)"),
    t("FillInformationPage.ราศีมีน (15 มี.ค. - 12 เม.ย.)"),
  ];
  const zodiacRange = [
    t("13 เม.ษ. - 14 พ.ค."),
    t("15 พ.ค. - 14 มิ.ย."),
    t("15 มิ.ย. - 14 ก.ค."),
    t("15 ก.ค. - 15 ส.ค."),
    t("16 ส.ค. - 16 ก.ย."),
    t("17 ก.ย. - 16 ต.ค."),
    t("17 ต.ค. - 15 พ.ย."),
    t("16 พ.ย. - 15 ธ.ค."),
    t("16 ธ.ค. - 14 ม.ค."),
    t("15 ม.ค. - 12 ก.พ."),
    t("13 ก.พ. - 14 มี.ค."),
    t("15 มี.ค. - 12 เม.ย."),
  ];

  useEffect(() => {
    setHelplerText(
      getDateText(
        selectedDate.getDate(),
        selectedDate.getMonth(),
        selectedDate.getFullYear()
      )
    );
    if (editCardNumber != null && formList) {
      if (formList[editCardNumber].fill) {
        changeName(formList[editCardNumber].name);
        changeSurname(formList[editCardNumber].surname);
        setZodiacValue(formList[editCardNumber].zodiacId);
        if (type == "type_1" || type == "type_2") {
          update_day_of_week(formList[editCardNumber].day_of_week);
        }
        if (type == "type_2" || type == "type_3") {
          update_chinese_zoidac(formList[editCardNumber].chinese_zodiac);
        }
        if (
          formList[editCardNumber].birthDate === 0 &&
          formList[editCardNumber].birthMonth === 0 &&
          formList[editCardNumber].birthYear === 0
        ) {
          handleDateChange(null);
        } else {
          handleDateChange(
            new Date(
              i18n.language === "th"
                ? formList[editCardNumber].birthYear - 543
                : formList[editCardNumber].birthYear,
              formList[editCardNumber].birthMonth,
              formList[editCardNumber].birthDate
            )
          );
        }
        if (window.location.href.includes("redeem")) {
          updatePhoneNo(formList[editCardNumber].phone_no);
          updateTransID(formList[editCardNumber].trans_id);
        }
      } else {
        setZodiacValue((selectedDate.getMonth() + 9) % 12);
      }
    }
  }, []);

  useEffect(() => {
    if (type_selector) {
      Object.entries(type_selector).forEach(([key, value]) => {
        if (key == select_artwork || state == "SubscriptionPersonalInfo") {
          Object.entries(value).forEach(([value_key, value_value]) => {
            if (value_key == "chinese_zodiac")
              update_chinese_zoidac_list(value_value);
            else if (value_key == "day_of_week")
              update_day_of_week_list(value_value);
          });
        }
      });
    }
  }, [type_selector, select_artwork, state]);

  const openDatePicker = () => {
    let datepick = document.getElementsByClassName("ant-input")[0];
    datepick.click();
    updateOpenDatePicker(true);
  };
  useImperativeHandle(ref, () => {
    return {
      setFormList: setFormList,
      isDateFormatCorrect: isDateFormatCorrect,
      setSubscriber: setSubscriber,
      getTransId: getFormTransid,
      setTransIdStatus: setTransIdStatus,
      updateAstralProfile: updateAstralProfile,
    };
  });

  const getFormTransid = () => {
    return trans_id;
  };

  const updateAstralProfile = async () => {
    const result = await checkAstrologyProfile(
      name,
      surname,
      selectedDate.getDate(),
      selectedDate.getMonth(),
      selectedDate.getFullYear(),
      timeValue
    );
    updateElementIndex(result.element_index);
    updateElementSupportIndex(result.support_element_index);
    update_chinese_zoidac(result.chinese_zodiac_index);
  };

  const setFormList = async (isShowDate) => {
    var form = formList;
    var moon_phase = await getThaiMoonPhase(
      selectedDate.getFullYear(),
      selectedDate.getMonth() + 1,
      selectedDate.getDate(),
      dateDatabase
    );
    form[editCardNumber].psd_filename =
      multipleAttribute.length > 0
        ? selectPackage
        : contextAWDb[selectedIndex].filename;
    form[editCardNumber].attribute =
      multipleAttribute.length > 0 ? multipleAttribute : [];
    form[editCardNumber].fill = true;
    form[editCardNumber].name = name.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    );
    form[editCardNumber].surname = surname.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    );
    form[editCardNumber].zodiacId = zodiacValue;
    form[editCardNumber].birthDate = selectedDate.getDate();
    form[editCardNumber].birthMonth = selectedDate.getMonth();
    form[editCardNumber].birthYear =
      i18n.language === "th"
        ? selectedDate.getFullYear() + 543
        : selectedDate.getFullYear();
    form[editCardNumber].moon_phase = moon_phase;
    form[editCardNumber].showBirthDate = isShowDate;
    form[editCardNumber].selectImageId = selectedIndex;
    form[editCardNumber].selectImage =
      multipleAttribute.length > 0
        ? 0
        : contextAWDb[selectedIndex].thumbnailPath;
    if (multipleAttribute.length > 0) form[editCardNumber].id = selectPackage;
    else form[editCardNumber].id = contextAWDb[selectedIndex].id;
    //if (type == 'type_1')
    form[editCardNumber].chinese_zodiac =
      type != "type_2" && type != "type_3" ? "" : chineseZodiac;
    //else if (type == 'type_2')
    form[editCardNumber].day_of_week =
      type != "type_1" && type != "type_2"
        ? selectedDate.getDay()
        : dayOfWeekValue;
    if (type == "type_5") {
      form[editCardNumber].phone_no = phone_no;
      form[editCardNumber].trans_id = trans_id;
    }
    if (type === "type_6") {
      form[editCardNumber].element_index = elementIndex;
      form[editCardNumber].element_support_index = elementSupportIndex;
      form[editCardNumber].chinese_zodiac = chineseZodiac;
    }
    console.log(form[editCardNumber]);
    updateFormList([...form]);
  };

  const setSubscriber = () => {
    var sub_info = subscriberInfo;
    sub_info.name = name.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    );
    sub_info.surname = surname.replace(
      /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g,
      ""
    );
    sub_info.zodiacId = zodiacValue;
    sub_info.birthDate = selectedDate.getDate();
    sub_info.birthMonth = selectedDate.getMonth();
    sub_info.birthYear =
      i18n.language === "th"
        ? selectedDate.getFullYear() + 543
        : selectedDate.getFullYear();
    sub_info.chinese_zodiac = chineseZodiac;
    sub_info.day_of_week = selectedDate.getDay();
    updateSubscriberInfo({ ...sub_info });
    //sessionStorage.setItem(session_subscription_user_info.key,JSON.stringify(sub_info))
  };

  const ZodiacSelectorList = zodiacList.map((zodiac, index) => {
    const zodiacFormatted =
      i18n.language === "zh"
        ? zodiac
        : zodiac.replace(
            /\((.*?)\)/g,
            '<span style="color: #C4C4C4">&nbsp;($1)</span>'
          );
    return (
      <StyledMenu id={"zodiacList" + index} value={index} key={index}>
        <div dangerouslySetInnerHTML={{ __html: zodiacFormatted }} />
      </StyledMenu>
    );
  });

  const ZodiacMenuSelector = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TextLabelWrapper>{t("FillInformationPage.ราศี")}</TextLabelWrapper>
        <DateTextLabelWrapper>
          <UnOrderedListStyled>
            <li>{t("FillInformationPage.เลือกราศีตามหลักการนับส่วนตัวได้")}</li>
          </UnOrderedListStyled>
        </DateTextLabelWrapper>
        <FormControl variant="filled" style={{ background: "#f5f5f5" }}>
          <StyledSelector
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={zodiacValue}
            label="zodiac"
            disableUnderline
            IconComponent={ExpandMoreIcon}
            onChange={(e) => {
              setZodiacValue(e.target.value);
            }}
          >
            {ZodiacSelectorList}
          </StyledSelector>
        </FormControl>
      </div>
    );
  };

  const ChineseZodiac = () => {
    if (
      type_selector &&
      type_selector.name &&
      (type == "type_3" || type == "type_2")
    ) {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TextLabelWrapper>
            {t(`FillInformationPage.${type_selector.name.chinese_zodiac}`)}
          </TextLabelWrapper>
          <DateTextLabelWrapper>
            <UnOrderedListStyled>
              <li>
                {t(
                  `FillInformationPage.เลือก${type_selector.name.chinese_zodiac}ตามหลักการนับส่วนตัวได้`
                )}
              </li>
            </UnOrderedListStyled>
          </DateTextLabelWrapper>
          <FormControl variant="filled">
            <StyledSelector
              labelId="demo-simple-select-label-chinese-zodiac"
              id="demo-simple-select-chinese-zodiac"
              /*defaultValue={
                formList[editCardNumber].fill
                  ? formList[editCardNumber].chinese_zodiac
                  : chineseZodiac
              }*/
              value={chineseZodiac}
              label="chineseZodiac"
              disableUnderline
              IconComponent={ExpandMoreIcon}
              onChange={(e) => {
                update_chinese_zoidac(e.target.value);
              }}
            >
              {ChineseZodiacSelectorList}
            </StyledSelector>
          </FormControl>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const ChineseZodiacSelectorList = chineseZodiacList?.map((zodiac, index) => {
    return (
      <StyledMenu id={"chineseZodiac" + index} value={index} key={index}>
        {t(`FillInformationPage.${zodiac}`)}
      </StyledMenu>
    );
  });

  const TimeSelectorList = timeList?.map((time, index) => {
    return (
      <StyledMenu id={"time_" + index} value={index} key={index}>
        {time}
      </StyledMenu>
    );
  });

  const TimeSelector = () => {
    return (
      <div style={{ display: "flex", flexDirection: "column" }}>
        <TextLabelWrapper>{"เวลาเกิด"}</TextLabelWrapper>
        <FormControl variant="filled" style={{ background: "#f5f5f5" }}>
          <StyledSelector
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={timeValue}
            label="time"
            disableUnderline
            IconComponent={ExpandMoreIcon}
            onChange={(e) => {
              setTimeValue(e.target.value);
            }}
          >
            {TimeSelectorList}
          </StyledSelector>
        </FormControl>
      </div>
    );
  };

  const DayOfWeekSelector = () => {
    if (type_selector && (type == "type_1" || type == "type_2")) {
      return (
        <div style={{ display: "flex", flexDirection: "column" }}>
          <TextLabelWrapper>
            {t(`FillInformationPage.${type_selector.name.day_of_week}`)}
          </TextLabelWrapper>
          <DateTextLabelWrapper>
            <UnOrderedListStyled>
              <li>
                {t(
                  `FillInformationPage.เลือก${type_selector.name.day_of_week}ตามหลักการนับส่วนตัวได้`
                )}
              </li>
            </UnOrderedListStyled>
          </DateTextLabelWrapper>
          <FormControl variant="filled">
            <StyledSelector
              labelId="demo-simple-select-label-chinese-zodiac"
              id="demo-simple-select-chinese-zodiac"
              value={dayOfWeekValue}
              label="day_of_week"
              disableUnderline
              IconComponent={ExpandMoreIcon}
              onChange={(e) => {
                update_day_of_week(e.target.value);
              }}
            >
              {dayOfWeekSelectorList}
            </StyledSelector>
          </FormControl>
        </div>
      );
    } else {
      return <></>;
    }
  };

  const dayOfWeekSelectorList = dayOfWeekList.map((zodiac, index) => {
    return (
      <StyledMenu id={"day_of_week_" + index} value={index} key={index}>
        {t(`FillInformationPage.${zodiac}`)}
      </StyledMenu>
    );
  });

  return (
    <CardWrapper style={{margin:`0vh auto ${type=="type_4"?'200px':'12vh'} auto`}}>
      {type == "type_4" ? (
        <TextLabelWrapper>{t("FillInformationPage.ชื่อเล่น")}</TextLabelWrapper>
      ) : (
        <TextLabelWrapper>{t("FillInformationPage.ชื่อ")}</TextLabelWrapper>
      )}
      <StyledTextField
        id="name"
        label=""
        variant="outlined"
        defaultValue={formList[editCardNumber].name}
        inputProps={{
          form: {
            autocomplete: "off",
          },
          maxLength:
            nameLength && nameLength.first_name ? nameLength.first_name : 20,
        }}
        onChange={(e) => {
          changeName(e.target.value);
        }}
      />
      {type == "type_4" ? (
        <></>
      ) : (
        <>
          <TextLabelWrapper>
            {t("FillInformationPage.นามสกุล")}
          </TextLabelWrapper>
          <StyledTextField
            id="surname"
            label=""
            variant="outlined"
            defaultValue={formList[editCardNumber].surname}
            inputProps={{
              form: {
                autocomplete: "off",
              },
              maxLength:
                nameLength && nameLength.last_name ? nameLength.last_name : 20,
            }}
            onChange={(e) => {
              changeSurname(e.target.value);
            }}
          />
        </>
      )}
      <TextLabelWrapper>
        {t("FillInformationPage.วัน เดือน ปีเกิดแบบ พ.ศ. (วว/ดด/ปปปป)")}
      </TextLabelWrapper>
      <DateTextLabelWrapper>
        <UnOrderedListStyled>
          <li>
            {t(
              "FillInformationPage.ใช้ประกอบการแสดงสัญลักษณ์เกี่ยวกับวันเกิดเท่านั้น ไม่มีการเก็บข้อมูล หรือนำข้อมูลของท่านไปใช้ในรูปแบบอื่น ๆ"
            )}
          </li>
        </UnOrderedListStyled>
      </DateTextLabelWrapper>
      <DatePickerWrapper className={`date-picker-${type=='type_4'?'type-4':'default'}`}>
        <WatDatePicker
          locale={i18n.language}
          value={selectedDate}
          onChange={handleWatDatePickerChange}
          dateFormat={"yyyy-MM-dd"} // (using date-fns format)[https://date-fns.org/v2.12.0/docs/format]
          minDate={"1930-12-26"} // supported date as string
          maxDate={"2029-12-26"} // also supported dayjs or moment
          ref={(e) => dateUiRef(forwardRef(e))}
          readOnly={false}
          clearable={false}
        />
        <TextOverPicker onClick={openDatePicker}>{dateDisplay}</TextOverPicker>
      </DatePickerWrapper>
      {type=="type_6"&&<TimeSelector />}
      <ZodiacMenuSelector />

      {type == "type_3" ? (
        <ChineseZodiac />
      ) : type == "type_1" ? (
        <DayOfWeekSelector />
      ) : type == "type_2" ? (
        <>
          <ChineseZodiac />
          <DayOfWeekSelector />
        </>
      ) : type == "type_5" ? (
        <>
          <TextLabelWrapper>
            {t("FillInformationPage.กรอกเบอร์โทรศัพท์ เพื่อรับภาพทาง SMS")}
          </TextLabelWrapper>
          <StyledTextField
            type="tel"
            id="phone_no"
            label=""
            variant="outlined"
            defaultValue={formList[editCardNumber].phone_no}
            inputProps={{
              form: {
                autocomplete: "off",
              },
              maxLength: 20,
            }}
            onChange={(e) => {
              updatePhoneNo(e.target.value);
            }}
          />
          <TextLabelWrapper>{t("เลขที่ใบเสร็จ")}</TextLabelWrapper>
          <DateTextLabelWrapper>
            <UnOrderedListStyled>
              <li>
                {t(
                  "* หากเป็นใบเสร็จรูปแบบเต็มหรือใบกำกับภาษี กรุณากรอกเลขที่ใบเสร็จ 9 หลักสุดท้าย"
                )}
              </li>
            </UnOrderedListStyled>
          </DateTextLabelWrapper>
          <StyledTextField
            type="tel"
            id="trans_id"
            error={transIdStatus.status}
            helperText={transIdStatus.status ? transIdStatus.result : ""}
            label=""
            variant="outlined"
            defaultValue={formList[editCardNumber].trans_id}
            inputProps={{
              form: {
                autocomplete: "off",
              },
              maxLength: 9,
            }}
            onChange={(e) => {
              updateTransID(e.target.value);
            }}
          />
        </>
      ) : (
        <></>
      )}
    </CardWrapper>
  );
};

export default forwardRef(TextFieldCard);
