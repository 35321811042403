import React, { useEffect, useState } from "react";
import {
  HeadTextStyled,
  TipsWrapper,
  TipImage,
  TipsTextStyled,
  ImageWrapper,
  Image,
  GalleryCardWrapper,
  GalleryWrapper,
  PreviewImageWrapper,
  PreviewImage,
  CloseButton,
  ActionButton,
  BGDark,
  GalleryLeft,
  GalleryRight,
  PriceNumber,
  PreviewImage_,
} from "./styles";
import {
  artworkDatabase,
  isPreviewImage,
  selectedURL as selectedURLAtom,
  selectedIndex as selectedIndexAtom,
} from "../../global/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import tipImg from "../../assets/cart/tips.svg";
import useLongPress from "../../utils/useLongPress";
import CancelIcon from "../../assets/gallery/cancelButton.svg";
import { useNavigate } from "react-router-dom";
import NavLogo from "../../components/NavLogo";
import { useSessionStorage } from "react-use";
import {
  discountCoupon,
  reffer_url,
  sessionFormType,
  session_artwork_database,
  session_collection_selection_info,
  session_is_preview_image,
  session_name_length,
  session_select_index,
  session_select_url,
} from "../../utils/sessionStorage";
import styled from "styled-components";
import TagManager from "react-gtm-module";
import { useContext } from "react";
import { UserContext } from "../../utils/Context/userContext";
import useNavigationHandling from "../../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";

let isTouchmove = false;
const isTouchEvent = (event) => {
  return "touches" in event;
};
const preventDefault = (event) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

// FIXME: preview large image render previous image before load new src
// FIXME: select same picture

const GalleryPageSelection = ({ filter_topic_id, filter_price, show_price = true }) => {
  const {contextAWDb} = useContext(UserContext)
  //const [artwork_db,] = useSessionStorage(session_artwork_database.key,session_artwork_database.default)
  //const artwork_db = useRecoilValue(artworkDatabase); // data
  const [filtered_artwork_db, update_filtered_artwork_db] = useState("");
  const [filtered_item_id, update_filtered_item_id] = useState("");
  const [preview, updatePreview] = useState("");
  // handle touch move
  const [width, setWidth] = useState(window.innerWidth);
  const [height, setHeight] = useState(window.innerHeight);
  const [useWidth, setUseWidth] = useState(true);
  /*
  const [isPreview, set_isPreview] = useRecoilState(isPreviewImage);
  const [selectedURL, updateSelectUrl] = useRecoilState(selectedURLAtom);
  const [selectedIndex, updateSelectedIndex] = useRecoilState(selectedIndexAtom);
 */
  const [isPreview, set_isPreview] = useSessionStorage(
    session_is_preview_image.key,
    session_is_preview_image.default
  );
  const [, updateSelectUrl] = useSessionStorage(session_select_url.key, session_select_url.default);
  const [, updateSelectedIndex] = useSessionStorage(
    session_select_index.key,
    session_select_index.default
  );
  const [session_reffer_url, update_session_reffer_url] = useSessionStorage(
    reffer_url.key,
    reffer_url.default
  );
  const [collection_selection_info,update_collection_selection_info] = useSessionStorage(
    session_collection_selection_info.key,
    session_collection_selection_info.default
  );
  const [, update_formType] = useSessionStorage(sessionFormType.key, sessionFormType.default);
  const [nameLength,update_name_length] = useSessionStorage(
    session_name_length.key,
    session_name_length.default,
  )
  const {t} = useTranslation();
  const { navigateHandler } = useNavigationHandling();
  useEffect(() => {
    if (contextAWDb && !filtered_artwork_db && filter_topic_id && !filtered_item_id) {
      let filtered_artwork = [],
        filtered_id = [];
        contextAWDb.map((item, idx) => {
        if (item.tags.includes(filter_topic_id)) {
          if (filter_price) {
            if (item.price === filter_price) {
              filtered_artwork.push(item);
              filtered_id.push(idx);
            }
          } else {
            filtered_artwork.push(item);
            filtered_id.push(idx);
          }
        }
      });
      update_filtered_artwork_db(filtered_artwork);
      update_filtered_item_id(filtered_id);
    }
  });
  useEffect(() => {
    if (preview) {
      set_isPreview(true);
    } else set_isPreview(false);
  }, [preview]);
  // start handle touch move
  useEffect(() => {
    if (
      (width < 600 && 1.69 * width > window.innerHeight) ||
      (width > 600 && 0.25 * width > window.innerHeight)
    ) {
      setUseWidth(false);
    } else {
    }
    TagManager.dataLayer({
      dataLayer: {
        event: 'view_item',
        collection_id:collection_selection_info.collection_name?collection_selection_info.collection_name:'',
        refID:session_reffer_url
      },
    });
    window.addEventListener("resize", handleResize);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);
    return () => {
      window.removeEventListener("touchmove", preventDefault, {
        passive: true,
      });
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);
  const handleResize = () => {
    let vh = window.innerHeight;
    setHeight(vh);
    let vw = window.innerWidth;
    setWidth(vw);
  };
  const handleTouchMove = () => {
    isTouchmove = true;
  };
  const handleTouchEnd = () => {
    isTouchmove = false;
  };
  // end handle touch move

  const handleSelectImage = () => {
    updateSelectUrl(filtered_artwork_db[preview - 1].thumbnailPath);
    updateSelectedIndex(filtered_item_id[preview - 1]);
    update_formType(filtered_artwork_db[preview - 1].form_type);
    let imageNameLength={
      first_name:filtered_artwork_db[preview - 1].firstNameLength,
      last_name:filtered_artwork_db[preview - 1].lastNameLength,
    }
    update_name_length(imageNameLength)
    setTimeout(() => {
      if(window.location.href.includes('redeem')) navigateHandler("/redeem/filled");
      else navigateHandler("/filled")
    }, 200);
  };

  const onClickImage = (index) => {
    updatePreview(index);
    set_isPreview(true);
  };

  const ItemArray = () => {
    let colLeft = [];
    let colRight = [];
    filtered_artwork_db.map((item, idx) => {
      if (filtered_artwork_db.length === 1) {
      }
      if (idx % 2 === 0) {
        colLeft = [
          ...colLeft,
          <ImageWrapperComponent
            updatePreview={onClickImage}
            item={item}
            idx={idx}
            key={"image_id" + idx}
            show_price={show_price}
            show_click_image={filtered_artwork_db.length === 1}
          />,
        ];
      } else {
        colRight = [
          ...colRight,
          <ImageWrapperComponent
            updatePreview={onClickImage}
            item={item}
            idx={idx}
            key={"image_id" + idx}
            show_price={show_price}
          />,
        ];
      }
    });

    return [colLeft, colRight];
  };
  return (
    <>
      <HeadTextStyled>{t("GalleryPage.เลือกภาพที่ต้องการ")}</HeadTextStyled>
      <TipsWrapper>
        {/* <TipImage src={tipImg} /> */}
        <div style={{ flexDirection: "column" }}>
          <TipsTextStyled>
            • {t("GalleryPage.กดเลือกภาพที่สนใจ เพื่อดูภาพขนาดใหญ่")}
            <br />• {t("GalleryPage.ภาพที่เหมาะกับพื้นดวงของท่าน จะแสดงหลังกรอกข้อมูลเสร็จเรียบร้อยในหน้ารายการสั่งซื้อ")}
          </TipsTextStyled>
        </div>
      </TipsWrapper>
      <GalleryCardWrapper>
        {filtered_artwork_db && (
          <>
            <GalleryLeft position={"left"}>{ItemArray()[0]}</GalleryLeft>
            <GalleryRight position={"right"}>{ItemArray()[1]}</GalleryRight>
          </>
        )}
      </GalleryCardWrapper>
      <PreviewImageWrapper isPreview={isPreview}>
        <BGDark isPreview={isPreview} />
        <PreviewImage_
          bgUrl={preview && filtered_artwork_db[preview - 1].largeThumbnailPath}
          isPreview={isPreview}
        >
          <CloseButton
            src={CancelIcon}
            onClick={() => {
              set_isPreview(false);
            }}
            isPreview={isPreview}
          />
          <ActionButton
            disable={false}
            primary={true}
            width={parseInt(width) < 600 ? "42vw" : "12.5vw"}
            isPreview={isPreview}
            onClick={handleSelectImage}
          >
            {t("GalleryPage.เลือกภาพนี้")}
          </ActionButton>
        </PreviewImage_>
        {/* <PreviewImage
          src={preview && filtered_artwork_db[preview - 1].largeThumbnailPath}
          isPreview={isPreview}
        ></PreviewImage>
        <CloseButton
          src={CancelIcon}
          onClick={() => {
            set_isPreview(false);
          }}
          isPreview={isPreview}
        />
        <ActionButton
          disable={false}
          primary={true}
          width={parseInt(width) < 600 ? "42vw" : "12.5vw"}
          isPreview={isPreview}
          onClick={handleSelectImage}
        >
          เลือกภาพนี้
        </ActionButton> */}
      </PreviewImageWrapper>
    </>
  );
};

const ClickImageToChoose = styled.p`
  margin: 0;
  font-weight: 400;
  color: #d52b2c;
`;

const ImageWrapperComponent = ({ updatePreview, idx, item, show_price, show_click_image }) => {
  const {voucher} = useContext(UserContext)
  const [discount_type, ] = useSessionStorage(
    discountCoupon.key,
    discountCoupon.default
  );
  const [discountPrice,updateDiscountPrice] = useState()
  
  const {t} = useTranslation();

  useEffect(()=>{
    if(voucher!=''){
      if (discount_type.type == "AMOUNT") {
        var discount_amount = (discount_type.amount_off / 100).toFixed(2);
        if(item.sale_price!='')
          updateDiscountPrice((item.sale_price - discount_amount).toFixed(2));
        else 
          updateDiscountPrice((item.price - discount_amount).toFixed(2));
      }
      //percent
      else if (discount_type.type == "PERCENT") {
        if(item.sale_price!=''){
          var discount_amount = ((discount_type.percent_off / 100) * item.sale_price).toFixed(2);
          updateDiscountPrice((item.sale_price - discount_amount<0?0:item.sale_price - discount_amount).toFixed(2));
        }
        else{
          var discount_amount = ((discount_type.percent_off / 100) * item.price).toFixed(2);
          updateDiscountPrice((item.price - discount_amount<0?0:item.price - discount_amount).toFixed(2));
        }
      }
    }
  },[voucher])

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const onLongPress = (i) => {};

  const onClick = (i) => {
    if (!isTouchmove) {
      updatePreview(idx + 1);
    }
    isTouchmove = false;
  };

  const longpress = useLongPress(onLongPress, onClick, idx, defaultOptions);
  return (
    <ImageWrapper {...longpress}>
      <Image src={item && item.thumbnailPath} />
      {show_price?voucher!=''&&(!item.sale_price||item.sale_price=='')? 
      <PriceNumber><div style={{ color: 'black', textDecoration: 'line-through' }}>{item.price} </div><div style={{color:'red'}}>&nbsp;{discountPrice} {t("บาท")}</div></PriceNumber>
      :voucher!=''&&item.sale_price!=''?
      <PriceNumber><div style={{ color: 'black', textDecoration: 'line-through' }}>{item.price} </div><div style={{color:'red', textDecoration: 'line-through' }}>&nbsp;{discountPrice}</div><div style={{color:'red'}}>&nbsp;{discountPrice} {t("บาท")}</div></PriceNumber>
      :item.sale_price!=null&&item.sale_price!=''?
      <PriceNumber><div style={{ color: 'black', textDecoration: 'line-through' }}>{item.price} </div><div style={{color:'red'}}>&nbsp;{item.sale_price} {t("บาท")}</div></PriceNumber>
      :<PriceNumber>{item.price} {t("บาท")}</PriceNumber>
      :<></>}
      {show_click_image && <ClickImageToChoose>{t("กดที่ภาพเพื่อเลือก")}</ClickImageToChoose>}
    </ImageWrapper>
  );
};

export default GalleryPageSelection;
