import React, { useState, useEffect, useRef, useImperativeHandle, forwardRef, memo } from "react";
import styled from "styled-components";
import RectangleImg from "../assets/cart/Rectangle.svg";
import {
  getDateText,
  getTimeInQueue,
  getZodiacName,
} from "../utils/utils";
import PreviewCard from "../components/PreviewCard";
import CancelIcon from "../assets/gallery/cancelButton.svg";
import { Default } from "react-spinners-css";
import {
  sessionRefID,
  useSessionStorage,
  multiple_attribute,
  session_chinese_zodiac_list,
  session_day_of_week_list,
} from "../utils/sessionStorage";

import { useContext } from "react";
import { UserContext } from "../utils/Context/userContext";
import { useTranslation } from "react-i18next";

const LargePreviewImageWrapper = styled.img`
  width: 100%;
  height: 100%;
  margin: auto;
  pointer-events: none;
  object-fit: contain;
`;

const LargePreviewWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: 10;
`;
const ImageAreaWrapper = styled.div`
  position: relative;
  height: ${(props) => `${props.vh}px`};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  //display: flex;
`;

const PositionImageWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.useWidth ? `90vw` : `${props.vh * 0.518}px`)};
  height: ${(props) =>
    props.useWidth ? `calc(90vw / 0.518)` : `${props.vh}px`};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CancelIconStyled = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 9vw;
  cursor: pointer;
  pointer-events: auto;
  @media (min-width: 600px) {
    width: 2.5vw;
  }
`;
const SpinnerWrapper = styled(Default)`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

var gCloudStorageURL = "https://storage.googleapis.com/doseart_asia_output/";

const PreviewComponent = () => {
    const [clickID, updateClickID] = useState(1);
    const { t } = useTranslation();
    const [vh, updateVh] = useState(window.innerHeight);
    const [width, setWidth] = useState(window.innerWidth);
    const [largePreviewLoading, setLoading] = useState(true);
    const [isLoading, updateLoading] = useState(true);
    const [isPreviewImage, updatePreviewImage] = useState(false);
    const [useWidth, setUseWidth] = useState(true);
    const { contextAWDb, voucher, formList, updateFormList } =
      useContext(UserContext);
    const [circularProgress, updateCircularProgress] = useState(0);
    const [multipleAttribute] = useSessionStorage(
      multiple_attribute.key,
      multiple_attribute.default
    );
    const [chineseZodiacList] = useSessionStorage(
      session_chinese_zodiac_list.key,
      session_chinese_zodiac_list.default
    );
    const [dayOfWeekList] = useSessionStorage(
      session_day_of_week_list.key,
      session_day_of_week_list.default
    );
    const [referenceID] = useSessionStorage(
      sessionRefID.key,
      sessionRefID.default
    );
  
    var intervalCircularProgressRef = useRef();
    var intervalFunction = useRef();
    var progressTime = 1;
    var currentTime = 0;
  
    useEffect(() => {
      return () => {
        clearInterval(intervalFunction.current);
        clearInterval(intervalCircularProgressRef.current);
      };
    }, []);
  
    useEffect(() => {
      if (referenceID != null) {
        getTimeInQueue(referenceID.length).then((time) => {
          progressTime = time;
          intervalCircularProgressRef.current = setInterval(
            countdownProgress,
            100
          );
          intervalFunction.current = setInterval(checkURLImage, 1000);
        });
      }
    }, [referenceID]);
  
    function checkURLImage() {
      var promises = [];
      referenceID.forEach((element, index) => {
        promises.push(
          new Promise((resolve, reject) => {
            var http = new XMLHttpRequest();
            var previewImageURL = gCloudStorageURL + element;
            http.onreadystatechange = function () {
              if (this.readyState == 4) {
                // Typical action to be performed when the document is ready:
                if (http.status != 404) {
                  resolve("true");
                } else reject("false");
              }
            };
            http.open("HEAD", previewImageURL, false);
            http.send();
          })
        );
      });
      Promise.all(promises)
        .then(() => {
          updateLoading(false);
          clearInterval(intervalCircularProgressRef.current);
          clearInterval(intervalFunction.current);
        })
        .catch(() => {});
    }
  
    const countdownProgress = () => {
      currentTime += 0.1;
      var progression = Math.round((currentTime / progressTime) * 100);
      if (progression >= 95) {
        clearInterval(intervalCircularProgressRef.current);
        progression = 95;
      }
      updateCircularProgress(progression);
    };
  
    const LargePreviewImage = () => {
      return (
        <LargePreviewWrapper>
          <ImageAreaWrapper vh={vh}>
            <PositionImageWrapper vh={vh * 0.9} useWidth={width / vh < 0.518}>
              {largePreviewLoading ? (
                <SpinnerWrapper color="#E4202A" size={70} />
              ) : (
                <></>
              )}
              <LargePreviewImageWrapper
                src={gCloudStorageURL + referenceID[clickID]}
                useWidth={useWidth}
                onLoad={() => setLoading(false)}
              ></LargePreviewImageWrapper>
              <CancelIconStyled
                src={CancelIcon}
                onClick={() => updatePreviewImage(false)}
              />
            </PositionImageWrapper>
          </ImageAreaWrapper>
        </LargePreviewWrapper>
      );
    };
  
    const PreviewCardWrapper = () => {
      if (referenceID != null) {
        return formList.map((element, i) => {
          return (
            <PreviewCard
              isLoading={isLoading}
              circularProgress={circularProgress}
              sampleImage={
                multipleAttribute.length == 0 && element.selectImage
                  ? element.selectImage
                  : RectangleImg
              }
              name={element.name + " " + element.surname}
              birthdate={
                element.birthDate != 0
                  ? getDateText(
                      element.birthDate,
                      element.birthMonth,
                      element.birthYear,
                      t
                    )
                  : ""
              }
              isShowDate={element.showBirthDate}
              zodiac={getZodiacName(element.zodiacId)}
              addOnClick={() => {
                updateClickID(i);
                updatePreviewImage(true);
                setLoading(true);
              }}
              id={i}
              referenceID={referenceID[i]}
              chinese_zodiac={
                element.chinese_zodiac === ""
                  ? ""
                  : chineseZodiacList[element.chinese_zodiac]
              }
              day_of_week={
                element.day_of_week === ""
                  ? ""
                  : dayOfWeekList[element.day_of_week]
              }
            />
          );
        });
      } else return <></>;
    };
  
    return (
      <>
        <div style={{ marginBottom: "15vh" }}>
          <PreviewCardWrapper />
        </div>
        {isPreviewImage ? <LargePreviewImage /> : <></>}
      </>
    );
  };

  export default memo(PreviewComponent)