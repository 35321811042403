import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import RectangleImg from "../assets/cart/Rectangle.svg";
import EditImg from "../assets/cart/editImage.svg";
import magnifying_glass from "../assets/cart/magnifying_glass.svg";
import useLongPress from "../utils/useLongPress";
import { previewLoading, previewLoadingProgress } from "../global/atom";
import { Default } from "react-spinners-css";
import fluidText from "../utils/fluidText";
import { fluidRange } from "polished";
import { useRecoilState, useRecoilValue } from "recoil";
import { GCLOUDSTORAGEURL_SUBSCRIPTION, getTimeInQueue, handleFontFamily } from "../utils/utils";
import CircleProgressComponent from "./CircularProgressbar";
import { useTranslation } from "react-i18next";

const CartWrapper = styled.button`
  background-color: #f9f9f9;
  width: 88.46vw;
  //height: min-content;
  border-radius: 15px;
  margin: 1vh auto;
  filter: drop-shadow(5px 10px 5px rgba(0, 0, 0, 0.1));
  //align-items:center;
  flex-direction: row;
  display: flex;
  border: 3px solid transparent;
  @media (min-width: 600px) {
    width: 38vw;
    margin: 0vh auto 1vh auto;
    min-width: 400px;
  }
`;

const PreviewImage = styled.img`
  width: 15.5vh;
  height: auto;
  pointer-events: none;
  margin: auto;
  @media (min-width: 600px) {
    //width: 10vw;
    height: auto;
  }
`;

const SpinnerWrapper = styled(Default)`
  margin: 0 auto;
`;

const LoadingWrapper = styled.div`
  margin: auto;
  width: 15.5vh;
  height: 30vh;
  background-color: rgba(255, 255, 255, 0.8);
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  display: flex;
  @media (min-width: 600px) {
    width: 15.5vh;
    height: 30vh;
  }
`;

const LoadingContent = styled.div`
  margin: auto;
`;

const PreviewImageWrapper = styled.div`
  width: 15.5vh;
  height: fit-content;
  margin: 3vw 5vw 3vw 3vw;
  position: relative;
  cursor: ${(prop) => (prop.isLoading ? "auto" : "pointer")};
  @media (min-width: 600px) {
    width: 15.5vh;
    height: fit-content;
    margin: 1vw 1.2vw 1vw 1vw;
  }
`;

const HeaderCardText = styled.div`
  ${fluidText(18, 23)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 600;
  color: black;
  text-align: left;
  @media (min-width: 600px) {
  }
`;

const DetailCardText = styled.div`
  ${fluidText(16, 22)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: ${(props) => (props.filled ? "black" : "#D1D9E6")};
  text-align: left;
  overflow: hidden;
  touch-action: none;
  -ms-touch-action: none;
  width: 38vw;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (min-width: 600px) {
    //font-size: 1.3rem;
    width: 20vw;
  }
`;

const DetailWrapper = styled.div`
  margin-top: 3vw;
  @media (min-width: 600px) {
    margin-top: 1vw;
  }
`;

const SpinnerText = styled.div`
  //width:71.84vw;
  margin: 0 auto;
  font-size: 1em;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
`;

const ManginfyingGlassIcon = styled.img`
  width: 30%;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  position: absolute;
`;

const PreviewCard = ({
  sampleImage,
  name,
  birthdate,
  zodiac,
  addOnClick,
  isShowDate,
  referenceID,
  id,
  day_of_week,
  chinese_zodiac,
  circularProgress,
  isLoading,
  isSubscribe,
}) => {
  const timeoutFunction = useRef();
  const intervalFunction = useRef();
  const delayShowImage = useRef();
  var gCloudStorageURL = isSubscribe
    ? GCLOUDSTORAGEURL_SUBSCRIPTION
    : "https://storage.googleapis.com/doseart_asia_output/";
  const [isPreviewLoadingArray, updatePreview] = useRecoilState(previewLoading);
  //const [isLoading, updateLoading] = useState(true);
  const [refID, updateRefID] = useState(referenceID);
  var progressTime = 1;
  var currentTime = 0;
  const loadingProgressRef = useRef();
  const { t } = useTranslation();

  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  useEffect(() => {
    return () => {
      clearInterval(intervalFunction.current);
      clearTimeout(timeoutFunction.current);
      if (delayShowImage.current) clearTimeout(delayShowImage.current);
    };
  }, []);

  const countdownProgress = () => {
    //var temp = [...previewLoadingProgressArray]
    //temp[id] +=0.1
    //updatePreviewLoadingProgress(temp)
    currentTime += 0.1;
    var progression = Math.round((currentTime / progressTime) * 100);

    if (progression >= 95) progression = 95;
  };

  function checkIntervalURL() {
    var fetchInterval = 1000;
    intervalFunction.current = setInterval(checkURLImage, fetchInterval);
  }

  function checkURLImage() {
    var http = new XMLHttpRequest();
    var previewImageURL = gCloudStorageURL + referenceID;
    http.onreadystatechange = function () {
      if (this.readyState == 4) {
        // Typical action to be performed when the document is ready:
        if (http.status != 404) {
          clearInterval(intervalFunction.current);
          delayShowImage.current = setTimeout(showPreviewImage, 2000);
        }
      }
    };
    http.open("HEAD", previewImageURL, false);
    http.send();
  }

  const showPreviewImage = () => {
    //updateLoading(false)
    //var temp = [...isPreviewLoadingArray]
    //temp[id] = false
    //updatePreview(temp)
  };

  const onLongPress = () => {};

  const longpress = useLongPress(onLongPress, addOnClick, 0, defaultOptions);

  return (
    <CartWrapper>
      <PreviewImageWrapper isLoading={isLoading}>
        {isLoading ? (
          <div>
            <PreviewImage src={sampleImage} />
            <LoadingWrapper>
              <LoadingContent>
                <CircleProgressComponent value={circularProgress} />
              </LoadingContent>
            </LoadingWrapper>
          </div>
        ) : (
          <div {...longpress}>
            <PreviewImage src={gCloudStorageURL + refID} />
            <ManginfyingGlassIcon src={magnifying_glass} />
          </div>
        )}
      </PreviewImageWrapper>
      <DetailWrapper>
        <HeaderCardText>{t("PreviewGenerate.ภาพพื้นหลัง")}</HeaderCardText>
        {name == " " ? (
          <DetailCardText filled={false}>{t("PreviewGenerate.ชื่อ นามสกุล")}</DetailCardText>
        ) : (
          <DetailCardText filled={true}>{name}</DetailCardText>
        )}
        {birthdate == "" ? (
          <DetailCardText filled={false}>
            {t("PreviewGenerate.วัน เดือน ปีเกิด")}
          </DetailCardText>
        ) : isShowDate ? (
          <DetailCardText filled={true}>{birthdate}</DetailCardText>
        ) : (
          <>
            <DetailCardText filled={false}>{birthdate}</DetailCardText>
            <DetailCardText filled={false}>
              ({t("PreviewGenerate.ไม่แสดงวันเกิด")})
            </DetailCardText>
          </>
        )}
        <DetailCardText filled={true}>{t(zodiac)}</DetailCardText>
        {chinese_zodiac != "" ? (
          <DetailCardText filled={true}>{chinese_zodiac}</DetailCardText>
        ) : (
          <></>
        )}
        {day_of_week != "" ? (
          <DetailCardText filled={true}>{t(day_of_week)}</DetailCardText>
        ) : (
          <></>
        )}
      </DetailWrapper>
    </CartWrapper>
  );
};

export default PreviewCard;
