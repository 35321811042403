import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const useNavigationHandling = () => {
  const { i18n } = useTranslation();
  const navigate = useNavigate();

  const navigateHandler = (params) => {
    if (params === -1) {
      navigate(params);
      return;
    }
    // check language
    if (i18n.language !== "th")
      navigate(
        `/${i18n.language}${params}`
      );
    else navigate(params);
  };

  const getPathHandler = (params) => {
    if (i18n.language !== "th") return `/${i18n.language}${params}`;
    else return params;
  };

  return { navigateHandler, getPathHandler };
};

export default useNavigationHandling;
