import React, { useState, useEffect } from "react";
import styled from "styled-components";
import FilterButton from "./FilterButton";
import fluidText from "../utils/fluidText";
import DoseartButton from "./DoseartButton";
import CancelIcon from "../assets/gallery/cancelButton.svg";
import { useSessionStorage, filterCheck } from "../utils/sessionStorage";
import { useTranslation } from "react-i18next";
import { handleFontFamily } from "../utils/utils";

const ContentWrapper = styled.div`
  width: 100vw;
  height: 100%;
  //align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  pointer-events: auto;
  overflow-y: auto;
  z-index: 10;
  white-space: nowrap;
`;

const CardWrapper = styled.div`
  position: relative;
  left: 50%;
  top: 50%;
  width: 90vw;
  //   overflow-y: scroll;
  height: 80vh;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  bottom: 0;
  border-radius: 10px;
  //padding : 0 10px;
  flex-direction: column;
  display: flex;
  @media (min-width: 600px) {
    width: 40vw;
    min-width: 400px;
    //padding: 0 1vw;
  }
`;

const HeadTextStyled = styled.div`
  width: 82vw;
  margin: 2vh auto 1vh auto;
  ${fluidText(20, 25)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: left;
  @media (min-width: 600px) {
    width: 35vw;
    min-width: 350px;
  }
`;

const SeperateLineStyled = styled.div`
  height: 0vh;
  width: 80vw;
  border: 1px solid #d1d9e6;
  margin: 1vh auto;
  @media (min-width: 600px) {
    width: 35vw;
    min-width: 350px;
  }
`;

const SubHeadTextStyled = styled.div`
  width: 82vw;
  margin: 1vh auto 1vh auto;
  ${fluidText(16, 18)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: left;
  @media (min-width: 600px) {
    width: 35vw;
    min-width: 350px;
  }
`;

const ButtonFilterContainerStyled = styled.div`
  width: 100%;
  height: fit-content;
  padding-bottom: 8rem;
  margin: auto;

  overflow-y: scroll;
  overflow-x: hidden;

  @media (min-width: 600px) {
    // width: 35vw;
    min-width: 350px;
  }
`;

const ButtonFilterWrapperStyled = styled.div`
  width: 84vw;
  margin: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  @media (min-width: 600px) {
    width: 35vw;
    min-width: 350px;
  }
`;

const FooterWrapper = styled.div`
  z-index: 9;
  position: absolute;
  width: 90vw;
  bottom: 0;
  padding-top: 1.2vh;
  padding-bottom: 1.2vh;
  border-radius: 10px;
  background-color: white;
  //box-shadow: 0px 0px 10px #D1D9E6;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  box-shadow: 0px 0px 10px #d1d9e6;
  @media (min-width: 600px) {
    width: 100%;
  }
`;

const CancelIconStyled = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 6vw;
  pointer-events: auto;
  cursor: pointer;
  @media (min-width: 600px) {
    width: 3vw;
  }
`;

const FilterComponent = ({
  onClickClose,
  updateFilterAW,
  allGallery,
  catalog,
  awToTagSet,
  tagToawSet,
  catalogDatabase,
}) => {
  const [headerCatalog, updateHeadCatalog] = useState(); // headertag : 'name'
  var [allHeaderWithCatalog, updateAllHeaderCatalog] = useState(); //{color : [color_1,color2]}
  const [filter, updateFilter] = useSessionStorage(
    filterCheck.key,
    filterCheck.default
  ); //filter search  color_01 (buttonChecked) : true
  const [filterButtonDisable, updateFilterDisable] = useState();
  const [filterResult, updateFilterResult] = useState(); // return to gallery page

  const { t } = useTranslation();

  const width = window.innerWidth;

  useEffect(() => {
    if (catalogDatabase) {
      var catalogtags = {};
      var headerCatalogTag = {};
      var filterTag = {};
      var buttonTag = {};
      var g = [];
      var header = [];
      var headerWithTag = {};
      catalogDatabase[0].category.forEach((category) => {
        Object.entries(category).forEach(([key, value]) => {
          if (!key.includes("recommend")) {
            headerCatalogTag[key] = value;
            headerWithTag[key] = new Array();
            header.push(key);
          }
        });
      });
      Object.entries(catalogDatabase[0].tag).forEach(([key, value]) => {
        catalogtags[key] = value;
        filterTag[key] = false;
        buttonTag[key] = false;
        g.push(key);
        header.forEach((headerValue) => {
          if (key.includes(headerValue)) {
            var tmp = headerWithTag[headerValue];
            tmp.push(key);
            headerWithTag[headerValue] = tmp;
          }
        });
      });
      updateAllHeaderCatalog(headerWithTag);
      if (filter == "") updateFilter(filterTag);
      updateFilterDisable(buttonTag);
      updateHeadCatalog(headerCatalogTag);
    }
  }, [catalogDatabase]);

  useEffect(() => {
    var catalogtags = {};
    var headerCatalogTag = {};
    var filterTag = {};
    var buttonTag = {};
    var g = [];
    var header = [];
    var headerWithTag = {};
    catalogDatabase[0].category.forEach((category) => {
      Object.entries(category).forEach(([key, value]) => {
        if (!key.includes("recommend")) {
          headerCatalogTag[key] = value;
          headerWithTag[key] = new Array();
          header.push(key);
        }
      });
    });
    //catalogDatabase[0].tag.forEach((tag) => {
    Object.entries(catalogDatabase[0].tag).forEach(([key, value]) => {
      catalogtags[key] = value;
      filterTag[key] = false;
      buttonTag[key] = false;
      g.push(key);
      header.forEach((headerValue) => {
        if (key.includes(headerValue)) {
          var tmp = headerWithTag[headerValue];
          tmp.push(key);
          headerWithTag[headerValue] = tmp;
        }
      });
    });
    // })
    updateAllHeaderCatalog(headerWithTag);
    if (filter == "") {
      updateFilter(filterTag);
    } else {
      filterTag = filter;
    }
    updateIntersectButton(buttonTag, filterTag);
    //updateFilterDisable(buttonTag)
    updateHeadCatalog(headerCatalogTag);
  }, []);

  useEffect(() => {
    if (filterButtonDisable) {
      updateIntersectButton(filterButtonDisable, filter);
    }
  }, [filter]);

  const addFilter = (value) => {
    var tmp = filter;
    tmp[value] = true;
    updateFilter((current) => ({ ...current, tmp }));
  };
  const removeFilter = (value) => {
    var tmp = filter;
    tmp[value] = false;
    updateFilter((current) => ({ ...current, tmp }));
  };

  const clearFilter = () => {
    var tmp = filter;
    allGallery.forEach((tag) => {
      tmp[tag] = false;
    });
    updateFilter((current) => ({ ...current, tmp }));
  };

  function union(setA, setB) {
    let _union = new Set(setA);
    for (let elem of setB) {
      _union.add(elem);
    }
    return _union;
  }

  function intersection(setA, setB) {
    let _intersection = new Set();
    for (let elem of setB) {
      if (setA.has(elem)) {
        _intersection.add(elem);
      }
    }
    return _intersection;
  }

  function symmetricDifference(setA, setB) {
    let _difference = new Set(setA);
    for (let elem of setB) {
      if (_difference.has(elem)) {
        _difference.delete(elem);
      } else {
        _difference.add(elem);
      }
    }
    return _difference;
  }

  function updateIntersectButton(button, filterTag) {
    var intersectSet = new Set(); // [dog_1,dog_2,...]
    var tmp = button;
    var allSet = new Set();
    allGallery.forEach((tag) => {
      tmp[tag] = false;
      allSet.add(tag);
      if (filterTag[tag]) {
        if (intersectSet.size == 0) {
          intersectSet = tagToawSet[tag];
        } else
          intersectSet = new Set(
            [...intersectSet].filter((x) => tagToawSet[tag].has(x))
          );
      }
    });
    updateFilterResult(intersectSet);
    var tagIntersect = new Set();
    intersectSet.forEach((value) => {
      if (tagIntersect.size == 0) {
        tagIntersect = awToTagSet[value];
      } else tagIntersect = union(tagIntersect, awToTagSet[value]);
    });
    var symdiff = symmetricDifference(tagIntersect, allSet);
    if (symdiff.size != allSet.size) {
      symdiff.forEach((value) => {
        tmp[value] = true;
      });
    }
    updateFilterDisable((current) => ({ ...current, ...tmp }));
  }

  const ArtworkSelect = () => {
    const regex_zodiac = /zodiac_\d+/;
    const regex_color = /color_\d+/;
    const regex_day = /day_\d+/;
    const regex_other = /other_\d+/;
    const regex_luck =/luck_\d+/;

    if (allHeaderWithCatalog && catalog && filter && filterButtonDisable) {
      var awGallery = Object.entries(allHeaderWithCatalog).map(
        ([key, value]) => {
          var filterButton = value.map((e) => {
            if (tagToawSet[e].size == 0) return <></>;
            else if(e.match(regex_zodiac)||e.match(regex_color)||e.match(regex_day)||e.match(regex_other)||e.match(regex_luck)){
              return (
                <FilterButton
                  value={catalog[e]}
                  filter={e}
                  addFilter={addFilter}
                  removeFilter={removeFilter}
                  isChecked={filter[e]}
                  isDisable={filterButtonDisable[e]}
                />
              );
            }
          });
          return (
            <>
              {key.includes("zodiac") || key.includes("other") ? (
                <></>
              ) : (
                <SeperateLineStyled />
              )}
              <SubHeadTextStyled>{t(`FilterComponent.${headerCatalog[key]}`)}</SubHeadTextStyled>
              <ButtonFilterWrapperStyled>
                {filterButton}
              </ButtonFilterWrapperStyled>
            </>
          );
        }
      );
      return <div>{awGallery}</div>;
    } else return <></>;
  };

  return (
    <ContentWrapper>
      <CardWrapper>
        <CancelIconStyled src={CancelIcon} onClick={onClickClose} />
        <HeadTextStyled>{t("FilterComponent.เลือกตัวกรอง")}</HeadTextStyled>
        <ButtonFilterContainerStyled>
          <ArtworkSelect />
        </ButtonFilterContainerStyled>
        <FooterWrapper>
          <DoseartButton
            width={parseInt(width) < 600 ? "40vw" : "12.5vw"}
            isDisable={false}
            isPrimary={false}
            text={t("FilterComponent.ล้าง")}
            handleOnClick={clearFilter}
          />
          <DoseartButton
            width={parseInt(width) < 600 ? "40vw" : "12.5vw"}
            isDisable={false}
            isPrimary={true}
            text={t("FillInformationPage.ตกลง")}
            handleOnClick={() => {
              updateFilterAW(filterResult);
              onClickClose();
            }}
          />
        </FooterWrapper>
      </CardWrapper>
    </ContentWrapper>
  );
};

export default FilterComponent;
