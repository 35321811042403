import React, { useEffect, useState } from "react";
import styled from "styled-components";
import orientationSVG from "../assets/home/orientation.svg";
import closeSVG from "../assets/gallery/cancelButton.svg";
import { IS_ANDROID, IS_IOS } from "../utils/support";
import { useTranslation } from "react-i18next";
import { handleFontFamily } from "../utils/utils";

const ContentWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  /* width: ${(props) => props.width + "px"};
  height: ${(props) => props.height + "px"}; */
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: ${(props) => (props.isShow ? "12" : "-12")};
  opacity: ${(props) => (props.isShow ? "1" : "0")};
  touch-action: none;
`;

const CardWrapper = styled.div`
  position: relative;
  margin: 5% auto auto auto;
  background-color: #f9f9f9;
  //bottom: 0;
  border-radius: 15px;
  padding: 5% 5%;
  flex-direction: column;
  display: flex;
`;

const OrientationSVG = styled.img`
  width: 16vw;
  margin: 2vw auto;
`;

const CloseButton = styled.img`
  width: 3.2vw;
  position: absolute;
  top: 10px;
  right: 10px;
`;

const TextStyled = styled.div`
  margin: 0vh auto;
  font-size: 1.2rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: center;
`;

const HighlightText = styled.div`
  color: #e4202a;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 600;
  font-size: 1.2rem;
`;

const TextWrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
`;

const OrientationPopup = () => {
  const vw = window.innerWidth;
  const vh = window.innerHeight;
  const { t } = useTranslation();

  const [isLandscape, setIsLandscape] = useState(false);
  useEffect(() => {
    window.addEventListener(
      "orientationchange",
      handleOrientationChange,
      false
    );

    return function cleanup() {
      window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  const handleOrientationChange = () => {
    if (
      (window.orientation == 90 || window.orientation == -90) &&
      (IS_ANDROID || IS_IOS)
    ) {
      setIsLandscape(true);
      // disable scroll y
    } else {
      setIsLandscape(false);
      // enable scroll y
    }
  };

  const onClose = () => {
    setIsLandscape(false);
  };

  return (
    <ContentWrapper width={vw} height={vh} isShow={isLandscape}>
      <CardWrapper>
        <CloseButton src={closeSVG} onClick={onClose} />
        <OrientationSVG src={orientationSVG} />
        <TextStyled>{t("เพื่อประสบการณ์ที่ดีในการสั่งซื้อ")}</TextStyled>
        <TextWrapper>
          <TextStyled dangerouslySetInnerHTML={{__html: t("กรุณาหมุนหน้าจอโทรศัพท์ให้แสดงผลเป็น แนวตั้ง")}}></TextStyled>
        </TextWrapper>
      </CardWrapper>
    </ContentWrapper>
  );
};

export default OrientationPopup;
