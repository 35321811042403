import React from "react";
import styled from "styled-components";

import fluidText from "../utils/fluidText";
import { fluidRange } from "polished";
import { handleFontFamily } from "../utils/utils";

const ButtonStyled = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${(props) =>
    props.disable ? "#C4C4C4" : props.primary ? "#E4202A" : "white"};
  color: ${(props) => (props.primary ? "white" : "#E4202A")};
  pointer-events: ${(props) => (props.disable ? "none" : "initial")};
  //font-size: 1rem;
  padding: 1vh 0px;
  border-radius: 10px;
  ${handleFontFamily()}
  font-weight: 300;
  width: ${(props) => (props.width ? props.width : "auto")};
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: ${(props) =>
    props.disable
      ? "2px solid rgb(196, 196, 196)"
      : "2px solid rgb(228, 32, 42)"};
  ${fluidText(14, 18)}
  min-width: 120px;
  @media (min-width: 600px) {
    min-width: 170px;
    ${fluidRange(
      {
        prop: "font-size",
        fromSize: "16px",
        toSize: "19px",
      },

      "600px",
      "1800px"
    )}//font-size :1.1rem;;
  }
`;

const ButtonWrapper = styled.div`
  z-index: 1;
  @media (min-width: 600px) {
    font-size: 1.1rem;
    margin: ${(props) => (!props.isSetMargin ? "0 0.75vw" : "")};
  }
`;

const DoseartButton = ({
  width,
  isDisable,
  isPrimary,
  text,
  handleOnClick,
  isSetMargin,
}) => {
  return (
    <ButtonWrapper>
      <ButtonStyled
        type="button"
        width={width}
        primary={isPrimary}
        disable={isDisable}
        onClick={handleOnClick}
        isSetMargin={isSetMargin}
      >
        {text}
      </ButtonStyled>
    </ButtonWrapper>
  );
};

export default DoseartButton;
