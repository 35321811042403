import React, { useContext, useEffect, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  artworkDatabase,
  catalogDatabase as catalogDatabaseAtom,
  selectedIndex as selectedIndexAtom,
  selectedURL as selectedURLAtom,
  selectEditCard,
  nProduct,
  collection_type_atom,
  collection_type_object_atom,
  package_id_selection_atom,
  multiple_index_selection,
  multiple_url_selection,
  multple_attribute_selection,
  edit_card_state,
  bundleTagPrice,
} from "../../global/atom";
import styled from "styled-components";
import {
  select_package,
  sessionFormType,
  useSessionStorage,
  package_name_detail,
  session_select_edit_card,
  session_nProducts,
  session_bundle_tag_price,
  session_select_url,
  session_edit_card_state,
  session_select_index,
  session_collection_type_object,
  session_package_id_selection_atom,
  session_collection_type,
  session_is_preview_image,
  session_multiple_index_selection,
  session_multiple_url_selection,
  session_multple_attribute_selection,
  session_collection_selection_info,
  session_artwork_database,
  discountCoupon,
  session_name_length,
} from "../../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import {
  collection_selection_info as collection_selection_info_atom,
  isPreviewImage,
} from "../../global/atom";
import { sessionPrice } from "../../utils/sessionStorage";
import GalleryPageSelection from "./GalleryPage";
import {
  COLLECTION_API_URL,
  checkLangAvailableDatabase,
  checkNewLine,
  getImageListFromCollectionAndTag,
  handleFontFamily,
  handleNewLine,
} from "../../utils/utils";
import GalleryPageMultipleSelection from "./GalleryPageMultipleSelection";
import axios from "axios";
import fluidText from "../../utils/fluidText";
import { UserContext } from "../../utils/Context/userContext";
import useNavigationHandling from "../../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";

const ContainerWrapper = styled.div`
  width: 100%;
  height: auto;

  padding-bottom: 1rem;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-y: ${(props) => (props.isPreview ? "hidden" : "auto")};
  -webkit-overflow-scrolling: touch;
`;

const SelectionButton = styled.button`
  width: 90%;
  max-width: 360px;
  height: auto;

  margin: 0.3rem auto;

  border: none;
  border-radius: 16px;

  font-size: 32px;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: normal;
  color: ${(props) => (props.is_special ? "#b06e20" : "white")};
  background-color: ${(props) => (props.is_special ? "#e3c665" : "#aa0e19")};;
  box-shadow: 0px 4px 16px rgba(92, 92, 92, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px 0;
  div {
    width: 97%;
    height: 86%;
    border: white 1px solid;
    border-radius: 12px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    div {
      position: relative;
      width: 3rem;
      height: 3rem;
      margin: 1rem;
    }
    img {
      width: 3rem;
      max-height: 3rem;
      height: auto;
      margin: 1rem;
    }
    h3 {
      //margin-left: 1.6rem;
      //margin:0 1rem 0.5rem 0;
      ${fluidText(20, 26)}
      ${handleFontFamily()}
      text-align: left;
      font-style: normal;
      font-weight: normal;
      line-height: 100%;
      @media (min-width: 400px) {
        //font-size: 1.8rem;
        //margin-left: 2rem;
      }
      p {
        ${fluidText(20, 26)}
        margin:0;
        @media (min-width: 400px) {
          ${fluidText(20, 26)}//margin-left: 2rem;;;
        }
      }
    }
    span {
      width: auto;
      margin: 5px 0 0;
      height: 85%;
      padding:1rem 0;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      h3 {
        margin: 0;
        font-size: 1.6rem;
        ${handleFontFamily()}
        font-style: normal;
        font-weight: normal;
        line-height: 100%;
      }
      p {
        margin:5px 0 0;
        font-size: 1.2rem;
        ${handleFontFamily()}
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
      }
    }
  }

  :hover {
    /* background-color: black; */
    cursor: pointer;
    color: ${(props) => (props.is_special ? "#7f4807" : "#c8b280")};
  }
  /* transition: all 0.5s; */
`;

const SelectionButtonPackages = styled.button`
  width: 85%;
  max-width: 400px;
  height: auto;

  margin: 0.3rem auto;

  border: none;
  border-radius: 16px;

  font-size: 32px;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: normal;
  color: ${(props) => (props.is_special ? "#b06e20" : "white")};

  background-color: ${(props) => (props.is_special ? "#e3c665" : "#aa0e19")};;
  box-shadow: 0px 4px 16px rgba(92, 92, 92, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;

  div {
    width: 100%;
    height: 86%;
    border: white 1px solid;
    border-radius: 12px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    div {
      position: relative;
      width: 3rem;
      height: 3rem;
      margin: 1rem;
    }
    img {
      width: 3rem;
      height: auto;
      max-height: 3rem;
      margin: 1rem;
    }
    h3 {
      margin-left: 1.6rem;
      font-size: 2rem;
      ${handleFontFamily()}
      font-style: normal;
      font-weight: normal;
      line-height: 100%;
      @media (min-width: 400px) {
        margin-left: 2.4rem;
      }
    }
    span {
      width: auto;
      margin: 0;
      height: 85%;
      padding: 0.8rem 0;
      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      h3 {
        margin: 0 1rem 0.5rem 0;
        ${fluidText(20, 26)}
        ${handleFontFamily()}
        font-style: normal;
        font-weight: normal;
        line-height: 100%;
      }
      p {
        margin: 0;
        font-size: 1.2rem;
        ${handleFontFamily()}
        font-style: normal;
        font-weight: 300;
        line-height: 100%;

        s {
          text-decoration: line-through solid white 2px;
        }
        b {
          font-weight: 600;
          letter-spacing: 1px;
          margin-left: 0.3rem;
        }
      }
    }
  }

  :hover {
    /* background-color: black; */
    cursor: pointer;
    color: ${(props) => (props.is_special ? "#7f4807" : "#c8b280")};
  }
`;

const PageWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* padding-top: 15%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PageSelection = styled.div`
  width: 100%;
  height: 100%;
  /* padding-top: 15%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PackageSelectionWrapper = styled.div`
  width: 100%;
  height: 100%;
  /* padding-top: 15%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TitleText = styled.p`
  //width:88.46vw;
  margin: 2vh auto 2vh auto;
  ${fluidText(24, 28)}
  //font-size: 1.5rem; 
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 300;
  color: black;
  text-align: center;
  @media (min-width: 600px) {
    //width: 38vw;
    margin: 3vh auto 3vh auto;
  }
`;

/**
 * check collection_type case
 * ct_a_xx
 * ct_a_packages_xx
 * ct_b_xx
 * ct_c_xx
 * ct_d
 *
 * case ct_a
 * - read package_list
 * - title, count, full_price, discount_price
 *
 * case ct_b
 * - read page
 * - title page
 * - read package_list
 *
 * case ct_c
 * - set image fillter by topic selection
 *
 * case ct_d
 * - go gallery page (power user)
 */

/**
 * filter flow
 * db -> tag intersection
 */

/**
 * ct_a_state
 * - package
 * - selection
 */

const PackagePage = () => {
  //const collection_selection_info = useRecoilValue(collection_selection_info_atom);
  const { contextAWDb, voucher } = useContext(UserContext);
  //const [data,] = useSessionStorage(session_artwork_database.key,session_artwork_database.default)
  //const data = useRecoilValue(artworkDatabase);
  const [, updateFormType] = useSessionStorage(
    sessionFormType.key,
    sessionFormType.default
  );
  const [nameLength,update_name_length] = useSessionStorage(
    session_name_length.key,
    session_name_length.default,
  )
  const catalogDatabase = useRecoilValue(catalogDatabaseAtom);
  //const [, updateEditCard] = useRecoilState(selectEditCard);
  //const [, updateNProducts] = useRecoilState(nProduct);
  const [, updateEditCard] = useSessionStorage(
    session_select_edit_card.key,
    session_select_edit_card.default
  );
  const [, updateNProducts] = useSessionStorage(
    session_nProducts.key,
    session_nProducts.default
  );
  const [collection_selection_info] = useSessionStorage(
    session_collection_selection_info.key,
    session_collection_selection_info.default
  );
  const [, updateBundleTagPrice] = useSessionStorage(
    session_bundle_tag_price.key,
    session_bundle_tag_price.default
  );
  const [selectedURL, updateSelectUrl] = useSessionStorage(
    session_select_url.key,
    session_select_url.default
  );
  const [, updateEditCardState] = useSessionStorage(
    session_edit_card_state.key,
    session_edit_card_state.default
  );
  const [, updateSelectedIndex] = useSessionStorage(
    session_select_index.key,
    session_select_index.default
  );
  const [collection_type_object, update_collection_type_object] =
    useSessionStorage(
      session_collection_type_object.key,
      session_collection_type_object.default
    );
  const [, update_package_id_selection] = useSessionStorage(
    session_package_id_selection_atom.key,
    session_package_id_selection_atom.default
  );
  const [collection_type, set_collection_type] = useSessionStorage(
    session_collection_type.key,
    session_collection_type.default
  );
  const [isPreview] = useSessionStorage(
    session_is_preview_image.key,
    session_is_preview_image.default
  );
  // clear multiple selection gallery
  const [, updateMultipleSelectedIndex] = useSessionStorage(
    session_multiple_index_selection.key,
    session_multiple_index_selection.default
  );
  const [, updateMultipleSelectedUrl] = useSessionStorage(
    session_multiple_url_selection.key,
    session_multiple_url_selection.default
  );
  const [, updateMultipleAttribute] = useSessionStorage(
    session_multple_attribute_selection.key,
    session_multple_attribute_selection.default
  );

  const [isLoadedData, set_isLoadedData] = useState(false);
  const [state, updateState] = useState();
  const [ct_a_state, update_ct_a_state] = useState("package");
  const [filter_topic, update_filter_topic] = useState();
  const [max_image_selection, update_max_image_selection] = useState();
  const [selectPackage, updateSelectPackage] = useSessionStorage(
    select_package.key,
    select_package.default
  );
  const [product_price, updateProductPrice] = useSessionStorage(
    sessionPrice.key,
    sessionPrice.default
  );
  const [, update_package_name_detail] = useSessionStorage(
    package_name_detail.key,
    package_name_detail.default
  );
  const [discount_type] = useSessionStorage(
    discountCoupon.key,
    discountCoupon.default
  );
  const { navigateHandler } = useNavigationHandling();
  const { t } = useTranslation();

  // fetch then collect in recoil
  // seperate data collection

  async function getData(packages, object_arr) {
    for (const item of packages) {
      let package_ = (await axios(COLLECTION_API_URL + "/packages/" + item))
        .data;
      package_["package_id"] = item;
      object_arr.push(package_);
    }
  }

  useEffect(() => {
    if (collection_selection_info) {
      console.log(collection_type_object[collection_type])
      let collection_type_ = collection_selection_info.collection_type_id;
      if (collection_type !== collection_type_) {
        set_collection_type(collection_type_);
        // get collection data
        if (
          collection_type_.includes("ct_a") ||
          collection_type_.includes("ct_b")
        ) {
          axios
            .get(COLLECTION_API_URL + "/collection_types/" + collection_type_)
            .then((res) => {
              let data = res.data;
              if (collection_type_.includes("ct_a")) {
                /**
                 * structure
                 * collection obj
                 * - title
                 * - package
                 */
                let col_ct_a = {};
                col_ct_a.title = data.title;
                col_ct_a.package_list = [];
                getData(data.package_list, col_ct_a.package_list)
                  .then(() => {
                    update_collection_type_object({
                      ...collection_type_object,
                      [collection_type_]: col_ct_a,
                    });
                    // set_isLoadedData(true);
                  })
                  .catch((err) => console.error("Something failed:", err));
              } else if (collection_type_.includes("ct_b")) {
                /**
                 * structure
                 * collection obj
                 * - page
                 *   - title
                 *   - package
                 */
                let col_ct_b = [];
                getData(data.package_list, col_ct_b)
                  .then(() => {
                    update_collection_type_object({
                      ...collection_type_object,
                      [collection_type_]: col_ct_b,
                    });
                    // set_isLoadedData(true);
                  })
                  .catch((err) => console.error("Something failed:", err));
              }
            });
        }
      } else if (collection_type_.includes("ct_c") && catalogDatabase) {
        axios
          .get(COLLECTION_API_URL + "/collection_types/" + collection_type_)
          .then((res) => {
            let data = res.data;
            updateEditCard(0);
            updateNProducts(1);
            updateEditCardState("package");
            update_filter_topic(data.filter_topic);
            // let selectKey = "";
            // for (let item of Object.entries(catalogDatabase[0].tag)) {
            //   if (item[1] === data.filter_topic) {
            //     selectKey = item[0];
            //     console.log("selectKey: ", selectKey);
            //   }
            // }
            // update_filter_topic(selectKey);
          })
          .catch((err) => {
            console.error(err);
          });
      } else {
        set_isLoadedData(true);
      }
    }
  }, [
    collection_selection_info,
    collection_type,
    isLoadedData,
    collection_type_object,
    catalogDatabase,
  ]);

  function getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }

  //handle on click ct_b
  const handleClickSelectionButton = (tag) => {
    //next step check collection
    var selectKey = tag,
      image_list = [];
    // console.log("catalogDatabase: ", catalogDatabase[0]);

    // console.log("tag: ", tag);
    // // loop over tag
    // for (let item of Object.entries(catalogDatabase[0].tag)) {
    //   if (item[1] === tag) {
    //     selectKey = item[0];
    //     console.log("selectKey: ", item);
    //   }
    // }
    image_list = getImageListFromCollectionAndTag(
      contextAWDb,
      collection_selection_info.collection_type_id,
      selectKey
    );

    if (image_list.length > 0) {
      var index = Math.floor(Math.random() * image_list.length);
      var item = image_list[index];
      updateSelectedIndex(item.index);
      updateSelectUrl(item.image.thumbnailPath);
      updateProductPrice(item.image.price);
      updateFormType(item.image.form_type);
      let imageNameLength={
        first_name:item.image.firstNameLength,
        last_name:item.image.lastNameLength,
      }
      update_name_length(imageNameLength)
      updateEditCardState("package");
      //init number of product to show in cart page
      updateEditCard(0);
      updateNProducts(1);
      updateBundleTagPrice("");
      updateState("Preview");
    } else {
      alert("no image");
    }
  };

  // get package_list

  useEffect(() => {
    if (state === "Preview" && selectedURL) {
      if(window.location.href.includes('redeem')) navigateHandler("/redeem/filled");
      else navigateHandler("/filled");
    }
  }, [state, selectedURL]);

  // clean up
  useEffect(() => {
    console.log(collection_type_object[collection_type]);
    //console.log(collection_type_object[collection_type].title);
    return function cleanup() {
      updateState("");
    };
  }, []);

  const handleSelectPackage = (input) => {
    updateSelectPackage(input.template_id);
    updateProductPrice(input.package_price);
    update_package_id_selection(input.package_id);
    if (collection_type.includes("packages")) {
      //init number of product to show in cart page
      updateEditCardState("gallery");
      updateEditCard(0);
      updateNProducts(input.nArtwork);
      updateBundleTagPrice({
        tag: input.aw_tag_filter,
        price: input.aw_price_filter,
        select_package_name : input.select_package_name
      });
      setTimeout(() => {
        if(window.location.href.includes('redeem')) navigateHandler("/redeem/cart")
        else
        navigateHandler("/cart");
      }, 200);
    } else {
      updateEditCardState("package");
      update_max_image_selection(input.nArtwork);
      update_filter_topic(input.template_id);
      update_package_name_detail({
        package_name: input.package_name,
        icon: input.thumbnail,
        title : input.customise_title
      });
      // clear multiple image select
      updateMultipleSelectedIndex([]);
      updateMultipleAttribute([]);
      updateMultipleSelectedUrl([]);
      //init number of product to show in cart page
      updateEditCard(0);
      updateNProducts(1);
      setTimeout(() => {
        update_ct_a_state("selection");
      }, 200);
    }
  };

  return (
    <ContainerWrapper isPreview={isPreview}>
      {collection_selection_info && (
        <>
          {collection_type.includes("ct_a") &&
            collection_type_object[collection_type] && (
              <>
                {isLoadedData ? (
                  <>
                    {ct_a_state === "package" && (
                      <PackageSelectionWrapper>
                        <TitleText id="ct-a-title-text">
                          {t(checkLangAvailableDatabase(collection_type_object[collection_type].title).trim())}
                        </TitleText>
                        {collection_type_object[
                          collection_type
                        ].package_list.map((item, idx) => (
                          <SelectionButtonEl
                            key={idx}
                            item={item}
                            idx={idx}
                            handleSelectPackage={() =>
                              handleSelectPackage(item)
                            }
                            voucher={voucher}
                            discount_type={discount_type}
                          />
                        ))}
                      </PackageSelectionWrapper>
                    )}
                    {ct_a_state === "selection" && (
                      <GalleryPageMultipleSelection
                        filter_topic_id={filter_topic}
                        max_image_selection={max_image_selection}
                      />
                    )}
                  </>
                ) : (
                  <>loading. . .</>
                )}
              </>
            )}
          {collection_type.includes("ct_b") &&
            collection_type_object[collection_type] && (
              <>
                {isLoadedData ? (
                  <PageWrapper>
                    {collection_type_object[collection_type].map(
                      (page, p_idx) => (
                        <PageSelection key={p_idx}>
                          <TitleText>{t(checkLangAvailableDatabase(page.title))}</TitleText>
                          {page.selection_list.map((item, idx) => (
                            <SelectionButton_ct_b_El
                              key={idx}
                              item={item}
                              idx={idx}
                              voucher={voucher}
                              discount_type={discount_type}
                              handleClickSelectionButton={
                                handleClickSelectionButton
                              }
                            />
                          ))}
                        </PageSelection>
                      )
                    )}
                  </PageWrapper>
                ) : (
                  <>loading. . .</>
                )}
              </>
            )}
          {collection_type.includes("ct_c") && filter_topic && (
            <GalleryPageSelection filter_topic_id={filter_topic} />
          )}
        </>
      )}
    </ContainerWrapper>
  );
};

const SelectionButtonEl = ({
  item,
  idx,
  handleSelectPackage,
  voucher,
  discount_type,
}) => {
  const [hover, set_hover] = useState(false);
  const { t } = useTranslation();

  var total_amount = item.package_price
  if (voucher != "") {
    if (discount_type.type == "AMOUNT") {
      var discount_amount = (discount_type.amount_off / 100).toFixed(2);
      total_amount = (total_amount - discount_amount).toFixed(2);
    }
    //percent
    else if (discount_type.type == "PERCENT") {
      var discount_amount = (
        (discount_type.percent_off / 100) *
        total_amount
      ).toFixed(2);
      total_amount = (total_amount - discount_amount).toFixed(2);
    }
    if (total_amount < 0) total_amount = 0.0;
  }
  return (
    <SelectionButtonPackages
      onClick={handleSelectPackage}
      onMouseEnter={() => set_hover(true)}
      onMouseLeave={() => set_hover(false)}
      is_special={item.special_button=='TRUE'}
    >
      <div>
        <img src={hover ? item.logo_hover : item.logo} />
        <span>
          {checkNewLine(t(checkLangAvailableDatabase(item.package_name)))}
          {voucher != "" && !item.full_price ? (
            <p>
              <s>{item.package_price}</s>{" "}
              <b>
                {total_amount} {t("บาท")}
              </b>
            </p>
          ) : voucher != "" && item.full_price ? (
            <p>
              <s>{item.package_price}</s> <s>{item.package_price}</s>{" "}
              <b>
                {total_amount} {t("บาท")}
              </b>
            </p>
          ) : item.full_price ? (
            <p>
              <s>{item.full_price}</s>{" "}
              <b>
                {item.package_price} {t("บาท")}
              </b>
            </p>
          ) : (
            <p>
              {item.package_price} {t("บาท")}
            </p>
          )}
        </span>
      </div>
    </SelectionButtonPackages>
  );
};

const SelectionButton_ct_b_El = ({
  item,
  idx,
  handleClickSelectionButton,
  voucher,
  discount_type,
}) => {
  const { t } = useTranslation();
  const [hover, set_hover] = useState(false);
  var total_amount = item.price;
  if (voucher != "") {
    if (discount_type.type == "AMOUNT") {
      var discount_amount = (discount_type.amount_off / 100).toFixed(2);
      total_amount = (total_amount - discount_amount).toFixed(2);
    }
    //percent
    else if (discount_type.type == "PERCENT") {
      var discount_amount = (
        (discount_type.percent_off / 100) *
        total_amount
      ).toFixed(2);
      total_amount = (total_amount - discount_amount).toFixed(2);
    }
    if (total_amount < 0) total_amount = 0.0;
  }
  return (
    <SelectionButton
      onClick={() => handleClickSelectionButton(item.tag)}
      onMouseEnter={() => set_hover(true)}
      onMouseLeave={() => set_hover(false)}
      is_special={item.special_button=='TRUE'}
    >
      <div>
        <img src={hover ? item.logo_hover : item.logo} />
        <span>
          {checkNewLine(t(checkLangAvailableDatabase(item.title).trim()))}
          {item.price && voucher != "" ? (
            <h3>
            <p>
              <s>{item.price}</s>{" "}
              <b>
                {total_amount} {t("บาท")}
              </b>
            </p>
            </h3>
          ) : item.price && voucher == "" ? (
            <h3>
            <p>
              {item.price} {t("บาท")}
            </p>
            </h3>
          ) : (
            <></>
          )}
       
        </span>
      </div>
    </SelectionButton>
  );
};

export default PackagePage;
