import React from "react";
import { useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { isPreviewImage, selectedIndex, selectedURL } from "../global/atom";
import { session_is_preview_image, useSessionStorage } from "../utils/sessionStorage";
import GalleryPageSelection from "./HomePage/GalleryPage";

const ContainerWrapper = styled.div`
  width: 100%;
  height: auto;

  padding-top: 3rem;
  padding-bottom: 1rem;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: ${(props) => (props.isPreview ? "hidden" : "auto")};
`;

const GalleryMultipleSelectionPage = () => {
  //const isPreview = useRecoilValue(isPreviewImage);
  const[isPreview,] = useSessionStorage(session_is_preview_image.key,session_is_preview_image.default)
  const { topic_id, aw_price } = useParams();

  return (
    <ContainerWrapper isPreview={isPreview}>
      <GalleryPageSelection
        filter_topic_id={topic_id}
        filter_price={aw_price}
        show_price={!aw_price}
      />
    </ContainerWrapper>
  );
};

export default GalleryMultipleSelectionPage;
