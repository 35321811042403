import React from "react";
import styled from "styled-components";
import RectangleImg from "../assets/cart/Rectangle.svg";
import EditImg from "../assets/cart/editImage.svg";
import fluidText from "../utils/fluidText";
import { useTranslation } from "react-i18next";
import { checkLangAvailableDatabase, handleFontFamily } from "../utils/utils";

const CartWrapper = styled.button`
  background-color: #f9f9f9;
  width: 88.46vw;
  margin: 1vh auto;
  box-shadow: 4px 4px 10px rgba(0, 0, 0, 0.15);
  border-radius: 24px;
  cursor: pointer;
  align-items: center;
  flex-direction: row;
  display: flex;
  border: 3px solid transparent;
  &:focus {
    border: 3px solid #e4202a;
  }
  @media (min-width: 600px) {
    width: 38vw;
    margin: 0vh auto 2vh auto;
    min-width: 400px;
  }
`;

const PreviewImage = styled.img`
  //width: 13vw;
  height: 16vh;
  margin: 3vw 4vw 3vw 10vw;
  pointer-events: none;
  @media (min-width: 600px) {
    //width: 5.3vw;
    height: 20vh;
    margin: 1.8vw 1.8vw 1.8vw 4vw;
  }
`;

const PreviewIcon = styled.img`
  height: 8vh;
  pointer-events: none;
  margin: 4vh 4vw;
  @media (min-width: 600px) {
    //width: 5.3vw;
    margin: 6vh 1.8vw;
  }
`;

const HeaderCardText = styled.div`
  ${fluidText(18, 23)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 600;
  color: black;
  text-align: left;
  align-items: center;
  display: flex;
  justify-content: space-between;
  @media (min-width: 600px) {
    margin-bottom: 1vh;
    //font-size: 1.3rem;
  }
`;

const DetailCardText = styled.div`
  ${fluidText(15, 22)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  width: 46vw;
  color: ${(props) => (props.filled ? "black" : "#D1D9E6")};
  text-align: left;
  overflow: hidden;
  /* none -> cannot scroll */
  /* touch-action: none;
    -ms-touch-action: none; */
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (min-width: 600px) {
    width: 20vw;
  }
`;

const DetailWrapper = styled.div`
  width: 69vw;
  @media (min-width: 600px) {
    width: 29vw;
  }
`;

const EditImageWrapper = styled.img`
  width: 4vw;
  margin-right: 3vw;
  @media (min-width: 600px) {
    width: 2.2vh;
    margin-right: 2vw;
  }
`;

const CartCard = ({
  isFilled,
  previewImage,
  name,
  birthdate,
  zodiac,
  isShowDate,
  chinese_zodiac,
  day_of_week,
  handleOnClick,
  tagFilter,
  catalogDatabase,
  packageDetail,
  packageName,
}) => {
  const { t } = useTranslation();
  return (
    <CartWrapper onClick={handleOnClick}>
      {packageDetail && packageDetail.icon != "" ? (
        <PreviewIcon src={packageDetail.icon} />
      ) : (
        <PreviewImage src={isFilled ? previewImage : RectangleImg} />
      )}
      <DetailWrapper>
        <>
          <HeaderCardText>
            {packageDetail && packageDetail.package_name == ""
              ? packageName 
                ? (t(checkLangAvailableDatabase(packageName))).replace('\n','').replace('\\n',' ')
                : t("CartPage.ภาพพื้นหลัง")
              : t(checkLangAvailableDatabase(packageDetail.package_name).split("(")[0]).replace("\n",'').replace('\\n',' ')}
            <EditImageWrapper src={EditImg} />
          </HeaderCardText>
          {name == " " ? (
            <DetailCardText filled={false}>
              {t("CartPage.ชื่อ นามสกุล")}
            </DetailCardText>
          ) : (
            <DetailCardText filled={true}>
              {name}
            </DetailCardText>
          )}
          <DetailCardText filled={true}>
            {birthdate}
          </DetailCardText>
          <DetailCardText filled={true}>
            {zodiac}
          </DetailCardText>
          {chinese_zodiac != "" ? (
            <DetailCardText filled={true}>
              {chinese_zodiac}
            </DetailCardText>
          ) : (
            <></>
          )}
          {day_of_week != "" ? (
            <DetailCardText filled={true}>
              {day_of_week}
            </DetailCardText>
          ) : (
            <></>
          )}
        </>
      </DetailWrapper>
    </CartWrapper>
  );
};

export default CartCard;
