import { atom, selector } from "recoil";
import localStorageEffect from "../utils/localStorageEffect";
import { useSessionStorage } from "../utils/sessionStorage";

export const page = atom({
  key: "page",
  default: "/start",
});

export const serverStatus = atom({
  key: "serverStatus",
  default: true,
});

// CartFillInComplete,CartFillComplete,SelectImage,FillInformation,
//SendingMessage,PreviewGenerate,Payment,waitCheckout,WaitNonWaterMark,DownloadSuccess

export const state = atom({
  key: "state",
  default: "",
});

export const previewIndex = atom({
  key: "previewIndex",
  default: -1,
});

export const selectedIndex = atom({
  key: "selectedIndex",
  default: -1,
  effects_UNSTABLE: [localStorageEffect("current_selectedIndex")],
});

export const selectedURL = atom({
  key: "selectedURL",
  default: "",
});

export const alertedQr = atom({
  key: "alertedQr",
  default: false,
});

export const cardChargeID = atom({
  key: "cardChargeID",
  default: "",
});

export const formList = atom({
  key: "fromList",
  default: {
    fill: false,
    name: "",
    surname: "",
    birthDate: 29,
    birthMonth: 11, //month 0-11
    birthYear: 1994,
    zodiacId: 0,
    selectImageId: "",
    selectImage: "https://storage.googleapis.com/doseart_asia_output/20210316-153528",
    showBirthDate: true,
    id: "",
  },
  effects_UNSTABLE: [localStorageEffect("current_formList")],
});

export const prevFormList = atom({
  key: "prevFromList",
  default: [
    {
      fill: false,
      name: "",
      surname: "",
      birthDate: 29,
      birthMonth: 11,
      birthYear: 1994,
      zodiacId: 0,
      selectImageId: "",
      selectImage: "https://storage.googleapis.com/doseart_asia_output/20210316-153528",
      showBirthDate: true,
    },
  ],
});

export const previewLoading = atom({
  key: "previewLoading",
  default: [],
});

export const previewLoadingProgress = atom({
  key: "previewLoadingProgress",
  default: []
})

export const referenceID = atom({
  key: "referenceID",
  default: "0",
  effects_UNSTABLE: [localStorageEffect("current_referenceID")],
});

export const contactID = atom({
  key: "contactID",
  default: "",
  effects_UNSTABLE: [localStorageEffect("current_contactID")],
});

export const productPrice = atom({
  key: "productPrice",
  default: 59,
  effects_UNSTABLE: [localStorageEffect("current_productPrice")],
});

export const nProduct = atom({
  key: "nProduct",
  default: "",
  effects_UNSTABLE: [localStorageEffect("current_nProduct")],
});

export const bundleTagPrice = atom({
  key: "bundleTagPrice",
  default: "",
  effects_UNSTABLE: [localStorageEffect("current_bundleTagPrice")],
});
export const prevBundleTagPrice = atom({
  key: "prevBundleTagPrice",
  default: "",
  effects_UNSTABLE: [localStorageEffect("current_prevBundleTagPrice")],
});

export const selectEditCard = atom({
  key: "selectEditCard",
  default: "",
  effects_UNSTABLE: [localStorageEffect("current_selectEditCard")],
});

export const orderID = atom({
  key: "orderID",
  default: "",
  effects_UNSTABLE: [localStorageEffect("current_orderID")],
});

export const artworkDatabase = atom({
  key: "artworkDatabase",
  default: "",
});

export const catalogDatabase = atom({
  key: "catalogDatabase",
  default: "",
});

export const dateDatabase = atom({
  key: "dateDatabase",
  default: "",
});

export const formTypeDatabase = atom({
  key: "formTypeDatabase",
  default: "",
});

export const filterAW = atom({
  key: "filterAW",
  default: "",
});

export const order_type_id = atom({
  key: "order_type_id",
  default: "",
  effects_UNSTABLE: [localStorageEffect("current_order_type_id")],
});

export const collection_selection_info = atom({
  key: "collection_selection_info",
  default: {},
  effects_UNSTABLE: [localStorageEffect("current_collection_selection")],
});

export const collection_type_object_atom = atom({
  key: "collection_type_object",
  default: {},
});

export const package_id_selection_atom = atom({
  key: "package_id_selection",
  default: "",
  effects_UNSTABLE: [localStorageEffect("current_package_id_selection_atom")],
});

export const collection_type_atom = atom({
  key: "collection_type",
  default: "",
});

export const collections_atom = atom({
  key: "collections_atom",
  default: "",
});

export const collection_tags = atom({
  key: "collection_tags",
  default : [],
})

export const fetch_collection = atom({
  key: 'fetch_collection',
  default : []
})

export const isPreviewImage = atom({
  key: "preview_image",
  default: false,
});

export const multiple_index_selection = atom({
  key: "multiple_image_selection",
  default: [],
  effects_UNSTABLE: [localStorageEffect("current_multiple_image_selection")],
});

export const multiple_url_selection = atom({
  key: "multiple_image_url_selection",
  default: [],
  effects_UNSTABLE: [localStorageEffect("current_multiple_image_url_selection")],
});

export const multple_attribute_selection = atom({
  key: "multple_attribute_selection",
  default: [],
  effects_UNSTABLE: [localStorageEffect("current_multple_attribute_selection")],
});

export const select_package = atom({
  key: "select_package",
  default: "",
  effects_UNSTABLE: [localStorageEffect("current_select_package")],
});

export const edit_card_state = atom({
  key: "edit_card_state",
  default: "",
  effects_UNSTABLE: [localStorageEffect("current_edit_card_state")],
});

export const chinese_zodiac_list = atom({
  key: "chinese_zodiac_list",
  default: [],
  effects_UNSTABLE: [localStorageEffect("current_chinese_zodiac_list")],
});

export const day_of_week_list = atom({
  key: "day_of_week_list",
  default: [],
  effects_UNSTABLE: [localStorageEffect("current_day_of_week_list")],
});

export const artwork_show_birthdate = atom({
  key: 'artwork_show_birthdate',
  default: '',
  effects_UNSTABLE: [localStorageEffect('current_artwork_show_birthdate')]
})

export const fbc_cookies = atom({
  key: 'fbc_cookies',
  default: '',
})

export const fbp_cookies = atom({
  key: 'fbp_cookies',
  default: '',
})

export const phoneNumberState = atom({
  key:'phoneNumberState',
  default:''
})

export const checkPhoneNumberState = selector({
  key:'checkPhoneNumberState',
  get: ({get})=>{
    const text = get(phoneNumberState)
    return text.length>0;
  }
})