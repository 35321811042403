import React, {
  useState,
  useEffect,
  useMemo,
  useRef,
  useLayoutEffect,
} from "react";
import { isMobilePhone, isEmail } from "validator";
import styled from "styled-components";
import FooterComponent from "../components/FooterComponent";
import TextField from "@material-ui/core/TextField";
import tipImg from "../assets/cart/tips.svg";
import Radio from "@material-ui/core/Radio";

import MasterCardLogo from "../assets/payment/mastercard-logo.svg";
import VisaCardLogo from "../assets/payment/visa.svg";
import PromptpayLogo from "../assets/payment/promtpay.png";
import KbankLogo from "../assets/payment/kbank.png";
import KrungSriLogo from "../assets/payment/krungsribank.png";
import KrungThaiLogo from "../assets/payment/krungthaibank.png";
import BkkBankLogo from "../assets/payment/bkkbank.png";
import ScbBankLogo from "../assets/payment/scbbank.png";
import AlipayLogo from "../assets/payment/Alipay.svg";

import {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
  validatePhoneNumberLength
} from 'libphonenumber-js'
import Checkbox from "@material-ui/core/Checkbox";
import ReactPixel from "react-facebook-pixel";
import {
  state as stateAtom,
  contactID as contactIDAtom,
  orderID as orderIDAtom,
  formList,
  dateDatabase as dateDatabaseAtom,
  artworkDatabase,
  productPrice as productPriceAtom,
  alertedQr,
  cardChargeID as cardChargeIDAtom,
  package_id_selection_atom,
  fbc_cookies,
  fbp_cookies,
} from "../global/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import NotificationCard from "../components/NotificationCard";
import { useNavigate } from "react-router";
import DoseartButton from "../components/DoseartButton";
import { subscribe } from "on-screen-keyboard-detector";
import { IS_ANDROID, IS_FACEBOOK_APP, IS_IOS } from "../utils/support";
import fluidText from "../utils/fluidText";
import { fluidRange } from "polished";
import {
  checkChargeId,
  checkSumOrderID,
  createQROrder,
  getThaiMoonPhase,
  createOrderIDInDatabase,
  addCustomerToDatabase,
  getAllProductPackages,
  createCardCharge,
  recreateOrderIDIndatabase,
  checkValidVoucher,
  releaseValidVoucher,
  DEPLOY_VERSION,
  checkPaymentStatusFromDatabase,
  sendConversionAPI,
  payWithAIS,
  API_URL,
  handleFontFamily,
  getOrderData,
  sendErrorToChatbot,
} from "../utils/utils";
import Paymentloader from "../components/PaymentLoader";
import { Link } from "react-router-dom";
import { useSessionStorage } from "react-use";
import {
  session_artwork_database,
  sessionDateDatabase,
  select_package,
  sessionOrderID,
  sessionPrice,
  sessionRefID,
  sessionShowKpayment,
  sessionState,
  sessionContact,
  paymentMethod,
  sessionVoucher,
  sessionTotalAmount,
  multiple_attribute,
  reffer_url,
  session_package_id_selection_atom,
  session_voucher_valid_key,
  sessionReferenceOrder,
  session_fbc_cookies,
  session_fbp_cookies,
  session_indexUID,
  session_phone_number,
} from "../utils/sessionStorage";
import { format, utcToZonedTime } from "date-fns-tz";
import {
  deleteOrderInIndexedDB,
  getIndexedCustomer,
  getIndexedStore,
  updateIndexedDBOrderId,
  updateIndexedDBPaymentStatus,
  updatePaymentMethodIndexedDB,
} from "../utils/IndexedDBStore";
import { fi } from "date-fns/locale";
import TagManager from "react-gtm-module";
import axios from "axios";
import NotificationIcon from "../assets/cart/noti.svg";
import { useContext } from "react";
import { UserContext } from "../utils/Context/userContext";
import useNavigationHandling from "../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";

const HeadTextStyled = styled.div`
  width: 88.46vw;
  margin: 4vh auto 1vh auto;
  //font-size: 1.5em;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: center;
  ${fluidText(20, 25)}
  @media(min-width:600px) {
    width: auto;
    margin: 3vh auto 3vh auto;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    width: 40vw;
    margin: auto;
    min-width: 500px;
  }
  //align-items:center;
`;

const HeaderCardTextStyled = styled.div`
  //width:88.46vw;
  //margin: 4vh auto 4vh auto;

  ${fluidText(16, 20)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 600;
  color: black;
  text-align: left;
  height: 33px;
  line-height: 33px;
  //margin-bottom: 1.5vh;
`;

const CardTextStyled = styled.div`
  //width:88.46vw;
  //margin: 4vh auto 4vh auto;
  //font-size: 0.8em;
  ${fluidRange(
    {
      prop: "font-size",
      fromSize: "12px",
      toSize: "17px",
    },

    "300px",
    "1400px"
  )}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: left;
`;

const EmailTextStyled = styled.div`
  margin: 0vh auto;
  width: 82.3vw;
  ${fluidText(15, 18)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: left;
  @media (min-width: 600px) {
    //font-size: 1rem;
    width: 32vw;
    min-width: 400px;
  }
`;

const CardWrapper = styled.div`
  width: 88.4vw;
  border-radius: 24px;
  filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.15));
  flex-direction: column;
  display: flex;
  margin: auto;
  background: #f9f9f9;
  @media (min-width: 600px) {
    width: 34vw;
    min-width: 450px;
  }
  padding: 15px 0px;
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    width: 82.3vw;
    height: 5.33vh;
    margin: 1vh auto 1vh auto;
    ${handleFontFamily()}
    font-style: normal;
    font-weight: 400;
    @media (min-width: 600px) {
      width: 28vw;
      min-width: 350px;
    }
    fieldset {
      border-color: #e5e5e5;
    }
    &.Mui-focused fieldset {
      border-color: #e5e5e5;
    }
    background-color: #e5e5e5;
  }
`;

const StyleCouponTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    width: 74.3vw;
    height: 5.33vh;
    margin: 1vh auto 1vh auto;
    ${handleFontFamily()}
    font-style: normal;
    font-weight: 400;
    @media (min-width: 600px) {
      width: 28vw;
      min-width: 350px;
    }
    fieldset {
      border-color: #e5e5e5;
    }
    &.Mui-focused fieldset {
      border-color: #e5e5e5;
    }
    background-color: #e5e5e5;
  }
`;

const TipImage = styled.img`
  width: 9.2vw;
  margin-left: 2vw;
  margin-bottom: 1vh;
  @media (min-width: 600px) {
    width: 6vh;
    height: 6vh;
  }
`;

const TipsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  width: 88.46vw;
  margin: auto;
  margin-bottom: 2vh;
  @media (min-width: 600px) {
    width: 34vw;
    min-width: 450px;
    margin-bottom: 2vh;
  }
`;

const TipsRedTextStyled = styled.div`
  ${fluidText(12, 20)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: #e4202a;
  text-align: left;
  word-wrap: normal;
  @media (min-width: 600px) {
    //font-size: 1rem;
  }
  //margin-left : 5.1vw;
`;

const TipsTextStyled = styled.div`
  ${fluidText(12, 20)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: left;
  word-wrap: normal;
  @media (min-width: 600px) {
    //font-size: 1rem;
  }
  //margin-left : 5.1vw;
`;

const UnOrderedListStyled = styled.ul`
  list-style-position: outside;
`;

const CardLogoImage = styled.img`
  // width:13vw;
  height: 8vw;
  width: auto;
  margin-right: 8vw;
  @media (min-width: 600px) {
    height: 2vw;
    margin-right: 1vw;
  }
`;
const PromptpayLogoImage = styled.img`
  // width:13vw;
  height: 14vw;
  width: auto;
  margin-right: 8vw;
  @media (min-width: 600px) {
    height: 2vw;
    margin-right: 1vw;
  }
`;
const BankLogoImage = styled.img`
  // width:13vw;
  height: 8vw;
  width: auto;
  margin-right: 4vw;
  @media (min-width: 600px) {
    height: 2vw;
    margin-right: 1vw;
  }
`;

const CreditCardWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding-right: 10vw;
  @media (min-width: 600px) {
    padding-right: 4vw;
  }
`;

const CreditCardTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0.5vh;
  margin-bottom: 1vh;
`;

const CardLogoWrapper = styled.div`
  margin: 1vh 0vw;
  display: flex;
  flex-direction: row;
`;

const NotiContentWrapper = styled.div`
  width: 100vw;
  height: 100%;
  //align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: 10;
`;

const NotificationImage = styled.img`
  width: 15.7vw;
  margin: 3vh auto;
  @media (min-width: 600px) {
    //width: 4.3vw;
    height: 6.5vh;
    margin: 3.4vh auto;
  }
`;

const NotiHeadTextStyled = styled.div`
  width: 71.84vw;
  margin: 0vh auto 1vh auto;
  font-size: 1.3rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 600;
  color: black;
  text-align: center;
  @media (min-width: 600px) {
    width: 24vw;
    min-width: 380px;
    font-size: 1.6rem;
  }
`;

const NotiTextStyled = styled.div`
  width: 71.84vw;
  margin: 1vh auto 2vh auto;
  font-size: 1.1rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: center;
  @media (min-width: 600px) {
    width: 24vw;
    min-width: 380px;
    font-size: 1.3rem;
  }
`;

const NotiCardWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  width: 90vw;
  height: min-content;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  bottom: 0;
  border-radius: 15px;
  padding: 2vh 1vw;
  flex-direction: column;
  display: flex;
  @media (min-width: 600px) {
    width: 24vw;
    padding: 2vh 1.5vw;
    min-width: 400px;
    //padding: 0 1vw;
  }
`;

const ButtonWrapper = styled.div`
  width: 90vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin: 0vh auto;
  @media (min-width: 600px) {
    width: 24vw;
    min-width: 400px;
    //margin : 1vh auto 1.5vw auto;
  }
`;

const PaymentNotiWrapper = styled.div`
  display: flex;
  padding: 1vh auto 1vh auto;
  flex-direction: column;
`;

const DoseartButtonWrapper = styled.div`
  margin-right: 2vw;
  @media (min-width: 600px) {
    margin-right: 0vw;
  }
`;

const NotiCouponButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
`;

const useToggleOnFocus = (initialState = false) => {
  const [show, toggle] = useState(initialState);

  const eventHandlers = useMemo(
    () => ({
      onFocus: () => toggle(true),
      onBlur: () => toggle(false),
    }),
    []
  );

  return [show, eventHandlers];
};

let OmiseCard;

const PaymentPage = () => {
  const [checked, setChecked] = useState(false);
  const [state, updateState] = useRecoilState(stateAtom);
  const [email, updateEmail] = useState("");
  const [showNoti, updateShowNoti] = useState(false);
  const [contactID, updateContactID] = useSessionStorage(
    sessionContact.key,
    sessionContact.default
  );
  const [hide, updateHide] = useState(false);
  const [height, updateHeight] = useState(window.innerHeight);
  const [focus, handleFocus] = useToggleOnFocus();
  const [orderID, updateOrderID] = useSessionStorage(
    sessionOrderID.key,
    sessionOrderID.default
  );
  const [reference_order, updateReferenceOrder] = useSessionStorage(
    sessionReferenceOrder.key,
    sessionReferenceOrder.default
  );
  const { navigateHandler } = useNavigationHandling();
  const { t, i18n } = useTranslation();
  //const fomrListValue = useRecoilValue(formList)

  const width = window.innerWidth;
  const [chargeId, updateChargeId] = useState(null);
  const [showPaymentLoader, setPaymentLoader] = useState(false);
  const [paymentStatus, updatePaymentStatus] = useState("");
  const [referenceID, updateRefID] = useSessionStorage(
    sessionRefID.key,
    sessionRefID.default
  );
  const [isGetChargeId, setIsGetChargeId] = useState(false);
  const [isUpdateByIndex, setIsUpdateByIndex] = useState(false);
  //const [productPackage, updateProductPackage] = useState('package_1')
  //const data = useRecoilValue(artworkDatabase)
  const { contextAWDb, voucher, update_voucher, formList, updateFormList } =
    useContext(UserContext);
  //const [data,] = useSessionStorage(session_artwork_database.key,session_artwork_database.default)
  var checkInquiryTimeout = useRef();
  var checkPaymentStatus = useRef();
  var keyboardDetect = useRef();
  //const [dateDatabase,updateDateDatabase] =useSessionStorage(sessionDateDatabase.key,sessionDateDatabase.default)
  const [dateDatabase, updateDateDatabase] = useRecoilState(dateDatabaseAtom);
  const [productPrice, updateProductPrice] = useSessionStorage(
    sessionPrice.key,
    sessionPrice.default
  );
  const [finishStatus, setfinishStatus] = useState(false);
  const [isKPaymentShow, setKPaymentShow] = useSessionStorage(
    sessionShowKpayment.key,
    sessionShowKpayment.default
  );
  const [isTriggerBackButton, setIsTriggerBackButton] = useState(false);
  const [isContactError, setContectError] = useState(false);
  const [showQRCodeAlert, setShowQRCodeAlert] = useRecoilState(alertedQr);
  const [SelectedPaymentMethod, setSelectedPaymentMethod] = useSessionStorage(
    paymentMethod.key,
    paymentMethod.default
  );
  //const [voucher, update_voucher] = useSessionStorage(sessionVoucher.key, sessionVoucher.default)
  const [total_amount, update_total_amount] = useSessionStorage(
    sessionTotalAmount.key,
    sessionTotalAmount.value
  );
  const [token, setToken] = useState();
  const [cardChargeID, setCardChargeID] = useRecoilState(cardChargeIDAtom);
  const [selectPackage, updateSelectPackage] = useSessionStorage(
    select_package.key,
    select_package.default
  );
  const [phoneNumber,] = useSessionStorage(session_phone_number.key,session_phone_number.default) 
  //const [package_id, updatePackageID] = useRecoilState(package_id_selection_atom)
  const [package_id, updatePackageID] = useSessionStorage(
    session_package_id_selection_atom.key,
    session_package_id_selection_atom.default
  );
  const [multipleAttribute, updateMultipleAttribute] = useSessionStorage(
    multiple_attribute.key,
    multiple_attribute.default
  );
  const [sales_partner, update_sales_partner] = useSessionStorage(
    reffer_url.key,
    reffer_url.default
  );
  const onBackButtonEvent = (e) => {
    e.preventDefault();
    setIsTriggerBackButton(true);
  };
  const [loadDataState, updataLoadDataState] = useState(false);
  const [indexUID, updateIndexUID] = useState(); //use to recover from indexedDB
  const [changePage, updateChangePage] = useState("");
  const [voucher_valid_key, update_voucher_valid_key] = useSessionStorage(
    session_voucher_valid_key.key,
    session_voucher_valid_key.default
  );
  const [s_indexUID,update_s_indexUID] = useSessionStorage(
    session_indexUID.key,
    session_indexUID.default
  )
  const [isSessionClear, updateSessionClear] = useState(false);
  const [countCheckPayment, updateCountCheckPayment] = useState(0);
  //const fbc= useRecoilValue(fbc_cookies)
  const [fbc,setFbc] = useSessionStorage(
    session_fbc_cookies.key,
    session_fbc_cookies.default
  );
  //const fbp = useRecoilValue(fbp_cookies)
  const [fbp,setFbp] = useSessionStorage(
    session_fbp_cookies.key,
    session_fbp_cookies.default
  );
  const [isMobile,update_isMobile] = useState(false);

  useEffect(() => {
    if (isTriggerBackButton) {
      if (!finishStatus && isKPaymentShow) {
        setShowQRCodeAlert(true);
      } else {
        navigateHandler("/preview");
      }
    }
  }, [isTriggerBackButton]);

  useEffect(() => {
    if (showQRCodeAlert) {
      if (window.confirm(t("การกดย้อนกลับจะทำให้ QR code นี้ใช้การไม่ได้"))) {
        setShowQRCodeAlert(false);
        setfinishStatus(true);
        navigateHandler("/preview");
        // your logic
        //props.navigateHandler("/");
      } else {
        try {
          window.navigateState(null, null, window.location.href);
          setfinishStatus(false);
        } catch (err) {
          console.error(err);
        }
      }
    }
  }, [showQRCodeAlert]);

  useEffect(() => {
    if (!isKPaymentShow && voucher != "" && voucher_valid_key != "") {
      releaseVoucherSession();
    }
  }, [isKPaymentShow]);

  useEffect(async () => {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    getFbCookies();
    setKPaymentShow(false);
    setShowQRCodeAlert(false);
    update_isMobile(IS_ANDROID||IS_IOS)
    OmiseCard = window.OmiseCard;
    OmiseCard.configure({
      publicKey: "pkey_5wcfaureic9uhpzxlec", // update public key
      currency: "thb",
      frameLabel: "Luckydose",
      submitLabel: "PAY NOW",
      buttonLabel: "Pay with Omise",
    });

    if (IS_IOS) {
      setPaymentLoader(true);
    }

    if (urlParams.get("chargeId") != null) setPaymentLoader(true);
    if (!isGetChargeId) {
      updateChargeId(urlParams.get("chargeId"));
    }
    
    if (urlParams.has("order")) {
      updateIndexUID(urlParams.get("order"));
    }

    setToken(urlParams.get("token"));
    if (
      state == "Payment" ||
      state == "PreviewGenerate" ||
      state == "PaymentSuccess"
    ) {
      if (state !== "PaymentSuccess") updateState("Payment");
      else setPaymentLoader(true);
    } else {
      //navigateHandler('/')
    }
    if (orderID != null && orderID != "" && urlParams.get("chargeId") == null) {
      checkSumByOrderId();
    }
    updateState("Payment");
    if (
      (orderID == "" || orderID == null || reference_order == "") &&
      urlParams.get("order") != null
    ) {
      getIndexedStore(urlParams.get("order")).then((value) => {
        //updateSelectPackage(value.select_package)
        updateState("Payment");
        if(value){
          updateIndexUID(value.uid);
          updateOrderID(value.orderID);
          updateReferenceOrder(value.reference_order);
          setSelectedPaymentMethod(value.payment_method);
          if (value.customer_ids) {
            var tmp = value.customer_ids;
            update_total_amount(value.total_amount);
            var promises = [];
            var formArray = [];
            tmp.forEach((id) => {
              promises.push(
                getIndexedCustomer(id)
                  .then((customer) => {
                    formArray.push(customer);
                  })
                  .catch((e) => {})
              );
            });
            Promise.all(promises).then(() => {
              updataLoadDataState(true);
              updateFormList(formArray);
              updateSessionClear(true);
              setIsUpdateByIndex(true);
              //checkSumByOrderId()
            });
          }
        }
        else{
          //TODO get value from database
          getOrderData(urlParams.get("order")).then((result)=>{
            updateIndexUID(urlParams.get("order"));
            updateOrderID(result.orderID);
            updateReferenceOrder(result.reference_order);
            update_total_amount(result.total_amount)
          })
        }
        
      });
    } else if (orderID != null && orderID != "") {
      updataLoadDataState(true);
    }
    //checkInquiryTimeout.current = setTimeout(checkInquiry, 900000);

    if (IS_ANDROID) {
      keyboardDetect.current = subscribe((visibility) => {
        if (visibility === "hidden") {
          updateHide(false);
        } else {
          updateHide(true);
        }
      });
    }

    try {
      //window.navigateState(null, null, window.location.href);
    } catch (err) {
      console.error(err);
    }
    
    //if(urlParams.get('chargeId') == null)
    //checkPaymentStatus.current = setInterval(checkPayment, 5000)
    return () => {
      //clearTimeout(checkInquiryTimeout.current);
      if (checkPaymentStatus.current) clearInterval(checkPaymentStatus.current);
      window.removeEventListener("popstate", onBackButtonEvent);
      if (IS_ANDROID) keyboardDetect.current();
    };
  }, []);

  useEffect(() => {
    if (reference_order /*&& IS_IOS*/)
      checkPaymentStatus.current = setInterval(checkPayment, 5000);
    /*return () => {
      if (reference_order) clearInterval(checkPaymentStatus.current);
    };*/
  }, [reference_order]);

  useEffect(() => {
    window.KPayment.onClose(() => onCloseKbank());
  }, [window.KPayment]);

  useEffect(() => {
    if (voucher != null && voucher == "" && changePage == "/preview") {
      navigateHandler("/preview");
    }
  }, [voucher, changePage]);

  useEffect(() => {
    if (indexUID) {
      update_s_indexUID(indexUID);
    }
    if (reference_order && indexUID && !loadDataState) {
      getIndexedStore(indexUID).then((value) => {
        if (value) {
          //updateSelectPackage(value.select_package)
          update_total_amount(value.total_amount);
          if (value.customer_ids) {
            var tmp = value.customer_ids;
            var promises = [];
            var formArray = [];
            tmp.forEach((id) => {
              promises.push(
                getIndexedCustomer(id)
                  .then((customer) => {
                    formArray.push(customer);
                  })
                  .catch((e) => {})
              );
            });
            Promise.all(promises).then(() => {
              updataLoadDataState(true);
              updateFormList(formArray);

              const queryString = window.location.search;
              const urlParams = new URLSearchParams(queryString);
              var mode = value.payment_method;
              if (urlParams.has("mode")) {
                mode = "card";
                setSelectedPaymentMethod("card");
              }
              //return
              checkSumOrderID(
                reference_order,
                chargeId,
                contactID,
                mode,
                voucher,
                value.reference_order
              ).then((res) => {
                //debugger
                if (res.status === "success") {
                  var res_id = new Array(res.id.length);
                  for (var x = 0; x < res.id.length; x++) {
                    res_id[res.id[x].index] = res.id[x].ref;
                  }
                  setPaymentLoader(true);
                  updateState("PaymentSuccess");
                  updateRefID(res_id);
                  updatePaymentStatus("success");
                  clearInterval(checkPaymentStatus.current);
                } else if (res.status === "failed") {
                  if (state != "PreviewGenerate") updatePaymentStatus("failed");
                }
              });
              //checkSumByOrderId()
            });
          }
        }
      });
    }
  }, [indexUID, reference_order]);

  useEffect(() => {
    if (isUpdateByIndex) {
      setPaymentLoader(true);
      checkSumByOrderId();
    }
  }, [isUpdateByIndex]);

  useEffect(async () => {
    if (token != null) {
      setPaymentLoader(true);
      var product_list = [];
      var formArray = formList;
      for (var x = 0; x < formArray.length; x++) {
        if (multipleAttribute.length == 0) {
          product_list.push(formArray[x].id);
        }
      }
      var res = await createCardCharge(
        orderID,
        token,
        total_amount,
        sales_partner,
        package_id,
        product_list,
        "card",
        reference_order,
        i18n.language === "th"?"th":"zh"
      );
      if (res.status != null && res.status == "success") {
        setCardChargeID(res.charge_id);
        //navigateHandler(res.url)
        window.location.href = res.url;
        //window.open(res.url, '_blank');
      } else if (res.status == "failed") {
        updatePaymentStatus("failed");
      }
    }
  }, [token]);

  useEffect(() => {
    if (chargeId != null && paymentStatus != "success" && !isGetChargeId) {
      setIsGetChargeId(true);
      setPaymentLoader(true);
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      var paymentMethod = "qr";
      //card will go this way
      if (urlParams.has("mode")) paymentMethod = "card";
      if (paymentMethod != SelectedPaymentMethod)
        setSelectedPaymentMethod(paymentMethod);
      //will remove some data from backend to make it secure
      checkChargeId(chargeId, paymentMethod).then((res) => {
        //check paid status
        if (!res) return;
        if (res.status && (res.order_id || res.reference_order)) {
          if (orderID||res.order_id||res.reference_order) {
              if (res.status == "success") {
                if(!reference_order) updateReferenceOrder(res.reference_order)
                if (!indexUID) updateIndexUID(res.indexUID);
                //navigateHandler('/checkout-complete')
                checkSumOrderID(
                  res.order_id,
                  chargeId,
                  contactID,
                  urlParams.has("mode")?"card":"qr",
                  voucher,
                  reference_order?reference_order:res.reference_order
                ).then((response) => {
                  if (response.status === "success") {
                    var res_id = new Array(response.id.length);
                    for (var x = 0; x < response.id.length; x++) {
                      res_id[response.id[x].index] = response.id[x].ref;
                    }
                    setPaymentLoader(true);
                    updateState("PaymentSuccess");
                    updateRefID(res_id);
                    updatePaymentStatus("success");
                    clearInterval(checkPaymentStatus.current);
                  } else {
                    //updatePaymentStatus('failed')
                  }
                });
              } else {
                updatePaymentStatus("failed");
              }
            } else {
              updateIndexUID(res.indexUID);
              updateReferenceOrder(res.reference_order);
              if(res.order_id)
                updateOrderID(res.order_id);
            }
          
        } else {
          //updatePaymentStatus("failed");
        }
      });
    }
  }, [chargeId, formList]);

  useEffect(() => {
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    if (
      paymentStatus === "success" &&
      (orderID||reference_order) &&
      contextAWDb
    ) {
      setTimeout(goToCheckout, 2000);
    } else if (paymentStatus === "failed"&&urlParams.get("chargeId") == null) {
      setPaymentLoader(false);
      if (voucher != "" && voucher_valid_key != "") {
        releaseVoucherSession();
      }
    }
  }, [paymentStatus, orderID, contextAWDb, loadDataState,reference_order]);

  const getFbCookies = () => {
    //React facebook pixel
    var fbc_name = "_fbc";
    var fbp_name = "_fbp";
    const value = `; ${document.cookie}`;
    //fpc
    var parts = value.split(`; ${fbc_name}=`);
    if (parts.length === 2) {
      var part_value = parts.pop().split(";").shift();
      //sendConversionAPI('PageView',fbc,fbp)
      setFbc(part_value);
    //fbp
    parts = value.split(`; ${fbp_name}=`);
    if (parts.length === 2) {
      var part_value = parts.pop().split(";").shift();
      //sendConversionAPI('PageView',fbc,fbp)
      setFbp(part_value);
    }
  }
  };

  const alipayConfigure = () => {
    OmiseCard.configure({
      defaultPaymentMethod: "alipay",
    });
    OmiseCard.configureButton("#omise-payment");
    OmiseCard.attach();
  };

  const internetBankingConfigure = () => {
    OmiseCard.configure({
      defaultPaymentMethod: "mobile_banking",
      //defaultPaymentMethod:"alipay"
    });
    OmiseCard.configureButton("#omise-payment");
    OmiseCard.attach();
  };

  const omiseCardHandler = () => {
    const createOmiseCharge = async (
      orderID,
      sales_partner,
      package_id,
      product_list,
      reference_order,
      amount,
      token,
    ) => {
      var url = null
      if(IS_IOS){
        if(navigator.userAgent.indexOf('Messenger')>1) url = 'fb-messenger://'
        else if(navigator.userAgent.indexOf('Instagram')>1) url = 'instagram://'
        else if(IS_FACEBOOK_APP) url = 'fb://'
      }
      try {
        const res = await axios({
          method: "post",
          url: API_URL + "/checkout-omise-payment", // TODO: update url
          data: {
            orderID,
            sales_partner,
            package_id,
            product_list,
            reference_order,
            amount,
            token,
            language:i18n.language === "th"?"th":i18n.language,
            redirect_url:url
          },
          headers: {
            "Content-Type": "application/json",
          },
        });

        const { authorizeUri } = res.data;
        if (authorizeUri) {
          // this.props.clearCart()
          window.location.href = authorizeUri;
        }
      } catch (error) {
        console.log(error);
      }
    };

    var product_list = [];
    var formArray = formList;
    for (var x = 0; x < formArray.length; x++) {
      if (multipleAttribute.length == 0) {
        product_list.push(formArray[x].id);
      }
    }

    const cart = {
      orderID,
      sales_partner,
      package_id,
      product_list,
      reference_order: reference_order,
      amount: (parseInt(total_amount*100)).toString(),
    };

    OmiseCard.open({
      frameDescription: "",
      amount: cart.amount,
      onCreateTokenSuccess: (token) => {
        createOmiseCharge(
          cart.orderID,
          cart.sales_partner,
          cart.package_id,
          cart.product_list,
          cart.reference_order,
          cart.amount,
          token
        );
      },
      onFormClosed: () => {},
    });
  };

  const createOmiseInterrnetBanking = () => {
    // e.preventDefault();
    internetBankingConfigure();
    omiseCardHandler();
  };

  const createOmiseAlipay = () => {
    alipayConfigure();
    omiseCardHandler();
  };

  const onClickCloseFailed = () => {
    updatePaymentStatus("");
    if (
      SelectedPaymentMethod == "card" ||
      SelectedPaymentMethod == "unionPay"
    ) {
      if (!isSessionClear) navigateHandler("/preview");
      else {
        deleteOrderInIndexedDB(indexUID);
        navigateHandler("/home");
      }
    }
  };

  const releaseVoucherSession = () => {
    if (voucher != "") {
      releaseValidVoucher(voucher, voucher_valid_key).then(() => {
        update_voucher_valid_key("");
      });
    }
  };

  const onCloseKbank = () => {
    setKPaymentShow(false);
    setShowQRCodeAlert(false);
    if (window.location.pathname.includes("payment")) {
      checkSumByOrderId();
    }
  };

  const checkPayment = () => {
    var queryString = window.location.search;
    var urlParams = new URLSearchParams(queryString);
    if (
      reference_order != null &&
      reference_order != "" /*&&
      !urlParams.get("chargeId")*/
    ) {
      checkPaymentStatusFromDatabase(reference_order).then((result) => {
        if (result && result.payment_status) {
          clearInterval(checkPaymentStatus.current);
          //checkSumByOrderId()
          window.location.reload(false);
        } else if (result && !result.payment_status) {
          if (countCheckPayment == 0 && urlParams.get("chargeId") != null) setPaymentLoader(false);
          updateCountCheckPayment(countCheckPayment + 1);
        }
      });
    } /*else if (urlParams.get("chargeId")) {
      //clearInterval(checkPaymentStatus.current);
    }*/
  };

  //when took too long
  const checkOrderInquiry = async () => {
    var res = await checkChargeId(orderID, SelectedPaymentMethod);
    if (res.order_id && res.status) {
      if (res.order_id === orderID && res.status === "success") {
        checkSumOrderID(
          res.order_id,
          chargeId,
          contactID,
          SelectedPaymentMethod,
          voucher,
          reference_order
        ).then((res) => {
          if (res.status === "success") {
            var res_id = new Array(res.id.length);
            for (var x = 0; x < res.id.length; x++) {
              res_id[res.id[x].index] = res.id[x].ref;
            }
            clearInterval(checkPaymentStatus.current);
            setPaymentLoader(true);
            updateState("PaymentSuccess");
            updateRefID(res_id);
            updatePaymentStatus("success");
          }
        });
      }
    }
  };

  const checkInquiry = async () => {
    if (state != "Payment") return;
    checkOrderInquiry();
  };

  const checkSumByOrderId = async () => {
    checkPaymentStatusFromDatabase(reference_order)
      .then((res) => {
        if (res.payment_status && res.order_id == orderID) {
          checkSumOrderID(
            res.order_id,
            "",
            contactID,
            SelectedPaymentMethod,
            voucher,
            reference_order
          ).then((res) => {
            //debugger
            if (res.status === "success") {
              var res_id = new Array(res.id.length);
              for (var x = 0; x < res.id.length; x++) {
                res_id[res.id[x].index] = res.id[x].ref;
              }
              clearInterval(checkPaymentStatus.current)
              setPaymentLoader(true);
              updateState("PaymentSuccess");
              updateRefID(res_id);
              updatePaymentStatus("success");
            } else {
              updatePaymentStatus("failed");
            }
          });
        } else {
          var queryString = window.location.search;
          var urlParams = new URLSearchParams(queryString);
          if(urlParams.get("chargeId") == null)
            setPaymentLoader(false);
        }
      })
      .catch((e) => {});
  };

  const goToCheckout = () => {
    //setTimeout(saveCustomerdataToDatabase,2000)
    var product_list = [];
    var items = [];
    var pixelitems = [];
    try{
      for (var x = 0; x < formList.length; x++) {
        if (multipleAttribute.length > 0) {
          items.push({
            item_id: formList[x].id,
            item_name: formList[x].id,
            price: total_amount,
            quantity: 1,
          });
          pixelitems.push(formList[x].id);
        } else {
          items.push({
            item_id: formList[x].id,
            item_name: formList[x].id,
            price: formList[x].price,
            quantity: 1,
          });
          pixelitems.push(formList[x].id);
        }
      }
      TagManager.dataLayer({
        dataLayer: {
          ecommerce: null,
        },
      });
      var tag_event = DEPLOY_VERSION == "PROD" ? "prod_purchased" : "purchase";
      TagManager.dataLayer({
        dataLayer: {
          event: tag_event,
          ecommerce: {
            currencyCode: "THB",
            transaction_id: reference_order,
            value: total_amount,
            items: items,
          },
        },
      });
      //React facebook pixel
      if (DEPLOY_VERSION == "PROD"&&pixelitems.length>0) {
        if(isValidPhoneNumber(phoneNumber,'TH')){
          var advance_setting = {ph:phoneNumber}
          ReactPixel.init("973339143546259",advance_setting);
        }
        else{
          ReactPixel.init("973339143546259");
        }
        
        ReactPixel.track("Purchase", {
          value: total_amount,
          currency: "THB",
          content_ids: pixelitems,
          order_id: reference_order,
        });
      }
      var idURL = referenceID;
      if (referenceID.length > 1) {
        idURL = referenceID[0];
        for (var x = 1; x < referenceID.length; x++) {
          idURL = idURL + "&" + referenceID[x];
        }
      }
    }catch(e){
      sendErrorToChatbot(e)
    }
    
    updateIndexedDBPaymentStatus(indexUID)
      .then(() => {
        navigateHandler("/checkout-complete?id=" + idURL);
      })
      .catch((e) => {
        console.log(e);
        if(referenceID){
          navigateHandler("/checkout-complete?id=" + idURL);
        }
        //console.log(e)
      });
  };

  const PaymentNoti = ({ onClickBack, onClickNext }) => {
    return (
      <NotiContentWrapper>
        <NotiCardWrapper>
          <PaymentNotiWrapper>
            <NotificationImage src={NotificationIcon} />
            <NotiHeadTextStyled>
              {t("รหัสจากคูปองหมดสิทธิ์ใช้งาน")}
            </NotiHeadTextStyled>
            <NotiTextStyled>
              {t("รหัสจากคูปองดังกล่าวถูกใช้ครบ")}
              <br />
              {t("ตามจำนวนสิทธิ์ที่กำหนดเอาไว้แล้ว")}
              <br />
              {t("ขออภัยในความไม่สะดวก")}
            </NotiTextStyled>
            <NotiCouponButtonWrapper>
              <DoseartButton
                width={parseInt(width) < 600 ? "85vw" : "25vw"}
                isDisable={false}
                isPrimary={true}
                text={t("ย้อนกลับไปหน้ารายการสั่งซื้อ")}
                isSetMargin={true}
                handleOnClick={onClickNext}
              />
            </NotiCouponButtonWrapper>
          </PaymentNotiWrapper>
        </NotiCardWrapper>
      </NotiContentWrapper>
    );
  };

  const QRAlert = () => {
    return (
      <div style={{ zIndex: "9999999" }}>
        <NotiContentWrapper>
          <NotiCardWrapper>
            <PaymentNotiWrapper>
              <NotiTextStyled>
                {t("การกดย้อนกลับจะทำให้ QR code นี้ใช้การไม่ได้")}
              </NotiTextStyled>
              <ButtonWrapper>
                <DoseartButtonWrapper>
                  <DoseartButton
                    width={parseInt(width) < 600 ? "28vw" : "7vw"}
                    isDisable={false}
                    isPrimary={false}
                    text={t("ยกเลิก")}
                    isSetMargin={true}
                    handleOnClick={() => {
                      try {
                        //window.navigateState(null, null, window.location.href);
                      } catch (err) {
                        console.error(err);
                      }
                      setfinishStatus(false);
                    }}
                  />
                </DoseartButtonWrapper>
                <DoseartButtonWrapper>
                  <DoseartButton
                    width={parseInt(width) < 600 ? "28vw" : "7vw"}
                    isDisable={false}
                    isPrimary={true}
                    text={t("ยืนยัน")}
                    isSetMargin={true}
                    handleOnClick={() => {
                      setShowQRCodeAlert(false);
                      setfinishStatus(true);
                      navigateHandler("/cart");
                    }}
                  />
                </DoseartButtonWrapper>
              </ButtonWrapper>
            </PaymentNotiWrapper>
          </NotiCardWrapper>
        </NotiContentWrapper>
      </div>
    );
  };

  const PaymentFail = () => {
    return (
      <NotificationCard
        onClickNext={onClickCloseFailed}
        state={state}
        payment={SelectedPaymentMethod}
      />
    );
  };

  const recreateOrder = async (formArray) => {
    var data_list = [];
    var product_list = [];
    var setId = orderID;
    for (var x = 0; x < formArray.length; x++) {
      var date = new Date(
        formArray[x].birthYear,
        formArray[x].birthMonth,
        formArray[x].birthDate
      );
      var dayOfWeek =
        formArray[x].day_of_week === ""
          ? date.getDay()
          : formArray[x].day_of_week;
      var moonPhase = await getThaiMoonPhase(
        formArray[x].birthYear,
        formArray[x].birthMonth + 1,
        formArray[x].birthDate,
        dateDatabase
      );
      if (multipleAttribute.length > 0) {
        var form_data = {
          psd_filename: selectPackage,
          name: formArray[x].name,
          surname: formArray[x].surname,
          zodiac: formArray[x].zodiacId.toString(),
          day_of_week: dayOfWeek.toString(),
          moon_phase: moonPhase.toString(),
          isShowDate: formArray[x].showBirthDate,
          date: formArray[x].birthDate,
          month: formArray[x].birthMonth,
          year: formArray[x].birthYear,
          chinese_zodiac: formArray[x].chinese_zodiac.toString(),
        };
        data_list.push(form_data);
      } else {
        var form_data = {
          psd_filename: contextAWDb[formArray[x].selectImageId].filename,
          name: formArray[x].name,
          surname: formArray[x].surname,
          zodiac: formArray[x].zodiacId.toString(),
          day_of_week: dayOfWeek.toString(),
          moon_phase: moonPhase.toString(),
          isShowDate: formArray[x].showBirthDate,
          date: formArray[x].birthDate,
          month: formArray[x].birthMonth,
          year: formArray[x].birthYear,
          chinese_zodiac: formArray[x].chinese_zodiac.toString(),
        };
        data_list.push(form_data);
        product_list.push(contextAWDb[formArray[x].selectImageId].id);
      }
    }
    const getOrderID = await createQROrder(package_id, voucher, product_list);
    await createOrderIDInDatabase(
      data_list,
      getOrderID.id,
      package_id,
      voucher,
      multipleAttribute,
      product_list,
      sales_partner,
    );
    //clearTimeout(checkInquiryTimeout.current);

    //checkInquiryTimeout.current = setTimeout(checkInquiry, 900000);
    if (getOrderID.id) {
      updateOrderID(getOrderID.id);
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
      updateIndexedDBOrderId(urlParams.get("order")).then((value) => {
        setId = getOrderID.id;
        showKPayment(setId);
      });
    }
  };

  const createUnionPay = async () => {
    var product_list = [];
    var formArray = formList;
    for (var x = 0; x < formArray.length; x++) {
      if (multipleAttribute.length == 0) {
        product_list.push(formArray[x].id);
      }
    }
    var res = await createCardCharge(
      orderID, // for QR
      "",
      total_amount, // total amout for create charge
      sales_partner,
      package_id,
      product_list,
      "unionpay",
      reference_order, // reference order for charge Id
      i18n.language === "th"?"th":"zh"
    );
    if (res.status != null && res.status == "success") {
      setCardChargeID(res.charge_id);
      //navigateHandler(res.url)
      window.location.href = res.url;
      //window.open(res.url, '_blank');
    }
  };

  const createPayment = async () => {
    if (voucher && voucher != "") {
      const checkVoucher = await checkValidVoucher(total_amount, voucher, true);
      if (checkVoucher.valid) {
        update_voucher_valid_key(checkVoucher.key);
      } else {
        updateShowNoti(true);
        return;
      }
    }
    const checkOrderId = checkPaymentStatusFromDatabase(reference_order).then(
      (res) => {
        if (!res) return;
        if (!res.payment_status) {
          showKPayment(res.order_id);
        } else if (res.payment_status && res.order_id == orderID) {
          checkSumOrderID(
            checkOrderId.order_id,
            "",
            contactID,
            SelectedPaymentMethod,
            voucher,
            reference_order
          ).then((res_sum) => {
            if (res_sum.status === "success") {
              var res_id = new Array(res_sum.id.length);
              for (var x = 0; x < res_sum.id.length; x++) {
                res_id[res_sum.id[x].index] = res_sum.id[x].ref;
              }
              clearInterval(checkPaymentStatus.current)
              setPaymentLoader(true);
              updateState("PaymentSuccess");
              updateRefID(res_id);
              updatePaymentStatus("success");
            } else showKPayment(res.order_id);
          });
        }
      }
    );
  };

  const showKPayment = async (id) => {
    if (SelectedPaymentMethod === "qr")
      window.KPayment.setPaymentMethods("qr"); //card
    else if (SelectedPaymentMethod === "card")
      window.KPayment.setPaymentMethods("card"); //card
    else if (SelectedPaymentMethod === "unionPay") {
      createUnionPay();
      return;
    }
    window.KPayment.setOrderId(id);
    window.KPayment.setAmount(parseFloat(total_amount).toFixed(2));
    setKPaymentShow(true);
    setPaymentLoader(true);
    updatePaymentMethodIndexedDB(indexUID, SelectedPaymentMethod)
      .then((s) => {
        window.KPayment.show();
      })
      .catch((e) => {
        //Show kpayment if there no data in indexuid
        window.KPayment.show();
      });
  };

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSubmit = () => {};

  return (
    <ContentWrapper>
      <form method="GET" action="/payment" onSubmit={handleSubmit}></form>
      {showQRCodeAlert && IS_IOS ? <QRAlert /> : <></>}
      <HeadTextStyled>{t("PaymentPage.วิธีการชำระเงิน")}</HeadTextStyled>
      <TipsWrapper>
        <TipsRedTextStyled>
          •{" "}
          {t(
            "PaymentPage.ภาพที่ได้รับอาจถูกตัดบางส่วน ตามการแสดงผลภาพพื้นหลังของหน้าจอ"
          )}
        </TipsRedTextStyled>
        <TipsTextStyled>
        {t("รวมการสั่งซื้อ")} {total_amount}{t("บาท")}
        </TipsTextStyled>
        <TipsTextStyled>
        {t("หมายเลขคำสั่งซื้อ")} {s_indexUID}
        </TipsTextStyled>
      </TipsWrapper>
      <CardWrapper style={{ marginBottom: "12vh" }}>
        {
          <CreditCardWrapper>
            <Radio
              checked={SelectedPaymentMethod === "card"}
              onChange={() => setSelectedPaymentMethod("card")}
              value="a"
              name="radio-buttons"
              inputProps={{ "aria-label": "A" }}
            />
            <CreditCardTextWrapper>
              <HeaderCardTextStyled>
                {t("PaymentPage.ชำระเงินผ่านบัตรเครดิต")}
              </HeaderCardTextStyled>
              <CardLogoWrapper>
                <CardLogoImage src={VisaCardLogo} />
                <CardLogoImage src={MasterCardLogo} />
              </CardLogoWrapper>
            </CreditCardTextWrapper>
          </CreditCardWrapper>
        }
        {/*<CreditCardWrapper>
                    <Radio
                        checked={SelectedPaymentMethod === 'unionPay'}
                        onChange={() => setSelectedPaymentMethod('unionPay')}
                        value="a"
                        name="radio-buttons"
                        inputProps={{ 'aria-label': 'A' }}
                    />
                    <CreditCardTextWrapper>
                        <HeaderCardTextStyled>ชำระเงินผ่านบัตร UnionPay/TPN</HeaderCardTextStyled>
                        <CardLogoWrapper>
                            <CardLogoImage src={VisaCardLogo} /><CardLogoImage src={MasterCardLogo} />
                        </CardLogoWrapper>
                    </CreditCardTextWrapper>
                </CreditCardWrapper>*/}
        {isMobile&& (
          <CreditCardWrapper>
            <Radio
              checked={SelectedPaymentMethod === "internetBanking"}
              onChange={() => {
                setSelectedPaymentMethod("internetBanking");
                internetBankingConfigure();
              }}
              value="b"
              name="radio-buttons"
              inputProps={{ "aria-label": "B" }}
            />
            <CreditCardTextWrapper>
              <HeaderCardTextStyled>
                {t("PaymentPage.ชำระเงินผ่าน Mobile Banking")}
              </HeaderCardTextStyled>
              <CardLogoWrapper>
                <BankLogoImage src={KbankLogo} />
                <BankLogoImage src={BkkBankLogo} />
                <BankLogoImage src={KrungSriLogo} />
                <BankLogoImage src={ScbBankLogo} />
                <BankLogoImage src={KrungThaiLogo} />
              </CardLogoWrapper>
            </CreditCardTextWrapper>
          </CreditCardWrapper>
            )}
        {<CreditCardWrapper>
          <Radio
            checked={SelectedPaymentMethod === "alipay"}
            onChange={() => {
              setSelectedPaymentMethod("alipay");
              alipayConfigure();
            }}
            value="d"
            name="radio-buttons"
            inputProps={{ "aria-label": "D" }}
          />
          <CreditCardTextWrapper>
            <HeaderCardTextStyled>{t("PaymentPage.ชำระเงินผ่าน Alipay")}</HeaderCardTextStyled>
            <CardLogoWrapper>
              <CardLogoImage src={AlipayLogo} />
            </CardLogoWrapper>
          </CreditCardTextWrapper>
          </CreditCardWrapper>}
        <CreditCardWrapper>
          <Radio
            checked={SelectedPaymentMethod === "qr"}
            onChange={() => setSelectedPaymentMethod("qr")}
            value="c"
            name="radio-buttons"
            inputProps={{ "aria-label": "C" }}
          />
          <CreditCardTextWrapper>
            <HeaderCardTextStyled>
              {t("PaymentPage.ชำระเงินผ่าน QR Code")}
            </HeaderCardTextStyled>
            <CardLogoWrapper>
              <PromptpayLogoImage src={PromptpayLogo} />
            </CardLogoWrapper>
            <CardTextStyled>
              {t('PaymentPage.หลังจากกด "สั่งซื้อ"')}
              <br />
              <br />
              {t("PaymentPage.สำหรับผู้สั่งซื้อที่ทำรายการผ่านสมาร์ทโฟน")}
              <br />
              <br />
              {t("PaymentPage.1. บันทึกภาพหน้าจอที่มี QR Code")}
              <br />
              {t("PaymentPage.2. เปิดแอป Mobile Banking แล้วเลือกเมนูสแกน")}
              <br />
              {t("PaymentPage.3. กดที่ไอคอนรูปเพื่อเลือกรูปจากคลังภาพ")}
              <br />
              {t("PaymentPage.4. เลือกรูปภาพที่มี QR Code ที่ได้บันทึกไว้")}
              <br />
              {t("PaymentPage.5. ตรวจสอบยอด แล้วชำระเงิน")}
              <br />
              {t("PaymentPage.6. กลับมาที่หน้าเว็บไซต์นี้อีกครั้ง")}
              <br />
              <br />
              {t("PaymentPage.สำหรับผู้สั่งซื้อที่ทำรายการผ่านเดสก์ท็อป")}
              <br />
              <br />
              {t("PaymentPage.1. เปิดแอป Mobile Banking")}
              <br />
              {t(
                "PaymentPage.2. ชำระเงินโดยการสแกน QR Code ที่ปรากฎขึ้นบนหน้าจอเดสก์ท็อป"
              )}
              <br />
              {t("PaymentPage.3. ตรวจสอบยอด แล้วชำระเงิน")}
              <br />
              {t("PaymentPage.4. รับรูปภาพพื้นหลังจากเดสก์ท็อป")}
            </CardTextStyled>
          </CreditCardTextWrapper>
        </CreditCardWrapper>

        {voucher == "" ? (
          <></>
        ) : (
          <CreditCardWrapper>
            <div style={{ flexDirection: "column", paddingLeft: "4vh" }}>
              {t("Coupon Code")}
              <StyleCouponTextField defaultValue={voucher} disabled={true} />
            </div>
          </CreditCardWrapper>
        )}
        <CreditCardWrapper>
          <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
          />
          <CreditCardTextWrapper>
            <CardTextStyled>
              {t("PaymentPage.ฉันได้อ่าน และยอมรับเงื่อนไขการใช้เว็บไซต์")}
              <br />
              <Link
                to="/termsandcondition"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("PaymentPage.ข้อกำหนดและเงื่อนไข")}
              </Link>
              &nbsp;{t("PaymentPage.และ")}&nbsp;
              <Link
                to="/refundcondition"
                target="_blank"
                rel="noopener noreferrer"
              >
                {t("PaymentPage.นโยบายการคืนเงิน")}
              </Link>
            </CardTextStyled>
          </CreditCardTextWrapper>
        </CreditCardWrapper>
      </CardWrapper>
      {showNoti ? (
        <PaymentNoti
          onClickBack={() => {
            navigateHandler("/home");
          }}
          onClickNext={() => {
            update_voucher("");
            updateChangePage("/preview");
            //navigateHandler('/')
            //add payment here
            //createUnionPay()
            //createPayment();
            //updateContactID(email)
            //navigateHandler('/checkout-complete')
          }}
        />
      ) : (
        <></>
      )}
      {paymentStatus == "failed" ? <PaymentFail /> : <></>}

       {showPaymentLoader ? (
        <Paymentloader />
      ) : (
         
      <FooterComponent
        isDisableNextButton={!checked || isContactError}
        SelectedPaymentMethod={SelectedPaymentMethod}
        addOnClickNextFunction={() => {
          //if (email == '')
          //updateShowNoti(true)
          //add payment here
          //else {
          updateContactID(email);
          if (SelectedPaymentMethod === "internetBanking"||SelectedPaymentMethod === "alipay"){
            omiseCardHandler();
          }
            /*createOmiseInterrnetBanking();
          else if (SelectedPaymentMethod === "alipay") createOmiseAlipay();*/
          else if (
            SelectedPaymentMethod === "card" ||
            SelectedPaymentMethod === "qr"
          )
            createPayment();
          //history.push('/checkout-complete')
          //}
        }}
        hide={hide}
      />)}
    </ContentWrapper>
  );
};

export default PaymentPage;
