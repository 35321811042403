import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Redirect,
  Navigate,
  Routes,
} from "react-router-dom";
import AlacarteGalleryPage from "./GalleryPage";
import FillInformationPage from "./FillInformationPage";
import AisPaymentPage from "./PaymentPage";
import CartPage from "../CartPage";
import PreviewGeneratePage from "../PreviewGenerate";

const AlacartePage = () => {
  return (
    <Routes>
      <Route path="/gallery" element={<AlacarteGalleryPage/>} />
      <Route path="/fill" element={<FillInformationPage/>} />
      <Route path="/cart" element={<CartPage/>} />
      <Route path="/preview" element={<PreviewGeneratePage/>} />
      <Route path="/payment" element={<AisPaymentPage/>} />
      <Route path="/" element={<AlacarteGalleryPage/>} />
    </Routes>
  );
};

export default AlacartePage;
