import React,{useState} from "react"
import styled from 'styled-components';
import packageBG from '../assets/package/package.svg'
import oneCard from '../assets/package/onecard.svg'
import dashImg from '../assets/package/dash.svg'
import { useNavigate } from "react-router";
import { formList as formListAtom} from '../global/atom'
import { useRecoilState } from "recoil";
import fluidText from "../utils/fluidText";
import { fluidRange } from "polished"
import { useSessionStorage } from "react-use";
import { createStoreOrder, getIndexedStore, updateOrderId } from "../utils/IndexedDBStore";
import useNavigationHandling from "../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";
import { handleFontFamily } from "../utils/utils";

const PackageWrapper = styled.button`
    background-color:#F9F9F9;
    width:88.46vw;
    //height:23.07vw;
    border-radius:15px;
    margin: 1.5vh auto;
    filter: drop-shadow(4px 4px 10px rgba(0, 0, 0, 0.15));
    cursor: pointer;
    align-items:center;
    flex-direction: row;
    display:flex;
    border : 3px solid transparent;
    &:focus{
        border: 3px solid #E4202A;
    }
    
    @media(min-width:600px){
        width: 38vw;
        padding : 1vh 0.5vw;
        min-width: 450px;
        //height: 5.93vw;
    }
`;

const CardImage = styled.img`
    //width: 5.89vw;
    height: 4.6vh;
    margin-top: 1vh;
    @media(min-width:600px){
        //width: 1.67vw;
        //height: 2.5vw;
    }
`;

const CardWrapper = styled.div`
    flex-direction: column;
    width: 32vw;
    @media(min-width:600px){
        width: 14vw;  
    }
`;

const DashImage = styled.img`
    //width: 0.9vw;
    height: 7.7vh;
    @media(min-width:600px){
        //width: 0.25vw;  
        height: 7.7vh;
    }
`;

const NCardText = styled.div`
    font-size : 0.7rem;
    ${handleFontFamily()}
    font-style: normal;
    font-weight: 600;
    color : black;
    margin-top: 1vh;
    @media(min-width:600px){
        margin-top: 0.4vh;  
    }
`;

const PriceText = styled.div`
    //font-size : 3rem;
    ${fluidRange({
		prop: 'fontSize',
		fromSize: `30px`,
		toSize: `50px`
	},
		'600px',
		'300px'
	)}
    ${handleFontFamily()}
    font-style: italic;
    font-weight: 600;
    color : #E4202A;
    width : 29.1vw;
    @media(min-width:600px){
        ${fluidRange({
		prop: 'fontSize',
		fromSize: `25px`,
		toSize: `60px`
	},
		'600px',
		'1400px'
	)}
        width: 14vw;  
    }
`;

const CurrencyText = styled.div`
    ${fluidRange({
		prop: 'fontSize',
		fromSize: `30px`,
		toSize: `50px`
	},
		'300px',
		'1400px'
	)}
    ${handleFontFamily()}
    font-style: normal;
    font-weight: 700;
    color : #E4202A;
    @media(min-width:600px){
        ${fluidRange({
		prop: 'fontSize',
		fromSize: `25px`,
		toSize: `45px`
	},
		'600px',
		'1400px'
	)}
        //width: 12vw;  
    }
`;

const PackageComponent = ({npicture,price,updateProductPrice,updateNProduct,onClick})=>{
    const { navigateHandler } = useNavigationHandling();
    const {t} = useTranslation();

    const handleOnClick = ()=>{
        updateProductPrice(price)
        updateNProduct(npicture)
        onClick()
        //navigateHandler('/cart')
    }

    return (
        <PackageWrapper onClick={handleOnClick}>
            <CardWrapper>
                <CardImage src={oneCard}/>
                <NCardText>{npicture} {t("ภาพ")}</NCardText>
            </CardWrapper>
            <DashImage  src={dashImg}/>
            <PriceText>{price}</PriceText>
            <CurrencyText>{t("บาท")}</CurrencyText>
        </PackageWrapper>
    )
}

export default PackageComponent;