import React, { useState, useEffect, useRef } from "react";
import styled from 'styled-components';
import fluidText from "../../utils/fluidText";
import { fluidRange } from "polished";
import DummyImage from '../../assets/cart/Rectangle.svg'
import BlogComponent from "../../components/BlogComponent";
import { useRecoilState } from "recoil";
import BlogsJson from '../../assets/blogs.json'
import {  state as stateAtom,} from "../../global/atom";
import { handleFontFamily } from "../../utils/utils";

const ContentWrapper = styled.div`
    width:90vw;
    height:100% ;
    margin:3.2rem auto 0px auto;
    padding-top:1vh ;
    @media(min-width:600px){
        width: 50vw;
    }
`

const RedTextStyled = styled.div`
    margin:2vh auto;
    ${fluidText(16, 22)}
    ${handleFontFamily()}
    font-style: normal;
    font-weight: 400;
    color : #E4202A;
    text-align:center;
    word-wrap: normal;
    @media(min-width:600px){
        ${fluidText(12, 22)}
    }
    //margin-left : 5.1vw;
`;

const BlogsPages = () => {

    const [state, updateState] = useRecoilState(stateAtom);

    useEffect(()=>{
        updateState('blogs')
    },[])

    const Blogs = BlogsJson?.map((blog,index)=>{
        return <BlogComponent image={blog.blog_image} title={blog.blog_title} description={blog.blog_description} isTop={index==0} content={blog.blog_name}/>
    })

    return (
        <ContentWrapper>
            <RedTextStyled>Blogs</RedTextStyled>
            {Blogs}
        </ContentWrapper>
    )
}

export default BlogsPages