import React, { useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import {
  artworkDatabase as artworkDatabaseAtom,
  fetch_collection as fetch_collection_atom,
} from "../global/atom";
import fluidText from "../utils/fluidText";
import couponsvg from "../assets/package/coupon.svg";
import TextField from "@material-ui/core/TextField";
import DoseartButton from "./DoseartButton";
import { checkValidVoucher, handleFontFamily } from "../utils/utils";
import { useNavigate } from "react-router-dom";
import { encryptWithAES } from "../utils/utils";
import { useSessionStorage } from "react-use";
import {
  sessionTotalAmount,
  sessionPrice,
  sessionVoucher,
  discountCoupon,
  session_select_edit_card,
  session_nProducts,
  session_artwork_database,
  session_fetch_artwork,
} from "../utils/sessionStorage";
import { useContext } from "react";
import { UserContext } from "../utils/Context/userContext";
import LoaderComponent from "./LoaderComponent";
import useNavigationHandling from "../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";

const ContentWrapper = styled.div`
  width: 100vw;
  height: 100%;
  //align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: 10;
`;

const ButtonWrapper = styled.div`
  width: 90vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 2vh auto 3vw auto;
  @media (min-width: 600px) {
    width: 100%;
    margin: 2vh auto 1.5vw auto;
  }
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    width: 65vw;
    height: 5.33vh;
    border-radius: 8px;
    margin: 1vh auto 1vh auto;
    ${handleFontFamily()}
    font-style: normal;
    font-weight: 400;
    text-align: center;
    @media (min-width: 600px) {
      width: 15vw;
      min-width: 300px;
    }
    input {
      text-align: center;
    }
    fieldset {
      border-color: #e5e5e5;
    }
    &.Mui-focused fieldset {
      border-color: #e5e5e5;
    }
    background-color: #e5e5e5;
  }
`;

const CardWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  width: 90vw;
  height: min-content;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  bottom: 0;
  border-radius: 15px;
  //padding : 0 10px;
  flex-direction: column;
  display: flex;
  @media (min-width: 600px) {
    width: 35vw;
    min-width: 400px;
    //padding: 0 1vw;
  }
`;

const HeadTextStyled = styled.div`
  //width:71.84vw;
  margin: 0vh auto 1vh auto;
  ${fluidText(20, 25)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 600;
  color: black;
  text-align: center;
  @media (min-width: 600px) {
    width: 24vw;
    //font-size: 1.8rem;
    min-width: 400px;
  }
`;

const ErrorTextStyled = styled.div`
  width: 65vw;
  margin: 0vh auto;
  ${fluidText(10, 16)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: red;
  @media (min-width: 600px) {
    //font-size: 1.4rem;
    width: 15vw;
    min-width: 300px;
  }
`;

const TextStyled = styled.div`
  //width:71.84vw;
  margin: 1vh auto 2vh auto;
  ${fluidText(16, 22)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: center;
  @media (min-width: 600px) {
    font-size: 1.4rem;
    min-width: 400px;
  }
`;

const CouponImage = styled.img`
  //width: 5.89vw;
  height: 5vh;
  margin: 2vh auto;
  @media (min-width: 600px) {
    //width: 1.67vw;
    //height: 2.5vw;
  }
`;

const ValidateCoupon = ({ amount, closeVoucherPopup, code }) => {
  const [checkButton, setCheckButton] = useState(false);
  const [voucher, updateVoucher] = useState();
  const [voucherValid, setVoucherValid] = useState(true);
  const [showHelperText, updateShowHelperText] = useState(false);
  //const [voucherSaved,updateVoucherSaved] = useSessionStorage(sessionVoucher.key,sessionVoucher.default)
  const [price, updatePrice] = useSessionStorage(
    sessionPrice.key,
    sessionPrice.default
  );
  const [totalamount, updateTotalamount] = useSessionStorage(
    sessionTotalAmount.key,
    sessionTotalAmount.default
  );
  const [discount_coupon, update_discount_coupon] = useSessionStorage(
    discountCoupon.key,
    discountCoupon.default
  );
  const [editCard, updateEditCard] = useSessionStorage(
    session_select_edit_card.key,
    session_select_edit_card.default
  );
  const [nProducts, updateNProducts] = useSessionStorage(
    session_nProducts.key,
    session_nProducts.default
  );
  //const [couponCollection,updateCouponCollection] = useSessionStorage(session_coupon_collection.key,session_coupon_collection.default)
  const {
    contextAWDb,
    setContextAWDb,
    update_voucher,
    couponCollection,
    updateCouponCollection,
    fetchArtwork,
    updateFetchArtwork,
    updateCouponProducts,
  } = useContext(UserContext);
  const [errorReason, updateErrorReason] = useState("");
  const width = window.innerWidth;
  const [nextURL, updateNextURL] = useState();
  const [isNext, setIsNext] = useState(false);
  const { navigateHandler } = useNavigationHandling();
  const [isLoading, updateIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    if (code && !voucher) {
      updateVoucher(code);
      updateIsLoading(true);
    } else if (isLoading && voucher && contextAWDb && contextAWDb.length > 0) {
    /*else if(code&&voucher&&contextAWDb&&contextAWDb.length>0){
            checkVoucherValid()
        }*/
      checkVoucherValid();
    }
  }, [voucher, code, contextAWDb, isLoading]);

  const filterProductsByVoucher = (metadata_products) => {
    var result_products = [];
    metadata_products.forEach((product) => {
      fetchArtwork.forEach((aw) => {
        if (aw.id === product) {
          result_products.push(aw);
        }
      });
    });
    setContextAWDb(result_products);
  };

  const checkVoucherValid = async () => {
    setCheckButton(false);
    var result = await checkValidVoucher(amount, voucher);
    setCheckButton(true);
    if (!result.valid) {
      updateIsLoading(false);
      setVoucherValid(false);
      switch (result.reason) {
        case "voucher not found":
          updateErrorReason(t("*รหัสไม่ถูกต้อง") + t("กรุณากรอกใหม่"));
          break;
        case "voucher expired":
          updateErrorReason(t("*รหัสนี้หมดอายุแล้ว") + t("กรุณากรอกใหม่"));
          break;
        case "quantity exceeded":
          updateErrorReason(t("*รหัสถูกใช้แล้ว") + t("กรุณากรอกใหม่"));
          break;
        default:
          updateErrorReason(t("*รหัสไม่ถูกต้อง") + t("กรุณากรอกใหม่"));
          break;
      }
    } else {
      update_voucher(voucher);
      update_discount_coupon(result.discount);
      if (
        result.metadata &&
        result.metadata.redirect_url &&
        result.metadata.redirect_url !== "/gallery"
      ) {
        if (result.metadata.products) {
          updateCouponProducts(result.metadata.products);
          filterProductsByVoucher(result.metadata.products);
        }
        if (result.metadata.collections) {
          updateCouponCollection(result.metadata.collections);
        } else if (result.metadata && result.metadata.collections === null) {
          updateCouponCollection(null);
        }
        updateNextURL(result.metadata.redirect_url);
      }
      //set up gallery
      else {
        if (result.metadata && result.metadata.products) {
          updateCouponProducts(result.metadata.products);
          filterProductsByVoucher(result.metadata.products);
        }
        if (result.metadata && result.metadata.collections) {
          updateCouponCollection(result.metadata.collections);
        } else if (result.metadata && result.metadata.collections === null) {
          updateCouponCollection(null);
        }
        updatePrice(amount);
        updateTotalamount(result.total_amount);
        update_discount_coupon(result.discount);
        update_voucher(voucher);
        updateEditCard(0);
        updateNProducts(1);
        updateNextURL("/gallery");
        //setIsNext(true)
      }
    }
  };
  useEffect(() => {
    if (nextURL) {
      //setTimeout(()=>{navigateHandler(nextURL);},200)
      navigateHandler(nextURL);
    }
  }, [price, totalamount, nextURL]);

  return (
    <ContentWrapper>
      {isLoading && <LoaderComponent />}
      <CardWrapper>
        <CouponImage src={couponsvg} />
        <HeadTextStyled>{t("ValidateCouponComponent.กรอกรหัสคูปอง")}</HeadTextStyled>
        <TextStyled>
          {t("ValidateCouponComponent.โปรดกรอกรหัสที่ได้จากคูปองในช่องว่างให้ถูกต้อง")}
        </TextStyled>
        <StyledTextField
          id="name"
          label=""
          variant="outlined"
          error={!voucherValid}
          placeholder={t("ValidateCouponComponent.CODE")}
          inputProps={{
            form: {
              autocomplete: "off",
            },
            maxLength: 20,
          }}
          onChange={(e) => {
            if (e.target.value == "") {
              setCheckButton(false);
              return;
            }
            setCheckButton(true);
            updateVoucher(e.target.value);
          }}
        />
        {voucherValid ? (
          <></>
        ) : (
          <ErrorTextStyled>{errorReason}</ErrorTextStyled>
        )}
        <ButtonWrapper>
          <DoseartButton
            width={parseInt(width) < 600 ? "40vw" : "10vw"}
            isDisable={false}
            isPrimary={false}
            text={t("ValidateCouponComponent.ยกเลิก")}
            handleOnClick={closeVoucherPopup}
          />
          <DoseartButton
            width={parseInt(width) < 600 ? "40vw" : "10vw"}
            isDisable={!checkButton}
            isPrimary={true}
            text={t("ValidateCouponComponent.ตกลง")}
            handleOnClick={() => updateIsLoading(true)}
          />
        </ButtonWrapper>
      </CardWrapper>
    </ContentWrapper>
  );
};

export default ValidateCoupon;
