import React, { useState, useEffect } from "react";
import styled from "styled-components";
import fluidText from "../utils/fluidText";
import { artworkDatabase, state as stateAtom } from "../global/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import qrScan from "../assets/thankyou/qrscan.png";
import ReactPixel from "react-facebook-pixel";
import ContactLogoComponent from "../components/ContactLogoComponent";
import {
  session_artwork_database,
  session_state,
  useSessionStorage,
} from "../utils/sessionStorage";
import { useContext } from "react";
import { UserContext } from "../utils/Context/userContext";
import { useTranslation } from "react-i18next";
import { handleFontFamily } from "../utils/utils";

const ContentWrapper = styled.div`
  //position: fixed;
  //top:0;
  //left:0;
  width: 100vw;
  height: 100%;
  //background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    width: 38vw;
    margin: auto;
    min-width: 400px;
  }
`;

const HeadTextStyled = styled.div`
  width: 88.46vw;
  margin: 4vh auto 4vh auto;
  ${fluidText(22, 25)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: left;
  @media (min-width: 600px) {
    width: 38vw;
    min-width: 400px;
  }
`;

/*const ContactWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  //margin: 4vh auto 14vh auto ;
`;

const ContactTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 4vw;
  @media (min-width: 600px) {
    margin-left: 2vw;
  }
`;*/

const QRScan = styled.img`
  margin: 0vh auto 4vh auto;
  width: 80vw;
  @media (min-width: 600px) {
    margin: 0vh auto 2vh auto;
    width: 27vw;
    min-width: 270px;
  }
`;

/*const ContactTextStyled = styled.div`
  ${fluidText(13, 17)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: left;
  @media (min-width: 600px) {
    //font-size: 0.8rem;
  }
`;*/

const DoseartLogoWrapper = styled.img`
  width: 30vw;
  min-width: 100px;
  margin-top: 6px;
  @media (min-width: 600px) {
    width: 7.5vw;
    min-width: 150px;
  }
`;

const ThankyouPage = () => {
  const [, updateState] = useRecoilState(stateAtom);
  //const [, updateState] = useSessionStorage(session_state.key,session_state.default)
  const [tag, updateTag] = useState();
  const [price, updatePrice] = useState();
  const { contextAWDb } = useContext(UserContext);
  //const [data,] = useSessionStorage(session_artwork_database.key,session_artwork_database.default)
  //const data = useRecoilValue(artworkDatabase);
  const width = window.innerWidth;

  const { t } = useTranslation();

  useEffect(() => {
    ReactPixel.init("973339143546259");
    const queryString = window.location.search;
    const decodeURL = decodeURI(queryString);
    const urlParams = new URLSearchParams(decodeURL);
    updateTag(urlParams.get("tag"));
    updatePrice(urlParams.get("price"));
    updateState("Thankyou");
  }, []);

  useEffect(() => {
    if (tag && contextAWDb && price) {
      var hasTag = false;
      for (let i = 0; i < contextAWDb.length; i++) {
        if (contextAWDb[i].id === tag) hasTag = true;
      }
      if (hasTag) {
        ReactPixel.track("Purchase", {
          value: price,
          currency: "THB",
          content_ids: tag,
        });
      } else {
        ReactPixel.track("Purchase", { value: price, currency: "THB" });
      }
      updateState("Thankyou");
    }
  }, [tag, price, contextAWDb]);

  return (
    <ContentWrapper>
      <HeadTextStyled>{t("ขอบคุณสำหรับการสั่งซื้อ")}</HeadTextStyled>
      <QRScan src={qrScan} />
      <ContactLogoComponent />
    </ContentWrapper>
  );
};

export default ThankyouPage;
