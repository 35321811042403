import React, { useState, useEffect } from "react";
import styled from 'styled-components';

const ImageWrapper = styled.img`
    width: 20vw;
`;

const DownloadPage = () => {

    var linkDownload = ["https://storage.googleapis.com/doseart_asia_output/20210315-173122", "https://storage.googleapis.com/doseart_asia_output/yorushika.jpg"]

    const handleOnClick = () => {
        linkDownload.forEach((element, index) => {
            var linkElement = document.createElement("a");
            linkElement.target = "_blank";
            linkElement.href = element;
            document.body.appendChild(linkElement);
            setTimeout(() => {
                linkElement.click();
                document.body.removeChild(linkElement);
            }, 500 * index)
        });
    }

    const iframeMethod = () => {
        linkDownload.forEach((url,index) => {
            let iframe = document.createElement('iframe');
            iframe.style.visibility = 'collapse';
            document.body.append(iframe);

            iframe.contentDocument.write(
                `<form action="${url.replace(/\"/g, '"')}" method="GET" ></form>`
            );
            iframe.contentDocument.forms[0].submit();

            // setTimeout(() => iframe.remove(), 2000*(index+1));
        });
    }

    const asyncFetchMethod = async () => {
        linkDownload.forEach(async (url,index) => {
            await fetch(url, {
                method: 'GET'
            })
                .then(response => response.blob())
                .then(blob => {
                    var urlBlob = window.URL.createObjectURL(blob);
                    var a = document.createElement('a');
                    a.href = urlBlob;
                    a.download = "tiger"+index+".jpg";
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                })
        })

    }

    return (
        <div>
            <a href="https://storage.googleapis.com/doseart_asia_output/20210315-173122">
                <ImageWrapper src="https://storage.googleapis.com/doseart_asia_output/20210315-173122" />
            </a>
            <a href="https://storage.googleapis.com/doseart_asia_output/yorushika.jpg">
                <ImageWrapper src="https://storage.googleapis.com/doseart_asia_output/yorushika.jpg" />
            </a>
            <button onClick={() => { iframeMethod() }}>download</button>
        </div>
    )
}

export default DownloadPage;