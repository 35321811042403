import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import tipImg from "../assets/cart/tips.svg";
import CartCard from "../components/CartCard";
import {
  formList as formListAtom,
  state as stateAtom,
  artworkDatabase,
  prevFormList as prevFormListAtom,
  referenceID as referenceIDAtom,
  orderID as orderIDAtom,
  dateDatabase as dateDatabaseAtom,
  productPrice as productPriceAtom,
  previewIndex as previewIndexAtom,
  nProduct,
  selectEditCard as selectEditCardAtom,
  multple_attribute_selection,
  edit_card_state,
  chinese_zodiac_list,
  day_of_week_list,
  bundleTagPrice as bundleTagPriceAtom,
  catalogDatabase as catalogDatabaseAtom,
  prevBundleTagPrice,
  fbc_cookies,
  fbp_cookies,
} from "../global/atom";
import ReactPixel from "react-facebook-pixel";
import FooterComponent from "../components/FooterComponent";
import {
  DEPLOY_VERSION,
  getDateText,
  getThaiMoonPhase,
  getZodiacName,
  sendConversionAPI,
  sendMessageToCreateArtwork,
} from "../utils/utils";
import NotificationCard from "../components/NotificationCard";
import LoaderComponent from "../components/LoaderComponent";
import CancelIcon from "../assets/gallery/cancelButton.svg";
import { use100vh } from "react-div-100vh";
import fluidText from "../utils/fluidText";
import { fluidRange } from "polished";
import { Default } from "react-spinners-css";
import { LocalConvenienceStoreOutlined } from "@material-ui/icons";
import {
  sessionDateDatabase,
  sessionOrderID,
  sessionPrice,
  sessionRefID,
  sessionState,
  useSessionStorage,
  select_package,
  sessionShowKpayment,
  sessionVoucher,
  discountCoupon,
  sessionTotalAmount,
  multiple_attribute,
  package_name_detail,
  session_nProducts,
  session_select_edit_card,
  session_state,
  session_preview_index,
  session_prev_form_list,
  session_edit_card_state,
  session_bundle_tag_price,
  session_prev_bundle_tag_price,
  session_multple_attribute_selection,
  session_chinese_zodiac_list,
  session_day_of_week_list,
  reffer_url,
  session_collection_selection_info,
  session_artwork_database,
  session_fbc_cookies,
  session_fbp_cookies,
} from "../utils/sessionStorage";
import TagManager from "react-gtm-module";
import { useContext } from "react";
import { UserContext } from "../utils/Context/userContext";
import useNavigationHandling from "../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";

const HeadTextStyled = styled.div`
  //width:88.46vw;
  margin: 2vh auto 2vh auto;
  ${fluidText(24, 28)}
  //font-size: 1.5rem;
  font-style: normal;
  font-weight: 300;
  color: black;
  text-align: center;
  @media (min-width: 600px) {
    //width: 38vw;
    margin: 3vh auto 3vh auto;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  @media (min-width: 600px) {
    width: 40vw;
    margin: auto;
    min-width: 500px;
  }
`;

const TipImage = styled.img`
  width: 9.2vw;
  //margin-left : 5.1vw;
  //margin-right: 5.1vw;
  margin-left: 2vw;
  margin-right: 4vw;
  pointer-events: none;
  @media (min-width: 600px) {
    width: 6vh;
    height: 6vh;
    margin-right: 1vw;
  }
`;

const TipsTextStyled = styled.div`
  //font-size: 0.9rem;
  ${fluidText(12, 20)}
  font-style: normal;
  font-weight: 400;
  color: "black";
  text-align: left;
  align-items: center;
  display: flex;
  @media (min-width: 600px) {
    height: 6vh;
    //font-size: 1rem;
  }
  //margin-left : 5.1vw;
`;

const TipsRedTextStyled = styled.div`
  ${fluidText(12, 20)}
  font-style: normal;
  font-weight: 400;
  color: #e4202a;
  text-align: left;
  word-wrap: normal;
  @media (min-width: 600px) {
    //font-size: 1rem;
  }
  //margin-left : 5.1vw;
`;

const TipsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 90vw;
  margin: 0 auto 4vh auto;
  @media (min-width: 600px) {
    //width: 38vw;
    min-width: 400px;
  }
`;

const PriceHeaderText = styled.div`
  margin: 1vh auto 0 auto;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 600;
  color: black;
  flex-direction: row;
  display: flex;
  @media (min-width: 600px) {
    margin: 0 auto 1vh auto;
  }
`;

const RedText = styled.div`
  color: #e4202a;
  margin-left: 0.5rem;
`;

const LargePreviewImageWrapper = styled.img`
  width: 100%;
  height: 100%;
  margin: auto;
  pointer-events: none;
  object-fit: contain;
`;

const LargePreviewWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: 10;
`;
const ImageAreaWrapper = styled.div`
  position: relative;
  height: ${(props) => `${props.vh}px`};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  //display: flex;
`;

const PositionImageWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.useWidth ? `90vw` : `${props.vh * 0.518}px`)};
  height: ${(props) =>
    props.useWidth ? `calc(90vw / 0.518)` : `${props.vh}px`};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CancelIconStyled = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 9vw;
  cursor: pointer;
  pointer-events: auto;
  @media (min-width: 600px) {
    width: 2.5vw;
  }
`;
const SpinnerWrapper = styled(Default)`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

const emptyFormFill = {
  fill: false,
  name: "",
  surname: "",
  zodiacId: "",
  birthDate: "",
  birthMonth: "",
  birthYear: "",
  showBirthDate: false,
  selectImageId: "",
  selectImage: "",
  id: "",
};

const CartPage = () => {
  const catalogDatabase = useRecoilValue(catalogDatabaseAtom);
  const { contextAWDb, setContextAWDb, voucher, formList, updateFormList } =
    useContext(UserContext);
  //const [data,] = useSessionStorage(session_artwork_database.key,session_artwork_database.default)
  //const data = useRecoilValue(artworkDatabase);
  const [state, updateState] = useRecoilState(stateAtom);
  const [, updatePreviewIndex] = useSessionStorage(
    session_preview_index.key,
    session_preview_index.default
  );
  const [prevFormList, updatePrevFormList] = useSessionStorage(
    session_prev_form_list.key,
    session_prev_form_list.default
  );
  const [editCardState] = useSessionStorage(
    session_edit_card_state.key,
    session_edit_card_state.default
  );
  const [refIDs, updateRefID] = useSessionStorage(
    sessionRefID.key,
    sessionRefID.default
  );
  const [bundleTagPrice] = useSessionStorage(
    session_bundle_tag_price.key,
    session_bundle_tag_price.default
  );
  const [buffer_bundleTagPrice, update_buffer_bundleTagPrice] =
    useSessionStorage(
      session_prev_bundle_tag_price.key,
      session_prev_bundle_tag_price.default
    );
  const [multpleAttribute] = useSessionStorage(
    session_multple_attribute_selection.key,
    session_multple_attribute_selection.default
  );
  const [chineseZodiacList] = useSessionStorage(
    session_chinese_zodiac_list.key,
    session_chinese_zodiac_list.default
  );
  const [dayOfWeekList] = useSessionStorage(
    session_day_of_week_list.key,
    session_day_of_week_list.default
  );
  //const fbc= useRecoilValue(fbc_cookies)
  const [fbc] = useSessionStorage(
    session_fbc_cookies.key,
    session_fbc_cookies.default
  );
  //const fbp = useRecoilValue(fbp_cookies)
  const [fbp] = useSessionStorage(
    session_fbp_cookies.key,
    session_fbp_cookies.default
  );
  const { navigateHandler } = useNavigationHandling();
  const [showNoti, updateShowNoti] = useState(false);
  const [, setIsReference] = useState(false);
  const [, updateLoading] = useState(false);
  const height = use100vh();
  const [, updateVh] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [, setUseWidth] = useState(true);
  const { t, i18n } = useTranslation();
  //const [dateDatabase,updateDateDatabase] =useSessionStorage(sessionDateDatabase.key,sessionDateDatabase.default)
  const [dateDatabase] = useRecoilState(dateDatabaseAtom);
  const [productPrice, updateProductPrice] = useSessionStorage(
    sessionPrice.key,
    sessionPrice.default
  );
  //const [voucher, ] = useSessionStorage(sessionVoucher.key, sessionVoucher.default);
  const [discount_type] = useSessionStorage(
    discountCoupon.key,
    discountCoupon.default
  );
  const [total_amount, update_total_amount] = useSessionStorage(
    sessionTotalAmount.key,
    sessionTotalAmount.default
  );
  const [session_reffer_url, update_session_reffer_url] = useSessionStorage(
    reffer_url.key,
    reffer_url.default
  );
  const [collection_selection_info] = useSessionStorage(
    session_collection_selection_info.key,
    session_collection_selection_info.default
  );

  //const nProducts = useRecoilValue(nProduct);
  //const [selectEditCard, updateSelectEditCard] = useRecoilState(selectEditCardAtom);
  const [selected_edit_card, updateSelectEditCard] = useSessionStorage(
    session_select_edit_card.key,
    session_select_edit_card.default
  );
  const [nProducts] = useSessionStorage(
    session_nProducts.key,
    session_nProducts.value
  );

  const [selectPackage] = useSessionStorage(
    select_package.key,
    select_package.default
  );
  const [, updateMultipleAttribute] = useSessionStorage(
    multiple_attribute.key,
    multiple_attribute.default
  );
  const [packageNameDetail] = useSessionStorage(
    package_name_detail.key,
    package_name_detail.default
  );
  const [nextPage, updateNextPage] = useState("");

  var intervalFunction = useRef();
  var timeoutFunction = useRef();

  useEffect(() => {
    if (
      (width < 600 && 1.69 * width > window.innerHeight) ||
      (width > 600 && 0.25 * width > window.innerHeight)
    ) {
      setUseWidth(false);
    } else {
    }
    if (nProducts && formList) {
      var allFilled = true;
      for (var i = 0; i < nProducts; i++) {
        allFilled = allFilled && formList[i].fill;
      }
      if (allFilled) updateState("CartFillComplete");
      else updateState("CartFillInComplete");
    }
    return () => {
      clearInterval(intervalFunction.current);
    };
  }, []);

  useEffect(() => {
    updateMultipleAttribute(multpleAttribute);
  }, [multpleAttribute]);

  function loopCheckTag(arr, arr_2) {
    // check type string or array
    if (typeof arr_2 === "string") {
      if (arr === arr_2) return true;
      else return false;
    } else {
      if (typeof arr === "string") {
        return false;
      } else {
        if (Array.isArray(arr)) {
          for (let i = 0; i < arr.length; i++) {
            if (arr[i] !== arr_2[i]) return false;
          }
        }
        return true;
      }
    }
  }

  useEffect(() => {
    if (formList !== null && nProducts !== null && bundleTagPrice) {
      if (
        formList.length !== parseInt(nProducts) ||
        !buffer_bundleTagPrice ||
        !loopCheckTag(buffer_bundleTagPrice.tag, bundleTagPrice.tag)
      ) {
        var temp = [];
        var prevTemp = [];
        if (typeof bundleTagPrice.tag === "string") {
          for (var i = 0; i < nProducts; i++) {
            temp.push({
              ...emptyFormFill,
              tag: bundleTagPrice.tag,
              price: bundleTagPrice.price,
            });
            prevTemp.push(emptyFormFill);
          }
        } else if (typeof bundleTagPrice.tag === "object") {
          if (typeof bundleTagPrice.price === "object") {
            for (var i = 0; i < nProducts; i++) {
              temp.push({
                ...emptyFormFill,
                tag: bundleTagPrice.tag[i],
                price: bundleTagPrice.price[i],
              });
              prevTemp.push(prevFormList[0]);
            }
          } else {
            for (var i = 0; i < nProducts; i++) {
              temp.push({
                ...emptyFormFill,
                tag: bundleTagPrice.tag[i],
                price: bundleTagPrice.price,
              });
              prevTemp.push(prevFormList[0]);
            }
          }
        }
        updateFormList(temp);
        updatePrevFormList(prevTemp);
        update_buffer_bundleTagPrice(bundleTagPrice);
      }
    }
    if (formList !== null && nProducts !== null) {
      var allFilled = true;
      for (var i = 0; i < nProducts; i++) {
        allFilled = allFilled && formList[i].fill;
      }
      if (allFilled) updateState("CartFillComplete");
      else updateState("CartFillInComplete");
    }
  }, [formList, nProducts, bundleTagPrice, buffer_bundleTagPrice]);

  useEffect(() => {
    updateMultipleAttribute(multpleAttribute);
  }, [multpleAttribute]);

  function goToPreviewImage() {
    var amount = productPrice;
    if (selectPackage == "") {
      amount = 0;
      for (var x = 0; x < formList.length; x++) {
        var sales_price =
          contextAWDb[formList[x].selectImageId].sale_price == null ||
          contextAWDb[formList[x].selectImageId].sale_price == ""
            ? contextAWDb[formList[x].selectImageId].price
            : contextAWDb[formList[x].selectImageId].sale_price;
        amount += parseFloat(sales_price);
      }
      if (window.location.href.includes("aisalc")) {
        var filterPrice = [59, 99, 159, 199, 249, 299];
        var discoutPrice = [49, 79, 129, 159, 199, 239];
        for (var i = 0; i < filterPrice.length; i++) {
          if (amount == filterPrice[i]) {
            update_total_amount(discoutPrice[i]);
            updateProductPrice(amount);
          }
        }
      } else {
        update_total_amount(amount);
        updateProductPrice(amount);
      }
    } else {
      update_total_amount(amount);
    }
    if (voucher != "") {
      if (discount_type.type == "AMOUNT") {
        var discount_amount = (discount_type.amount_off / 100).toFixed(2);
        update_total_amount((amount - discount_amount).toFixed(2));
      }
      //percent
      else if (discount_type.type == "PERCENT") {
        var discount_amount = (
          (discount_type.percent_off / 100) *
          amount
        ).toFixed(2);
        update_total_amount((amount - discount_amount).toFixed(2));
      }
    }

    TagManager.dataLayer({
      dataLayer: {
        event: "add_to_cart",
        collection_id: collection_selection_info.collection_name
          ? collection_selection_info.collection_name
          : "",
        refID: session_reffer_url,
      },
    });
    if (DEPLOY_VERSION == "PROD") {
      ReactPixel.init("973339143546259");
      ReactPixel.track("AddToCart");
      sendConversionAPI("AddToCart", fbc, fbp);
    }
    if (window.location.href.includes("aisalc"))
      updateNextPage("/aisalc/preview");
    else if (window.location.href.includes("redeem"))
      updateNextPage("/redeem/preview");
    else updateNextPage("/preview");
    //navigateHandler("/preview");
  }

  const handleOnClickCartCard = (form, idx) => {
    updatePreviewIndex(-1);
    updateSelectEditCard(idx);
    if (editCardState == "package") {
      if (window.location.href.includes("redeem"))
        updateNextPage("/redeem/package");
      else updateNextPage("/home/package");
      //setTimeout(()=>{navigateHandler("/home/package");},100)
    } else if (form.tag) {
      if (window.location.href.includes("redeem"))
      updateNextPage("/redeem/gallery-selection/" + form.tag + "/" + form.price);
      else updateNextPage("/gallery-selection/" + form.tag + "/" + form.price);
      //setTimeout(()=>navigateHandler("/gallery-selection/" + form.tag + "/" + form.price),100);
    } else {
      if (window.location.href.includes("aisalc"))
        updateNextPage("/aisalc/gallery");
      else if (window.location.href.includes("redeem"))
        updateNextPage("/redeem/gallery");
      else updateNextPage("/gallery");
      //setTimeout(()=>navigateHandler("/gallery"),100);
    }
  };

  useEffect(() => {
    if (nextPage !== "") {
      if (
        (nextPage == "/preview" || nextPage == "/aisalc/preview") &&
        total_amount != null &&
        productPrice != null &&
        refIDs !== ""
      ) {
        navigateHandler(nextPage);
      } else if (
        selected_edit_card !== "" &&
        nextPage != "/preview" &&
        nextPage != "/aisalc/preview"
      ) {
        navigateHandler(nextPage);
      }
    }
  }, [nextPage, refIDs]);

  function checkGenerateNewID() {
    var isSame = true;
    for (var i = 0; i < nProducts; i++) {
      isSame = isSame && checkObjectEqual(prevFormList[i], formList[i]);
    }
    if (multpleAttribute.length > 0) {
      isSame = false;
    }
    if (!refIDs || refIDs == "") isSame = false;
    return !isSame;
  }

  function checkObjectEqual(object1, object2) {
    const keys1 = Object.keys(object1);
    const keys2 = Object.keys(object2);
    if (keys1.length !== keys2.length) return false;
    for (let key of keys1) {
      if (object1[key] !== object2[key]) return false;
    }
    return true;
  }

  const getChristianYear = (year) => {
    return i18n.language === "th" ? year - 543 : year;
  };

  const createWaterMarkArtwork = async () => {
    var refIDarray = [];
    var promises = [];
    formList.forEach((element, index) => {
      refIDarray.push("");
      promises.push(
        new Promise((resolve, reject) => {
          var CE_year;
          var date = new Date(
            getChristianYear(element.birthYear),
            element.birthMonth,
            element.birthDate
          );
          var dayOfWeek =
            element.day_of_week !== "" ? element.day_of_week : date.getDay();
          getThaiMoonPhase(
            getChristianYear(element.birthYear),
            element.birthMonth + 1,
            element.birthDate,
            dateDatabase
          ).then((moonPhase) => {
            var birthDate = element.showBirthDate ? element.birthDate : 0;
            var birthMonth = element.showBirthDate ? element.birthMonth : 0;
            var birthYear = element.showBirthDate ? element.birthYear : 0;
            if (multpleAttribute.length > 0) {
              sendMessageToCreateArtwork(
                selectPackage,
                "true",
                element.name,
                element.surname,
                birthDate.toString(),
                birthMonth.toString(),
                birthYear.toString(),
                element.zodiacId.toString(),
                dayOfWeek.toString(),
                moonPhase.toString(),
                multpleAttribute,
                element.chinese_zodiac.toString()
              )
                .then((returnValue) => {
                  refIDarray[index] = returnValue;
                  updatePrevFormList(formList);
                  resolve("solve");
                })
                .catch((e) => {
                  reject(e);
                });
            } else {
              sendMessageToCreateArtwork(
                contextAWDb[element.selectImageId].filename,
                "true",
                element.name,
                element.surname,
                birthDate.toString(),
                birthMonth.toString(),
                birthYear.toString(),
                element.zodiacId.toString(),
                dayOfWeek.toString(),
                moonPhase.toString(),
                [],
                element.chinese_zodiac.toString(),
                element.element_index?.toString(),
                element.element_support_index?.toString(),
              )
                .then((returnValue) => {
                  refIDarray[index] = returnValue;
                  updatePrevFormList(formList);
                  resolve("solve");
                })
                .catch((e) => {
                  reject(e);
                });
            }
          });
        })
      );
    });
    Promise.all(promises)
      .then(() => {
        updateRefID(refIDarray);
        setIsReference(true);
        goToPreviewImage();
        //setTimeout(() => goToPreviewImage(), 100);
      })
      .catch((e) => {
        //console.log(e)
      });
  };

  window.addEventListener("resize", () => {
    let vh = window.innerHeight;
    updateVh(vh);
    let vw = window.innerWidth;
    setWidth(vw);
  });

  const onClickNextOnNoti = () => {
    if (checkGenerateNewID()) {
      createWaterMarkArtwork();
      setIsReference(false);
      updateLoading(true);
    } else {
      goToPreviewImage();
    }
    updateShowNoti(false);
    updateState("SendingMessage");
  };

  const CartCardComponent = () => {
    const { t } = useTranslation();
    return formList.map((form, idx) => {
      return (
        <CartCard
          key={idx}
          isFilled={form?.fill}
          previewImage={multpleAttribute.length == 0 && form?.selectImage}
          name={form?.name + " " + form?.surname}
          birthdate={
            form?.birthDate != 0
              ? getDateText(
                  form?.birthDate,
                  form?.birthMonth,
                  form?.birthYear,
                  t
                )
              : ""
          }
          isShowDate={form?.showBirthDate}
          zodiac={t(getZodiacName(form?.zodiacId))}
          packageDetail={packageNameDetail}
          tagFilter={form?.tag}
          catalogDatabase={catalogDatabase}
          packageName={
            bundleTagPrice && bundleTagPrice.select_package_name
              ? bundleTagPrice.select_package_name[idx]
              : null
          }
          handleOnClick={() => handleOnClickCartCard(form, idx)}
          chinese_zodiac={
            form.chinese_zodiac === ""
              ? ""
              : chineseZodiacList[form.chinese_zodiac]
          }
          day_of_week={
            form.day_of_week === "" ? "" : dayOfWeekList[form.day_of_week]
          }
        />
      );
    });
  };

  const CustomImageState = () => {
    return (
      <>
        <HeadTextStyled>
          {t("CartPage.ใส่ข้อมูลในภาพที่ต้องการซื้อ")}
        </HeadTextStyled>
        <TipsWrapper>
          <TipsTextStyled>
            <br />• {t("CartPage.กดที่การ์ด เพื่อใส่ข้อมูลในภาพ")}
          </TipsTextStyled>
        </TipsWrapper>
        <div style={{ marginBottom: "15vh" }}>
          <CartCardComponent />
        </div>
        {showNoti ? (
          <NotificationCard
            onClickBack={() => {
              updateShowNoti(false);
            }}
            onClickNext={() => {
              onClickNextOnNoti();
            }}
            height={height}
            state={state}
          />
        ) : (
          <></>
        )}
        <FooterComponent addOnClickNextFunction={() => onClickNextOnNoti()} />
      </>
    );
  };

  const SendingMessageState = () => {
    return <LoaderComponent />;
  };

  return (
    <ContentWrapper>
      {state == "SendingMessage" ? (
        <SendingMessageState />
      ) : (
        <CustomImageState />
      )}
    </ContentWrapper>
  );
};

export default CartPage;
