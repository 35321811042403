import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useRecoilState } from "recoil";
import { state as stateAtom } from "../global/atom";
import backIcon from "../assets/home/back.svg";
import { useSessionStorage } from "../utils/sessionStorage";
import { useContext } from "react";
import { UserContext } from "../utils/Context/userContext";
import DoseartButton from "./DoseartButton";
import LuckydoseXAIS from "../assets/subscription/luckydoseXAIS_logo.svg";
import useNavigationHandling from "../utils/useNavigationHandling";
export const LUCKYDOSE_BLACK = (
  <svg
    width="480"
    height="57"
    viewBox="0 0 480 57"
    fill="#000000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.9569 1.18555H0V54.1542H38.7347V50.2829H8.9569V1.18555Z"
      fill="#000000"
    />
    <path
      d="M87.2946 31.1522C87.3608 34.2594 86.8286 37.3503 85.727 40.2563C84.8117 42.6342 83.4248 44.8022 81.6496 46.6299C80.0287 48.266 78.0832 49.5446 75.9384 50.3835C71.678 52.0186 66.9672 52.0421 62.6907 50.4497C60.5189 49.6692 58.5317 48.4485 56.8536 46.8642C55.1756 45.2798 53.8429 43.366 52.939 41.2425C51.8883 38.7551 51.3718 36.0747 51.4228 33.3749V1.39648H42.4807V33.4043C42.4149 36.888 43.1422 40.3409 44.6076 43.502C45.9426 46.2967 47.8569 48.7754 50.2232 50.7735C52.5486 52.7218 55.2307 54.1992 58.1203 55.1232C63.9675 57.0268 70.2678 57.0268 76.115 55.1232C79.0114 54.1926 81.704 52.7189 84.0489 50.7809C86.4307 48.7874 88.3559 46.305 89.6939 43.502C91.162 40.3418 91.8895 36.8882 91.8209 33.4043V1.39648H87.2946V31.1522Z"
      fill="#000000"
    />
    <path
      d="M113.43 11.3256C115.084 9.09825 117.201 7.25536 119.634 5.92344C122.283 4.5262 125.244 3.82709 128.238 3.89213C130.297 3.90446 132.349 4.12889 134.361 4.56187C136.46 5.00528 138.504 5.67912 140.455 6.57111C142.397 7.4644 144.222 8.59408 145.887 9.93453C147.544 11.2669 148.974 12.8603 150.119 14.6522L150.604 15.3882L153.99 13.3569L153.49 12.562C151.028 8.76383 147.639 5.65583 143.642 3.5315C139.41 1.19108 134.199 0.00614109 128.186 0.00614109C123.753 -0.0789887 119.346 0.721708 115.226 2.36129C111.724 3.79216 108.555 5.92924 105.916 8.63922C103.462 11.2029 101.555 14.2374 100.307 17.5593C97.8051 24.1042 97.8051 31.3422 100.307 37.8871C101.555 41.209 103.462 44.2436 105.916 46.8072C108.552 49.5205 111.722 51.6581 115.226 53.0852C119.345 54.7307 123.752 55.5341 128.186 55.4477C134.221 55.4477 139.41 54.2701 143.591 51.9518C147.552 49.8215 150.922 46.7382 153.394 42.9802L153.916 42.1632L150.604 40.2865L150.148 41.0813C149.135 42.828 147.816 44.3785 146.255 45.6591C144.636 46.9933 142.85 48.1093 140.941 48.9784C138.955 49.887 136.874 50.5731 134.737 51.0244C132.561 51.505 130.341 51.7517 128.113 51.7604C125.13 51.8604 122.173 51.1752 119.539 49.7733C117.107 48.4475 114.989 46.6153 113.327 44.4006C111.622 42.1002 110.354 39.5056 109.588 36.7464C108.771 33.8734 108.355 30.9014 108.352 27.9146C108.353 24.925 108.782 21.951 109.625 19.0828C110.421 16.2909 111.709 13.6638 113.43 11.3256Z"
      fill="#000000"
    />
    <path
      d="M204.462 1.18555H197.919L168.443 25.7085V1.18555H159.486V54.1542H168.443V31.0002L171.431 28.5052L194.644 54.1542H206.678L178.585 22.3303L204.462 1.18555Z"
      fill="#000000"
    />
    <path
      d="M238.341 30.0509L219.522 1.25195H208.283L232.195 36.5202V54.1544H241.226V34.2608L263.003 1.25195H257.086L238.341 30.0509Z"
      fill="#000000"
    />
    <path
      d="M358.902 0.162109C334.107 0.162109 329.625 15.6177 329.625 27.3934C329.625 39.1692 334.718 55.2872 358.902 55.2872C383.087 55.2872 388.172 39.1397 388.172 27.3861C388.172 15.6325 383.698 0.162109 358.902 0.162109ZM358.902 52.7922C342.784 52.7922 339.892 39.6181 339.892 27.3861C339.892 15.1541 342.909 2.60558 358.902 2.60558C374.895 2.60558 377.869 15.5294 377.869 27.3861C377.869 39.2428 375.028 52.7701 358.902 52.7701V52.7922Z"
      fill="#000000"
    />
    <path
      d="M414.557 22.9617C403.775 19.5983 401.133 16.7574 401.133 11.7821C401.133 6.80689 404.6 3.28154 413.24 3.28154C418.656 3.3593 423.967 4.78326 428.696 7.42513V3.18586C428.696 3.18586 422.675 0.396484 413.328 0.396484C403.981 0.396484 395.378 3.15642 393.906 12.0324C392.434 20.9083 396.437 28.1209 409.509 32.2645C422.756 36.4596 426.193 39.2048 425.435 44.7762C424.677 50.3476 418.973 52.3274 411.834 52.3274C404.695 52.3274 396.18 48.3384 393.516 46.4837V51.4442C399.315 53.7421 405.486 54.9618 411.724 55.0432C421.623 55.0432 433.251 49.8913 433.524 39.7053C433.796 29.5193 425.332 26.3178 414.557 22.9617Z"
      fill="#000000"
    />
    <path
      d="M298.277 1.35953H269.633V54.0338H300.463C300.463 54.0338 323.742 54.2546 323.742 27.2072C323.742 0.159873 298.277 1.35953 298.277 1.35953ZM295.554 51.7081H279.841V3.72939H295.65C304.445 3.72939 313.247 8.54273 313.247 27.3839C313.247 46.225 304.629 51.7081 295.554 51.7081Z"
      fill="#000000"
    />
    <path
      d="M479.999 4.27581V1.36133H440.984V54.0356H479.999V51.1211H450.935V28.2247H476.017V25.3102H450.935V4.27581H479.999Z"
      fill="#000000"
    />
  </svg>
);

export const LUCKYDOSE = (
  <svg
    width="480"
    height="57"
    viewBox="0 0 480 57"
    fill="#B00020"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.9569 1.18555H0V54.1542H38.7347V50.2829H8.9569V1.18555Z"
      fill="#B00020"
    />
    <path
      d="M87.2946 31.1522C87.3608 34.2594 86.8286 37.3503 85.727 40.2563C84.8117 42.6342 83.4248 44.8022 81.6496 46.6299C80.0287 48.266 78.0832 49.5446 75.9384 50.3835C71.678 52.0186 66.9672 52.0421 62.6907 50.4497C60.5189 49.6692 58.5317 48.4485 56.8536 46.8642C55.1756 45.2798 53.8429 43.366 52.939 41.2425C51.8883 38.7551 51.3718 36.0747 51.4228 33.3749V1.39648H42.4807V33.4043C42.4149 36.888 43.1422 40.3409 44.6076 43.502C45.9426 46.2967 47.8569 48.7754 50.2232 50.7735C52.5486 52.7218 55.2307 54.1992 58.1203 55.1232C63.9675 57.0268 70.2678 57.0268 76.115 55.1232C79.0114 54.1926 81.704 52.7189 84.0489 50.7809C86.4307 48.7874 88.3559 46.305 89.6939 43.502C91.162 40.3418 91.8895 36.8882 91.8209 33.4043V1.39648H87.2946V31.1522Z"
      fill="#B00020"
    />
    <path
      d="M113.43 11.3256C115.084 9.09825 117.201 7.25536 119.634 5.92344C122.283 4.5262 125.244 3.82709 128.238 3.89213C130.297 3.90446 132.349 4.12889 134.361 4.56187C136.46 5.00528 138.504 5.67912 140.455 6.57111C142.397 7.4644 144.222 8.59408 145.887 9.93453C147.544 11.2669 148.974 12.8603 150.119 14.6522L150.604 15.3882L153.99 13.3569L153.49 12.562C151.028 8.76383 147.639 5.65583 143.642 3.5315C139.41 1.19108 134.199 0.00614109 128.186 0.00614109C123.753 -0.0789887 119.346 0.721708 115.226 2.36129C111.724 3.79216 108.555 5.92924 105.916 8.63922C103.462 11.2029 101.555 14.2374 100.307 17.5593C97.8051 24.1042 97.8051 31.3422 100.307 37.8871C101.555 41.209 103.462 44.2436 105.916 46.8072C108.552 49.5205 111.722 51.6581 115.226 53.0852C119.345 54.7307 123.752 55.5341 128.186 55.4477C134.221 55.4477 139.41 54.2701 143.591 51.9518C147.552 49.8215 150.922 46.7382 153.394 42.9802L153.916 42.1632L150.604 40.2865L150.148 41.0813C149.135 42.828 147.816 44.3785 146.255 45.6591C144.636 46.9933 142.85 48.1093 140.941 48.9784C138.955 49.887 136.874 50.5731 134.737 51.0244C132.561 51.505 130.341 51.7517 128.113 51.7604C125.13 51.8604 122.173 51.1752 119.539 49.7733C117.107 48.4475 114.989 46.6153 113.327 44.4006C111.622 42.1002 110.354 39.5056 109.588 36.7464C108.771 33.8734 108.355 30.9014 108.352 27.9146C108.353 24.925 108.782 21.951 109.625 19.0828C110.421 16.2909 111.709 13.6638 113.43 11.3256Z"
      fill="#B00020"
    />
    <path
      d="M204.462 1.18555H197.919L168.443 25.7085V1.18555H159.486V54.1542H168.443V31.0002L171.431 28.5052L194.644 54.1542H206.678L178.585 22.3303L204.462 1.18555Z"
      fill="#B00020"
    />
    <path
      d="M238.341 30.0509L219.522 1.25195H208.283L232.195 36.5202V54.1544H241.226V34.2608L263.003 1.25195H257.086L238.341 30.0509Z"
      fill="#B00020"
    />
    <path
      d="M358.902 0.162109C334.107 0.162109 329.625 15.6177 329.625 27.3934C329.625 39.1692 334.718 55.2872 358.902 55.2872C383.087 55.2872 388.172 39.1397 388.172 27.3861C388.172 15.6325 383.698 0.162109 358.902 0.162109ZM358.902 52.7922C342.784 52.7922 339.892 39.6181 339.892 27.3861C339.892 15.1541 342.909 2.60558 358.902 2.60558C374.895 2.60558 377.869 15.5294 377.869 27.3861C377.869 39.2428 375.028 52.7701 358.902 52.7701V52.7922Z"
      fill="#B00020"
    />
    <path
      d="M414.557 22.9617C403.775 19.5983 401.133 16.7574 401.133 11.7821C401.133 6.80689 404.6 3.28154 413.24 3.28154C418.656 3.3593 423.967 4.78326 428.696 7.42513V3.18586C428.696 3.18586 422.675 0.396484 413.328 0.396484C403.981 0.396484 395.378 3.15642 393.906 12.0324C392.434 20.9083 396.437 28.1209 409.509 32.2645C422.756 36.4596 426.193 39.2048 425.435 44.7762C424.677 50.3476 418.973 52.3274 411.834 52.3274C404.695 52.3274 396.18 48.3384 393.516 46.4837V51.4442C399.315 53.7421 405.486 54.9618 411.724 55.0432C421.623 55.0432 433.251 49.8913 433.524 39.7053C433.796 29.5193 425.332 26.3178 414.557 22.9617Z"
      fill="#B00020"
    />
    <path
      d="M298.277 1.35953H269.633V54.0338H300.463C300.463 54.0338 323.742 54.2546 323.742 27.2072C323.742 0.159873 298.277 1.35953 298.277 1.35953ZM295.554 51.7081H279.841V3.72939H295.65C304.445 3.72939 313.247 8.54273 313.247 27.3839C313.247 46.225 304.629 51.7081 295.554 51.7081Z"
      fill="#B00020"
    />
    <path
      d="M479.999 4.27581V1.36133H440.984V54.0356H479.999V51.1211H450.935V28.2247H476.017V25.3102H450.935V4.27581H479.999Z"
      fill="#B00020"
    />
  </svg>
);
const NavBar = styled("div")`
  width: 100%;
  height: 3.2rem;
  /* top: 0;
  position: fixed; */
  background: #fff;
  box-shadow: 0px 0px 10px rgba(159, 159, 159, 0.25);

  display: flex;
  justify-content: center;
  align-items: center;
  position: ${(props) => (props.isHome ? "fixed" : "unset")};
  z-index: 9;
`;

const LuckyDoseLogoWrapper = styled("div")`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  height: 100%;
  width: 100%;
  max-width: 600px;
  pointer-events: ${(props) => (props.onPreview ? "none" : "auto")};
  cursor: pointer;

  width: auto;
  svg {
    object-fit: contain;
    object-position: center;
    height: 30%;
  }
`;

const BackIcon = styled.img`
  height: 50%;
  margin: auto;
  display: flex;
`;

const BackIconWrapper = styled.div`
  height: 48px;
  display: flex;
  position: fixed;
  left: 24px;
  pointer-events: ${(props) => (props.onPreview ? "none" : "auto")};
  cursor: pointer;
  @media (min-width: 600px) {
    width: 48px;
  }
`;

const NavLogo = ({ isHome }) => {
  const { navigateHandler } = useNavigationHandling();
  const [state] = useRecoilState(stateAtom);
  const {
    setContextAWDb,
    voucher,
    update_voucher,
    updateCouponCollection,
    fetchArtwork,
    onShowPreview,
  } = useContext(UserContext);
  //const [fetchCollection,] = useSessionStorage(session_fetch_artwork.key,session_fetch_artwork.default)
  const handleClickLogo = () => {
    if (window.location.href.includes("ais_collection")) {
    } else if (window.location.href.includes("aisalc")) {
      if (!window.location.href.includes("gallery")) {
        window.scrollTo(0, 0);
        navigateHandler("/aisalc/gallery");
      }
    } else if (
      window.location.href.includes("aispostpaid") &&
      !window.location.href.includes("login")
    ) {
      window.scrollTo(0, 0);
      navigateHandler("/aispostpaid/package");
    } else if (
      window.location.href.includes("ais") &&
      !window.location.href.includes("login")
    ) {
      window.scrollTo(0, 0);
      navigateHandler("/ais/package");
    } else if( window.location.href.includes("redeem")){
      navigateHandler("/redeem/home")
    } 
    else if (
      !window.location.href.includes("login") &&
      !window.location.href.includes("aisalc/gallery")
    ) {
      window.scrollTo(0, 0);
      navigateHandler("/home");
    }
  };

  const handleBack = () => {
    if (window.location.href.includes("ais_collection")) {
      window.scrollTo(0, 0);
      navigateHandler("/home");
    } else {
      navigateHandler(-1);
    }
  };

  const onCancelCoupon = () => {
    update_voucher("");
    updateCouponCollection([]);
    setContextAWDb(fetchArtwork);
    navigateHandler("/home");
  };

  const CouponBar = () => {
    return (
      <div
        style={{
          width: "auto",
          position: "fixed",
          top: "3.2rem",
          right: "0px",
          padding: "10px 20px",
        }}
      >
        {voucher}
        <DoseartButton
          text={"Cancel"}
          isPrimary={false}
          handleOnClick={onCancelCoupon}
        />
      </div>
    );
  };
  
  return (
    <NavBar isHome={isHome&&!window.location.href.includes("redeem")}>
      {(state == "group_collection" ||
        state == "blogs" ||
        state == "blog_content" ||
        (window.location.href.includes("ais") &&
          !window.location.href.includes("package"))) && (
        <BackIconWrapper onPreview={onShowPreview} onClick={handleBack}>
          <BackIcon src={backIcon} />
        </BackIconWrapper>
      )}
      {window.location.href.includes("ais") ? (
        <LuckyDoseLogoWrapper
          onClick={handleClickLogo}
          onPreview={onShowPreview}
        >
          <img style={{ maxHeight: "30%" }} src={LuckydoseXAIS} />
        </LuckyDoseLogoWrapper>
      ) : (
        <LuckyDoseLogoWrapper onClick={handleClickLogo}>
          {LUCKYDOSE_BLACK}
        </LuckyDoseLogoWrapper>
      )}
      {/*voucher&&<CouponBar/>*/}
    </NavBar>
  );
};

export default NavLogo;
