// import DateFnsUtils from "@date-io/date-fns";
import AdapterDateFns from "@material-ui/lab/AdapterDateFns";
import { getYear } from "date-fns";

export default class CustomA extends AdapterDateFns {
  constructor({ locale, formats }) {
    super({ locale, formats });
  }

  toBuddhistYear(date, format_) {
    var christianYear = getYear(date);
    var buddhishYear = (parseInt(christianYear) + 543).toString();
    // console.log({ buddhishYear, date });
    // to string
    // replace year
    // to date

    let date_str = date.toString();
    let date_BE_str = date_str.replace(christianYear, buddhishYear);
    let date_BE = new Date(date_BE_str);

    // let formats_key = this.formats[format_];
    // console.log({ date, date_str, date_BE_str, date_BE, formats_key });
    return this.formatByString(date_BE, this.formats[format_]);
  }

  getMonth = (date) => {
    return this.toBuddhistYear(date);
  };

  format = (date, formatKey) => {
    return this.toBuddhistYear(date, formatKey);
  };

  // formatByString = (date, formatString) => {
  //   return this.format__default["default"](date, formatString, { locale: this.locale });
  // };
}
