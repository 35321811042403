import React, { useState, useEffect, useMemo, useRef, useContext } from "react";
import styled from "styled-components";
import DoseartButton from "./DoseartButton";
import { checkPhoneNumberState, state as stateAtom } from "../global/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import fluidText from "../utils/fluidText";
import { fluidRange } from "polished";
import { IS_IOS } from "../utils/support";
import { useSessionStorage } from "react-use";
import { reffer_url, sessionState, session_state } from "../utils/sessionStorage";
import useNavigationHandling from "../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";
import { UserContext } from "../utils/Context/userContext";

const FooterCardWrapper = styled.div`
  width: 100vw;
  background-color: #f9f9f9;
  box-shadow: 0px 0px 10px #d1d9e6;
  border-radius: 24px 24px 0px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 6vw 0;
  @media (min-width: 600px) {
    width: 43vw;
    padding: 4vh 0;
    min-width: 520px;
    justify-content: center;
  }
  //z-index: 9;
`;

const FooterWrapper = styled.div`
  z-index: 9;
  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0%);
  display: flex;
  flex-direction: column;
`;

const FooterComponent = ({
  isDisableNextButton,
  SelectedPaymentMethod,
  addOnClickNextFunction,
  addonClickBackFunction,
  hide,
}) => {
  const [state, updateState] = useRecoilState(stateAtom);
  const {activePreviewButton} =useContext(UserContext)
  //const[state, updateState]  = useSessionStorage(session_state.key,session_state.default)
  var { navigateHandler, getPathHandler } = useNavigationHandling();
  const previewButton = useRecoilValue(checkPhoneNumberState)
  const [session_reffer_url,] = useSessionStorage(reffer_url.key,reffer_url.default)
  const width = window.innerWidth;

  const { t } = useTranslation();

  const OnClickBackButton = () => {
    switch (state) {
      case "SelectImage":
        updateState("CartFillInComplete");
        navigateHandler("/cart");
        break;
      case "FillInformation":
        navigateHandler("/gallery");
        break;
      case "PreviewGenerate":
        addonClickBackFunction();
        updateState("CartFillComplete");
        break;
      case "Payment":
        updateState("PreviewGenerate");
        navigateHandler("/cart");
        break;
      case "DownloadSuccess":
        //updateState("")
        //navigateHandler("/")
        break;
      case "SubscriptionDownload":
        window.scrollTo(0, 0);
        if(window.location.href.includes("aispostpaid"))
        navigateHandler("/aispostpaid/user");
        else
        navigateHandler("/ais/user");
        break;
      default:
        break;
    }
  };

  const OnClickNextButton = () => {
    switch (state) {
      case "SelectImage":
        if(window.location.href.includes("redeem"))
        navigateHandler("/redeem/filled");
        else navigateHandler("/filled");
        addOnClickNextFunction();
        break;
      case "FillInformation":
        addOnClickNextFunction();
        break;
      case "PreviewGenerate":
        //updateState("CartFillComplete")
        addOnClickNextFunction();
        break;
      case "Payment":
        addOnClickNextFunction();
        break;
      case "UploadSuccess":
        addOnClickNextFunction();
        break;
      case "DownloadSuccess":
        addOnClickNextFunction();
        break;
      case "PurchasedHistory":
        navigateHandler("/");
        break;
      case "SubscriptionLogin":
        addOnClickNextFunction();
        break;
      case "SubscriptionPersonalInfo":
        addOnClickNextFunction();
        break;
      case "SubscriptionPreview":
        addOnClickNextFunction();
        break;
      case "SubscriptionDownload":
        addOnClickNextFunction();
        break;
      default:
        break;
    }
  };

  return (
    <>
      {hide ? (
        <></>
      ) : (
        <FooterWrapper>
          {state == "CartFillInComplete" ? (
            <FooterCardWrapper>
              <DoseartButton
                width={parseInt(width) < 600 ? "88vw" : "26.5vw"}
                isDisable={true}
                isPrimary={true}
                text={t("CartPage.ดูรายการสั่งซื้อ")}
              />
            </FooterCardWrapper>
          ) : state == "CartFillComplete" ? (
            <FooterCardWrapper>
              <DoseartButton
                width={parseInt(width) < 600 ? "88vw" : "26.5vw"}
                isDisable={false}
                isPrimary={true}
                text={t("CartPage.ดูรายการสั่งซื้อ")}
                handleOnClick={addOnClickNextFunction}
              />
            </FooterCardWrapper>
          ) : state == "SelectImage" ? (
            <FooterCardWrapper>
              <DoseartButton
                width={parseInt(width) < 600 ? "40vw" : "12.5vw"}
                isDisable={false}
                isPrimary={false}
                text={t("ย้อนกลับ")}
                handleOnClick={OnClickBackButton}
                isSetMargin={true}
              />
              <DoseartButton
                width={parseInt(width) < 600 ? "40vw" : "12.5vw"}
                isDisable={isDisableNextButton ? true : false}
                isPrimary={true}
                text={t("ต่อไป")}
                handleOnClick={OnClickNextButton}
                isSetMargin={true}
              />
            </FooterCardWrapper>
          ) : state == "FillInformation" ? (
            <FooterCardWrapper>
              <DoseartButton
                width={parseInt(width) < 600 ? "88vw" : "26.5vw"}
                isDisable={isDisableNextButton ? true : false}
                isPrimary={true}
                text={t("FillInformationPage.ตกลง")}
                handleOnClick={OnClickNextButton}
              />
            </FooterCardWrapper>
          ) : state == "PreviewGenerate" ? (
            <FooterCardWrapper>
              <DoseartButton
                width={parseInt(width) < 600 ? "88vw" : "26.5vw"}
                isDisable={isDisableNextButton ? true : false}
                isPrimary={true}
                text={t("ต่อไป")}
                handleOnClick={OnClickNextButton}
              />
            </FooterCardWrapper>
          )  : state == "Payment" ?(
          <FooterCardWrapper>
            {SelectedPaymentMethod === 'internetBanking' ||SelectedPaymentMethod === 'alipay'? 
            <form 
              id="omise-payment"
              className="btn omise-payment">
              <DoseartButton
                  width={parseInt(width) < 600 ? '88vw' : '26.5vw'}
                  isDisable={isDisableNextButton ? true : false}
                  isPrimary={true}
                  text={t("PaymentPage.สั่งซื้อ")}
                  handleOnClick={OnClickNextButton}
              ></DoseartButton>
            </form> :
            <DoseartButton
                  width={parseInt(width) < 600 ? '88vw' : '26.5vw'}
                  isDisable={isDisableNextButton ? true : false}
                  isPrimary={true}
                  text={t("PaymentPage.สั่งซื้อ")}
                  handleOnClick={OnClickNextButton}
              ></DoseartButton>
            }
          </FooterCardWrapper>
          ) : state == "DownloadSuccess" || state == "UploadSuccess" ? (
            <>
              {IS_IOS ? (
                <FooterCardWrapper>
                  <Link
                    to={session_reffer_url?getPathHandler(`/partner/${session_reffer_url}`):getPathHandler("/home")}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <DoseartButton
                      width={parseInt(width) < 600 ? "88vw" : "26.5vw"}
                      isDisable={false}
                      isPrimary={false}
                      text={t("CheckoutPage.สั่งภาพพื้นหลังเพิ่ม")}
                      handleOnClick={OnClickBackButton}
                    />
                  </Link>
                </FooterCardWrapper>
              ) : (
                <FooterCardWrapper>
                  <div style={{ display: "flex", flexDirection: "column" }}>
                    <div style={{ marginBottom: "2vh" }}>
                      <Link
                        to={session_reffer_url?getPathHandler(`/partner/${session_reffer_url}`):getPathHandler("/home")}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <DoseartButton
                          width={parseInt(width) < 600 ? "88vw" : "26.5vw"}
                          isDisable={false}
                          isPrimary={false}
                          text={t("CheckoutPage.สั่งภาพพื้นหลังเพิ่ม")}
                          handleOnClick={OnClickBackButton}
                        />
                      </Link>
                    </div>
                    <DoseartButton
                      width={parseInt(width) < 600 ? "88vw" : "26.5vw"}
                      isDisable={false}
                      isPrimary={true}
                      text={t("CheckoutPage.ดาวน์โหลดภาพทั้งหมด")}
                      handleOnClick={OnClickNextButton}
                    />
                  </div>
                </FooterCardWrapper>
              )}
            </>
          ) : state == "PurchasedHistory" ? (
            <FooterCardWrapper>
              <DoseartButton
                width={parseInt(width) < 600 ? "88vw" : "26.5vw"}
                isDisable={false}
                isPrimary={false}
                text={t("CheckoutPage.สั่งภาพพื้นหลังเพิ่ม")}
                handleOnClick={OnClickNextButton}
              />
            </FooterCardWrapper>
          ) : state == "SubscriptionLogin" ||
            state == "SubscriptionPersonalInfo" ? (
            <FooterCardWrapper>
              <DoseartButton
                width={parseInt(width) < 600 ? "88vw" : "26.5vw"}
                isDisable={isDisableNextButton ? true : false}
                isPrimary={true}
                text={t("FillInformationPage.ตกลง")}
                handleOnClick={OnClickNextButton}
              />
            </FooterCardWrapper>
          ) : state == "SubscriptionPreview" ? (
            <FooterCardWrapper>
              <DoseartButton
                width={parseInt(width) < 600 ? "88vw" : "26.5vw"}
                isDisable={isDisableNextButton ? true : false}
                isPrimary={true}
                text={t("ยืนยัน")}
                handleOnClick={OnClickNextButton}
              />
            </FooterCardWrapper>
          ) : state == "SubscriptionDownload" ? (
            <FooterCardWrapper>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div style={{ marginBottom: "2vh" }}>
                  <DoseartButton
                    width={parseInt(width) < 600 ? "88vw" : "26.5vw"}
                    isDisable={false}
                    isPrimary={false}
                    text={t("กลับหน้าแพ็กเกจ")}
                    handleOnClick={OnClickBackButton}
                  />
                </div>
                {!IS_IOS && (
                  <DoseartButton
                    width={parseInt(width) < 600 ? "88vw" : "26.5vw"}
                    isDisable={false}
                    isPrimary={true}
                    text={t("ดาวน์โหลดทั้งหมด")}
                    handleOnClick={OnClickNextButton}
                  />
                )}
              </div>
            </FooterCardWrapper>
          ) : (
            <></>
          )}
        </FooterWrapper>
      )}
    </>
  );
};

export default FooterComponent;
