import React from "react";
import styled from 'styled-components';
import fluidText from "../utils/fluidText";
import useNavigationHandling from "../utils/useNavigationHandling";
import { handleFontFamily } from "../utils/utils";

const ImageWrapper = styled.img`
    width:35vw ;
    height:35vw ;
    margin: 0vh 5vw 5vw 0vh;
    @media(min-width:600px){
        width:10.4vw;
        height:10.4vw;
        margin: 0vh 1.5vw 1.5vw 0vh;
    }
`

const TitleWrapper = styled.div`
    ${fluidText(16, 20)}
    ${handleFontFamily()}
    font-style: normal;
    font-weight: 400;
    margin-bottom:1vw ;
    @media(min-width:600px){
        ${fluidText(22, 26)}
    }
`
const DescriptionWrapper = styled.div`
    ${fluidText(12, 18)}
    ${handleFontFamily()}
    font-style: normal;
    font-weight: 300;
    margin-bottom:1.5vw ;
`

const BlogWrapper = styled.div`
    display:flex ;
    height:auto;
    @media(min-width:600px){
        width:50vw;
        flex-direction:row ;
    }
    flex-direction:row ;
    cursor:pointer ;
`

const TextWrapper = styled.div`
    display:flex ;
    flex-direction:column ;
    @media(min-width:600px){
        max-width:40vw ;
    }
    max-width:90vw ;
`
const LineBtwBlog = styled.div`
height:2px ;
width:100% ;
background:#C4C4C4 ;
margin-bottom:5vw ;
@media(min-width:600px){
    margin-bottom:1.5vw ;
    }
`

const BlogComponent = ({ image, title, description, isTop,content }) => {
    const { navigateHandler } = useNavigationHandling()

    const onClickBlog = ()=>{
        navigateHandler('/blogs/'+content)
    }

    return (
        <>
            {!isTop && <LineBtwBlog/>}
            <BlogWrapper onClick={onClickBlog}>
                <ImageWrapper src={image} />
                <TextWrapper>
                    <TitleWrapper>{title}</TitleWrapper>
                    <DescriptionWrapper>{description}</DescriptionWrapper>
                </TextWrapper>
            </BlogWrapper>
        </>
    )
}

export default BlogComponent