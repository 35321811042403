import React, { useEffect, useRef, useState } from "react";
import TextFieldCard from "../components/TextFieldCard";
import styled from "styled-components";
import tipImg from "../assets/cart/noti.svg";
import FooterComponent from "../components/FooterComponent";
import {
  state as stateAtom,
  // selectedURL as selectedURLAtom,
  // selectedIndex as selectedIndexAtom,
  // selectEditCard,
  formTypeDatabase,
  artworkDatabase,
  selectedIndex,
  artwork_show_birthdate,
  // multple_attribute_selection,
} from "../global/atom";
import {
  reffer_url,
  select_package,
  sessionFormType,
  session_artwork_show_birthdate,
  session_collection_selection_info,
  session_select_index,
  session_state,
  session_artwork_database,
  session_name_length,
} from "../utils/sessionStorage";
import DoseartButton from "../components/DoseartButton";
import { useRecoilState, useRecoilValue } from "recoil";
import { IS_ANDROID } from "../utils/support";
import fluidText from "../utils/fluidText";
import { fluidRange } from "polished";
import { useNavigate } from "react-router";
import { useSessionStorage } from "react-use";
import TagManager from "react-gtm-module";
import { useContext } from "react";
import { UserContext } from "../utils/Context/userContext";
import useNavigationHandling from "../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";
import { checkTransactionId, handleFontFamily } from "../utils/utils";
// import { sessionState } from "../utils/sessionStorage";

const ButtonStyled = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${(props) =>
    props.disable ? "#C4C4C4" : props.primary ? "#E4202A" : "white"};
  color: ${(props) => (props.primary ? "white" : "#E4202A")};
  pointer-events: ${(props) => (props.disable ? "none" : "initial")};
  //font-size: 1rem;
  padding: 1vh 0px;
  border-radius: 10px;
  ${handleFontFamily()}
  font-weight: 300;
  width: ${(props) => (props.width ? props.width : "auto")};
  display: inline-block;
  text-align: center;
  cursor: pointer;
  border: ${(props) =>
    props.disable
      ? "2px solid rgb(196, 196, 196)"
      : "2px solid rgb(228, 32, 42)"};
  ${fluidText(14, 18)}
  min-width: 120px;
  @media (min-width: 600px) {
    min-width: 170px;
    ${fluidRange(
      {
        prop: "font-size",
        fromSize: "16px",
        toSize: "20px",
      },
      "600px",
      "1600px"
    )}//font-size :1.1rem;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
  }
`;

const CustomButtonWrapper = styled.div`
  z-index: 1;
  @media (min-width: 600px) {
    font-size: 1.1rem;
    margin: ${(props) => (!props.isSetMargin ? "0 0.75vw" : "")};
  }
`;

const NotiWrapper = styled.div`
  width: 100vw;
  height: 100%;
  //align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: 10;
`;

const NotiCardWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  width: 90vw;
  height: min-content;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  bottom: 0;
  border-radius: 15px;
  padding: 2vh 0;
  flex-direction: column;
  display: flex;
  @media (min-width: 600px) {
    //width: 33vw;
    ${fluidRange(
      {
        prop: "width",
        fromSize: "380px",
        toSize: "653px",
      },
      "600px",
      "1600px"
    )}//min-width: 400px;
    //padding: 0 1vw;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  /* height: ${(props) => `${props.vh}px`}; */

  height: auto;
  overflow-x: hidden;
  overflow-y: scroll;
  padding-bottom: 10vh;
  @media (min-width: 600px) {
    /* width: 40vw; */
    /* min-width: 500px; */
    margin: auto;
  }
`;

const NotiHeaderText = styled.div`
  ${fluidRange(
    {
      prop: "font-size",
      fromSize: "18px",
      toSize: "26px",
    },

    "300px",
    "600px"
  )}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 600;
  margin: 2vh auto;
`;

const DateNotiHeader = styled.div`
  ${fluidRange(
    {
      prop: "font-size",
      fromSize: "18px",
      toSize: "26px",
    },

    "300px",
    "600px"
  )}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 600;
  margin: 2vh 3vw;
  @media (min-width: 600px) {
    //width: 33.4vw;
    ${fluidRange(
      {
        prop: "font-size",
        fromSize: "18px",
        toSize: "26px",
      },

      "600px",
      "1600px"
    )}
  }
`;

const DateNotiText = styled.div`
  margin: 0 0 4vh 3vw;
  font-weight:300 ;
  ${fluidRange(
    {
      prop: "font-size",
      fromSize: "13px",
      toSize: "24px",
    },

    "300px",
    "600px"
  )}
  margin:0 auto 2vh;
  padding: 0 2vw ;
  text-align: center;
  @media(min-width:600px) {
    //width: 33.4vw;
    min-width: 390px;
    ${fluidRange(
      {
        prop: "font-size",
        fromSize: "18px",
        toSize: "24px",
      },

      "600px",
      "1600px"
    )}
  }
`;

const UnOrderedListStyled = styled.ul`
  margin: 0 auto 4vh auto;
  padding: 0 2vw;
  ${fluidRange(
    {
      prop: "font-size",
      fromSize: "13px",
      toSize: "24px",
    },

    "300px",
    "600px"
  )}
  @media(min-width:600px) {
    //width: 33.4vw;
    min-width: 390px;
  }
`;

const HeadTextStyled = styled.div`
  //width: 88.46vw;
  margin: 2vh auto 2vh auto;
  ${fluidText(24, 28)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 300;
  color: black;
  text-align: left;
  @media (min-width: 600px) {
    margin: 3vh auto 3vh auto;
    //width: 40vw;
  }
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

const Wrapper = styled.div`
  margin-right: 2vw;
  @media (min-width: 600px) {
    margin-right: 0vw;
  }
`;

const TipsTextStyled = styled.div`
  ${fluidText(15, 18)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: "black";
  text-align: left;
  align-items: center;
  display: flex;
  @media (min-width: 600px) {
    height: 6vh;
    //font-size: 1rem;
  }
  //margin-left : 5.1vw;
`;

const TipsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 88.46vw;
  margin: 1vh auto;
  @media (min-width: 600px) {
    width: 40vw;
    min-width: 500px;
    margin-bottom: 2vh;
  }
`;

const TipImage = styled.img`
  width: 7.6vh;
  margin: 2vh auto;
  @media (min-width: 600px) {
    width: 6vh;
    height: 6vh;
  }
`;

const FillInformationPage = () => {
  const [state, updateState] = useRecoilState(stateAtom);
  //const [state, updateState] = useSessionStorage(session_state.key,session_state.default);
  const [formType] = useSessionStorage(
    sessionFormType.key,
    sessionFormType.default
  );
  const [nameLength,] = useSessionStorage(
    session_name_length.key,
    session_name_length.default,
  )
  const form_type_database = useRecoilValue(formTypeDatabase);
  const TextFieldCardRef = useRef();
  // const [value, setValue] = React.useState(null);
  const [hide, updateHide] = useState(false);
  const [height] = useState(window.innerHeight);
  const [dateError, setDateError] = useState(false);
  const [showNoti, setShowNoti] = useState(false);
  const [showDateNoti, setDateNoti] = useState(false);
  const [vh, updateVh] = useState(window.innerHeight);
  //const data = useRecoilValue(artworkDatabase);
  const { contextAWDb, formList } = useContext(UserContext);
  //const [data,] = useSessionStorage(session_artwork_database.key,session_artwork_database.default)
  const [form_selector, update_form_selector] = useState();
  const [doPopup, updateShowPopup] = useState(true);
  const [selectPackage] = useSessionStorage(
    select_package.key,
    select_package.default
  );
  /*
  const selected_index = useRecoilValue(selectedIndex);
  const artworkShowBrithDate = useRecoilValue(artwork_show_birthdate)*/

  const [selected_index] = useSessionStorage(
    session_select_index.key,
    session_select_index.default
  );
  const [artworkShowBrithDate] = useSessionStorage(
    session_artwork_show_birthdate.key,
    session_artwork_show_birthdate.default
  );
  const [session_reffer_url, update_session_reffer_url] = useSessionStorage(
    reffer_url.key,
    reffer_url.default
  );
  const [collection_selection_info, update_collection_selection_info] =
    useSessionStorage(
      session_collection_selection_info.key,
      session_collection_selection_info.default
    );

  const [isNext, updateIsNext] = useState(false);
  const [transStatus,updateTransStatus]=useState(0)
  const { t } = useTranslation();

  // const multipleAttribute = useRecoilValue(multple_attribute_selection);
  const width = window.innerWidth;
  var { navigateHandler } = useNavigationHandling();

  useEffect(() => {
    updateState("FillInformation");
    window.addEventListener("resize", checkWindowHeight);

    // hidden scroll
    let body = document.body;
    body.style.overflow = "hidden";
    let root = document.getElementById("root");
    root.style.overflow = "hidden";

    return function cleanup() {
      window.removeEventListener("resize", checkWindowHeight);

      // enable scroll
      let body = document.body;
      body.style.overflow = "unset";
      let root = document.getElementById("root");
      root.style.overflow = "unset";
    };
  }, []);

  useEffect(() => {
    if (contextAWDb && selected_index !== "") {
      if (contextAWDb[selected_index].hasBirthDate != null)
        if (contextAWDb[selected_index].hasBirthDate == true)
          updateShowPopup(true);
        else updateShowPopup(false);
    }
  }, [contextAWDb, selected_index]);

  useEffect(() => {
    if (contextAWDb[selected_index] == null && artworkShowBrithDate != "") {
      if (artworkShowBrithDate == true) {
        updateShowPopup(true);
      } else {
        updateShowPopup(false);
      }
    }
  }, [artworkShowBrithDate]);

  const checkWindowHeight = () => {
    let vh = window.innerHeight;
    updateVh(vh);
    if (IS_ANDROID) {
      if (parseInt(window.innerHeight) < 0.8 * height) updateHide(true);
      else updateHide(false);
    }
  };

  const OnClickCloseNoti = () => {
    setShowNoti(false);
  };

  useEffect(() => {
    if (formType) {
      for (var x = 0; x < form_type_database.length; x++) {
        if (formType == form_type_database[x].id)
          update_form_selector(form_type_database[x]);
      }
    }
  }, [formType, form_type_database]);

  useEffect(() => {
    if (isNext) {
      if(window.location.href.includes("redeem"))
        navigateHandler("/redeem/cart");
      else navigateHandler("/cart");
    }
  }, [isNext]);

  const CustomizeButton = ({
    width,
    isDisable,
    isPrimary,
    text,
    handleOnClick,
    isSetMargin,
  }) => {
    return (
      <CustomButtonWrapper>
        <ButtonStyled
          width={width}
          primary={isPrimary}
          disable={isDisable}
          onClick={handleOnClick}
          isSetMargin={isSetMargin}
        >
          {text}
        </ButtonStyled>
      </CustomButtonWrapper>
    );
  };

  const Notification = () => {
    return (
      <NotiWrapper>
        <NotiCardWrapper>
          <TipImage src={tipImg} />
          <NotiHeaderText>{t("กรุณาระบุ วัน เดือน ปีเกิด")}</NotiHeaderText>
          <UnOrderedListStyled>
            <li>
              {t("ใช้ประกอบการแสดงสัญลักษณ์เกี่ยวกับ")}
              <br />
              {t("วันเกิดเท่านั้นไม่มีการเก็บข้อมูล")}
              <br />
              {t("หรือนำข้อมูลของท่านไปใช้ในรูปแบบอื่น ๆ")}
            </li>
            <li>
              {t("สามารถเลือกที่จะไม่แสดงวันเกิดบนภาพ")}
              <br />
              {t("พื้นหลังได้ ในขั้นตอนต่อไป")}
            </li>
          </UnOrderedListStyled>
          <div style={{ margin: "auto" }}>
            <DoseartButton
              width={parseInt(width) < 600 ? "82vw" : "26.5vw"}
              isDisable={false}
              isPrimary={true}
              text={t("FillInformationPage.ตกลง")}
              handleOnClick={OnClickCloseNoti}
            />
          </div>
        </NotiCardWrapper>
      </NotiWrapper>
    );
  };

  const onClickNextWhenNotShowNoti = async (isShow) => {
    await TextFieldCardRef.current.setFormList(isShow);
    updateIsNext(true);
  };

  const NotiTransactionHandle = () => {
    return (
      <NotiWrapper>
        <NotiCardWrapper>
        <TipImage src={tipImg} />
          <NotiHeaderText>{transStatus==1?t("เลขที่ใบเสร็จนี้ ถูกใช้ไปแล้ว"):t("เลขที่ใบเสร็จนี้ ไม่ถูกต้อง")}</NotiHeaderText>
          <DateNotiText>
            {transStatus==1?t("กรุณาตรวจสอบอีกครั้ง หรือติดต่อ Line @LUCKYDOSE"):t("กรุณาตรวจสอบเลขที่ใบเสร็จ 9 หลักอีกครั้ง")}
            </DateNotiText>
            <div style={{ margin: "auto" }}>
            <DoseartButton
              width={parseInt(width) < 600 ? "82vw" : "26.5vw"}
              isDisable={false}
              isPrimary={true}
              text={t("FillInformationPage.ตกลง")}
              handleOnClick={()=>{
                updateTransStatus(0)
              }}
            />
          </div>
        </NotiCardWrapper>
      </NotiWrapper>
    );
  };

  return (
    <>
      <ContentWrapper vh={vh}>
        {transStatus!=0&&<NotiTransactionHandle />}
        {showNoti ? <Notification /> : <></>}
        <HeadTextStyled>{t("FillInformationPage.กรอกข้อมูลสำหรับรูปภาพ")}</HeadTextStyled>
        <TextFieldCard
          setDateError={(e) => setDateError(e)}
          setShowNoti={(e) => setShowNoti(e)}
          ref={TextFieldCardRef}
          type={window.location.href.includes('redeem')?'type_5':formType}
          select_artwork={
            contextAWDb && selected_index !== "" && contextAWDb[selected_index]
              ? contextAWDb[selected_index].id
              : null
          }
          type_selector={form_selector}
          nameLength={nameLength}
        />
      </ContentWrapper>

      <FooterComponent
        addOnClickNextFunction={() => {
          TagManager.dataLayer({
            dataLayer: {
              event: "fill_form",
              collection_id: collection_selection_info.collection_name
                ? collection_selection_info.collection_name
                : "",
              refID: session_reffer_url,
            },
          });
          if(window.location.href.includes('redeem')){
            var trans_id = TextFieldCardRef.current.getTransId()
            var regex = /^[0-9]+$/;
            if(trans_id.length==9&&regex.test(trans_id))
            checkTransactionId(trans_id).then((result)=>{
              if(result.status){
                updateTransStatus(1)
                TextFieldCardRef.current.setTransIdStatus({status:true})
              }
              else{
                onClickNextWhenNotShowNoti(true)
              }
            })
            else{
              updateTransStatus(2)
              TextFieldCardRef.current.setTransIdStatus({status:true})
            }
          }
          else if(formType==='type_6'){
            TextFieldCardRef.current.updateAstralProfile().then(()=>{
              onClickNextWhenNotShowNoti(true);
            })
          }
          else{
            onClickNextWhenNotShowNoti(true);
          }
        }}
        hide={hide}
        isDisableNextButton={dateError}
      />
    </>
  );
};

export default FillInformationPage;
