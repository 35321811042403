import { createContext, useEffect, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
//root component
import App from "./App";

export const LocaleContext = createContext({
  locale: "",
  // eslint-disable-next-line
  setLocale: (newLocale) => {},
});

const LangRouter = () => {
  const { i18n } = useTranslation(),
    { pathname, search, hash } = useLocation(),
    navigate = useNavigate(),
    availableLocales = ["th/", "zh/","en/", "th", "zh","en"],
    defaultLocale = "th",
    pathnameLocale = pathname.substring(1, 4).toLowerCase(),
    [locale, setLocale] = useState(defaultLocale);

  document.body.dir = i18n.dir(i18n.language);

  useEffect(() => {
    if (availableLocales.includes(pathnameLocale)) {
      updateLocale(pathnameLocale.substring(0, 2));
    } else if (
      pathname === "/" ||
      pathnameLocale.substring(0, 2) === defaultLocale
    ) {
      updateLocale(defaultLocale);
    } else if (pathname.includes("payment")) {
      // payment?chargeId=' + req.body.objectId + '&mode=card'
      let serachParams = new URLSearchParams(search);
      let mode = serachParams.get("mode");
      if (mode === "card") {
        // get locale data from local storage
        let locale = window.localStorage.getItem("locale");
        if (locale !== "th") {
          navigate(`/${locale}${pathname}${hash}${search}`);
          return;
        }
      }
    }
    // eslint-disable-next-line
  }, [pathname]);

  useEffect(() => {
    let lang = defaultLocale;
    if (availableLocales.includes(pathnameLocale)) {
      lang = pathnameLocale.substring(0, 2);
      setLanguageHandler(lang);
    } else if (pathname === "/") {
      setLanguageHandler(lang);
    } else {
      setLanguageHandler(lang);
    }
    // eslint-disable-next-line
  }, [locale]);

  const setLanguageHandler = (lang) => {
    //set language attribute on HTML element
    document.documentElement.setAttribute("lang", lang);

    if (lang === "th") {
      i18n.changeLanguage("th");
    } else if(lang ==="zh"){
      i18n.changeLanguage("zh");
    }
    else{
      i18n.changeLanguage("en");
    }
  };

  const updateLocale = (newLocale) => {
    const newPath =
      newLocale === defaultLocale
        ? pathname.substring(3)
        : `/${newLocale}` + pathname.substring(3);
    if (locale !== newLocale) {
      if (
        newPath === `/${newLocale}/` ||
        newPath === `/${newLocale}` ||
        pathname === "/"
      ) {
        navigate(newLocale + "/home");
      } else {
        navigate(`${newPath}${hash}${search}`);
      }
      setLocale(newLocale);
    } else if (
      newPath === `/${newLocale}/` ||
      newPath === `/${newLocale}` ||
      pathname === "/"
    ) {
      navigate(newLocale + "/home");
    } else if (newLocale === "th") {
      // remove /th from path
      navigate(`${newPath}${hash}${search}`);
    }
  };

  return (
    <LocaleContext.Provider value={{ locale, setLocale: updateLocale }}>
      <Routes>
        {locale === "th" ? (
          <Route path={`/*`} element={<App />} />
        ) : (
          <Route path={`/${locale}/*`} element={<App />} />
        )}
        {/* <Route path="*" element={<NotFoundPage />} /> */}
      </Routes>
    </LocaleContext.Provider>
  );
};

export default LangRouter;
