import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { CardWrapper, SubscriptionBannerWrapper } from "../HomePage/styles";
import fluidText from "../../utils/fluidText";
import { useRecoilState } from "recoil";
import { state as stateAtom } from "../../global/atom";
import FooterComponent from "../../components/FooterComponent";
import TextField from "@material-ui/core/TextField";
import { fluidRange } from "polished";
import { useNavigate } from "react-router-dom";
import DoseartButton from "../../components/DoseartButton";
import PhoneImg from "../../assets/subscription/union.svg";
import { checkPhoneNumber, handleFontFamily, sendOTP, validateOTP } from "../../utils/utils";
import {
  session_subscription_user,
  useSessionStorage,
} from "../../utils/sessionStorage";
import LoaderComponent from "../../components/LoaderComponent";
import useNavigationHandling from "../../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";

const HeadTextStyled = styled.div`
  margin: 2vh auto 2vh auto;
  ${fluidText(18, 20)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 300;
  color: black;
  text-align: center;
  white-space: pre-line;
  @media (min-width: 600px) {
    ${fluidText(24, 28)}
    margin: 3vh auto 3vh auto;
  }
`;

const TextStyled = styled.div`
  margin: 2vh auto 2vh auto;
  width: 88.4vw;
  ${fluidText(13, 18)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 300;
  color: #828282;
  text-align: left;
  white-space: pre-line;
  @media (min-width: 600px) {
    width: 23.95vw;
    min-width: 450px;
    ${fluidText(14, 16)}
    margin: 2vh auto 2vh auto;
  }
`;

const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    width: 80%;
    height: 5.33vh;
    border-radius: 8px;
    margin: 1vh auto 1vh auto;
    ${handleFontFamily()}
    font-style: normal;
    font-size: 0.9rem;
    font-weight: 400;
    text-align: center;
    @media (min-width: 600px) {
      width: 90%;
      min-width: 300px;
    }
    fieldset {
      border-color: #e1e1e1;
    }
    &.Mui-focused fieldset {
      border-color: #e1e1e1;
    }
    background-color: #ffffff;
  }
`;

const NotiStyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    width: 80%;
    height: 5.33vh;
    border-radius: 8px;
    margin: 1vh auto 1vh auto;
    ${handleFontFamily()}
    font-style: normal;
    font-size: 0.9rem;
    font-weight: 400;
    text-align: center;
    @media (min-width: 600px) {
      width: 80%;
      min-width: 300px;
    }
    input {
      text-align: center;
    }
    fieldset {
      border-color: #e5e5e5;
    }
    &.Mui-focused fieldset {
      border-color: #e5e5e5;
    }
    background-color: #e5e5e5;
  }
`;

const TextLabelWrapper = styled.div`
  width: 80%;
  ${fluidRange(
    {
      prop: "font-size",
      fromSize: "12px",
      toSize: "20px",
    },

    "300px",
    "600px"
  )}
  ${handleFontFamily()}
  font-weight: 400;
  color: #5c5c5c;
  text-align: left;
  margin: auto;
  @media (min-width: 600px) {
    ${fluidRange(
      {
        prop: "font-size",
        fromSize: "16px",
        toSize: "18px",
      },

      "600px",
      "1400px"
    )}

    min-width: 390px;
  }
`;

const ErrortextWrapper = styled.div`
  width: 80%;
  ${fluidRange(
    {
      prop: "font-size",
      fromSize: "10px",
      toSize: "18px",
    },

    "300px",
    "600px"
  )}
  ${handleFontFamily()}
  font-weight: 400;
  color: #5c5c5c;
  text-align: left;
  margin: auto;
  @media (min-width: 600px) {
    ${fluidRange(
      {
        prop: "font-size",
        fromSize: "14px",
        toSize: "16px",
      },

      "600px",
      "1400px"
    )}

    min-width: 390px;
  }
`;

const SubTextLabelWrapper = styled.div`
  width: 80%;
  ${fluidRange(
    {
      prop: "font-size",
      fromSize: "12px",
      toSize: "14px",
    },

    "300px",
    "600px"
  )}
  ${handleFontFamily()}
  font-weight: 400;
  color: #828282;
  text-align: left;
  margin: auto;
  @media (min-width: 600px) {
    ${fluidRange(
      {
        prop: "font-size",
        fromSize: "12px",
        toSize: "14px",
      },

      "600px",
      "1400px"
    )}
    width: 60%;
    min-width: 320px;
  }
`;

const CardContentWrapper = styled.div`
  width: 100vw;
  height: 100%;
  //align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: 10;
`;

const CardHeadTextStyled = styled.div`
  //width:71.84vw;
  margin: 0vh auto 1vh auto;
  ${fluidText(20, 25)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 600;
  color: black;
  text-align: ${(props) => (props.isPayment ? "left" : "center")};
  @media (min-width: 600px) {
    width: 24vw;
    //font-size: 1.8rem;
    min-width: 400px;
  }
`;

const CardTextStyled = styled.div`
  //width:71.84vw;
  margin: 1vh auto 2vh auto;
  ${fluidText(16, 22)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: ${(props) => (props.isPayment ? "left" : "center")};
  @media (min-width: 600px) {
    width: 24vw;
    font-size: 1.4rem;
    min-width: 400px;
  }
`;

const CardNotiWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  width: 90vw;
  height: min-content;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  bottom: 0;
  border-radius: 15px;
  //padding : 0 10px;
  flex-direction: column;
  display: flex;
  @media (min-width: 600px) {
    width: 24vw;
    min-width: 400px;
    //padding: 0 1vw;
  }
`;

const ButtonWrapper = styled.div`
  width: 90vw;
  height: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin: 1vh auto 3vw auto;
  @media (min-width: 600px) {
    width: 100%;
    margin: 1vh auto 1.5vw auto;
  }
`;
const NotificationImage = styled.img`
  width: 9.7vw;
  margin: 3vh auto;
  @media (min-width: 600px) {
    //width: 4.3vw;
    height: 6.5vh;
    margin: 3.4vh auto;
  }
`;

const NotificationCard = ({
  onClickCancel,
  onClickNext,
  updateOTPValue,
  sendOTP,
  OTPValid,
  OTPRef,
}) => {
  const width = window.innerWidth;
  //const [OTPValid,setOTPValid] = useState(true)
  const { t } = useTranslation();
  const [OTPButton, setOTPButton] = useState(false);
  const [showResendOTP, setShowResendOTP] = useState(false);
  var [time, setTime] = useState(30);
  var intervalFunction = useRef();
  var currentTime = 30;

  useEffect(() => {
    intervalFunction.current = setInterval(countDown, 1000);

    return () => {
      clearInterval(intervalFunction.current);
    };
  }, []);

  const countDown = () => {
    currentTime -= 1;
    if (currentTime <= 0) {
      clearInterval(intervalFunction.current);
      setShowResendOTP(true);
      setTime(0);
    } else {
      setTime(currentTime);
    }
  };

  return (
    <CardContentWrapper>
      <CardNotiWrapper>
        <NotificationImage src={PhoneImg} />
        <CardHeadTextStyled>{t("กรอกรหัส OTP")}</CardHeadTextStyled>
        <CardTextStyled>{t("ระบุรหัส OTP ที่ได้รับทาง SMS")}</CardTextStyled>
        <NotiStyledTextField
          id="OTP"
          label=""
          variant="outlined"
          error={!OTPValid}
          placeholder="OTP eg. XXXXXX"
          inputProps={{
            form: {
              autocomplete: "off",
            },
            maxLength: 6,
          }}
          onChange={(e) => {
            if (e.target.value.length < 6 && OTPButton) {
              setOTPButton(false);
            } else if (e.target.value.length == 6) {
              setOTPButton(true);
            }
            updateOTPValue(e.target.value);
          }}
        />
        <SubTextLabelWrapper>
          <p style={{ margin: "auto", width: "fit-content" }}>
            Ref:&nbsp;{OTPRef}
          </p>
          <li>{t("รหัสผ่านมีอายุการใช้งาน 5 นาที")}</li>
          {showResendOTP ? (
            <li>
              {t("ไม่ได้รับ OTP ใช่ไหม?")}&nbsp;
              <span
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => {
                  sendOTP();
                  setShowResendOTP(false);
                  currentTime = 30;
                  setTime(30);
                  intervalFunction.current = setInterval(countDown, 1000);
                }}
              >
                {t("ขอรหัส OTP ใหม่อีกครั้ง")}
              </span>
            </li>
          ) : (
            <li>
              {t("สามารถขอ OTP ใหม่อีกครั้งได้ใน")} 00:
              {time < 10 ? time.toString().padStart(2, "0") : time}&nbsp;
              {t("นาที")}
            </li>
          )}
          {OTPValid && <br />}
          {!OTPValid && (
            <p style={{ margin: "0", color: "red" }}>
              {t("* รหัส OTP ไม่ถูกต้อง กรุณากรอกใหม่อีกครั้ง")}
            </p>
          )}
        </SubTextLabelWrapper>
        <ButtonWrapper>
          <DoseartButton
            width={parseInt(width) < 600 ? "40vw" : "9.37vw"}
            isDisable={false}
            isPrimary={false}
            text={t("ยกเลิก")}
            isSetMargin={true}
            handleOnClick={onClickCancel}
          />
          <DoseartButton
            width={parseInt(width) < 600 ? "40vw" : "9.37vw"}
            isDisable={!OTPButton}
            isPrimary={true}
            text={t("ตกลง")}
            isSetMargin={true}
            handleOnClick={onClickNext}
          />
        </ButtonWrapper>
      </CardNotiWrapper>
    </CardContentWrapper>
  );
};

const LoginPage = () => {
  const [state, updateState] = useRecoilState(stateAtom);
  const [subscriptionUser, updateSubscriptionUser] = useSessionStorage(
    session_subscription_user.key,
    session_subscription_user.default
  );
  const [isPhoneNumber, updateIsPhoneNumber] = useState(false);
  const [phoneNumber, updatePhoneNumber] = useState("");
  const [showOTP, setShowOTP] = useState(false);
  const [OTPValue, updateOTPValue] = useState("");
  const { navigateHandler } = useNavigationHandling();
  const [OTPValid, setOTPValid] = useState(true);
  const [OTPToken, updateOTPToken] = useState("");
  const [OTPRef, updateOTPRef] = useState("");
  const [isLoading, updateLoading] = useState(false);
  const [isPhoneValid, updateIsPhoneValid] = useState(true);
  const { t } = useTranslation();
  useEffect(() => {
    updateState("SubscriptionLogin");
  }, []);

  useEffect(() => {
    if (
      subscriptionUser &&
      subscriptionUser.phone_number &&
      subscriptionUser.phone_number !== "" &&
      OTPValue !== ""
    ) {
      navigateHandler("/ais/package");
    }
  }, [subscriptionUser]);
  return (
    <>
      {isLoading && <LoaderComponent />}
      <div
        style={{ marginTop: "3.2rem", height: "auto", marginBottom: "14vh" }}
      >
        <SubscriptionBannerWrapper
          jpg_small={
            "https://storage.googleapis.com/doseart_banner/AIS_Subscription/ais_subscription_small_V.3.jpg"
          }
          jpg_med={
            "https://storage.googleapis.com/doseart_banner/AIS_Subscription/ais_subscription_medium_V.3.jpg"
          }
          jpg_large={
            "https://storage.googleapis.com/doseart_banner/AIS_Subscription/ais_subscription_large_V.3.jpg"
          }
        ></SubscriptionBannerWrapper>
        <HeadTextStyled>
          {t("กรอกหมายเลขโทรศัพท์ AIS")} <br />
          {t("เพื่อดูแพ็กเกจของคุณ")}
        </HeadTextStyled>
        <CardWrapper>
          <TextLabelWrapper>{t("หมายเลขโทรศัพท์ AIS")}</TextLabelWrapper>
          <StyledTextField
            id="name"
            label=""
            variant="outlined"
            inputProps={{
              form: {
                autocomplete: "off",
              },
              maxLength: 10,
            }}
            onChange={(e) => {
              if (
                isPhoneNumber &&
                (e.target.value == "" || e.target.value.length < 10)
              ) {
                updateIsPhoneNumber(false);
              } else if (e.target.value.length == 10) updateIsPhoneNumber(true);
              //setCheckButton(true)
              updatePhoneNumber(e.target.value);
            }}
          />
          {!isPhoneValid && (
            <ErrortextWrapper>
              <p style={{ color: "red", margin: "0" }}>
                {t("ไม่สามารถส่งรหัสได้ กรุณาระบุหมายเลข AIS ใหม่อีกครั้ง")}
              </p>
            </ErrortextWrapper>
          )}
        </CardWrapper>
        {showOTP && (
          <NotificationCard
            onClickCancel={() => {
              setOTPValid(true);
              updateLoading(false);
              setShowOTP(false);
            }}
            onClickNext={() => {
              validateOTP(phoneNumber, OTPToken, OTPValue).then((result) => {
                if (result.status && result.status == "success") {
                  setOTPValid(true);
                  var temp = subscriptionUser;
                  temp.phone_number = phoneNumber;
                  updateSubscriptionUser((current) => ({
                    ...current,
                    ...temp,
                  }));
                } else {
                  setOTPValid(false);
                }
              });
            }}
            sendOTP={() => {
              sendOTP(phoneNumber).then((result) => {
                if (result.status == "success") {
                  updateOTPToken(result.token);
                  updateOTPRef(result.refno);
                }
              });
            }}
            OTPValid={OTPValid}
            OTPRef={OTPRef}
            updateOTPValue={(value) => updateOTPValue(value)}
          />
        )}
        <TextStyled>
          <span style={{ fontWeight: "400" }}>
            {t("เงื่อนไขบริการแพ็กเกจสำหรับลูกค้าเอไอเอส")}
          </span>
          <br />
          {t(
            "1. ลูกค้าเอไอเอสสมัครแพ็กเกจวอลเปเปอร์ในครั้งแรก รับสิทธิ์ใช้งานฟรี 1 เดือน"
          )}
          <br />
          {t(
            "2. หากลูกค้าเอไอเอสยกเลิกบริการ แล้วสมัครใช้แพ็กเกจอีกครั้ง จะไม่ได้รับสิทธิ์ใช้งานฟรี 1 เดือน ลูกค้าจะถูกคิดค่าบริการตามแพ็กเกจที่คุณเลือก"
          )}
          <br />
          {t("3. สอบถามการใช้งาน")}&nbsp;
          <a
            href="https://line.me/R/ti/p/@609hffrs?from=page"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#828282" }}
          >
            {t("Line @luckydose")}
          </a>
          &nbsp;{t("หรือ")}&nbsp;
          <a
            href="https://www.facebook.com/Luckydose.th"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: "#828282" }}
          >
            {t("FB Luckydose")}
          </a>
          <br />
          {t("4. หากต้องการยกเลิกบริการ กด *137 โทรออก")}
        </TextStyled>
        <FooterComponent
          isDisableNextButton={!isPhoneNumber}
          addOnClickNextFunction={() => {
            updateLoading(true);
            checkPhoneNumber(phoneNumber, "ais").then((result) => {
              if (result.status == "active") {
                updateSubscriptionUser({
                  phone_number: "",
                  active_package_ids: result.active_package_ids,
                });
                sendOTP(phoneNumber).then((result) => {
                  if (result.status == "success") {
                    updateOTPToken(result.token);
                    updateOTPRef(result.refno);
                    setShowOTP(true);
                    updateLoading(false);
                  }
                });
              } else {
                updateLoading(false);
                updateIsPhoneValid(false);
              }
            });
          }}
        />
      </div>
    </>
  );
};

export default LoginPage;
