import React, { useEffect, useState } from "react";
import {
  SectionWrapper,
  NewAlert,
  TitleWrapper,
  DescriptionWrapper,
  LinkWrapper,
  TitleContainer,
  CouponSectionWrapper,
  ContactLayout,
  ContactTextStyledWrapper,
  ContactLogoWrapper,
  ContactLogoAndTextWrapper,
  ContactLogo,
  ContactTextStyled,
  BannerWrapper,
  BannerImageWrapper,
  BannerImage,
} from "./styles";
import { useNavigate } from "react-router-dom";

import { useRecoilState, useResetRecoilState } from "recoil";
import {
  collections_atom,
  fetch_collection as fetch_collection_atom,
  state as stateAtom,
  collection_tags as collection_tags_atom,
} from "../../global/atom";
import axios from "axios";
import NavLogo, { LUCKYDOSE_BLACK } from "../../components/NavLogo";
import {
  checkLangAvailableDatabase,
  checkRefferURL,
  COLLECTION_API_URL,
  DEPLOY_VERSION,
  fetchAndHandleData,
  handleNewLine,
  sendEvent,
} from "../../utils/utils";
import TagManager from "react-gtm-module";
import {
  reffer_url,
  select_package,
  sessionVoucher,
  useSessionStorage,
  package_name_detail,
  filterCheck,
  session_select_edit_card,
  session_nProducts,
  session_bundle_tag_price,
  session_prev_bundle_tag_price,
  session_multple_attribute_selection,
  session_multiple_index_selection,
  session_multiple_url_selection,
  session_edit_card_state,
  session_select_index,
  session_order_type_id,
  session_filter_aw,
  session_package_id_selection_atom,
  session_artwork_show_birthdate,
  session_collection_selection_info,
  sessionRefID,
  session_prev_form_list,
  sessionReferenceOrder,
  sessionClientID,
} from "../../utils/sessionStorage";
import { Link } from "react-router-dom";

import emailLogo from "../../assets/home/email.svg";
import lineLogo from "../../assets/home/line.svg";
import telLogo from "../../assets/home/tel.svg";
import facebookLogo from "../../assets/home/facebookLogo.svg";
import ReactPixel from "react-facebook-pixel";
import gtag, { install } from "ga-gtag";
import NotificationCard from "../../components/NotificationCard";
import {
  checkHasPurchasedHistory,
  deleteOrderInIndexedDB,
  getMaxOrderIndex,
} from "../../utils/IndexedDBStore";
import { useContext } from "react";
import { UserContext } from "../../utils/Context/userContext";
import useNavigationHandling from "../../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";
import ContactLogoComponent from "../../components/ContactLogoComponent";

const emptyFormFill = {
  fill: false,
  name: "",
  surname: "",
  zodiacId: "",
  birthDate: "",
  birthMonth: "",
  birthYear: "",
  showBirthDate: false,
  selectImageId: "",
  selectImage: "",
  id: "",
};

/**
 * read data
 * collection_selections
 * sort by package_sections_id
 * @returns
 */
const HomePage = ({ partner_link }) => {
  const { navigateHandler } = useNavigationHandling();
  const [group_collections, update_group_collections] = useState();
  const [collections, update_collections] = useRecoilState(collections_atom);
  const [fetch_collection, update_fetch_collection] = useRecoilState(
    fetch_collection_atom
  );
  const [collection_tags, update_collection_tags] =
    useRecoilState(collection_tags_atom);
  const [all_gallery_collection, update_all_gallery] = useState();
  const [state, updateState] = useRecoilState(stateAtom);
  const [, updateEditCard] = useSessionStorage(
    session_select_edit_card.key,
    session_select_edit_card.default
  );
  const [, updateNProducts] = useSessionStorage(
    session_nProducts.key,
    session_nProducts.default
  );
  const [collection_selection_info, update_collection_selection_info] =
    useSessionStorage(
      session_collection_selection_info.key,
      session_collection_selection_info.default
    );
  const [, updateBundleTagPrice] = useSessionStorage(
    session_bundle_tag_price.key,
    session_bundle_tag_price.default
  );
  const [, update_buffer_bundleTagPrice] = useSessionStorage(
    session_prev_bundle_tag_price.key,
    session_prev_bundle_tag_price.default
  );
  const [, update_select_package] = useSessionStorage(
    select_package.key,
    select_package.default
  );
  const [changePage_link, set_changePage_link] = useState("");
  const [refferURL, update_refferURL] = useState();
  const [session_reffer_url, update_session_reffer_url] = useSessionStorage(
    reffer_url.key,
    reffer_url.default
  );
  const [, updateMultipleAttribute] = useSessionStorage(
    session_multple_attribute_selection.key,
    session_multple_attribute_selection.default
  );
  const [, updateMutipleIndexSelection] = useSessionStorage(
    session_multiple_index_selection.key,
    session_multiple_index_selection.default
  );
  const [, updateURLSelection] = useSessionStorage(
    session_multiple_url_selection.key,
    session_multiple_url_selection.default
  );
  const [, updatePrevList] = useSessionStorage(
    session_prev_form_list.key,
    session_prev_form_list.default
  );
  const [, update_edit_card_state] = useSessionStorage(
    session_edit_card_state.key,
    session_edit_card_state.default
  );
  const [, updateSelectedIndex] = useSessionStorage(
    session_select_index.key,
    session_select_index.default
  );
  const [, update_oreder_type_id] = useSessionStorage(
    session_order_type_id.key,
    session_order_type_id.default
  );
  const [, updateFilterAW] = useSessionStorage(
    session_filter_aw.key,
    session_filter_aw.default
  );
  const [, update_package_id_selection] = useSessionStorage(
    session_package_id_selection_atom.key,
    session_package_id_selection_atom.default
  );
  const [, update_artwork_show_birthDate] = useSessionStorage(
    session_artwork_show_birthdate.key,
    session_artwork_show_birthdate.default
  );
  const [, updateFilter] = useSessionStorage(
    filterCheck.key,
    filterCheck.default
  );
  const [, updateRefID] = useSessionStorage(
    sessionRefID.key,
    sessionRefID.default
  );
  const [, updateReferenceOrder] = useSessionStorage(
    sessionReferenceOrder.key,
    sessionReferenceOrder.default
  );
  const [, update_package_name_detail] = useSessionStorage(
    package_name_detail.key,
    package_name_detail.default
  );
  const [, update_session_client_id] = useSessionStorage(
    sessionClientID.key,
    sessionClientID.default
  );

  const { t,i18n } = useTranslation();
  const [overwrite_collection, update_overwrite_collection] = useState();
  const [show_coupon, update_show_coupon] = useState(true);

  const [showPurchasedButton, setShowPurchasedButton] = useState(false);
  const [latest_order_uid, update_latest_order] = useState();
  const [show_latest_order_noti, update_latest_order_noti] = useState(false);
  const [latest_payment, update_latest_payment] = useState();
  const width = window.innerWidth;
  const { voucher, couponCollection, updateFormList } = useContext(UserContext);

  /*const {ref,inView} = useInView({
    triggerOnce:true,
    skip:!("IntersectionObserver" in window)
  })*/

  const handleChangePage = (collection_obj) => {
    update_collection_selection_info(collection_obj);
    set_changePage_link(collection_obj.link);
  };
  useEffect(() => {
    if (changePage_link && collection_selection_info.collection_name) {
      navigateHandler(changePage_link);
    }
  }, [collection_selection_info]);

  useEffect(() => {
    // get data
    //check URL
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    if (urlParams.has("refID")) {
      var ref_url = urlParams.get("refID");
      //if (session_reffer_url == "") {
      checkRefferURL(ref_url).then((res) => {
        if (res.result) {
          if (res.overwrite) {
            update_overwrite_collection(res.overwrite);
            fetchCollection(res.overwrite);
          } else {
            fetchCollection();
          }
          if (session_reffer_url == "") {
            if (ref_url == "BbJ4VowBI3pLuupSP45n") {
              TagManager.dataLayer({
                dataLayer: {
                  event: "true_pageview",
                },
              });
            }
            TagManager.dataLayer({
              dataLayer: {
                event: "pageview",
                refID:ref_url,
              },
            });
            sendEvent(ref_url, "page_view");
          }
          update_session_reffer_url(ref_url);
        } else {
          fetchCollection();
        }
      });
      //}
    } else if (partner_link) {
      //var ref_url = urlParams.get("refID")
      //if (session_reffer_url == "") {
      checkRefferURL(partner_link).then((res) => {
        if (res.result) {
          //check url
          if (
            res.role &&
            res.role == "partner" &&
            window.location.href.includes("partner")
          ) {
            if (res.overwrite) {
              update_overwrite_collection(res.overwrite);
              fetchCollection(res.overwrite);
            } else {
              fetchCollection();
            }
            update_session_reffer_url(partner_link);
          } else if (
            res.role &&
            res.role == "agent" &&
            window.location.href.includes("agent")
          ) {
            if (res.overwrite) {
              update_overwrite_collection(res.overwrite);
              fetchCollection(res.overwrite);
            } else {
              fetchCollection();
            }
            update_session_reffer_url(partner_link);
          } else {
            navigateHandler("/home");
          }
          TagManager.dataLayer({
            dataLayer: {
              event: "pageview",
              refID:partner_link,
            },
          });
        } else {
          navigateHandler("/home");
        }
      });
      //}
    } else {
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
        },
      });
      fetchCollection();
    }
    // set default
    update_select_package("");
    var MEASUREMENT_ID = "G-5TM9EK429P";
    if (DEPLOY_VERSION == "PROD") {
      MEASUREMENT_ID = "G-2PCQ99MS9K";
    }

    install(MEASUREMENT_ID);

    gtag("get", MEASUREMENT_ID, "client_id", (field) => {
      update_session_client_id(field);
    });

    checkHasPurchasedHistory()
      .then((result) => {
        setShowPurchasedButton(result);
        checkLatestOrder();
      })
      .catch(() => {
        setShowPurchasedButton(false);
        checkLatestOrder();
      });

    // clear state
    updateState("home");
    updateNProducts("");
    updateBundleTagPrice("");
    update_buffer_bundleTagPrice("");
    updateMultipleAttribute([]);
    update_edit_card_state("");
    updateSelectedIndex("");
    update_collection_selection_info("");
    update_oreder_type_id("");
    update_package_id_selection("");
    updateMutipleIndexSelection([]);
    updateFormList([emptyFormFill]);
    updatePrevList([emptyFormFill]);
    updateURLSelection([]);
    //updateVoucher("");
    update_artwork_show_birthDate("");
    updateReferenceOrder("");
    update_package_name_detail({
      package_name: "",
      icon: "",
    });
    updateFilter("");
    updateFilterAW(new Set());
    updateRefID("");
  }, []);

  //update page view here
  useEffect(() => {
    if (refferURL && session_reffer_url == "") {
      checkRefferURL(refferURL).then((res) => {
        if (res.result) {
          update_session_reffer_url(refferURL);
          if (res.overwrite) {
            update_overwrite_collection(res.overwrite);
          }
          sendEvent(refferURL, "page_view");
        }
      });
    }
  }, [refferURL]);

  useEffect(() => {
    if (
      overwrite_collection &&
      overwrite_collection.Coupon &&
      overwrite_collection.Coupon.collection_order == "0"
    ) {
      update_show_coupon(false);
    }
  }, [overwrite_collection]);

  useEffect(() => {
    if (
      fetch_collection !== 0 &&
      couponCollection === null &&
      collection_tags
    ) {
      update_collections({
        30: {
          collection_name: "",
          collection_order: "30",
          collection_type_id: "ct_d",
          description: t("HomePage.เลือกวอลเปเปอร์จากคลังภาพของเรา"),
          detail_image_count: "",
          font_theme: "",
          gallery_prefix: "",
          gallery_suffix: "",
          group_collection_tag: "-",
          id: "All_picture",
          jpg_large:
            "https://storage.googleapis.com/doseart_banner/Power_user/power_user_large.jpg",
          jpg_med:
            "https://storage.googleapis.com/doseart_banner/Power_user/power_user_medium.jpg",
          jpg_small:
            "https://storage.googleapis.com/doseart_banner/Power_user/power_user_small.jpg",
          layout_type_id: "lt_01",
          red_text: "",
          title: t("HomePage.ดูภาพทั้งหมด"),
        },
      });
    } else if (
      couponCollection !== null &&
      couponCollection !== undefined &&
      couponCollection.length > 0 &&
      fetch_collection.length !== 0 &&
      collection_tags
    ) {
      var filter_collections = fetch_collection.filter((t_collection) =>
        couponCollection.includes(t_collection.id)
      );
      let sortable = filter_collections.map((item, index) => [
        checkLangAvailableDatabase(item.collection_order),
        index,
      ]);
      let sorted = {};
      for (let i = 0; i < sortable.length; i++) {
        sorted[sortable[i][0]] = filter_collections[sortable[i][1]];
      }
      update_collections(sorted);
    }
  }, [couponCollection, fetch_collection, collection_tags]);

  const fetchCollection = (overwrite_data = null) => {
    if (!group_collections) {
      var temp_collection_tags_data;
      fetchAndHandleData(COLLECTION_API_URL + "/collection_tags/").then(
        (collection_tags) => {
          if (collection_tags.data) {
            temp_collection_tags_data = collection_tags.data;
            update_collection_tags(temp_collection_tags_data);
            if (Object.keys(fetch_collection).length === 0) {
              fetchAndHandleData(COLLECTION_API_URL + "/collections/").then(
                (collections) => {
                  update_fetch_collection(collections.data);
                  if (collections) {
                    if (overwrite_data == null) {
                      var temp_collections = collections.data.concat(
                        temp_collection_tags_data
                      );
                      let sortable = temp_collections.map((item, index) => [
                        checkLangAvailableDatabase(item.collection_order),
                        index,
                      ]);
                      sortable.sort(function (a, b) {
                        return a[0] - b[0];
                      });
                      let sorted = {};
                      for (let i = 0; i < sortable.length; i++) {
                        if (
                          parseInt(
                            checkLangAvailableDatabase(temp_collections[sortable[i][1]].collection_order)
                          ) > 0 &&
                          (temp_collections[sortable[i][1]]
                            .group_collection_tag == "-" ||
                            !temp_collections[sortable[i][1]]
                              .group_collection_tag)
                        ) {
                          sorted[sortable[i][0]] =
                            temp_collections[sortable[i][1]];
                        }
                      }
                      update_collections(sorted);
                    } else {
                      var temp_collections = collections.data.concat(
                        temp_collection_tags_data
                      );
                      var overwrite_collection_names = [];
                      Object.entries(overwrite_data).map(([key, item]) => {
                        overwrite_collection_names.push(key);
                      });
                      let sortable = temp_collections.map((item, index) => {
                        if (item.collection_name in overwrite_data) {
                          return [
                            checkLangAvailableDatabase(overwrite_data[item.collection_name]
                              .collection_order),
                            index,
                          ];
                        } else return [checkLangAvailableDatabase(item.collection_order), index, item.id];
                      });
                      sortable.sort(function (a, b) {
                        return a[0] - b[0];
                      });

                      let sorted = {};
                      for (let i = 0; i < sortable.length; i++) {
                        if (
                          parseInt(sortable[i][0]) > 0 &&
                          (temp_collections[sortable[i][1]]
                            .group_collection_tag == "-" ||
                            !temp_collections[sortable[i][1]]
                              .group_collection_tag ||
                            (temp_collections[sortable[i][1]]
                              .group_collection_tag &&
                              overwrite_collection_names.includes(
                                temp_collections[sortable[i][1]].id
                              )))
                        ) {
                          sorted[sortable[i][0]] =
                            temp_collections[sortable[i][1]];
                        }
                      }
                      update_collections(sorted);
                    }
                  } else {
                    console.error("error: cannot getting data from server");
                  }
                }
              );
            }
          }
        }
      );
    }
  };

  const checkLatestOrder = async () => {
    getMaxOrderIndex()
      .then((latest_order) => {
        if (latest_order.order_index != 0) {
          if (!latest_order.payment_status) {
            update_latest_order_noti(true);
            update_latest_payment(latest_order.payment_method);
            update_latest_order(latest_order.uid);
          }
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <>
      {show_latest_order_noti && (
        <NotificationCard
          onClickBack={() => {
            deleteOrderInIndexedDB(latest_order_uid);
            update_latest_order_noti(false);
          }}
          onClickNext={() => {
            if (latest_payment && latest_payment != "ais")
              navigateHandler("/payment?order=" + latest_order_uid);
            else navigateHandler("/aisalc/payment?order=" + latest_order_uid);
          }}
          state={state}
        />
      )} 
      {collections &&
        Object.keys(collections).map((item, index) =>
          collections[item].collection_name != "Coupon" ? (
            <BannerWrapper first_section={index === 0}>
              <BannerImageWrapper>
              <BannerImage src={
                width<=735?checkLangAvailableDatabase(collections[item].jpg_small):
                width<=1068?checkLangAvailableDatabase(collections[item].jpg_med):
                checkLangAvailableDatabase(collections[item].jpg_large)} 
              loading="lazy"/>
              </BannerImageWrapper>              
              <TitleContainer>
                {collections[item].red_text ? (
                  <NewAlert>{t(collections[item].red_text)}</NewAlert>
                ) : (
                  <></>
                )}
                <TitleWrapper font_theme={collections[item].font_theme}>
                  {t(handleNewLine(checkLangAvailableDatabase(collections[item].title)).trim())}
                </TitleWrapper>
                <DescriptionWrapper font_theme={collections[item].font_theme}>
                  {t(handleNewLine(checkLangAvailableDatabase(collections[item].description)).trim())}
                </DescriptionWrapper>
                <LinkWrapper>
                  <ul>
                    <li
                      className={"primary"}
                      onClick={
                        collections[item].collection_type_id === "ct_d"
                          ? () => {
                              updateEditCard(0);
                              updateNProducts(1);
                              updateFormList([emptyFormFill]);
                              setTimeout(() => navigateHandler("/gallery"), 200);
                            }
                          : collections[item].collection_type_id === "group"
                          ? () => {
                              navigateHandler(
                                "/collection_group/" + collections[item].id
                              );
                            }
                          : () =>
                              handleChangePage({
                                link:
                                  "/intro/" + collections[item].collection_name,
                                ...collections[item],
                              })
                      }
                    >
                      <p>{t("HomePage.สั่งซื้อ")}</p>
                    </li>
                  </ul>
                </LinkWrapper>
              </TitleContainer>
              </BannerWrapper>
            /*</SectionWrapper>*/   
          ) : (
            <></>
          )
        )}     
      {/*AIS Subscription*/}
      {collections && !voucher && i18n.language=='th'&&(
        <SectionWrapper
          jpg_small={
            "https://storage.googleapis.com/doseart_banner/aisprivilege%20/%20aisprivilege_banner_small.jpg"
          }
          jpg_med={
            "https://storage.googleapis.com/doseart_banner/aisprivilege%20/%20aisprivilege_banner_medium.jpg"
          }
          jpg_large={
            "https://storage.googleapis.com/doseart_banner/aisprivilege%20/%20aisprivilege_banner_large.jpg"
          }
        >
          <TitleContainer>
            <TitleWrapper font_theme="light">
              {t("สิทธิพิเศษเฉพาะสมาชิกเอไอเอส")}
            </TitleWrapper>
            <DescriptionWrapper font_theme="light">
              {t("ให้คุณเป็นคนดวงดี แบบไร้ขีดจำกัดกับเครือข่าย AIS")}
            </DescriptionWrapper>
            {/* <div className="price-wrapper grey">From $999.</div> */}
            <LinkWrapper>
              <ul>
                <li
                  onClick={() => {
                    window.scrollTo(0, 0);
                    navigateHandler("/ais_collection");
                  }}
                  className={"ais"}
                >
                  <p>{t("คลิก")}</p>
                </li>
              </ul>
            </LinkWrapper>
          </TitleContainer>
        </SectionWrapper>
      )}
      {/* Review */}
      {collections && !voucher && i18n.language=='th'&&(
        <SectionWrapper
          jpg_small={
            "https://storage.googleapis.com/doseart_banner/Review/review_small.jpg"
          }
          jpg_med={
            "https://storage.googleapis.com/doseart_banner/Review/review_medium.jpg"
          }
          jpg_large={
            "https://storage.googleapis.com/doseart_banner/Review/review_large.jpg"
          }
        >
          <TitleContainer>
            <TitleWrapper font_theme="light">
              {t("รีวิว LUCKYDOSE")}
            </TitleWrapper>
            <DescriptionWrapper font_theme="light">
              {t("ประสบการณ์จากผู้ใช้จริง เสริมโชคจริง")}
            </DescriptionWrapper>
            <LinkWrapper>
              <a
                href="https://www.facebook.com/Luckydose.th"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "None" }}
              >
                <ul>
                  <li
                    onClick={() => {
                      //window.location = "https://www.facebook.com/Luckydose.th";
                    }}
                    className={"primary"}
                  >
                    <p>{t("HomePage.ดูรีวิว")}</p>
                  </li>
                </ul>
              </a>
            </LinkWrapper>
          </TitleContainer>
        </SectionWrapper>
      )}
      {/* Blogs */}
      {collections && !voucher && i18n.language=='th'&&(
        <CouponSectionWrapper
          jpg_small={
            "https://storage.googleapis.com/doseart_banner/Blog/blog_small.jpg"
          }
          jpg_med={
            "https://storage.googleapis.com/doseart_banner/Blog/blog_medium.jpg"
          }
          jpg_large={
            "https://storage.googleapis.com/doseart_banner/Blog/blog_large.jpg"
          }
        >
          <TitleContainer>
            <TitleWrapper font_theme="light">
              {t("สาส์นจากองค์เทพ")}
            </TitleWrapper>
            <DescriptionWrapper font_theme="light">
              {t("รู้ดวงชะตาของคุณก่อนใคร")}
            </DescriptionWrapper>
            <LinkWrapper>
              <ul>
                <li onClick={() => navigateHandler("/blogs")} className={"primary"}>
                  <p>{t("อ่านต่อ")}</p>
                </li>
              </ul>
            </LinkWrapper>
          </TitleContainer>
        </CouponSectionWrapper>
      )}
      {/* Partner */}
      {collections && !voucher &&i18n.language=='th'&& (
        <SectionWrapper
          jpg_small={
            "https://storage.googleapis.com/doseart_banner/Agent/Agent_small.jpg"
          }
          jpg_med={
            "https://storage.googleapis.com/doseart_banner/Agent/Agent_medium.jpg"
          }
          jpg_large={
            "https://storage.googleapis.com/doseart_banner/Agent/Agent_large.jpg"
          }
        >
          <TitleContainer>
            <TitleWrapper>{t("สมัครตัวแทนจำหน่าย")}</TitleWrapper>
            <DescriptionWrapper>
              {t("สร้างรายได้ ได้ทุกเวลา ด้วยมนตราผ่านเทคโนโลยี AI")}
            </DescriptionWrapper>
            <LinkWrapper>
              <a
                href="https://lin.ee/8FasTsL"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "None" }}
              >
                <ul>
                  <li
                    onClick={() => {
                      //window.location = "https://www.facebook.com/Luckydose.th";
                    }}
                    className={"primary"}
                  >
                    <p>{t("HomePage.สมัครเลย")}</p>
                  </li>
                </ul>
              </a>
            </LinkWrapper>
          </TitleContainer>
        </SectionWrapper>
      )}
      {/* Coupon */}
      {collections && show_coupon && !voucher && (
        <CouponSectionWrapper
          jpg_small={
            "https://storage.googleapis.com/doseart_banner/Coupon/coupon_small.jpg"
          }
          jpg_med={
            "https://storage.googleapis.com/doseart_banner/Coupon/coupon_medium.jpg"
          }
          jpg_large={
            "https://storage.googleapis.com/doseart_banner/Coupon/coupon_large.jpg"
          }
        >
          <TitleContainer>
            <TitleWrapper>{t("HomePage.คูปอง LUCKYDOSE")}</TitleWrapper>
            <DescriptionWrapper>
              {t("HomePage.ใช้รหัสจากคูปองแลกซื้อวอลเปเปอร์")}
            </DescriptionWrapper>
            {/* <div className="price-wrapper grey">From $999.</div> */}
            <LinkWrapper>
              <ul>
                <li onClick={() => navigateHandler("/coupon")} className={"primary"}>
                  <p>{t("HomePage.กรอกรหัสคูปอง")}</p>
                </li>
              </ul>
            </LinkWrapper>
          </TitleContainer>
        </CouponSectionWrapper>
      )}
      {collections && (
        <ContactLogoComponent/>
        
      )}
    </>
  );
};

export default HomePage;
