import React, {
  useEffect,
  useRef,
  useState,
  useImperativeHandle,
  forwardRef,
} from "react";
import { Carousel } from "@trendyol-js/react-carousel";
import styled from "styled-components";
import { motion } from "framer-motion";
import fluidText from "../utils/fluidText";
import CheckIcon from "../assets/gallery/checkIcon.svg";
import { useRecoilValue } from "recoil";

import {
  previewIndex as previewIndexAtom,
  selectedIndex as selectedIndexAtom,
} from "../global/atom";
import { handleFontFamily } from "../utils/utils";

const PreviewImage = styled.img`
  width: 24vw;
  height: 24 * 2778/1440vw;
  //aspect-ratio: attr(width)/attr(height);
  //margin: 0.75vw;
  //margin-right:4vw;
  opacity: ${(props) => (props.isSelected ? "30%" : "100%")};
  pointer-events: none;
  border-radius: 15px;
  @media (min-width: 600px) {
    width: 10vw;
    //width:  5vw;
    min-width: 118.42px;
    height: 10 * 2778/1440vw;
    min-height: 118.42 * 2778/1440px;
    //margin: 0.25vw;
  }
`;

const SubHeadTextStyled = styled.div`
  width: 80vw;
  margin: 1vh auto 1vh auto;
  ${fluidText(16, 18)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: left;
  @media (min-width: 600px) {
    //width: 40vw;
    min-width: 500px;
  }
`;

const RecommendGalleryConstrainWrapper = styled.div`
  width: 80vw;
  margin: auto;
  display: flex;
  @media (min-width: 600px) {
    width: 34vw;
    min-width: 400px;
  }
`;
const CheckCircleStyled = styled.img`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 6.15vw;
  left: 50%;
  opacity: 100%;
  z-index: 2;
  @media (min-width: 600px) {
    width: 2.5vw;
  }
`;

const PreviewImageWrapper = styled.div`
  position: relative;
  //height: 100%;
  display: inline-flex;
  margin-right: 4vw;
  cursor: pointer;
  @media (min-width: 600px) {
    margin-right: 2vw;
  }
`;

const variants = {
  open: { opacity: 1 },
  closed: { opacity: 0 },
};

const DefaultGalleryComponent = (
  { data, catalogDatabase, tagToawSet, onLongPressItem, onClickItem },
  ref
) => {
  const [headTag, updateHeadtag] = useState(); //{recomment_1:[1,2,3],recommend_2:[4,5,6]}
  const [selectedIndex, updateSelectedIndex] = useState();
  const constraintsRef = useRef(null);
  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };
  useEffect(() => {
    if (data && catalogDatabase && tagToawSet) {
      var headTag = {};
      Object.entries(tagToawSet).map(([key, value]) => {
        if (key.includes("recommend")) {
          var array = [];
          value.forEach((e) => {
            data.forEach((d, index) => {
              if (e == d.id && d.flag) {
                array.push(index);
              }
            });
          });
          headTag[key] = array;
        }
      });
      updateHeadtag(headTag);
    }
  }, [data, catalogDatabase, tagToawSet]);

  useImperativeHandle(ref, () => {
    return {
      updateSelectedIndex: updateSelectedIndex,
    };
  });

  const carouselSlide = (items) => {
    var item = items.map((e, index) => {
      return (
        <PreviewItem
          path={data[e].thumbnailPath}
          index={e}
          onClick={onClickItem}
          //isItemSelected={selectedIndex==e}
        />
      );
    });

    return (
      <motion.div
        drag="x"
        dragConstraints={constraintsRef}
        initial={{ translateX: 0 }}
        style={{ flexWrap: "nowrap", display: "flex", flexDirection: "row" }}
      >
        {item}
      </motion.div>
    );

    return (
      <Carousel show={3.2} swiping={true} infinite={false}>
        {item}
      </Carousel>
    );
  };

  const RecommendGallery = () => {
    if (catalogDatabase && headTag) {
      var RecommendCatalog = catalogDatabase[0].category.map((catalog) => {
        return Object.entries(catalog).map(([key, value]) => {
          if (headTag[key] && headTag[key].length != 0) {
            return (
              <>
                <SubHeadTextStyled>
                  {value}
                </SubHeadTextStyled>
                <RecommendGalleryConstrainWrapper ref={constraintsRef}>
                  {carouselSlide(headTag[key])}
                </RecommendGalleryConstrainWrapper>
              </>
            );
          }
        });
      });

      return <>{RecommendCatalog}</>;
    } else return <></>;
  };

  return (
    <>
      <RecommendGallery />
    </>
  );
};

const PreviewItem = ({ path, onClick, index }) => {
  const previewIndex = useRecoilValue(previewIndexAtom);
  const selectIndex = useRecoilValue(selectedIndexAtom);
  const [isItemSelected, updateItemSelected] = useState(false);
  /*useEffect(()=>{
            if(selectIndex!=null){
                updateItemSelected(selectIndex==index)
            }
        },[selectIndex])*/
  return (
    <motion.div onTap={() => onClick(index)}>
      <PreviewImageWrapper>
        {isItemSelected ? <CheckCircleStyled src={CheckIcon} /> : <></>}
        <PreviewImage src={path} isSelected={isItemSelected} />
      </PreviewImageWrapper>
    </motion.div>
  );
};

export default React.memo(forwardRef(DefaultGalleryComponent));
