import React from "react";
import styled from "styled-components";
import { Default } from "react-spinners-css";
import { useTranslation } from "react-i18next";
import { handleFontFamily } from "../utils/utils";

const LoaderTextStyled = styled.div`
  //width:71.84vw;
  margin: 1vh auto;
  font-size: 1.2em;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  @media (min-width: 600px) {
    margin: 1.5vh auto 4vh auto;
    font-size: 1.5rem;
  }
`;

const ContentWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgb(255, 255, 255);
  display: flex;
  flex-direction: column;
  z-index: 11;
`;

const LoaderContentWrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  display: flex;
  text-align: center;
`;

const LoaderTextStyled2 = styled.div`
  //margin: 0 auto;
  font-size: 0.8em;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  @media (min-width: 600px) {
    font-size: 1.2rem;
  }
`;

const LoadingWrapper = styled.div`
  margin: auto;
  display: flex;
  flex-direction: column;
`;

const LoaderTextDescriptionWrapper = styled.div`
  flex-direction: row;
  display: flex;
  justify-content: center;
`;

const SpinnerWrapper = styled(Default)`
  margin: 0 auto;
`;

const Paymentloader = () => {
  const { t } = useTranslation();
  return (
    <ContentWrapper>
      <LoaderContentWrapper>
        <LoadingWrapper>
          <SpinnerWrapper color="#E4202A" size={70} />
          <LoaderTextStyled>
            {t("PaymentLoader.กำลังดำเนินการ")}
            <br />
            {t("PaymentLoader.กรุณาอย่าปิดหน้าต่างนี้")}
          </LoaderTextStyled>
          <LoaderTextDescriptionWrapper>
            <LoaderTextStyled2>
              {t("PaymentLoader.LUCKYDOSE กำลังตรวจสอบการชำระเงินของคุณ")}
            </LoaderTextStyled2>
          </LoaderTextDescriptionWrapper>
        </LoadingWrapper>
      </LoaderContentWrapper>
    </ContentWrapper>
  );
};

export default Paymentloader;
