import React, { useState, useEffect, useRef, useContext } from "react";
import { useNavigate } from "react-router";
import {
  sessionRefID,
  session_subscription_selection_artwork,
  session_subscription_selection_package,
  session_subscription_user,
  useSessionStorage,
} from "../../utils/sessionStorage";
import styled from "styled-components";
import fluidText from "../../utils/fluidText";
import { IS_IOS } from "../../utils/support";
import {
  ImageWrapper,
  Image,
  GalleryLeft,
  GalleryRight,
} from "../HomePage/styles";
import useLongPress from "../../utils/useLongPress";
import {
  dateDatabase as dateDatabaseAtom,
  state as stateAtom,
} from "../../global/atom";
import { useRecoilState } from "recoil";
import FooterComponent from "../../components/FooterComponent";
import LoaderComponent from "../../components/LoaderComponent";
import { UserContext } from "../../utils/Context/userContext";
import {
  GCLOUDSTORAGEURL_SUBSCRIPTION,
  getPrediction,
  getTimeInQueue,
  handleFontFamily,
} from "../../utils/utils";
import LargePreviewComponent from "../../components/LargePreviewComponent";
import useNavigationHandling from "../../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";

const TipsRedTextStyled = styled.div`
  ${fluidText(12, 20)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: #e4202a;
  text-align: left;
  word-wrap: normal;
  width: 100%;
  @media (min-width: 600px) {
    //font-size: 1rem;
  }
  //margin-left : 5.1vw;
`;

export const GalleryCardWrapper = styled.div`
  width: 95%;
  height: auto;
  margin: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1vh;
  pointer-events: ${(props) => (props.isPreview ? "none" : "auto")};
  @media (min-width: 600px) {
    width: 100%;
    max-width: 450px;
    margin: 3vh auto 1vh auto;
  }
`;

const ContentWrapper = styled.div`
  //position: fixed;
  //top:0;
  //left:0;
  width: 100vw;
  height: auto;
  /* background-color: rgb(255,255,255); */
  display: flex;
  flex-direction: column;
  margin: 0 auto 20vh auto;
  @media (min-width: 600px) {
    width: 38vw;
    margin: 0 auto 20vh auto;
    min-width: 400px;
  }
`;

export const PredictionText = styled.div`
  font-size: 0.8rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;

  margin-top: 0;
  margin-bottom: 0.6rem;
  display: flex;
  flex-direction: row;

  @media (min-width: 600px) {
    font-size: 1rem;
  }
`;

const HeadTextStyled = styled.div`
  //width:88.46vw;
  //margin: 2vh auto 2vh auto;
  margin: auto;
  ${fluidText(18, 22)}
  //font-size: 1.5rem; 
    ${handleFontFamily()}
  font-style: normal;
  font-weight: 300;
  color: black;
  text-align: center;
  margin-bottom: 2vh;
  @media (min-width: 600px) {
    ${fluidText(20, 24)}//width: 38vw;
    //margin: 3vh auto 3vh auto;;;
  }
`;

const TipsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 90%;
  margin: 0 auto 1vh auto;
  @media (min-width: 600px) {
    width: 100%;
    /* min-width: 450px; */
    /* margin: 0 auto 4vh auto; */
  }
`;

const TextLabelWrapper = styled.p`
  //width:88.46vw;
  margin: 2vh auto 2vh auto;
  max-width: 400px;
  width: 90%;
  ${fluidText(16, 20)}
  //font-size: 1.5rem; 
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: left;
  white-space: pre-line;
`;

const DownloadImage = styled.img`
  //height: 55vh;
  width: 23vh;
  border-radius: 10px;
  margin: 0 auto 0 auto;
  cursor: pointer;
`;

let isTouchmove = false;
const isTouchEvent = (event) => {
  return "touches" in event;
};
const preventDefault = (event) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

const SubscriptionArtworkPage = () => {
  const [referenceID, updateRefID] = useSessionStorage(
    sessionRefID.key,
    sessionRefID.default
  );
  const [state, updateState] = useRecoilState(stateAtom);
  const [circularProgress, updateCircularProgress] = useState(0);
  const [selectedArtwork] = useSessionStorage(
    session_subscription_selection_artwork.key,
    session_subscription_selection_artwork.default
  );
  const [isLoading, updateLoading] = useState(true);
  const [preview, updatePreview] = useState("");
  const [isPreviewArtwork, updatePreviewArtwork] = useState(false);
  const [isPreviewDescription, updatePreviewDescription] = useState(false);
  const intervalFunction = useRef();
  const [selectPackageDetail, updateSelectedPackageDetail] = useSessionStorage(
    session_subscription_selection_package.key,
    session_subscription_selection_package.default
  );
  const [subscriptionUser] = useSessionStorage(
    session_subscription_user.key,
    session_subscription_user.default
  );
  const {
    subscriberInfo,
    updateSubscriberInfo,
    contextAWDb,
    setOnShowPreview,
  } = useContext(UserContext);
  var gCloudStorageURL = GCLOUDSTORAGEURL_SUBSCRIPTION;
  const [additionDownload, updateAdditionDownload] = useState([]);
  const { t } = useTranslation();
  const { navigateHandler } = useNavigationHandling();
  var currentTime = 0;
  var progressTime = 1;
  const loadingProgressRef = useRef();

  useEffect(() => {
    if (!subscriptionUser || !subscriptionUser.phone_number)
      navigateHandler("/ais/login");
    updateState("SubscriptionDownload");
    if (contextAWDb && selectedArtwork) {
      var date = new Date();
      getPrediction(
        subscriberInfo.birthDate,
        subscriberInfo.birthMonth,
        subscriberInfo.birthYear,
        subscriptionUser.phone_number,
        selectPackageDetail.timestamp,
        date.toString()
      ).then((result) => {
        contextAWDb.forEach((aw) => {
          if (aw.id == selectedArtwork.id) {
            var temp = [
              {
                title: t("ฤกษ์ในการตั้งวอลเปเปอร์"),
                src: result.lucky_wallpaper,
              },
              { title: t("บทสวด"), src: selectedArtwork.prayer },
              {
                title: t("คำอธิบายรายละเอียด"),
                src: selectedArtwork.description,
              },
              { title: t("อักษรรูน"), src: selectedArtwork.rune },
            ];
            temp = temp.filter((image) => image.src != "");
            updateAdditionDownload([...temp]);

          }
        });
      });
    }
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);
    return () => {
      if (loadingProgressRef.current) clearInterval(loadingProgressRef.current);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
      setOnShowPreview(false);
      enableScroll();
    };
  }, []);
  useEffect(() => {
    if (
      referenceID != null &&
      referenceID != "" &&
      additionDownload &&
      additionDownload.length > 0
    ) {
      getTimeInQueue(1).then((time) => {
        progressTime = time;
        loadingProgressRef.current = setInterval(countdownProgress, 100);
        intervalFunction.current = setInterval(checkURLImage, 1000);
      });
    }
  }, [referenceID, additionDownload]);

  useEffect(() => {
    if (isPreviewArtwork || isPreviewDescription) {
      setOnShowPreview(true);
      disableScroll();
    } else {
      setOnShowPreview(false);
      enableScroll();
    }
  }, [isPreviewArtwork, isPreviewDescription]);

  const handleTouchMove = () => {
    isTouchmove = true;
  };
  const handleTouchEnd = () => {
    isTouchmove = false;
  };

  const countdownProgress = () => {
    currentTime += 0.1;
    var progression = Math.round((currentTime / progressTime) * 100);
    if (progression >= 95) progression = 95;
    updateCircularProgress(progression);
  };

  function checkURLImage() {
    var http = new XMLHttpRequest();
    var previewImageURL = gCloudStorageURL + referenceID;
    http.onreadystatechange = function () {
      if (this.readyState == 4) {
        // Typical action to be performed when the document is ready:
        if (http.status != 404) {
          updateLoading(false);
          clearInterval(loadingProgressRef.current);
          clearInterval(intervalFunction.current);
          if (!IS_IOS) downloadAllImage();
        }
      }
    };
    http.open("HEAD", previewImageURL, false);
    http.send();
  }

  const disableScroll = () => {
    let root = document.getElementById("root-div100");
    root.style.overflow = "hidden";
  };
  const enableScroll = () => {
    // window.onscroll = function () {};

    let root = document.getElementById("root-div100");
    root.style.overflow = "scroll";
  };

  const onClickImage = (index) => {
    if (!(isPreviewDescription || isPreviewArtwork)) {
      disableScroll();
      updatePreview(index);
      updatePreviewDescription(true);
    }
  };

  const ItemArray = () => {
    let colLeft = [];
    let colRight = [];
    additionDownload.map((item, idx) => {
      if (additionDownload.length === 1) {
      }
      if (idx % 2 === 0) {
        colLeft = [
          ...colLeft,
          <ImageWrapperComponent
            updatePreview={onClickImage}
            item={item}
            idx={idx}
            key={"image_id" + idx}
          />,
        ];
      } else {
        colRight = [
          ...colRight,
          <ImageWrapperComponent
            updatePreview={onClickImage}
            item={item}
            idx={idx}
            key={"image_id" + idx}
          />,
        ];
      }
    });
    return [colLeft, colRight];
  };

  const ImageWrapperComponent = ({ updatePreview, idx, item }) => {
    const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
    };

    const onLongPress = (i) => {};

    const onClick = (i) => {
      if (!isTouchmove) {
        updatePreview(idx + 1);
      }
      isTouchmove = false;
    };

    const longpress = useLongPress(onLongPress, onClick, idx, defaultOptions);
    return (
      <ImageWrapper {...longpress}>
        <Image src={item.src} />
        <PredictionText>{item.title}</PredictionText>
      </ImageWrapper>
    );
  };

  const downloadAllImage = async () => {
    var downloadList = [...additionDownload];
    downloadList.push({
      src: gCloudStorageURL + referenceID,
      title: referenceID,
    });
    for (var x = 0; x < downloadList.length; x++) {
      var url = downloadList[x].src;
      const res = await fetch(url);
      const blob = await res.blob();
      const downloadUrl = URL.createObjectURL(blob);
      const anchor = document.createElement("a");
      anchor.href = downloadUrl;
      anchor.download = `${downloadList[x].title}.jpg`;
      anchor.target = "_blank";
      anchor.click();
    }
  };

  const downloadImage = async (item) => {
    var url = item.src;
    const res = await fetch(url);
    const blob = await res.blob();
    const downloadUrl = URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = downloadUrl;
    anchor.download = `${item.title}.jpg`;
    anchor.target = "_blank";
    anchor.click();
  };

  return (
    <div
      style={{
        marginTop: "calc(3.2rem + 2vh)",
        height: "auto",
        marginBottom: "5vh",
      }}
    >
      <ContentWrapper>
        {isLoading ? (
          <LoaderComponent value={circularProgress} />
        ) : (
          <>
            {(isPreviewDescription || isPreviewArtwork) && (
              <LargePreviewComponent
                previewImage={
                  isPreviewArtwork
                    ? gCloudStorageURL + referenceID
                    : additionDownload[preview - 1].src
                }
                updatePreviewImage={
                  isPreviewArtwork
                    ? updatePreviewArtwork
                    : updatePreviewDescription
                }
                isDownload={true}
                onClickSelectImage={() => {
                  downloadImage(
                    isPreviewArtwork
                      ? {
                          src: gCloudStorageURL + referenceID,
                          title: referenceID,
                        }
                      : additionDownload[preview - 1]
                  );
                }}
              />
            )}
            <HeadTextStyled>
              {t("ดาวน์โหลดวอลเปเปอร์ พร้อมฤกษ์ในการ")}
              <br />
              {t("ตั้งวอล บทสวด คำอธิบายภาพ และอักษรรูน")}
            </HeadTextStyled>
            <TipsWrapper>
              <TipsRedTextStyled>
                {IS_IOS ? (
                  <>
                    <span style={{ color: "#E4202A" }}>
                      • {t("กดค้างที่แต่ละภาพเพื่อดาวน์โหลด")}
                    </span>{" "}
                    <div style={{ margin: "0 auto", color: "black" }}>
                      • {t("หากมีข้อสงสัยเกี่ยวกับวิธีดาวน์โหลดภาพ กด")}{" "}
                      <span
                        style={{ textDecoration: "underline" }}
                        onClick={() => {
                          //setShowVDO(true);
                        }}
                      >
                        {t("ที่นี่")}
                      </span>
                    </div>
                  </>
                ) : (
                  <>
                    <span style={{ color: "#E4202A" }}>
                      •{" "}
                      {t(`สามารถดูรูปภาพของคุณได้ใน "คลังภาพ" หรือ "Gallery"`)}
                    </span>{" "}
                    <div style={{ margin: "0 auto", color: "black" }}>
                      •{" "}
                      {t(
                        `หากภาพไม่ดาวน์โหลดอัตโนมัติ สามารถกด "ดาวน์โหลดทั้งหมด" ด้านล่างได้`
                      )}
                    </div>
                  </>
                )}
              </TipsRedTextStyled>
            </TipsWrapper>
            <TextLabelWrapper>{t("วอลเปเปอร์มงคลเฉพาะคุณ")}</TextLabelWrapper>
            {referenceID && (
              <DownloadImage
                src={gCloudStorageURL + referenceID}
                onClick={() => {
                  if (!isPreviewDescription && !isPreviewArtwork) {
                    updatePreviewArtwork(true);
                  }
                }}
              />
            )}
            <div
              style={{
                width: "90%",
                height: "2px",
                background: "#C4C4C4",
                margin: "2vh auto",
              }}
            />
            <GalleryCardWrapper
              isPreview={isPreviewDescription || isPreviewArtwork}
            >
              {additionDownload && referenceID && (
                <>
                  <GalleryLeft position={"left"}>{ItemArray()[0]}</GalleryLeft>
                  <GalleryRight position={"right"}>
                    {ItemArray()[1]}
                  </GalleryRight>
                </>
              )}
            </GalleryCardWrapper>
          </>
        )}
      </ContentWrapper>
      {!isPreviewDescription && !isPreviewArtwork && (
        <FooterComponent addOnClickNextFunction={downloadAllImage} />
      )}
    </div>
  );
};

export default SubscriptionArtworkPage;
