import axios from "axios";
import moment from "moment";
import { availableLocales } from "../localeBundles";

export const DEPLOY_VERSION = "PROD"; //DEV,STAGE,PROD,ADMIN
export var API_URL, COLLECTION_API_URL;
export const GCLOUDSTORAGEURL_SUBSCRIPTION =
  "https://storage.googleapis.com/luckydose_subscription_output/";
//export const GCLOUDSTORAGEURL_SUBSCRIPTION = "https://storage.googleapis.com/doseart_asia_output/"

if (DEPLOY_VERSION == "PROD") {
  API_URL =
    "https://australia-southeast1-doseart-304207.cloudfunctions.net/luckydose_api";
  COLLECTION_API_URL =
    "https://australia-southeast1-doseart-304207.cloudfunctions.net/luckydose_api/v2";
} else if (DEPLOY_VERSION == "STAGE") {
  API_URL =
    "https://asia-southeast1-doseart-304207.cloudfunctions.net/luckydose_stage_api";
  COLLECTION_API_URL =
    "https://asia-southeast1-doseart-304207.cloudfunctions.net/luckydose_stage_api/v2";
} else if (DEPLOY_VERSION == "ADMIN") {
  API_URL =
    "https://asia-southeast1-doseart-304207.cloudfunctions.net/doseart_admin_api";
  COLLECTION_API_URL =
    "https://asia-southeast1-doseart-304207.cloudfunctions.net/doseart_admin_api/v2";
} else {
  //API_URL = 'http://192.168.1.74:8080'
  API_URL ="https://asia-southeast1-doseart-304207.cloudfunctions.net/doseart_dev_api";
  COLLECTION_API_URL =
    "https://asia-southeast1-doseart-304207.cloudfunctions.net/doseart_dev_api/v2";
}

export const fetchDatabaseFromFirestore = async () => {
  try {
    const res = await axios({
      method: "get",
      url: API_URL + "/getDatabase",
    });
    var result = res.data;
    var array = [];
    result.forEach((value) => {
      if (value.flag) {
        array.push(value);
      }
    });
    return array;
  } catch (e) {
    return;
  }
};

export function getDateText(day, month, year, t) {
  var monthName = [
    "มกราคม",
    "กุมภาพันธ์",
    "มีนาคม",
    "เมษายน",
    "พฤษภาคม",
    "มิถุนายน",
    "กรกฎาคม",
    "สิงหาคม",
    "กันยายน",
    "ตุลาคม",
    "พฤศจิกายน",
    "ธันวาคม",
  ];
  let __month = t ? t(monthName[month]) : monthName[month]
  return day + " " + (__month) + " " + year;
}

export function getZodiacName(id) {
  var zodiacList = [
    "ราศีเมษ",
    "ราศีพฤษภ",
    "ราศีเมถุน",
    "ราศีกรกฎ",
    "ราศีสิงห์",
    "ราศีกันย์",
    "ราศีตุลย์",
    "ราศีพิจิก",
    "ราศีธนู",
    "ราศีมังกร",
    "ราศีกุมภ์",
    "ราศีมีน",
  ];

  return zodiacList[id];
}

export const getFormType = async () => {
  try {
    const res = await axios({
      method: "get",
      url: API_URL + "/getFormType",
    });
    return res.data;
  } catch (e) {
    return;
  }
};

export const getQR = async () => {
  try {
    const res = await axios({
      method: "get",
      url: API_URL + "/getQR",
    });

    return res.data;
  } catch (e) {
    return;
  }
};

export const checkServer = async () => {
  var serverStatus = false;
  await fetch(API_URL + "/getServerStatus")
    .then((res) => res.json())
    .then((result) => {
      serverStatus = result;
    });
  return serverStatus;
};

export const getOrderData = async (indexUID)=>{
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/getOrderDatabase",
      data: {
        indexUID: indexUID,
      },
    });

    return res.data;
  } catch (e) {
    return "0";
  }
}

export const sendMessageToCreateArtwork = async (
  fileName,
  isWaterMark,
  name,
  surname,
  day,
  month,
  year,
  zodiac,
  dayOfWeek,
  moonPhase,
  attribute_name = [],
  chinese_zodiac = "",
  element_index = null,
  element_support_index = null
) => {
  // Send a POST request

  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/createImage",
      data: {
        fileName: fileName,
        isWaterMark: isWaterMark,
        name: name,
        surname: surname,
        day: day,
        month: month,
        year: year,
        zodiac: zodiac,
        dayOfWeek: dayOfWeek,
        moonPhase: moonPhase,
        attribute: attribute_name.length > 0 ? attribute_name : null,
        chinese_zodiac: chinese_zodiac,
        element_index : element_index,
        element_support_index : element_support_index
      },
    });

    return res.data;
  } catch (e) {
    return "0";
  }
};

export const recreateOrderIDIndatabase = async (orderID, date_list) => {
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/recreateOrderID",
      data: {
        orderID: orderID,
        date_list: date_list,
      },
    });
    return res.data;
  } catch (e) {
    return;
  }
};

export const checkPaymentStatusFromDatabase = async (reference_order) => {
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/checkPaymentStatus",
      data: {
        reference_order: reference_order,
      },
    });
    return res.data;
  } catch (e) {
    return;
  }
};

export const sendConversionAPI = async (
  event,
  fbc,
  fbp,
  reference_order = null,
  total_amount = null,
  product_ids = null
) => {
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/sendFbConversion",
      data: {
        event: event,
        fbc: fbc,
        fbp: fbp,
        reference_order: reference_order,
        total_amount: total_amount,
        product_ids: product_ids,
      },
    });
    return res.data;
  } catch (e) {
    return;
  }
};

export const createOrderIDInDatabase = async (
  datalist,
  order_id,
  product_package,
  voucher = null,
  attribute = null,
  product_list = null,
  reffer_url = null,
  indexUID,
  reference_order,
  phone_number,
  client_id,
  payment_method = null,
  fbp = null,
  fbc = null
) => {
  try {
    await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/createOrderID",
      data: {
        order_id: order_id,
        form_list: datalist,
        product_package: product_package,
        voucher: voucher === "" ? null : voucher,
        attribute: attribute,
        product_list: product_list,
        sales_partner: reffer_url,
        indexUID: indexUID,
        reference_order: reference_order,
        phone_number:phone_number,
        client_id: client_id,
        payment_method : payment_method,
        fbp:fbp,
        fbc:fbc
      },
    });
  } catch (e) {
    return e;
  }
};

export const sendErrorToChatbot = async (message) => {
  try {
    await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: "https://asia-southeast1-doseart-304207.cloudfunctions.net/doseart_dev_api/hookbot/sendError",
      data: {
        message: message,
      },
    });
  } catch (e) {
    return e;
  }
};

export const addCustomerToDatabase = async (
  refID,
  contact,
  psdFileName,
  name,
  surname,
  zodiac,
  dayOfWeek,
  moonPhase,
  timestamp,
  orderID
) => {
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/addCustomer",
      data: {
        fileName: refID,
        psdFileName: psdFileName,
        name: name,
        surname: surname,
        zodiac: zodiac,
        contact: contact,
        dayOfWeek: dayOfWeek,
        moonPhase: moonPhase,
        timestamp: timestamp,
        orderID: orderID,
      },
    });

    return res.data;
  } catch (e) {
    return "0";
  }
};

export const createOrderID = async(id)=>{
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data:{
        id:id
      },
      url: API_URL + "/payment/createReferenceOrder/",
    });
    return res.data;
  } catch (e) {
    return e;
  }
}

export const randomId = function(length = 6) {
  return Math.random().toString(36).substring(2, length+2);
};

export const createQROrder = async (
  productPackage,
  voucher = null,
  product_list,
  reffer_url,
  language
) => {
  try {
    var product_id = productPackage;
    if (!product_id) product_id = "";
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        package: product_id,
        voucher: voucher === "" ? null : voucher,
        product_list: product_list,
        reffer_url: reffer_url,
        language: language
      },
      url: API_URL + "/payment/createQROrder/",
    });
    return res.data;
  } catch (e) {
    return e;
  }
};

export const getAllProductPackages = async () => {
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/getPackage",
    });
    return res.data;
  } catch (e) {
    return;
  }
};

export const sendOTP = async (phone_number) => {
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        phone_number: phone_number,
      },
      url: API_URL + "/subscription/sendOTP",
    });
    return res.data;
  } catch (e) {
    return;
  }
};

export const validateOTP = async (phone_number, token, otp) => {
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        phone_number: phone_number,
        token: token,
        otp: otp,
      },
      url: API_URL + "/subscription/validateOTP",
    });
    return res.data;
  } catch (e) {
    return;
  }
};

export const checkSumOrderID = async (
  order_id,
  charge_id,
  contact,
  payment,
  voucher = null,
  reference_order
) => {
  try {
    var tempPayment = payment;
    if (payment == "unionPay") tempPayment = "card";
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/checkoutOrderID",
      data: {
        order_id: order_id,
        charge_id: charge_id,
        contact: contact,
        payment: tempPayment,
        voucher: voucher === "" ? null : voucher,
        reference_order: reference_order,
      },
    });
    return res.data;
  } catch (e) {
    return "0";
  }
};

export const createCardCharge = async (
  orderID,
  token,
  amount,
  reffer_url,
  package_name,
  product_list,
  type,
  reference_order,
  language
) => {
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        orderID: orderID,
        token: token,
        amount: amount,
        reffer_url: reffer_url,
        package: package_name,
        product_list: product_list,
        type: type,
        reference_order: reference_order,
        language: language
      },
      url: API_URL + "/payment/createCharge",
    });
    return res.data;
  } catch (e) {
    return 0;
  }
};

//check Inquiry when url has chargedID or check 15min laters
export const checkChargeId = async (chargeId, payment_method) => {
  try {
    var payment = payment_method;
    if (payment_method == "card" || payment_method == "unionPay")
      payment = "card";
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        chargeId: chargeId,
        payment_method: payment,
      },
      url: API_URL + "/checkChargeId",
    });
    return res.data;
  } catch (e) {
    return;
  }
};

export const getQueue = async () => {
  return await fetch(API_URL + "/getQueue")
    .then((res) => res.json())
    .then((result) => {
      return result;
    });
};

export const getDateDatabase = async () => {
  return await fetch(API_URL + "/getDateDatabase")
    .then((res) => res.json())
    .then(
      (result) => {
        return result;
      },
      (error) => {
        return;
      }
    );
};

export const getCatalog = async () => {
  var catalog = 0;
  await fetch(API_URL + "/getCatalog")
    .then((res) => res.json())
    .then(
      (result) => {
        catalog = result;
      },
      (error) => {
        return 0;
      }
    );
  return catalog;
};

export const checkOutQR = async (orderID) => {
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        orderID: orderID,
      },
      url: API_URL + "/payment/checkoutQR",
    });

    return res.data;
  } catch (e) {
    return;
  }
};

export const releaseValidVoucher = async (voucher, valid_key) => {
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/releaseVoucher",
      data: {
        session_key: valid_key,
        voucher: voucher,
      },
    });
    return res.data;
  } catch (e) {
    return;
  }
};

export const checkValidVoucher = async (amount, voucher, lock = false) => {
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/checkVoucher",
      data: {
        //orderID: orderID,
        amount: amount,
        //products: products,
        voucher: voucher,
        lock: lock,
      },
    });
    return res.data;
  } catch (e) {
    return "0";
  }
};

//year in put with AD
//month much be 1-12
export function getDayOfWeek(year, month, day) {
  //0: sun 1:mon 2:tue 3:wed 4:thu 5:fri 6:sat

  var k = day;
  var m = ((month + 9) % 12) + 1; //let march : 1 and feb : 12
  var c = Math.floor(year / 100); //century
  var y = year - c * 100;
  if (month < 3) year -= 1;
  var result =
    (k +
      Math.floor(2.6 * m - 0.2) -
      2 * c +
      y +
      Math.floor(y / 4) +
      Math.floor(c / 4)) %
    7;

  return (result + 7) % 7;
}

//year in put with AD
export function getMoonPhase(year_, month_, day_) {
  var year = year_;
  var month = month_;
  var day = day_;
  if (month < 3) {
    year -= 1;
    month += 12;
  }
  month += 1;

  var c = 365.25 * year;

  var e = 30.6 * month;

  var jd = c + e + day - 694039.09; // jd is total days elapsed

  jd /= 29.5305882; // divide by the moon cycle

  var b = parseInt(jd); // int(jd) -> b, take integer part of jd

  jd -= b; // subtract integer part to leave fractional part of original jd

  b = Math.round(jd * 30); // scale fraction from 0-8 and round

  if (b >= 30) b = 0; // 0 and 30 are the same so turn 30 into 0

  // 0 => New Moon
  // 15 => Full Moon

  return b;
}

export async function printThaiMoonPhase(year) {
  var year_ref = await getDateDatabase();
  var start = moment("1856-11-28", "YYYY-MM-DD");
  var loopStart = moment(year + "-01-01", "YYYY-MM-DD");
  var loopEnd = moment(parseInt(year) + 1 + "-01-01", "YYYY-MM-DD");
  const startRef = 1857;
  for (var m = moment(loopStart); m.isBefore(loopEnd); m.add(1, "days")) {
    var dayDiff = m.diff(start, "days");
    var startYear = 1857;
    var countDay = 0;
    if (dayDiff >= 0 && dayDiff <= 64287) {
      while (startYear < year) {
        countDay += getDayInMoonYear(startYear, year_ref);
        startYear++;
      }
    }

    if (dayDiff - countDay > getDayInMoonYear(year, year_ref)) {
      let dayDiffFromYear =
        dayDiff - countDay - getDayInMoonYear(year, year_ref);
      let countYear = getDaysArrayInMoonYear(
        year_ref[year - startRef + 1].value
      );
      for (let i = 0; i < countYear.length; i++) {
        if (countYear[i] <= dayDiffFromYear) {
          dayDiffFromYear -= countYear[i];
        } else {
          break;
        }
      }
    } else if (dayDiff - countDay === getDayInMoonYear(year, year_ref)) {
      return 1;
    } else {
      let countYear = getDaysArrayInMoonYear(year_ref[year - startRef].value);
      let dayDiffFromYear = dayDiff - countDay;
      for (let i = 0; i < countYear.length; i++) {
        if (countYear[i] <= dayDiffFromYear) {
          dayDiffFromYear -= countYear[i];
        } else {
          break;
        }
      }
    }
  }
}

//[0,0] ขึ้น 1 ค่ำ เดือน 1
export async function getThaiMoonPhase(year, month, day, year_ref) {
  //thai lunar year
  //use 28/11/1856 is [0,0] // ขึ้น 1 ค่ำ เดือน 1
  //var year_ref = await getDateDatabase();
  var start = moment("1856-11-28", "YYYY-MM-DD");
  var end = moment(year + "-" + month + "-" + day, "YYYY-MM-DD");
  var dayDiff = end.diff(start, "days");
  const startRef = 1857;
  var startYear = 1857;
  var countDay = 0;
  if (dayDiff >= 0 && dayDiff <= 64287) {
    while (startYear < year) {
      countDay += getDayInMoonYear(startYear, year_ref);
      startYear++;
    }
    if (dayDiff - countDay > getDayInMoonYear(year, year_ref)) {
      let dayDiffFromYear =
        dayDiff - countDay - getDayInMoonYear(year, year_ref);

      let countYear = getDaysArrayInMoonYear(
        year_ref[year - startRef + 1].value
      );
      for (let i = 0; i < countYear.length; i++) {
        if (countYear[i] <= dayDiffFromYear) {
          dayDiffFromYear -= countYear[i];
        } else {
          //make 0 = new moon (แรม 15 ค่ำ)
          return (dayDiffFromYear + 1) % 30;
        }
      }
    }
    //เท่ากับวันสุดท้ายคือขึ้น 1 ค่ำ
    else if (dayDiff - countDay === getDayInMoonYear(year, year_ref)) {
      return 1;
    } else {
      let countYear = getDaysArrayInMoonYear(year_ref[year - startRef].value);
      let dayDiffFromYear = dayDiff - countDay;

      for (let i = 0; i < countYear.length; i++) {
        if (countYear[i] <= dayDiffFromYear) {
          dayDiffFromYear -= countYear[i];
        } else {
          //console.log('month: ' + i)
          //make 0 = new moon (แรม 15 ค่ำ)
          return (dayDiffFromYear + 1) % 30;
        }
      }
    }
  } else return getMoonPhase(year, month, day);
}

export function getImageListFromCollection(data, collection_id) {
  var image_list = [];
  data.forEach((image, index) => {
    if (image.collection_id === collection_id) {
      image_list.push({
        image: image,
        index: index,
      });
    }
  });
  return image_list;
}

export function getImageListFromCollectionAndTag(data, collection_id, tag) {
  var image_list = [];
  data.forEach((image, index) => {
    if (image.collection_id === collection_id) {
      image.tags.forEach((image_tag) => {
        if (tag === image_tag)
          image_list.push({
            image: image,
            index: index,
          });
      });
    }
  });
  return image_list;
}

function getDaysArrayInMoonYear(year_value) {
  const regularMoonYear = [29, 30, 29, 30, 29, 30, 29, 30, 29, 30, 29, 30];
  const doubleMonthMoonYear = [
    29, 30, 29, 30, 29, 30, 29, 30, 30, 29, 30, 29, 30,
  ]; //have double month 8
  const addDayMoonYear = [29, 30, 29, 30, 29, 30, 30, 30, 29, 30, 29, 30]; // 30 day in month 7
  if (year_value === "0") return regularMoonYear;
  else if (year_value === "1") return addDayMoonYear;
  else return doubleMonthMoonYear;
}

function getDayInMoonYear(year, year_ref) {
  if (year >= year_ref[0].year) {
    const startYear = 1857;
    if (year_ref[year - startYear].value === "0") return 354;
    else if (year_ref[year - startYear].value === "1") return 355;
    else return 384;
  } else return 0;
}

export async function getTimeInQueue(n = 1) {
  var numberOfComputer = 2;
  var queue = await getQueue();
  var timeUsePerAW = 18;
  return (Math.ceil(queue + n) / numberOfComputer) * timeUsePerAW;
}

export const handleNewLine = (input) => {
  if (input&&input.includes("\\n")) {
    return input.replace("\\n", "\n");
  } else return input;
};

export const getPackagePrice = async (package_id) => {
  try {
    const res = await axios({
      method: "get",
      url: COLLECTION_API_URL + "/packages/" + package_id,
    });
    return res.data;
  } catch (e) {
    return;
  }
};

export const checkRefferURL = async (refferURL) => {
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/checkReffer",
      data: {
        refferID: refferURL,
      },
    });
    return res.data;
  } catch (e) {
    return "0";
  }
};

export const sendEvent = async (
  refferURL,
  event,
  purchased_items = null,
  order_id = null
) => {
  try {
    await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/sendEvent",
      data: {
        refferID: refferURL,
        event: event,
        purchased_items: purchased_items,
        order_id: order_id,
      },
    });
  } catch (e) {
    return 0;
  }
};

export const checkPhoneNumber = async (phone_number,package_info) => {
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/subscription/checkPhoneNumber",
      data: {
        phone_number: phone_number.replace("0", "66"),
        package: package_info
      },
    });
    return res.data;
  } catch (e) {
    return 0;
  }
};

export const getUserPackage = async (package_type_id, phone_number) => {
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/subscription/getUserPackageDetail",
      data: {
        package_type_id: package_type_id,
        phone_number: phone_number.replace("0", "66"),
      },
    });
    return res.data;
  } catch (e) {
    return 0;
  }
};

export const getPackageDetail = async (package_type_id) => {
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/subscription/getPackageDetail",
      data: {
        package_type_id: package_type_id,
      },
    });
    return res.data;
  } catch (e) {
    return 0;
  }
};

export const createSubscriptionArtwork = async (
  fileName,
  isWaterMark,
  name,
  surname,
  day,
  month,
  year,
  zodiac,
  dayOfWeek,
  moonPhase,
  phone_number,
  package_type_id,
  attribute_name = [],
  chinese_zodiac = ""
) => {
  // Send a POST request

  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/subscription/createImage",
      data: {
        fileName: fileName,
        name: name,
        surname: surname,
        day: day,
        month: month,
        year: year,
        zodiac: zodiac,
        dayOfWeek: dayOfWeek,
        moonPhase: moonPhase,
        attribute: attribute_name.length > 0 ? attribute_name : null,
        chinese_zodiac: chinese_zodiac,
        phone_number: phone_number.replace("0", "66"),
        package_type_id: package_type_id,
      },
    });

    return res.data;
  } catch (e) {
    return "0";
  }
};

export const checkTransactionId = async (trans_id)=>{
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/checkTransactionId",
      data: {
        id: trans_id,
      },
    });
    return res.data;
  } catch (e) {
    return 0;
  }
}

export const checkAstrologyProfile = async (name,surname,date,month,year,time_value)=>{
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/v3/numeing/getAstrologyProfile",
      data: {
        name:name,
        surname:surname,
        date:date,
        month:month,
        year:year,
        time_value:time_value,
      },
    });
    return res.data;
  } catch (e) {
    return 0;
  }
}

export const getPrediction = async (
  date,
  month,
  year,
  phone_number,
  timestamp,
  generated_timestamp
) => {
  try {
    const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: API_URL + "/subscription/getPrediction",
      data: {
        date: date,
        month: month,
        year: year,
        phone_number: phone_number,
        timestamp: timestamp,
        generated_timestamp: generated_timestamp,
      },
    });
    return res.data;
  } catch (e) {
    return 0;
  }
};

export const checkVoucherInCampaign = async (
  campaign_id
)=>{
  try{
    const res = await axios({
      method: "post",
      headers : {
        "Content-Type": "application/json"
      },
      url: API_URL+"/voucher/redeemVoucherInCampaign",
      data:{
        campaign_id:campaign_id
      }
    })
    return res.data;
  } catch (e) {
    return 0;
  }
}

export const payWithAIS = async (price) => {
  try {
    // generate a random integer between 0 and 999 inclusive
    var randomNum = Math.floor(Math.random() * 1000);

    // convert the integer to a string and add leading zeroes if necessary
    var randomStr = randomNum.toString().padStart(3, "0");

    // create a new Date object
    var date = new Date();

    // get the ISO-formatted date string
    var isoString = date.toISOString();

    // extract the parts of the string that you need
    var formattedDate =
      isoString.slice(0, 4) + // year
      isoString.slice(5, 7) + // month
      isoString.slice(8, 10) + // day
      isoString.slice(11, 13) + // hours
      isoString.slice(14, 16) + // minutes
      isoString.slice(17, 19) + // seconds
      randomStr;

    var xhr = new XMLHttpRequest();
xhr.open('POST', 'http://dcb.shinee.com:9393/WebForm3.aspx',true);
xhr.setRequestHeader('Content-Type', 'application/xml');
xhr.onreadystatechange = function() {
  if (xhr.readyState === 4) {
    if (xhr.status === 200) {
      console.log(xhr.responseText);
    } else {
      console.error('Error:', xhr.statusText);
    }
  }
};
xhr.send(`<?xml version="1.0"encoding="utf8"?><RequestXML><Transaction_ID>${formattedDate}</Transaction_ID><Service_key>LUCKD_OT_49</Service_key><Service_type>TICKET</Service_type></RequestXML>`);

    /*fetch("http://dcb.shinee.com:9393/WebForm3.aspx", {
      method: "POST",
      headers: {
        "Content-Type": "application/xml",
      },
      body: `<RequestXML><Transaction_ID>${formattedDate}</Transaction_ID><Service_key>LUCKD_OT_49</Service_key><Service_type>TICKET</Service_type></RequestXML>`,
    })
      .then((response) => {
        if (response.ok) {
          return response.text();
        } else {
          throw new Error("Network response was not ok.");
        }
      })
      .then((data) => {
        console.log(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });*/

    /*const res = await axios({
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      url: "http://dcb.shinee.com:9393/WebForm3.aspx",
      data: {
        Transaction_ID: formattedDate,
        Service_key: "LUCKD_OT_49",
        Service_type: "TICKET",
      },
    });*/
  } catch (e) {}
};

export const fetchAndHandleData = (url) => {
  function getData(url) {
    return new Promise((resolve) => {
      axios
        .get(url)
        .then((res) => {
          resolve(res);
        })
        .catch((err) => {
          console.error(err);
          resolve("error");
        });
    });
  }

  return new Promise(async (resolve) => {
    let times = 0,
      max_times = 3,
      error = false,
      data;

    while (true) {
      times += 1;
      data = await getData(url);
      if (data !== "error") break;
      else if (times === max_times) {
        error = true;
        break;
      }
    }

    if (error) resolve(false);
    else resolve(data);
  });
};

export const handleFontFamily = () => {
  // check locale from local storage
  if (window) {
    let locale = window.location.pathname.substring(1, 4).toLowerCase()
    if (locale.includes('zh')) return "font-family: 'Noto Sans SC', sans-serif;";
  }
  return "font-family: 'Prompt', sans-serif;";
}

export const checkLangAvailableDatabase = (input) => {
  if(input==undefined){return input}
  let locale = window.location.pathname.substring(1, 4).toLowerCase().replace('/', ''); // get current locale
  //console.log('[CheckLangAvailableDatabase] current locale: ', locale)
  if (input[locale]) {
    return input[locale]
  } else {
    if (input['th']) return input['th']
    else return input
  }
}

export const checkNewLine = (input) =>{
  const formattedString = input.replace(/\\n|\r\n|\n/g, '<br>');
  return <h3 dangerouslySetInnerHTML={{ __html: formattedString }} />
}