import styled from "styled-components";
import fluidText from "../../utils/fluidText";
import LazyLoad from "react-lazy-load";
import { handleFontFamily } from "../../utils/utils";

export const breakpoints = {
  xs: `(max-width: 430px)`,
  sm: `(max-width: 735px)`,
  lg: `(max-width: 1068px)`,
};

export const BannerWrapper = styled.div`
  display:inline-block ;
  width:100%;
  max-width:100vw ;
  height:736px;
  position:relative;
  overflow-y:auto;
  @media screen and ${breakpoints.lg} {
    height: 676px;
  }

  @media only screen and ${breakpoints.sm} {
    height: 548px;
  }
  border-top: ${(props) => (props.first_section ? "none" : ".3rem solid #fff")};
  border-bottom: 0.3rem solid #fff;
`

export const BannerImageWrapper = styled.div`
  width:100%;
  height:100% ;
  display:flex;
  align-items:center;
  justify-content:center;
  overflow:hidden;
  position:absolute ;
  left:0px ;
  top:0px ;
  z-index:-1;
`

export const BannerImage = styled.img`
object-fit:contain;
height:100%;
`

export const SectionWrapper = styled.section`
  background-image: url(${(props) => props.jpg_large});
  width: 100%;
  height: 736px;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-top: ${(props) => (props.first_section ? "none" : ".3rem solid #fff")};
  border-bottom: 0.3rem solid #fff;
  text-align: center;
  /* padding: 10px 20px; */
  color: #fff;
  margin-bottom: 0;

  @media screen and ${breakpoints.lg} {
    background-image: url(${(props) => props.jpg_med});
    height: 676px;
    background-position: bottom center;
    background-repeat: no-repeat;
  }

  @media only screen and ${breakpoints.sm} {
    background-image: url(${(props) => props.jpg_small});
    height: 548px;
    background-position: bottom center;
    background-repeat: no-repeat;
  }

  /* @media screen and ${breakpoints.xs} {
    background-image: url(${(props) => props.jpg_small});
    height: 676px;
    background-position: bottom center;
    background-repeat: no-repeat;
  } */
  overflow-y: auto;
`;

export const SubscriptionBannerWrapper = styled.section`
  background-image: url(${(props) => props.jpg_large});
  width: 100%;
  height: ${(props)=>props.state&&props.state=='aisalc'?'35vh':'45vh'};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-top: ${(props) => (props.first_section ? "none" : ".3rem solid #fff")};
  border-bottom: 0.3rem solid #fff;
  text-align: center;
  /* padding: 10px 20px; */
  color: #fff;
  margin-bottom: 0;

  @media screen and ${breakpoints.lg} {
    background-image: url(${(props) => props.jpg_med});
    height: 45vh;
    background-position: center;
    background-repeat: no-repeat;
  }

  @media only screen and ${breakpoints.sm} {
    background-image: url(${(props) => props.jpg_small});
    height: 75vw;
    background-position: bottom center;
    background-repeat: no-repeat;
  }

  /* @media screen and ${breakpoints.xs} {
    background-image: url(${(props) => props.jpg_small});
    height: 676px;
    background-position: bottom center;
    background-repeat: no-repeat;
  } */
  overflow-y: auto;
`;

export const DetailSectionWrapper = styled.section`
  //height: 100%;
  max-height: ${(props) => (props.height? props.height+'px' : "100%")};
  overflow-y: hidden;
`;



export const DetailSectionBg = styled.section`
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: -1;
  background-image: url(${(props) => props.bg_large});
  background-size: initial;
  background-position: center;
  background-repeat: no-repeat;
  pointer-events:none ;
  overflow-y: hidden;

  @media screen and ${breakpoints.lg} {
    background-image: url(${(props) => props.bg_medium});
    height: 1080px;
    background-position: bottom center;
    background-repeat: no-repeat;
  }

  @media only screen and ${breakpoints.sm} {
    background-image: url(${(props) => props.bg_small});
    height: 100%;
    background-position: bottom center;
    background-repeat: no-repeat;
    background-size: cover;
  }
`;

export const DetailImageWrapper = styled.div`
  width: 100vw;
  height: auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  scroll-snap-type: y mandatory;
  max-height: ${(props) => (props.height? props.height*0.9+'px' : "90vh")};
  overflow-y: scroll;
  @media only screen and ${breakpoints.sm} {
    /* height: 100vh; */
    /* height: calc(90vh - 3.2rem); */
    /* height: fit-content; */
    max-height: ${(props) => (props.height? props.height*0.8+'px' : "80vh")};
  }
`;
export const DetailImage = styled.div`
  height:${(props) => (props.height? props.height*0.9+'px' : "90vh")};
  width: auto;
  scroll-snap-align: start;
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  z-index:1 ;
  img {
    height: 100%;
    max-width:100%;
    object-fit:contain;
  }
  @media only screen and ${breakpoints.sm} {
    /* height: 100vh; */
    /* height: calc(90vh - 3.2rem); */
    /* height: fit-content; */
    height: ${(props) => (props.height? props.height*0.8+'px' : "80vh")};
    //margin-bottom:${(props) => (props.isLast? '10vh':'0px')};
    img {
      height: ${(props) => (props.height? props.height*0.8+'px' : "80vh")};
    }
  }
`;

export const DetailLazyLoadImage = styled(LazyLoad)`
  height:${(props) => (props.height? props.height*0.9+'px' : "90vh")};
  width: auto;
  scroll-snap-align: start;
  flex: none;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow-y: hidden;
  img {
    height: 100%;
  }
  @media only screen and ${breakpoints.sm} {
    /* height: 100vh; */
    /* height: calc(90vh - 3.2rem); */
    /* height: fit-content; */
    height: ${(props) => (props.height? props.height*0.8+'px' : "80vh")};
    //margin-bottom:${(props) => (props.isLast? '10vh':'0px')};
    img {
      height: 100%;
    }
  }
`;

export const TitleContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  align-items: center;
`;
export const NewAlert = styled.div`
  color: #d52b2c;
  font-weight: 600;
  ${fluidText(22, 26)}
`;
export const TitleWrapper = styled.div`
  font-weight: 600;
  font-size: 4rem;
  line-height: 1.15em;
  letter-spacing: -0.04em;
  white-space: pre-line;
  color: ${(props) => (props.font_theme === "light" ? "#fff" : "#1d1d1f")};

  @media screen and ${breakpoints.lg} {
    font-size: 3rem;
  }

  @media only screen and ${breakpoints.sm} {
    font-size: 2rem;
  }
`;
export const DescriptionWrapper = styled.div`
  font-size: 1.8rem;
  padding: 8px 0;
  margin: 0.2rem 0;
  color: ${(props) => (props.font_theme === "light" ? "#fff" : "#1d1d1f")};
  @media screen and ${breakpoints.lg} {
    font-size: 1.4rem;
    padding: 5px 0;
  }

  @media only screen and ${breakpoints.sm} {
    font-size: 1rem;
    padding: 3px 0;
  }
`;
export const LinkWrapper = styled.div`
  padding: 10px 0;
  ul {
    width: 40vw;
    max-width: 350px;
    min-width: 220px;
    display: flex;
    padding: 0px;
    justify-content: space-evenly;
    list-style: none;
    margin: 0;
    .ais {
      background-color: #8DC63F;
      color: white;
      padding: 0.3rem 1.2rem;
      border-radius: 30px;
      :hover {
        color: black;
        cursor: pointer;
      }
    }
    .secondary {
      background-color: white;
      color: #d52b2c;
      padding: 0.3rem 1.2rem;
      border-radius: 30px;
      :hover {
        color: black;
        cursor: pointer;
      }
    }
    .primary {
      background-color: #d52b2c;
      color: white;
      padding: 0.3rem 1.2rem;
      border-radius: 30px;
      :hover {
        color: #c8b280;
        cursor: pointer;
      }
    }
    li {
      display: inline-block;
      min-width: 25%;
      box-shadow: 0px 4px 8px rgba(92, 92, 92, 0.2);
      /* padding-right: 20px; */
      p {
        margin: 0;
        /* color: #e4202a; */
        text-decoration: none;
        font-size: 21px;
        transition: color 0.2s;
        font-weight: 400;
        /* :after {
          font-family: "FontAwesome";
          font-weight: 900;
          content: "\f105";
          padding-left: 6px;
        } */
      }
    }
  }

  @media screen and ${breakpoints.lg} {
    padding: 8px 0;
    ul {
      max-width: 330px;
      min-width: 310px;
      margin: 0;
      li {
        p {
          font-size: 1.2rem;
        }
      }
    }
  }

  @media only screen and ${breakpoints.sm} {
    padding: 4px 0;
    ul {
      max-width: 240px;
      min-width: 220px;

      .secondary {
        padding: 0.3rem 1rem;
      }
      .primary {
        padding: 0.3rem 1rem;
      }

      li {
        p {
          font-size: 0.8rem;
        }
      }
    }
  }
`;

export const HeadTextStyled = styled.div`
  //width:88.46vw;
  margin: 2vh auto 2vh auto;
  ${fluidText(24, 28)}
  //font-size: 1.5rem; 
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 300;
  color: black;
  text-align: center;
  white-space: pre-line;
  @media (min-width: 600px) {
    //width: 38vw;
    margin: 3vh auto 3vh auto;
  }
`;
export const TipsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 92vw;
  /* margin: 0 auto 1vh auto; */
  @media (min-width: 600px) {
    width: 38vw;
    min-width: 450px;
    /* margin: 0 auto 4vh auto; */
    margin: 0 auto;
  }
`;
export const TipImage = styled.img`
  width: 9.2vw;
  margin-left: 3.5vw;
  margin-right: 3.5vw;
  @media (min-width: 600px) {
    width: 6vh;
    height: 6vh;
    margin-left: 1vw;
    margin-right: 1vw;
  }
`;
export const TipsTextStyled = styled.div`
  ${fluidText(14, 18)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: "black";
  text-align: left;
  align-items: center;
  display: flex;

  margin-left: 2rem;

  white-space: pre-wrap;
  @media (min-width: 600px) {
    //height: 3vh;
  }
`;

export const ImageWrapper = styled.div`
  /* position: relative; */
  /* width: 100%; */
  width: 90%;
  height: fit-content;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 5vh;
  position: relative;
  box-shadow: 0px 3px 12px rgba(92, 92, 92, 0.2);
  border-radius: 15px;
  /* @media (min-width: 600px) {
    margin-bottom: 5vw;
    margin-left: 2.5vw;
  } */
`;

export const PriceNumber = styled.div`
  font-size: 0.8rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;

  margin-top: 0;
  margin-bottom: 0.6rem;
  display:flex ;
  flex-direction:row;

  @media (min-width: 600px) {
    font-size: 1rem;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: ${(props) => (props.isSelected ? "30%" : "100%")};
  /* pointer-events: none; */
  border-radius: 15px;
  @media (min-width: 600px) {
    width: 100%;
    min-width: 118.42px;
  }
`;
export const GalleryCardWrapper = styled.div`
  width: 95%;
  height:auto;
  margin: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;

  pointer-events: ${(props) => (props.isPreview ? "none" : "auto")};
  @media (min-width: 600px) {
    width: 100%;
    max-width: 450px;
    margin: 3vh auto 5vh auto;
  }
`;

export const GalleryWrapper = styled.div`
  display: grid;
  height:auto ;
  /* grid-template-columns: repeat(auto-fit, minmax(25vw, 1fr)); */
  /* grid-template-columns: repeat(3, 12px); */
  grid-template-columns: auto auto;
  /* grid-auto-rows: 1px; */
  grid-row-end: span;
  justify-content: center;
  grid-gap: 0rem;
  /* grid-gap: 1em; */

  width: 100%;
  margin-bottom: 30%;
  @media (min-width: 600px) {
    width: 38vw;
    min-width: 430px;
    margin: 2vw auto 10vh auto;
  }
`;
export const GalleryLeft = styled.div`
  width: 100%;
  height:auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  margin-right: 0.5rem;
`;

export const GalleryRight = styled.div`
  width: 100%;
  height:auto;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  align-items: flex-start;
  margin-left: 0.5rem;
`;
export const PreviewImageWrapper = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: ${(props) => (props.isPreview ? "10" : "-10")};
  opacity: ${(props) => (props.isPreview ? "1" : "0")};
  width: 100%;
  height: 100%;
`;

export const PreviewImage = styled.img`
  position: absolute;
  width: 90vw;
  height: auto;
  top: 50%;
  left: 50%;
  transform: ${(props) => (props.isPreview ? "translate(-50%, -50%)" : "translate(-50%, -20%)")};
  object-fit: contain;
  transition: all 0.5s;
`;

export const PreviewImage_ = styled.div`
  position: relative;
  width: 86vw;
  height: 86vh;
  max-width: 420px;
  top: 50%;
  left: 50%;
  transform: ${(props) => (props.isPreview ? "translate(-50%, -50%)" : "translate(-50%, -20%)")};
  /* object-fit: contain; */
  transition: all 0.5s;
  background-image: url(${(props) => props.bgUrl});
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;

  @media only screen and ${breakpoints.xs} {
    height: 0;
    padding-top: 150.64%;
  }
`;
export const CloseButton = styled.img`
  position: absolute;
  top: 2%;
  right: 5%;
  width: 9vw;
  pointer-events: auto;
  cursor: pointer;
  transform: ${(props) => (props.isPreview ? "translate(0%, 0%)" : "translate(0%, 30%)")};
  transition: all 0.5s;
  @media (min-width: 600px) {
    width: 3vw;
  }
`;
export const ActionButton = styled.button`
  /* Adapt the colors based on primary prop */
  position: absolute;
  bottom: 4%;
  left: 50%;
  transform: ${(props) => (props.isPreview ? "translate(-50%, 0%)" : "translate(-50%, 30%)")};
  transition: all 0.5s;

  background: ${(props) => (props.disable ? "#C4C4C4" : props.primary ? "#E4202A" : "white")};
  color: ${(props) => (props.primary ? "white" : "#E4202A")};
  pointer-events: ${(props) => (props.disable ? "none" : "initial")};
  //font-size: 1rem;
  padding: 1vh 0px;
  border-radius: 10px;
  ${handleFontFamily()}
  font-weight: 300;
  width: ${(props) => (props.width ? props.width : "auto")};
  display: inline-block;
  text-align: center;
  cursor: pointer;
  box-shadow: 0px 4px 20px rgba(92, 92, 92, 0.2);
  border: ${(props) =>
    props.disable ? "2px solid rgb(196, 196, 196)" : "2px solid rgb(228, 32, 42)"};
  ${fluidText(14, 18)}
  min-width: 120px;
  @media (min-width: 600px) {
    min-width: 170px;
    ${fluidText(16, 19)}
  }
`;
export const BGDark = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #333;
  opacity: ${(props) => (props.isPreview ? "0.75" : "0")};
  transition: all 0.2s;
`;

export const ActionButtonWrapper = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  width: 101vw;
  padding-bottom: 0.8rem;
  height: 10%;
  background-color: #fefefe;
  box-shadow: 0px 0px 24px rgba(92, 92, 92, 0.2);
  border-radius: 16px 16px 0 0;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (min-width: 600px) {
    width: 60vw;
  }
  button {
    width: 90%;
    max-width: 400px;
    margin: auto;
    background-color: #d52b2c;
    border: none;
    border-radius: 10px;
    color: white;
    height: 55%;

    font-size: 1rem;
    ${handleFontFamily()}
    font-style: normal;
    font-weight: normal;

    box-shadow: 0px 4px 16px rgba(92, 92, 92, 0.2);

    :hover {
      /* background-color: black; */
      cursor: pointer;
      color: #c8b280;
    }

    :disabled {
      background-color: #cccccc;
    }
  }
`;

export const CouponSectionWrapper = styled(SectionWrapper)`
  background-position: bottom center;
`;

export const ContactLayout = styled.div`
  flex-direction: row;
  align-items: center;
  width: 90%;
  //justify-content: flex-end;
  display: flex;
  justify-content: center;
  z-index: 1;
  /* transform: translate(-22%, 0); */
  padding-top: 5px;
  padding-bottom: 20px;
  @media (min-width: 600px) {
    align-items: flex-start;
    /* transform: translate(0, 10vh); */
    padding: 80px;
  }
`;

export const ContactTextStyledWrapper = styled.div`
  font-size: 1rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 600;
  /* color: white; */
  color: #333;
  z-index: 1;
  display: flex;
  margin-right: 2rem;
  //transform: translate(-70%, 0%);
  width: 8rem;
  svg {
    object-fit: contain;
    object-position: center;
    width: 100%;
  }
  @media (min-width: 600px) {
    /* transform: translate(-70%, 0%); */
    height: 4vh;
    width: 12rem;
    align-items: center;
    font-size: 1rem;
  }
`;

export const ContactLogo = styled.img`
  width: 3.5vh;
  height: 3.5vh;
  display: flex;
  cursor: pointer;
  z-index: 1;
  @media (min-width: 600px) {
    width: 4vh;
    height: 4vh;
  }
`;

export const ContactLogoAndTextWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 1.2vh;
  z-index: 1;
  @media (min-width: 600px) {
    margin-bottom: 0.5vh;
    margin-left: 1vw;
    margin-right: 0;
  }
`;

export const ContactLogoWrapper = styled.div`
  //margin-right: 38.775vw;
  display: flex;
  z-index: 1;
  @media (min-width: 600px) {
    left: 50%;
    flex-direction: column;
    /* transform: translate(-30%, 0%); */
  }
`;

export const ContactTextStyled = styled.div`
  font-size: 0.5rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 300;
  z-index: 1;
  /* color: white; */
  color: #333;
  display: inline-block;
  margin-left: 1vw;
  margin-right: 1vw;
  @media (min-width: 600px) {
    font-size: 1rem;
  }
`;

export const CardWrapper = styled.div`
  width: 88.4vw;
  border-radius: 15px;
  box-shadow: 4px 4px 10px rgba(92, 92, 92, 0.15);
  flex-direction: column;
  margin: 0vh auto 0vh auto;
  background: #f5f5f5;
  display: flex;
  padding: 15px 0px;
  //margin-bottom: 12vh;
  @media (min-width: 600px) {
    width: 23.95vw;
    min-width: 450px;
    //margin: 0vh auto 20vh auto;
  }
`;

export const TelCardWrapper = styled.div`
  width: 88.4vw;
  border-radius: 15px;
  box-shadow: 4px 4px 10px rgba(92, 92, 92, 0.15);
  flex-direction: column;
  margin: 0vh auto 0vh auto;
  background: #f5f5f5;
  display: flex;
  padding: 15px 0px;
  margin-bottom: 2vh;
  @media (min-width: 600px) {
    width: 38vw;
    min-width: 450px;
    //margin: 0vh auto 20vh auto;
  }
`;