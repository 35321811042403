import React, { useState, useEffect } from "react";
import styled from "styled-components";
import PackageComponent from "../components/PackageComponent";
import fluidText from "../utils/fluidText";

import { DefaultValue, useRecoilState } from "recoil";
import {
  state as stateAtom,
  formList as formListAtom,
  orderID as orderIDAtom,
  productPrice as productPriceAtom,
  nProduct as nProductAtom,
} from "../global/atom";
import { Default } from "react-spinners-css";
import {
  sessionOrderID,
  sessionPrice,
  sessionState,
  session_nProducts,
  useSessionStorage,
} from "../utils/sessionStorage";
import { useNavigate } from "react-router-dom";
import useNavigationHandling from "../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";
import { handleFontFamily } from "../utils/utils";

const TextStyled = styled.div`
  width: 88.46vw;
  margin: 4vh auto 3vh auto;
  ${fluidText(22, 30)}
  //font-size: 1.5rem; 
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: left;
  @media (min-width: 600px) {
    //width: 38vw;
    //font-size: 1.8rem;
    margin: 4vh auto 6vh auto;
  }
`;

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 600px) {
    width: 40vw;
    margin: auto;
    min-width: 450px;
  }
  //align-items:center;
`;

const PackageSelectPage = () => {
  const [productPrice, updateProductPrice] = useSessionStorage(
    sessionPrice.key,
    sessionPrice.default
  );
  const [state, updateState] = useRecoilState(stateAtom);
  //const [nProduct,updateNProduct] = useRecoilState(nProductAtom)
  const [nProduct, updateNProduct] = useSessionStorage(
    session_nProducts.key,
    session_nProducts.default
  );
  const [onSelectProduct, updateOnSelectProduct] = useState(false);
  const { t } = useTranslation();
  const { navigateHandler } = useNavigationHandling();
  useEffect(() => {
    updateProductPrice("");
    updateNProduct("");
    updateState("");
  }, []);

  useEffect(() => {
    if (productPrice != "" && nProduct != "" && onSelectProduct) {
      navigateHandler("/cart");
    }
  }, [productPrice, nProduct, onSelectProduct]);

  return (
    <ContentWrapper>
      <TextStyled>{t("เลือกแพคเกจที่ต้องการซื้อ")}</TextStyled>
      <PackageComponent
        npicture={1}
        price={59}
        updateProductPrice={() => updateProductPrice(59)}
        updateNProduct={updateNProduct}
        onClick={() => updateOnSelectProduct(true)}
      />
      <PackageComponent
        npicture={3}
        price={150}
        updateProductPrice={() => updateProductPrice(150)}
        updateNProduct={updateNProduct}
        onClick={() => updateOnSelectProduct(true)}
      />
    </ContentWrapper>
  );
};

export default PackageSelectPage;
