import React, { useEffect, useState } from "react";
import useLongPress from "../utils/useLongPress";

import styled from "styled-components";

const GalleryCardWrapper = styled.div`
  //background-color:#F9F9F9;
  width: 100%;
  margin: auto;
  //border-radius:15px;
  margin: 0vh auto 0vh auto;
  //box-shadow: 4px 4px 10px #D1D9E6;
  //border-radius: 15px;
  display: flex;
  pointer-events: ${(props) => (props.isPreview ? "none" : "auto")};
  @media (min-width: 600px) {
    width: 90%;
    max-width: 450px;
    margin: ${(props) => (props.isCheckout ? "1vh auto 0vh auto" : "1vh auto 5vh auto")};
  }
`;

const GalleryWrapper = styled.div`
  display: flex;
  //align-items: center;
  flex-direction: ${(props) => (props.isCheckout ? "row" : "column")};
  flex-wrap: wrap;
  margin: 4vw auto 0 auto;
  width: 100%;
  align-items: ${(props) => (props.position === "right" ? "flex-start" : "flex-end")};
  justify-content: center;
  //justify-content: space-evenly;
  //padding : 0.75vw;
  @media (min-width: 600px) {
    width: 100%;
    max-width: 430px;
    margin: 2vw auto 0 auto;
    //padding : 0.25vw;
  }
`;

const ImageWrapper = styled.div`
  width: ${(props) => (props.isCheckout ? "auto" : "90%")};
  height: fit-content;
  display: inline-block;
  cursor: pointer;
  margin-bottom: 3vw;
  position: relative;

  @media (min-width: 600px) {
    width: ${(props) => (props.isCheckout ? "auto" : "100%")};
    /* min-width: 450px; */
    /* margin: 1vh auto 5vh auto; */
    margin-bottom: 1vh;
  }
`;

const GalleryList = ({ items, onLongPressItem, onClickItem, isPreview, isCheckout, position }) => {
  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  return (
    <GalleryCardWrapper isPreview={isPreview} isCheckout={isCheckout}>
      <GalleryWrapper isCheckout={isCheckout} position={position}>
        {items?.map((item, index) => {
          return (
            <GalleryListItem
              key={index}
              item={item}
              index={index}
              onLongPress={onLongPressItem}
              onClick={onClickItem}
              defaultOptions={defaultOptions}
              isCheckout={isCheckout}
            />
          );
        })}
      </GalleryWrapper>
    </GalleryCardWrapper>
  );
};

const GalleryListItem = ({ item, index, onLongPress, onClick, defaultOptions, isCheckout }) => {
  const longpress = useLongPress(onLongPress, onClick, index, defaultOptions);

  return (
    <ImageWrapper isCheckout={isCheckout} {...longpress}>
      {item}
    </ImageWrapper>
  );
};

export default React.memo(GalleryList);
