import React from "react";
import styled from "styled-components";

import fluidText from "../utils/fluidText";
import { fluidRange } from "polished";
import { handleFontFamily } from "../utils/utils";
import { useTranslation } from "react-i18next";

const ButtonStyled = styled.button`
  /* Adapt the colors based on primary prop */
  background: ${(props) =>
    props.disable ? "#C4C4C4" : props.selected ? "#AA0E19" : "white"};
  color: ${(props) =>
    props.disable ? "white" : props.selected ? "#C8B280" : "#000000"};
  pointer-events: ${(props) => (props.disable ? "none" : "initial")};
  padding: 1vh 15px;
  border-radius: 30px;
  margin: 2.5px;
  ${handleFontFamily()}
  font-weight: 400;
  width: ${(props) => (props.width ? props.width : "auto")};

  text-align: center;
  cursor: pointer;
  border: none;
  filter: ${(props) =>
    props.disable
      ? "none"
      : props.selected
      ? "none"
      : "drop-shadow(2px 2px 10px #D1D9E6)"};
  ${fluidText(14, 18)}
  //min-width: 120px;
    @media(min-width:600px) {
    //min-width: 170px;
    ${fluidRange(
      {
        prop: "font-size",
        fromSize: "16px",
        toSize: "19px",
      },

      "600px",
      "1800px"
    )}//font-size :1.1rem;;;
  }
`;

const FilterButton = ({
  value,
  filter,
  addFilter,
  removeFilter,
  isChecked = false,
  isDisable = false,
}) => {
    const {t} = useTranslation();

  const handleChange = () => {
    if (!isChecked) {
      addFilter(filter);
    } else {
      removeFilter(filter);
    }
  };
  return (
    <ButtonStyled
      type="button"
      onClick={handleChange}
      selected={isChecked}
      disable={isDisable}
    >
      {t(`FilterComponent.${value}`)}
    </ButtonStyled>
  );
};

export default FilterButton;
