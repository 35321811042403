import React, { useEffect, useState } from "react";
import styled from "styled-components";
import backgroundImg from "../../assets/home/background_tiger4.png";
import { BrowserRouter, Route, useNavigate, useLocation, Routes } from "react-router-dom";

import { use100vh } from "react-div-100vh";
import fluidText from "../../utils/fluidText";
import { checkHasPurchasedHistory, getMaxOrderIndex } from "../../utils/IndexedDBStore";
import TagManager from "react-gtm-module";

import "./styles.css";
import IntroPage from "./IntroPage";
import PackagePage from "./PackagePage";
import HomePageMain from "./HomePage";
import {session_is_preview_image, useSessionStorage } from "../../utils/sessionStorage";
import AisCollectionGroup from "./AisCollectionGroup";
import { handleFontFamily } from "../../utils/utils";

const BGStyled = styled.div`
  /* width: 100vw; */
  /* height: 100%; */
  /* background-image: url(${backgroundImg});
  background-position: center center;
  background-size: cover; */
  //position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  touch-action: none;
  position: relative;
  -ms-touch-action: none;
  padding-top: 3rem;
  /* height: 100%; */
  @media (min-width: 600px) {
    width: 100vw;
    min-width: 500px;
    margin: auto;
    padding-top: 3rem;
  }
`;

const GradientBG = styled.div`
  width: 100vw;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  //background-image: linear-gradient(to top,rgba(0,0,0,0.65),rgba(0,0,0,0));
  background-color: rgba(0, 0, 0, 0.35);
`;

const MainTextStyled = styled.div`
  /* font-size: 2.0rem;  */
  ${fluidText(25, 40)}
  ${handleFontFamily()}
  font-weight: 600;
  letter-spacing: 0.2rem;
  z-index: 1;
  color: white;
  margin-top: 5vh;
  /* margin: ${(props) => props.height * 0.25 + "px"} auto 0 auto; */
`;

const DescriptionTextStyled = styled.div`
  /* font-size: 1.2rem;  */
  ${fluidText(16, 25)}

  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  z-index: 1;
  text-align: center;
  margin: ${(props) => props.height * 0.1 + "px"} auto ${(props) => props.height * 0.1 + "px"} auto;
  color: white;
  @media (min-width: 600px) {
    font-weight: 300;
  }
`;

const LuckyDoseLogo = styled.img`
  width: 61vw;
  height: auto;
  z-index: 1;
  //margin: 10vh auto 4vh auto;
  margin: ${(props) => props.height * 0.1 + "px"} auto ${(props) => props.height * 0.02 + "px"} auto;
  @media (min-width: 600px) {
    width: 20vw;
    min-width: 280px;
  }
`;

/**
 * page state
 * home
 * intro
 * package
 * @returns
 */

const Homepage = () => {
  
  const [, set_is_preview] = useSessionStorage(
    session_is_preview_image.key,
    session_is_preview_image.default
  );

  useEffect(() => {
    // enable scroll
    let body = document.body;
    body.style.overflow = "auto";
    let root = document.getElementById("root");
    root.style.overflow = "auto";
    set_is_preview(false);
  }, []);

  return (
    <>
      {/* {location.pathname.includes("package") && <NavLogo />} */}

      <BGStyled>
        {/* <NavLogo /> */}
        {/* <GradientBG></GradientBG>
      <LuckyDoseLogo src={luckyDoseLogo} height={height} />
      <DescriptionTextStyled height={height}>
        ภาพพื้นหลัง ตามพื้นดวง เสริมฮวงจุ้ย
        <br />
        ออกแบบชิ้นต่อชิ้นสำหรับคุณโดยเฉพาะ
        <br />
        ตามวันเกิด ราศี ปีนักษัตร
      </DescriptionTextStyled>

      <DoseartButton
        isPrimary={true}
        isDisable={false}
        text={"สั่งซื้อภาพพื้นหลัง"}
        width={parseInt(width) < 600 ? "55vw" : "20vw"}
        handleOnClick={() => navigateHandler("/package")}
      ></DoseartButton>

      {showPurchasedButton ? (
        <div style={{ margin: "2vh 0", zIndex: "2" }}>
          <DoseartButton
            isPrimary={false}
            isDisable={false}
            text={"ประวัติการสั่งซื้อ"}
            width={parseInt(width) < 600 ? "55vw" : "20vw"}
            handleOnClick={() => navigateHandler("/purchasedHistory")}
          />
        </div>
      ) : (
        <></>
      )} */}

        {/* section
      div
      new-alert
      title
      description
      links-wrapper
      learnmore | buy
       */}
        <Routes>
          <Route exact path="/" element={<HomePageMain/>} />
          <Route path="/intro" element={<IntroPage/>} />
          <Route path="/package" element={<PackagePage/>} />
        </Routes>
      </BGStyled>
    </>
  );
};

export default Homepage;
