import React, { useContext, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { HeadTextStyled } from "../HomePage/styles";
import { state as stateAtom, formTypeDatabase } from "../../global/atom";
import TextFieldCard from "../../components/TextFieldCard";
import {
  sessionFormType,
  sessionRefID,
  session_subscription_selection_artwork,
  session_subscription_selection_package,
  session_subscription_user,
  useSessionStorage,
} from "../../utils/sessionStorage";
import { useNavigate } from "react-router";
import MobileImg from "../../assets/subscription/mobile_no.svg";
import NumberImg from "../../assets/subscription/number.svg";
import SubtractImg from "../../assets/subscription/subtract.svg";
import TrickImg from "../../assets/subscription/trick.svg";
import Disable_NumberImg from "../../assets/subscription/disable_number.svg";
import LockImg from "../../assets/subscription/lock.svg";
import Disable_Subtract from "../../assets/subscription/disable_Subtract.svg";
import Disable_MobileImg from "../../assets/subscription/disable_mobile_no.svg";
import {
  ImageWrapper,
  Image,
  GalleryLeft,
  GalleryRight,
} from "../HomePage/styles";
import fluidText from "../../utils/fluidText";
import useLongPress from "../../utils/useLongPress";
import { UserContext } from "../../utils/Context/userContext";
import {
  GCLOUDSTORAGEURL_SUBSCRIPTION,
  getDateText,
  getPrediction,
  getThaiMoonPhase,
  getUserPackage,
  handleFontFamily,
  sendMessageToCreateArtwork,
} from "../../utils/utils";
import CanvasComponent from "../../components/CanvasComponent";
import LargePreviewComponent from "../../components/LargePreviewComponent";
import LoaderComponent from "../../components/LoaderComponent";
import { dateDatabase as dateDatabaseAtom } from "../../global/atom";
import { PredictionText } from "./SubscriptionArtworkPage";
import useNavigationHandling from "../../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";

let isTouchmove = false;
const isTouchEvent = (event) => {
  return "touches" in event;
};
const preventDefault = (event) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

const SelectionButton = styled.button`
  width: 90%;
  max-width: 400px;
  height: 6rem;
  margin: 0vh auto 2vh auto;
  border: none;
  border-radius: 16px;

  font-size: 32px;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: normal;
  color: ${(props) =>
    props.buttonType
      ? props.buttonType == "disabled"
        ? "#A8A8A8"
        : "white"
      : "white"};
  cursor: ${(props) => (props.buttonType != "disabled" ? "pointer" : "none")};
  pointer-events: ${(props) =>
    props.buttonType != "disabled" ? "auto" : "none"};
  background-color: ${(props) =>
    props.buttonType
      ? props.buttonType == "disabled"
        ? "#C4C4C4"
        : props.buttonType == "updated"
        ? "#C8B280"
        : props.buttonType == "updated"
        ? "#C8B280"
        : "#aa0e19"
      : "#aa0e19"};
  box-shadow: 0px 4px 16px rgba(92, 92, 92, 0.2);

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0;
  div {
    width: 97%;
    height: 86%;
    border: ${(props) =>
      props.buttonType
        ? props.buttonType == "disabled"
          ? "#A8A8A8 1px solid"
          : "white 1px solid"
        : "white 1px solid"};
    border-radius: 12px;

    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;

    div {
      position: relative;
      width: 3rem;
      height: 3rem;
      margin: 1rem;
    }
    img {
      width: 3rem;
      height: auto;
      margin: 1rem;
    }
    h3 {
      margin-left: 1rem;
      font-size: 1.2rem;
      ${handleFontFamily()}
      font-style: normal;
      font-weight: normal;
      line-height: 100%;
      @media (min-width: 400px) {
        margin-left: 1rem;
      }
    }
    span {
      width: auto;
      margin: 0;
      height: 85%;

      text-align: left;
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      h3 {
        margin: 0;
        font-size: 1.8rem;
        ${handleFontFamily()}
        font-style: normal;
        font-weight: normal;
        line-height: 100%;
      }
      p {
        margin: 0;
        font-size: 1.2rem;
        ${handleFontFamily()}
        font-style: normal;
        font-weight: 300;
        line-height: 100%;
      }
    }
  }

  :hover {
    /* background-color: black; */
    cursor: pointer;
    //color: #c8b280;
  }
  /* transition: all 0.5s; */
`;

const DownloadImage = styled.img`
  //height: 55vh;
  width: 23vh;
  border-radius: 10px;
  margin: 0 auto 0 auto;
  pointer-events: ${(props) => (props.isPreview ? "none" : "auto")};
  cursor: pointer;
`;

export const GalleryCardWrapper = styled.div`
  width: 95%;
  height: auto;
  margin: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-bottom: 1vh;
  pointer-events: ${(props) => (props.isPreview ? "none" : "auto")};
  @media (min-width: 600px) {
    width: 100%;
    max-width: 450px;
    margin: 3vh auto 1vh auto;
  }
`;

const TextLabelWrapper = styled.p`
  //width:88.46vw;
  margin: 2vh auto 2vh auto;
  max-width: 400px;
  width: 90%;
  ${fluidText(16, 20)}
  //font-size: 1.5rem; 
    ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: left;
  white-space: pre-line;
`;
const TextBulletWrapper = styled.li`
  ${fluidText(13, 16)}
`;

const TextUpdatePrediction = styled.p`
  //width:88.46vw;
  margin: 2vh auto 0.5vh auto;
  max-width: 400px;
  width: 90%;
  ${fluidText(12, 16)}
  //font-size: 1.5rem; 
    ${handleFontFamily()}
  font-style: ${(props) => (props.status == "updated" ? "italic" : "normal")};
  font-weight: ${(props) => (props.status == "updated" ? "500" : "300")};
  color: ${(props) => (props.status == "updated" ? "#C8B280" : "black")};
  text-align: left;
  white-space: pre-line;
`;

const SubscriberPackage = () => {
  const [preview, updatePreview] = useState("");
  const [previewType, updatePreviewType] = useState();
  const [prediction, updatePrediction] = useState();
  const [isPreview, set_isPreview] = useState(false);
  const [isPreviewArtwork, updatePreviewArtwork] = useState(false);
  const [isPreviewGenerateArtwork, updatePreviewGenerateArtwork] =
    useState(false);
  const [isLoading, updateLoading] = useState(false);
  const [selectPackageDetail, updateSelectedPackageDetail] = useSessionStorage(
    session_subscription_selection_package.key,
    session_subscription_selection_package.default
  );
  const [dateDatabase] = useRecoilState(dateDatabaseAtom);
  const [selectedArtwork, updateSelectedArtwork] = useSessionStorage(
    session_subscription_selection_artwork.key,
    session_subscription_selection_artwork.default
  );
  const [nextUrl, updateNextUrl] = useState("");
  const [filtered_artwork_db, update_filter_artwork] = useState([]);
  const [subscriptionUser] = useSessionStorage(
    session_subscription_user.key,
    session_subscription_user.default
  );
  const [referenceID, updateRefID] = useSessionStorage(
    sessionRefID.key,
    sessionRefID.default
  );
  const {
    subscriberInfo,
    updateSubscriberInfo,
    contextAWDb,
    setOnShowPreview,
  } = useContext(UserContext);
  const [showImages, updateShowImages] = useState([]);
  const [weekDate, updateWeekDate] = useState([]);
  const { t,i18n } = useTranslation();
  const { navigateHandler } = useNavigationHandling();
  var gCloudStorageURL = GCLOUDSTORAGEURL_SUBSCRIPTION;
  useEffect(() => {
    if (!subscriptionUser || !subscriptionUser.phone_number)
      navigateHandler("/aispostpaid/login");
    getUserPackage(selectPackageDetail.id, subscriptionUser.phone_number).then(
      (user_package) => {
        var detail = selectPackageDetail;
        detail.generated_id = user_package.generated_image_id;
        detail.generated_timestamp = user_package.generated_timestamp;
        detail.psd_file = user_package.psd_file;
        updateSelectedPackageDetail({ ...detail });
        getPrediction(
          subscriberInfo.birthDate,
          subscriberInfo.birthMonth,
          subscriberInfo.birthYear,
          subscriptionUser.phone_number,
          selectPackageDetail.timestamp,
          detail.generated_timestamp
        ).then((result) => {
          updatePrediction(result);
          //updateShowImages(result.monthly)
          if (!detail.generated_id && selectPackageDetail.artworks) {
            var filter_aw = contextAWDb.filter((aw) =>
              selectPackageDetail.artworks.includes(aw.id)
            );
            update_filter_artwork(filter_aw);
          } else {
            contextAWDb.forEach((aw) => {
              if (aw.filename == detail.psd_file) {
                var temp = [
                  {
                    title: t("ฤกษ์ในการตั้งวอลเปเปอร์"),
                    src: result.lucky_wallpaper,
                  },
                  { title: t("บทสวด"), src: aw.prayer },
                  { title: t("คำอธิบายรายละเอียด"), src: aw.description },
                  { title: t("อักษรรูน"), src: aw.rune },
                ];
                temp = temp.filter((image) => image.src != "");
                update_filter_artwork([...temp]);
              }
            });
          }
        });
      }
    );

    getFirstDayOfEachWeek();

    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);
    return () => {
      setOnShowPreview(false);
      enableScroll();
      window.removeEventListener("touchmove", preventDefault, {
        passive: true,
      });
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);

  const handleTouchMove = () => {
    isTouchmove = true;
  };
  const handleTouchEnd = () => {
    isTouchmove = false;
  };

  useEffect(() => {
    if (isPreview || isPreviewGenerateArtwork || isPreviewArtwork) {
      disableScroll();
      setOnShowPreview(true);
    } else if (!(isPreview && isPreviewGenerateArtwork && isPreviewArtwork)) {
      enableScroll();
      setOnShowPreview(false);
    }
  }, [isPreview, isPreviewArtwork, isPreviewGenerateArtwork]);

  const onClickImage = (index) => {
    if (!(isPreview || isPreviewArtwork || isPreviewGenerateArtwork)) {
      updatePreview(index);
      updatePreviewArtwork(true);
      disableScroll();
    }
  };

  const disableScroll = () => {
    let root = document.getElementById("root-div100");
    root.style.overflow = "hidden";
  };
  const enableScroll = () => {
    // window.onscroll = function () {};

    let root = document.getElementById("root-div100");
    root.style.overflow = "scroll";
  };

  useEffect(() => {
    if (selectedArtwork && nextUrl) {
      createWaterMarkArtwork();
    }
  }, [selectedArtwork]);

  const createWaterMarkArtwork = async () => {
    getThaiMoonPhase(
      subscriberInfo.birthYear-543,
      subscriberInfo.birthMonth + 1,
      subscriberInfo.birthDate,
      dateDatabase
    ).then((moonPhase) => {
      var day_of_week = new Date(
        subscriberInfo.birthYear-543,
        subscriberInfo.birthMonth,
        subscriberInfo.birthDate
      ).getDay();
      sendMessageToCreateArtwork(
        selectedArtwork.filename,
        "true",
        subscriberInfo.name,
        subscriberInfo.surname,
        subscriberInfo.birthDate.toString(),
        subscriberInfo.birthMonth.toString(),
        subscriberInfo.birthYear.toString(),
        subscriberInfo.zodiacId.toString(),
        day_of_week.toString(),
        moonPhase.toString(),
        [],
        subscriberInfo.chinese_zodiac.toString()
      ).then((returnValue) => {
        updateRefID(returnValue);
        navigateHandler(nextUrl);
      });
    });
  };

  const getFirstDayOfEachWeek = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const firstDayOfMonth = new Date(year, month - 1, 1);
    const firstWeekStart = new Date(firstDayOfMonth);
    firstWeekStart.setDate(
      firstDayOfMonth.getDate() - firstDayOfMonth.getDay()
    );
    let weekStart = new Date(firstWeekStart);
    //handle week start at last week of december
    var temp = [new Date(weekStart.getTime())];
    weekStart.setDate(weekStart.getDate() + 7);
    while (weekStart.getMonth() <= month - 1) {
      temp.push(new Date(weekStart.getTime()));
      weekStart.setDate(weekStart.getDate() + 7);
    }
    updateWeekDate([...temp]);
  };

  const ItemArray = () => {
    let colLeft = [];
    let colRight = [];
    filtered_artwork_db.map((item, idx) => {
      if (filtered_artwork_db.length === 1) {
      }
      if (idx % 2 === 0) {
        colLeft = [
          ...colLeft,
          <ImageWrapperComponent
            updatePreview={onClickImage}
            item={item}
            idx={idx}
            key={"image_id" + idx}
            isDownload={selectPackageDetail.generated_id != null}
          />,
        ];
      } else {
        colRight = [
          ...colRight,
          <ImageWrapperComponent
            updatePreview={onClickImage}
            item={item}
            idx={idx}
            key={"image_id" + idx}
            isDownload={selectPackageDetail.generated_id != null}
          />,
        ];
      }
    });

    return [colLeft, colRight];
  };

  const handleClickSelectionButton = (type) => {
    updatePreviewType(type);
    if (type == "monthly") {
      updateShowImages(prediction.monthly);
      set_isPreview(true);
    } else if (type == "weekly_moo") {
      updateShowImages([prediction.weekly_moo]);
      set_isPreview(true);
    } else if (type == "boost_lucky") {
      updateShowImages([prediction.boost_lucky]);
      set_isPreview(true);
    } else if (type == "lucky_4_number") {
      updateShowImages([prediction.lucky_4_number]);
      set_isPreview(true);
    }
  };

  const downloadImage = async (item) => {
    var url = item.src;
    const res = await fetch(url);
    const blob = await res.blob();
    const downloadUrl = URL.createObjectURL(blob);
    const anchor = document.createElement("a");
    anchor.href = downloadUrl;
    anchor.download = `${item.title}.jpg`;
    anchor.target = "_blank";
    anchor.click();
  };

  const ImageWrapperComponent = ({ updatePreview, idx, item, isDownload }) => {
    const defaultOptions = {
      shouldPreventDefault: true,
      delay: 500,
    };

    const onLongPress = (i) => {};

    const onClick = (i) => {
      if (!isTouchmove) {
        updatePreview(idx + 1);
      }
      isTouchmove = false;
    };

    const longpress = useLongPress(onLongPress, onClick, idx, defaultOptions);
    return (
      <ImageWrapper {...longpress}>
        <Image src={isDownload ? item.src : item && item.thumbnailPath} />
        {item.title && <PredictionText>{item.title}</PredictionText>}
      </ImageWrapper>
    );
  };
  const SendingMessageState = () => {
    return <LoaderComponent />;
  };

  const getLocaleYear= (year)=>{
    return i18n.language==='th'?year+543:year
  }

  const PredictionButton = (type, isEnable) => {
    var checkUpdateStatus = checkLastUpdate(type, isEnable);
    return (
      <>
        <TextUpdatePrediction status={checkUpdateStatus.status}>
          {checkUpdateStatus.text}
        </TextUpdatePrediction>
        <SelectionButton
          buttonType={isEnable ? checkUpdateStatus.status : "disabled"}
          onClick={
            isEnable &&
            checkUpdateStatus.status != "disabled" &&
            !(isPreview || isPreviewArtwork || isPreviewGenerateArtwork)
              ? () => handleClickSelectionButton(type)
              : () => {}
          }
        >
          <div>
            <img src={isEnable ? checkUpdateStatus.src : LockImg} />
            <h3>{checkUpdateStatus.buttonText}</h3>
          </div>
        </SelectionButton>
      </>
    );
  };

  const LuckyComponent = ()=>{
    return(
      <>
      <div
        style={{
          backgroundColor: "#C4C4C4",
          height: "2px",
          width: "90%",
          maxWidth: "450px",
          margin: "0vh auto 3vh auto",
        }}
      />
      <TextLabelWrapper>
        {t("เสริมพลังสายมู")}
        <TextBulletWrapper>
          {t("กดที่การ์ดเพื่อดูรายละเอียด")}
        </TextBulletWrapper>
      </TextLabelWrapper>
      {weekDate.length > 0 && selectPackageDetail.weekly_moo &&
        PredictionButton(
          "weekly_moo",
          selectPackageDetail && selectPackageDetail.weekly_moo
        )}
      {weekDate.length > 0 && selectPackageDetail.lucky_4_number  &&
        PredictionButton(
          "lucky_4_number",
          selectPackageDetail && selectPackageDetail.lucky_4_number
        )}
      {weekDate.length > 0 && selectPackageDetail.boost_lucky&&
        PredictionButton(
          "boost_lucky",
          selectPackageDetail && selectPackageDetail.boost_lucky
        )}
      {weekDate.length > 0 &&  selectPackageDetail.monthly_horoscope &&
        PredictionButton(
          "monthly",
          selectPackageDetail && selectPackageDetail.monthly_horoscope
        )}
      </>
    )
  }

  function checkLastUpdate(type, isEnable) {
    var today = new Date();
    var checkISSunMon = today.getDay() == 0 || today.getDay() == 1;
    if (!isEnable) {
      return {
        status: "disabled",
        text:  '',
        buttonText:
          type == "weekly_moo"
            ? t("มูทริคประจำสัปดาห์")
            : type == "monthly"
            ? t("ดวงรายเดือน")
            : type == "lucky_4_number"
            ? t("เลขมงคล 4 หลัก")
            : t("เสริมพลังเบอร์มือถือ"),
      };
    } else if (type == "weekly_moo" && checkISSunMon) {
      return {
        status: "updated",
        text: t("อัปเดต!"),
        buttonText: t("มูทริคประจำสัปดาห์"),
        src: TrickImg,
      };
    } else if (type == "weekly_moo") {
      var latest_update = weekDate[weekDate.length - 1];
      for (let i = 0; i < weekDate.length - 1; i++) {
        if (today >= weekDate[i] && today < weekDate[i + 1]) {
          latest_update = weekDate[i];
        }
      }
      return {
        status: "enabled",
        text: `${t("อัปเดตเมื่อวันที่")} ${getDateText(
          latest_update.getDate(),
          latest_update.getMonth(),
          getLocaleYear(latest_update.getFullYear()),
          t
        )}`,
        buttonText: t("มูทริคประจำสัปดาห์"),
        src: TrickImg,
      };
    } else if (type == "lucky_4_number" && today < weekDate[1]) {
      return {
        status: "disabled",
        text: `${t("จะอัปเดทวันที่")} ${getDateText(
          weekDate[1].getDate(),
          weekDate[1].getMonth(),
          getLocaleYear(weekDate[1].getFullYear()),
          t
        )}`,
        buttonText: t("เลขมงคล 4 หลัก"),
        src: Disable_NumberImg,
      };
    } else if (
      type == "lucky_4_number" &&
      today >= weekDate[1] &&
      today < weekDate[2] &&
      checkISSunMon
    ) {
      return {
        status: "updated",
        text: t("อัปเดต!"),
        buttonText: t("เลขมงคล 4 หลัก"),
        src: NumberImg,
      };
    } else if (type == "lucky_4_number")
      return {
        status: "enabled",
        text: `${t("อัปเดตเมื่อวันที่")} ${getDateText(
          weekDate[1].getDate(),
          weekDate[1].getMonth(),
          getLocaleYear(weekDate[1].getFullYear()),
          t
        )}`,
        buttonText: t("เลขมงคล 4 หลัก"),
        src: NumberImg,
      };
    else if (type == "boost_lucky" && today < weekDate[2]) {
      return {
        status: "disabled",
        text: `${t("จะอัปเดทวันที่")} ${getDateText(
          weekDate[2].getDate(),
          weekDate[2].getMonth(),
          getLocaleYear(weekDate[2].getFullYear()),
          t
        )}`,
        buttonText: t("เสริมพลังเบอร์มือถือ"),
        src: Disable_MobileImg,
      };
    } else if (
      type == "boost_lucky" &&
      today >= weekDate[2] &&
      today < weekDate[3] &&
      checkISSunMon
    )
      return {
        status: "updated",
        text: t("อัปเดต!"),
        buttonText: t("เสริมพลังเบอร์มือถือ"),
        src: MobileImg,
      };
    else if (type == "boost_lucky")
      return {
        status: "enabled",
        text: `${t("อัปเดตเมื่อวันที่")} ${getDateText(
          weekDate[2].getDate(),
          weekDate[2].getMonth(),
          getLocaleYear(weekDate[2].getFullYear()),
          t
        )}`,
        buttonText: t("เสริมพลังเบอร์มือถือ"),
        src: MobileImg,
      };
      else if (type == "monthly" && checkISSunMon)
      return {
        status: "updated",
        text: t("อัปเดต!"),
        buttonText: t("ดวงรายเดือน"),
        src: SubtractImg,
      };
    else if (type == "monthly")
      return {
        status: "enabled",
        text: `${t("อัปเดตเมื่อวันที่")} ${getDateText(
          1,
          weekDate[1].getMonth(),
          getLocaleYear(weekDate[1].getFullYear())
        )}`,
        buttonText: t("ดวงรายเดือน"),
        src: SubtractImg,
      };
  }

  return (
    <div
      style={{
        marginTop: "calc(3.2rem + 2vh)",
        height: "auto",
        marginBottom: "5vh",
      }}
    >
      {isLoading && <SendingMessageState />}
      {(isPreviewArtwork || isPreviewGenerateArtwork) && (
        <LargePreviewComponent
          previewImage={
            selectPackageDetail.generated_id
              ? isPreviewGenerateArtwork
                ? gCloudStorageURL + selectPackageDetail.generated_id
                : filtered_artwork_db[preview - 1].src
              : filtered_artwork_db[preview - 1].largeThumbnailPath
          }
          updatePreviewImage={
            isPreviewGenerateArtwork
              ? updatePreviewGenerateArtwork
              : updatePreviewArtwork
          }
          onClickSelectImage={() => {
            if (selectPackageDetail.generated_id) {
              downloadImage(
                isPreviewGenerateArtwork
                  ? {
                      src: gCloudStorageURL + selectPackageDetail.generated_id,
                      title: selectPackageDetail.generated_id,
                    }
                  : filtered_artwork_db[preview - 1]
              );
            } else {
              updateSelectedArtwork(filtered_artwork_db[preview - 1]);
              updateLoading(true);
              updateNextUrl("/aispostpaid/preview");
            }
          }}
          isDownload={selectPackageDetail.generated_id != null}
        />
      )}
      <HeadTextStyled>{selectPackageDetail.name}</HeadTextStyled>
      {isPreview && (
        <CanvasComponent
          width="520"
          previewType={previewType}
          height={
            previewType == "weekly_moo" || previewType == "boost_lucky"
              ? "520"
              : "1003"
          }
          items={showImages}
          updateShowStatus={set_isPreview}
        />
      )}
      <TextLabelWrapper>
        {t("วอลเปเปอร์ฟรีรายเดือน")}
        <TextBulletWrapper>
          {t("เลือกวอลเปเปอร์ได้ 1 ภาพ/เดือน")}
        </TextBulletWrapper>
        <TextBulletWrapper>
          {t("กดเลือกภาพที่สนใจเพื่อดูภาพขนาดใหญ่")}
        </TextBulletWrapper>
      </TextLabelWrapper>
      <GalleryCardWrapper
        isPreview={isPreview || isPreviewArtwork || isPreviewGenerateArtwork}
      >
        {filtered_artwork_db && !selectPackageDetail.generated_id && (
          <>
            <GalleryLeft position={"left"}>{ItemArray()[0]}</GalleryLeft>
            <GalleryRight position={"right"}>{ItemArray()[1]}</GalleryRight>
          </>
        )}
      </GalleryCardWrapper>
      {selectPackageDetail.generated_id && (
        <div
          style={{ display: "flex", width: "100%", flexDirection: "column" }}
        >
          <DownloadImage
            isPreview={
              isPreview || isPreviewArtwork || isPreviewGenerateArtwork
            }
            src={gCloudStorageURL + selectPackageDetail.generated_id}
            onClick={() => {
              if (
                !(isPreview || isPreviewArtwork || isPreviewGenerateArtwork)
              ) {
                disableScroll();
                updatePreviewGenerateArtwork(true);
              }
            }}
          />
          <div
            style={{
              width: "90%",
              height: "2px",
              background: "#C4C4C4",
              margin: "2vh auto",
            }}
          />
          <GalleryCardWrapper
            isPreview={
              isPreview || isPreviewArtwork || isPreviewGenerateArtwork
            }
          >
            {filtered_artwork_db && (
              <>
                <GalleryLeft position={"left"}>{ItemArray()[0]}</GalleryLeft>
                <GalleryRight position={"right"}>{ItemArray()[1]}</GalleryRight>
              </>
            )}
          </GalleryCardWrapper>
        </div>
      )}
      {(selectPackageDetail.weekly_moo||selectPackageDetail.lucky_4_number||selectPackageDetail.boost_lucky||selectPackageDetail.monthly_horoscope)&&<LuckyComponent/>}
    </div>
  );
};

export default SubscriberPackage;
