import { useState, useEffect } from 'react';

function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

export function useSessionStorage(key, defaultValue) {
  const [value, setValue] = useState(
    getSessionStorageOrDefault(key, defaultValue)
  );

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
}

// key's order kept in indexedDB
export const sessionOrderID = {
    key: 'sessionOrderID',
    default: ''
}

// order that refer to payment
export const sessionReferenceOrder = {
    key: 'sessionReferenceOrder',
    default : ''
}

// client ip address to send facebook pixel
export const sessionClientID = {
  key: 'sessionClientID',
  default : ''
}

// Customer's purchased image ID
export const sessionRefID = {
    key: 'sessionRefID',
    default: ''
}

// Customer's email not used
export const sessionContact = {
  key: 'sessionContact',
  default: ''
}

// Check is KPayment popup show
export const sessionShowKpayment = {
  key : 'isShowKPayment',
  default : false,
}

// Total amount 
export const sessionTotalAmount = {
  key : 'sessionTotalAmount',
  default : 59
}

// voucher
export const sessionVoucher = {
  key : 'sessionVoucher',
  default:''
}

// voucher expired session token
export const session_voucher_valid_key = {
  key : "session_voucher_valid_key",
  default : ''
}

// Artwork form type : default,type_1,type_2,type_3,type_4
export const sessionFormType = {
  key: 'sessionFormType',
  default : 'Default'
}

export const session_name_length = {
  key:  'sessionNameLength',
  default : {
    first_name : '',
    last_name : ''
  }
}

const formListArray = ()=>{
  var items = []
  items.push({
      fill: false,
        name:"",
        surname:"",
        birthDate:29,
        birthMonth:11,//month 0-11
        birthYear: 1994,
        zodiacId: 0,
        selectImageId:"",
        selectImage:"https://storage.googleapis.com/doseart_asia_output/20210316-153528",
        showBirthDate : true,
        chinese_zodiac:'',
        day_of_week:'',
        id:''
  })
  return items
}

// Payment Method
export const paymentMethod = {
  key: 'paymentMethod',
  default: "qr"
}

// State 
export const sessionState = {
    key: 'sessionState',
    default: ""
}

// Full price
export const sessionPrice = {
  key: 'sessionPrice',
  default : ''
}

// not used
export const sessionDateDatabase = {
  key: 'sessionDateDatabase',
  default : ''
}

// filter in power user
export const filterCheck = {
  key: 'filterCheck',
  default : ''
}

// not used
export const sessionGallerryState = {
  key: 'sessionGallerryState',
  default : 'default'
}

// detail of discount : type,amount_off,percent_off
export const discountCoupon = {
  key : 'discountCoupon',
  default : ''
}

// customise collection choosed choice
export const multiple_attribute = {
  key:'multiple_attribute',
  default : []
}

// selected package
export const select_package = {
  key : 'select_package',
  default : ''
}

// refferral
export const reffer_url = {
  key : 'reffer_url',
  default : ''
}

//package detail
export const package_name_detail = {
  key:'package_name_detail',
  default:{
    package_name: '',
    icon : ''
  }
}

// number of selected products
export const session_nProducts = {
  key: 'session_nProducts',
  default: ''
}

// index of selected card in Cart Page
export const session_select_edit_card = {
  key: 'session_select_edit_card',
  default: 0
}

// ct_a_pacakge collections detail : tag,price,select_package_name
export const session_bundle_tag_price = {
  key:'session_bundle_tag_price',
  default:''
}

// selected collection info
export const session_collection_selection_info = {
  key:'session_collection_selection_info',
  default:{}
}

// previous ct_a_package collections detail
export const session_prev_bundle_tag_price = {
  key:'session_prev_bundle_tag_price',
  default:''
}

// customise collection attribute artwork
export const session_multple_attribute_selection ={
  key:'session_multple_attribute_selection',
  default:[]
}

// customise collection selected index
export const session_multiple_index_selection ={
  key:'session_multiple_index_selection',
  default:[]
}

// customise collection selected thumbnail url
export const session_multiple_url_selection ={
  key:'session_multiple_url_selection',
  default:[]
}

// card state : package, gallery
export const session_edit_card_state = {
  key:'session_edit_card_state',
  default:''
}

// artwork index in power user
export const session_select_index = {
  key:'session_select_index',
  default:''
}

// not used
export const session_order_type_id = {
  key:'session_order_type_id',
  default:''
}

// artwork that filtered by coupon
export const session_filter_aw = {
  key:'session_filter_aw',
  default:new Set()
}

// package id
export const session_package_id_selection_atom = {
  key:'session_package_id_selection_atom',
  default:''
}

// not used
export const session_artwork_show_birthdate = {
  key:'session_artwork_show_birthdate',
  default:''
}

// state
export const session_state ={
  key:'session_state',
  default:''
}

// preview index
export const session_preview_index = {
  key:'session_preview_index',
  default:-1
}

// thumbnail path of selection artwork
export const session_select_url = {
  key: 'session_select_url',
  default:''
}

// chinese zodiac list
export const session_chinese_zodiac_list = {
  key:'session_chinese_zodiac_list',
  default:[]
}

// day of week list
export const session_day_of_week_list = {
  key:'session_day_of_week_list',
  default:[]
}

// check is preview to disable overflow on background
export const session_is_preview_image = {
  key:'session_is_preview_image',
  default:false
}

// collection type object in Package page
export const session_collection_type_object = {
  key:'session_collection_type_object',
  default: {},
}

//collection type
export const session_collection_type = {
  key:'collection_type',
  default:""
}

// previous form detail
export const session_prev_form_list = {
  key:'session_prev_form_list',
  default: [
    {
      fill: false,
      name: "",
      surname: "",
      birthDate: 29,
      birthMonth: 11,
      birthYear: 1994,
      zodiacId: 0,
      selectImageId: "",
      selectImage: "https://storage.googleapis.com/doseart_asia_output/20210316-153528",
      showBirthDate: true,
    },
  ],
}

//not used
export const session_artwork_database = {
  key: 'session_artwork_database',
  default : []
}

//not used
export const session_fetch_artwork = {
  key: 'session_fetch_artwork',
  default : []
}

// fbc cookies
export const session_fbc_cookies = {
  key:'session_fbc_cookies',
  default : ''
}

// fbp cookies
export const session_fbp_cookies = {
  key : 'session_fbp_cookies',
  default : ''
}

// customer phone number
export const session_phone_number ={
  key : 'session_phone_number',
  default: ''
}

export const session_indexUID = {
  key : 'session_indexUID',
  default : ''
}

//subscription user
export const session_subscription_user={
  key:'session_subscription_user',
  default : {
    phone_number: '',
    active_package_ids:[]
  }
}

//not used
export const session_subscription_user_package={
  key:'session_subscription_user_package',
  default:''
}

// subscription select package detail
export const session_subscription_selection_package={
  key: 'session_subscription_user_package',
  default:''
}

// subscription select artwork
export const session_subscription_selection_artwork={
  key:'session_subscription_selection_artwork',
  default:''
}