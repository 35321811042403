import {ContactLogo,ContactTextStyledWrapper,ContactLogoWrapper,ContactLogoAndTextWrapper,ContactTextStyled} from '../pages/HomePage/styles'
import emailLogo from "../assets/home/email.svg";
import lineLogo from "../assets/home/line.svg";
import facebookLogo from '../assets/home/facebookLogo.svg';
import styled from 'styled-components';
import  { LUCKYDOSE_BLACK } from "../components/NavLogo";
import { useTranslation } from "react-i18next";
import { IS_ANDROID, IS_IOS } from '../utils/support';
import { handleNewLine } from '../utils/utils';

const OnClickNewTap = styled.a`
    width:3.5vh ;
    height:3.5vh ;
@media(min-width:600px){
    width:4vh ;
    height:4vh ;
}
`
const ContactLayout = styled.div`
    flex-direction: row;
  align-items: center;
  //justify-content: flex-end;
  display: flex;
  justify-content: center;
  z-index: 1;
  /* transform: translate(-22%, 0); */
  padding: 5px 0;
  @media (min-width: 600px) {
    align-items: flex-start;
    /* transform: translate(0, 10vh); */
    margin:4vh auto 1vh auto ;
  }
`
const  LocationWrapper = styled.div`
  display:inline-block;
  background: #F9F9F9;
  color:#5C5C5C;
  padding:27px 0;
  width:100% ;
  align-items: center;

;
`

const LocationText = styled.div`
    white-space:pre-wrap ;
    font-size: 0.7rem;
    text-align: center;
    font-weight:300 ;
    @media (min-width: 600px) {
      font-size: 1rem;
      min-width:600px;
    }
`

const  ContactLayoutWrapper = styled.div`
  flex-direction:column ;
  width:100%;
`


const ContactLogoComponent = ()=>{
    const width = window.innerWidth;
    const { t } = useTranslation(); 
    return(
      <ContactLayoutWrapper>
          <ContactLayout>
              <ContactTextStyledWrapper>{LUCKYDOSE_BLACK}</ContactTextStyledWrapper>
              <ContactLogoWrapper>
                <ContactLogoAndTextWrapper>
                <OnClickNewTap href="https://www.facebook.com/Luckydose.th" target="_blank" rel="noopener noreferrer" style={{width:'4vh',height:'4vh'}}>
                  <ContactLogo
                    src={facebookLogo}
                    onClick={() => {
                      //window.location = "https://www.facebook.com/Luckydose.th";
                    }}
                  />
                  </OnClickNewTap>
                  {parseInt(width) < 600 ? <></> : <ContactTextStyled>facebook.com/Luckydose.th</ContactTextStyled>}
                </ContactLogoAndTextWrapper>
                <ContactLogoAndTextWrapper>
                <OnClickNewTap href="https://line.me/R/ti/p/@609hffrs?from=page" target="_blank" rel="noopener noreferrer" style={{width:'4vh',height:'4vh'}}>
                  <ContactLogo
                    src={lineLogo}
                    onClick={() => {
                     // window.location = "https://line.me/R/ti/p/@609hffrs?from=page";
                    }}
                  />
                   </OnClickNewTap>
                  {parseInt(width) < 600 ? <></> : <ContactTextStyled>@luckydose</ContactTextStyled>}
                </ContactLogoAndTextWrapper>
                <ContactLogoAndTextWrapper>
                <OnClickNewTap style={{width:'4vh',height:'4vh'}}>
                  <ContactLogo
                    src={emailLogo}
                    onClick={() => {
                      window.location = "mailto:contact@luckydose.co";
                    }}
                  />
                  </OnClickNewTap>
                  {parseInt(width) < 600 ? (
                    <></>
                  ) : (
                    <ContactTextStyled>contact@luckydose.co</ContactTextStyled>
                  )}
                </ContactLogoAndTextWrapper>
              </ContactLogoWrapper>
            </ContactLayout>
            {!window.location.href.includes('checkout')&&<LocationWrapper>
              <LocationText>
              {(IS_ANDROID||IS_IOS)?handleNewLine(t("บจก. โดสอาร์ต (สำนักงานใหญ่) เลขที่ 400 พื้นที่โซน B ซอยลาดพร้าว 94\\n(ปัญจมิตร) แขวงพลับพลา เขตวังทองหลาง กรุงเทพฯ 10310")):t("บจก. โดสอาร์ต (สำนักงานใหญ่) เลขที่ 400 พื้นที่โซน B ซอยลาดพร้าว 94 (ปัญจมิตร) แขวงพลับพลา เขตวังทองหลาง กรุงเทพฯ 10310")}
              </LocationText>
              </LocationWrapper>}
            </ContactLayoutWrapper>
    )
}

export default ContactLogoComponent