import React, { useContext, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import Div100vh from "react-div-100vh";
import CancelIcon from "../assets/gallery/cancelButton.svg";

import {
  CancelIconStyled,
  ImageAreaWrapper,
  LargePreviewImageWrapper,
  LargePreviewWrapper,
  PreviewButtonWrapper,
  SpinnerWrapper,
} from "../pages/GalleryPage/styles";
import DoseartButton from "./DoseartButton";
import { IS_ANDROID, IS_IOS } from "../utils/support";
import { useTranslation } from "react-i18next";
import { handleFontFamily } from "../utils/utils";

const isTouchEvent = (event) => {
  return "touches" in event;
};

const preventDefault = (event) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

const DescripitonText = styled.div`
  font-size: 1rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 300;
  color: white;

  margin-top: 0.6rem;
  display: flex;
  flex-direction: row;
`;

const PositionImageWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.useWidth ? `90vw` : `${props.vh * 0.518}px`)};
  height: ${(props) =>
    props.useWidth ? `calc(90vw / 0.518)` : `${props.vh}px`};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const LargePreviewComponent = ({
  previewImage,
  updatePreviewImage,
  onClickSelectImage,
  isDownload,
}) => {
  const [vh, updateVh] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [useWidth, setUseWidth] = useState(true);
  const [largePreviewLoading, setLoading] = useState(true);

  const { t } = useTranslation();

  useEffect(() => {
    //check width to use height or width
    if (
      (width < 600 && 1.69 * width > window.innerHeight) ||
      (width > 600 && 0.25 * width > window.innerHeight)
    ) {
      setUseWidth(false);
    }
    // scroll top
    window.scrollTo(0, 0);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("touchmove", preventDefault, {
        passive: true,
      });
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleResize = () => {
    let vh = window.innerHeight;
    updateVh(vh);
    let vw = window.innerWidth;
    setWidth(vw);
  };

  const enableScroll = () => {
    // window.onscroll = function () {};
    let root = document.getElementById("root-div100");
    root.style.overflow = "scroll";
  };

  return (
    <LargePreviewWrapper>
      <ImageAreaWrapper vh={vh}>
        <PositionImageWrapper
          vh={isDownload && IS_IOS ? vh * 0.8 : vh * 0.9}
          useWidth={width / vh < 0.518}
        >
          <div
            style={{
              maxWidth: "100%",
              maxHeight: "100%",
              width: "fit-content",
              height: "100%",
              position: "absolute",
              alignItems: "center",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <LargePreviewImageWrapper
              isDownload={isDownload ? true : false}
              IS_IOS={IS_IOS}
              src={previewImage}
              useWidth={useWidth}
              onLoad={() => setLoading(false)}
            />
            {largePreviewLoading ? (
              <SpinnerWrapper color="#E4202A" size={70} />
            ) : (
              <>
                <CancelIconStyled
                  src={CancelIcon}
                  onClick={() => {
                    updatePreviewImage(false);
                    //window.removeEventListener("scroll", preventMotion,true);
                    //window.removeEventListener("touchmove", preventMotion,true);
                    window.removeEventListener("touchmove", preventDefault, {
                      passive: true,
                    });
                    enableScroll();
                    //var htmlElement = document.querySelector("html");
                    //htmlElement.style.overflowY = 'auto';
                  }}
                />
                {isDownload && IS_IOS ? (
                  <></>
                ) : isDownload ? (
                  <PreviewButtonWrapper>
                    <DoseartButton
                      isDisable={false}
                      isPrimary={true}
                      text={t("ดาวน์โหลด")}
                      width={parseInt(width) < 600 ? "42vw" : "12.5vw"}
                      handleOnClick={onClickSelectImage}
                    />
                  </PreviewButtonWrapper>
                ) : (
                  <PreviewButtonWrapper>
                    <DoseartButton
                      isDisable={false}
                      isPrimary={true}
                      text={t("เลือกภาพนี้")}
                      width={parseInt(width) < 600 ? "42vw" : "12.5vw"}
                      handleOnClick={onClickSelectImage}
                    />
                  </PreviewButtonWrapper>
                )}
              </>
            )}
            {isDownload && IS_IOS && (
              <DescripitonText>
                {t("กดค้างที่ภาพเพื่อดาวน์โหลด")}
              </DescripitonText>
            )}
          </div>
        </PositionImageWrapper>
      </ImageAreaWrapper>
    </LargePreviewWrapper>
  );
};

export default LargePreviewComponent;
