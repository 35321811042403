import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { APP_VERSION } from "../App";
import { DBversion } from "../utils/IndexedDBStore";
import ReactEditor from "../components/ReactEditor";
import BlockJson from '../assets/blocks1.json'

const Test = () => {
  const [fetechData,updateData] = useState()

  useEffect(()=>{
    fetch('./blogs/blog_1.json').then((res)=>{
      return res.json()
    }).then((data)=>{
      updateData(data)
    })
  },[])

  return (
    <div>
      <p>App version : {APP_VERSION}</p>
      <p>Indexed DB : {DBversion}</p>
    </div>
  );
};

export default Test;
