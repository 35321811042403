import React, { useState } from "react";
import styled from "styled-components";
import NotificationIcon from "../assets/cart/noti.svg";
import ChromeSVG from "../assets/home/Chrome.svg";
import SafariSVG from "../assets/home/Safari.svg";
import link_copied from "../assets/home/link_copied.png";
import { useTranslation } from "react-i18next";
import { handleFontFamily } from "../utils/utils";

const ContentWrapper = styled.div`
  width: 100vw;
  height: 100%;
  //align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: 11;
`;

const CardWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  height: min-content;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  bottom: 0;
  border-radius: 15px;
  width: 91vw;
  //padding : 0 10px;
  flex-direction: column;
  display: flex;
  padding: 2vh 2.5vw 2vh 2.5vw;
`;

const NotificationImage = styled.img`
  width: 15.3vw;
  margin: 1vh auto;
`;

const HeaderTextStyled = styled.div`
  margin: 1vh auto 1vh auto;
  font-size: 1rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 600;
  color: black;
  text-align: center;
`;

const TextStyled = styled.p`
  font-size: 0.9rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  margin: 1vh 0 1vh 0;
`;

const SafariImg = styled.img`
  width: 13vw;
`;
const ChromeImg = styled.img`
  width: 11.5vw;
  margin: auto;
`;
const ButtonStyled = styled.button`
  background: #e4202a;
  color: white;
  font-size: 0.9rem;
  padding: 0.6vh 0;
  border-radius: 40px;
  width: 30vw;
  ${handleFontFamily()}
  font-weight: 300;
  text-align: center;
  border: 2px solid rgb(228, 32, 42);
  margin: 2vh auto 0vh auto;
`;

const LinkCopiedImage = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 5vh;
  z-index: 12;
`;

const HandleMobile = () => {
  const { t } = useTranslation();

  const handleCopyLink = (text) => {
    var dummy = document.createElement("textarea");
    // to avoid breaking orgain page when copying more words
    // cant copy when adding below this code
    // dummy.style.display = 'none'
    document.body.appendChild(dummy);
    //Be careful if you use texarea. setAttribute('value', value), which works with "input" does not work with "textarea". – Eduard
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    showConfirmationText();
  };

  const showConfirmationText = () => {
    var x = document.getElementById("txt");
    x.style.visibility = "visible";
    setTimeout(function () {
      x.style.visibility = "hidden";
    }, 1500);
  };

  return (
    <ContentWrapper>
      <LinkCopiedImage
        id="txt"
        src={link_copied}
        style={{ visibility: "hidden" }}
      />
      <CardWrapper>
        <NotificationImage src={NotificationIcon} />
        <HeaderTextStyled>
          {t("กรุณาคัดลอกลิงก์")}
          <br />
          {t("ไปเปิดในเบราว์เซอร์ที่เหมาะสมกับสมาร์ทโฟนของท่าน")}
        </HeaderTextStyled>
        <div style={{ display: "flex", alignItems: "center", margin: "auto" }}>
          <SafariImg src={SafariSVG} />
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "4vw",
              width: "42vw",
            }}
          >
            <TextStyled>
              {t("สำหรับ")}{" "}
              <a style={{ color: "red", fontWeight: "600" }}>{t("iOS")}</a>
              <br />
              {t("กรุณาเปิดด้วย")}{" "}
              <a style={{ fontWeight: "600" }}>{t("Safari")}</a>
            </TextStyled>
          </div>
        </div>
        <div style={{ display: "flex", alignItems: "center", margin: "auto" }}>
          <div style={{ display: "flex", width: "13vw", height: "13vw" }}>
            <ChromeImg src={ChromeSVG} />
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: "4vw",
              width: "42vw",
            }}
          >
            <TextStyled>
              {t("สำหรับ")}{" "}
              <a style={{ color: "red", fontWeight: "600" }}>{t("Android")}</a>
              <br />
              {t("กรุณาเปิดด้วย")}{" "}
              <a style={{ fontWeight: "600" }}>{t("Chrome")}</a>
            </TextStyled>
          </div>
        </div>
        <ButtonStyled
          onClick={() => {
            handleCopyLink(window.location.hostname);
          }}
        >
          {t("คัดลอกลิงก์")}
        </ButtonStyled>
      </CardWrapper>
    </ContentWrapper>
  );
};

export default HandleMobile;
