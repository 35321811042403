import React, { useContext, useEffect, useState } from "react";
import { Routes, useParams } from "react-router-dom";
import {
  SectionWrapper,
  NewAlert,
  TitleWrapper,
  DescriptionWrapper,
  LinkWrapper,
  TitleContainer,
  CouponSectionWrapper,
  ContactLayout,
  ContactTextStyledWrapper,
  ContactLogoWrapper,
  ContactLogoAndTextWrapper,
  ContactLogo,
  ContactTextStyled,
  BannerWrapper,
  BannerImageWrapper,
  BannerImage,
} from "../HomePage/styles";
import styled from "styled-components";
import {
  COLLECTION_API_URL,
  checkLangAvailableDatabase,
  checkRefferURL,
  checkVoucherInCampaign,
  fetchAndHandleData,
  handleFontFamily,
  handleNewLine,
} from "../../utils/utils";
import useNavigationHandling from "../../utils/useNavigationHandling";
import {
  useSessionStorage,
  reffer_url,
  session_select_edit_card,
  session_nProducts,
  session_collection_selection_info,
  discountCoupon,
} from "../../utils/sessionStorage";
import TagManager from "react-gtm-module";
import { useRecoilState } from "recoil";
import {
  collections_atom,
  fetch_collection as fetch_collection_atom,
  state as stateAtom,
  collection_tags as collection_tags_atom,
} from "../../global/atom";
import ContactLogoComponent from "../../components/ContactLogoComponent";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../utils/Context/userContext";
import NotificationIcon from "../../assets/cart/noti.svg";
import fluidText from "../../utils/fluidText";
import DoseartButton from "../../components/DoseartButton";
import LoaderComponent from "../../components/LoaderComponent";

const emptyFormFill = {
  fill: false,
  name: "",
  surname: "",
  zodiacId: "",
  birthDate: "",
  birthMonth: "",
  birthYear: "",
  showBirthDate: false,
  selectImageId: "",
  selectImage: "",
  id: "",
};

const NotificationImage = styled.img`
  width: 15.7vw;
  margin: 0vh auto;
  @media (min-width: 600px) {
    //width: 4.3vw;
    height: 6.5vh;
  }
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media (min-width: 600px) {
    width: 40vw;
    margin: auto;
    min-width: 500px;
  }
  //align-items:center;
`;

const HeadTextStyled = styled.div`
  //width:88.46vw;
  //margin: 2vh auto 2vh auto;
  margin: 2vh auto;
  ${fluidText(20, 24)}
  //font-size: 1.5rem; 
    ${handleFontFamily()}
  font-style: normal;
  font-weight: 500;
  color: black;
  text-align: center;
  @media (min-width: 600px) {
    //width: 38vw;
    //margin: 3vh auto 3vh auto;
  }
`;

const RedeemLandingPage = () => {
  const { partner_id, campaign_id } = useParams();
  const { navigateHandler } = useNavigationHandling();
  const [session_reffer_url, update_session_reffer_url] = useSessionStorage(
    reffer_url.key,
    reffer_url.default
  );
  const [collection_tags, update_collection_tags] =
    useRecoilState(collection_tags_atom);
  const [collections, update_collections] = useRecoilState(collections_atom);
  const [fetch_collection, update_fetch_collection] = useRecoilState(
    fetch_collection_atom
  );
  const { t, i18n } = useTranslation();
  const width = window.innerWidth;
  const [, updateEditCard] = useSessionStorage(
    session_select_edit_card.key,
    session_select_edit_card.default
  );
  const [, updateNProducts] = useSessionStorage(
    session_nProducts.key,
    session_nProducts.default
  );
  const [collection_selection_info, update_collection_selection_info] =
    useSessionStorage(
      session_collection_selection_info.key,
      session_collection_selection_info.default
    );
  const [changePage_link, set_changePage_link] = useState("");
  const {
    voucher,
    couponCollection,
    updateFormList,
    updateCouponCollection,
    updateCouponProducts,
    setContextAWDb,
    update_voucher,
    fetchArtwork,
  } = useContext(UserContext);
  const [discount_coupon, update_discount_coupon] = useSessionStorage(
    discountCoupon.key,
    discountCoupon.default
  );
  const [voucherReachLimit, setVoucherReachLimit] = useState(false);
  const [isLoading,updateLoading] = useState(true)

  const fetchCollection = async () =>
    new Promise((resolve, reject) => {
      var temp_collection_tags_data;
      fetchAndHandleData(COLLECTION_API_URL + "/collection_tags/").then(
        (collection_tags) => {
          if (collection_tags.data) {
            temp_collection_tags_data = collection_tags.data;
            update_collection_tags(temp_collection_tags_data);
            if (Object.keys(fetch_collection).length === 0) {
              fetchAndHandleData(COLLECTION_API_URL + "/collections/").then(
                (collections) => {
                  update_fetch_collection(collections.data);
                  if (collections) {
                    var temp_collections = collections.data.concat(
                      temp_collection_tags_data
                    );
                    let sortable = temp_collections.map((item, index) => [
                      checkLangAvailableDatabase(item.collection_order),
                      index,
                    ]);
                    sortable.sort(function (a, b) {
                      return a[0] - b[0];
                    });
                    let sorted = {};
                    for (let i = 0; i < sortable.length; i++) {
                      if (
                        parseInt(
                          checkLangAvailableDatabase(
                            temp_collections[sortable[i][1]].collection_order
                          )
                        ) > 0 &&
                        (temp_collections[sortable[i][1]]
                          .group_collection_tag == "-" ||
                          !temp_collections[sortable[i][1]]
                            .group_collection_tag)
                      ) {
                        sorted[sortable[i][0]] =
                          temp_collections[sortable[i][1]];
                      }
                    }
                    update_collections(sorted);
                    resolve("update complete");
                  } else {
                    console.error("error: cannot getting data from server");
                    reject("update error");
                  }
                }
              );
            }
          }
        }
      );
    });

  const filterProductsByVoucher = (metadata_products) => {
    var result_products = [];
    metadata_products.forEach((product) => {
      fetchArtwork.forEach((aw) => {
        if (aw.id === product) {
          result_products.push(aw);
        }
      });
    });
    setContextAWDb(result_products);
  };

  useEffect(() => {
    if (changePage_link && collection_selection_info.collection_name) {
      navigateHandler("/redeem" + changePage_link);
    }
  }, [collection_selection_info]);

  useEffect(() => {
    if (
      couponCollection !== null &&
      couponCollection !== undefined &&
      couponCollection.length > 0 &&
      fetch_collection.length !== 0
    ) {
      var filter_collections = fetch_collection.filter((t_collection) =>
        couponCollection.includes(t_collection.id)
      );
      let sortable = filter_collections.map((item, index) => [
        checkLangAvailableDatabase(item.collection_order),
        index,
      ]);
      let sorted = {};
      for (let i = 0; i < sortable.length; i++) {
        sorted[sortable[i][0]] = filter_collections[sortable[i][1]];
      }
      update_collections(sorted);
    }
  }, [fetch_collection, couponCollection]);

  const handleChangePage = (collection_obj) => {
    update_collection_selection_info(collection_obj);
    set_changePage_link(collection_obj.link);
  };

  useEffect(() => {
    //check partner id
    if (fetchArtwork.length > 0) {
      fetchCollection();
      if (partner_id && campaign_id) {
        checkRefferURL(partner_id).then((res) => {
          if (res.result) {
            update_session_reffer_url(partner_id);
            TagManager.dataLayer({
              dataLayer: {
                event: "pageview",
                refID: partner_id,
              },
            });
          } else {
            navigateHandler("/home");
          }
        });
        //check coupon
        checkVoucherInCampaign(campaign_id).then((result) => {
          if (result.status) {
            update_voucher(result.voucher);
            update_discount_coupon(result.discount);
            if (result.metadata && result.metadata.products) {
              updateCouponProducts(result.metadata.products);
              filterProductsByVoucher(result.metadata.products);
            }
            if (result.metadata && result.metadata.collections) {
              updateCouponCollection(result.metadata.collections);
            } else if (
              result.metadata &&
              result.metadata.collections === null
            ) {
              updateCouponCollection(null);
            }
          }
          //handle status full
          else setVoucherReachLimit(true);
          updateLoading(false)
        });
      } else if (!voucher) {
        navigateHandler("/home");
      }
      else{
        updateLoading(false)
      }
    }
  }, [fetchArtwork]);

  const LimitRedemption = () => {
    return (
      <ContentWrapper>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "80vw",
          }}
        >
          <NotificationImage src={NotificationIcon} />
          <HeadTextStyled>
            {t("ขออภัย สิทธิ์เต็มแล้ว")}
            <br />
            {t("สามารถติดตามแคมเปญในครั้งถัดไป ได้ที่ไลน์นี้")}
          </HeadTextStyled>
          <DoseartButton
            text={t("สั่งซื้อวอลเปเปอร์")}
            isPrimary={true}
            width={parseInt(width) < 600 ? "85vw" : "20vw"}
            handleOnClick={() => {
              navigateHandler("/home");
            }}
          />
        </div>
      </ContentWrapper>
    );
  };

  return (
    <>
      {isLoading?<>
        <LoaderComponent/>
      </>
      :collections &&
        !voucherReachLimit &&
        Object.keys(collections).map((item, index) =>
          collections[item].collection_name != "Coupon" ? (
            <BannerWrapper first_section={index === 0}>
              <BannerImageWrapper>
                <BannerImage
                  src={
                    width <= 735
                      ? checkLangAvailableDatabase(collections[item].jpg_small)
                      : width <= 1068
                      ? checkLangAvailableDatabase(collections[item].jpg_med)
                      : checkLangAvailableDatabase(collections[item].jpg_large)
                  }
                  loading="lazy"
                />
              </BannerImageWrapper>
              <TitleContainer>
                {collections[item].red_text ? (
                  <NewAlert>{t(collections[item].red_text)}</NewAlert>
                ) : (
                  <></>
                )}
                <TitleWrapper font_theme={collections[item].font_theme}>
                  {t(
                    handleNewLine(
                      checkLangAvailableDatabase(collections[item].title)
                    ).trim()
                  )}
                </TitleWrapper>
                <DescriptionWrapper font_theme={collections[item].font_theme}>
                  {t(
                    handleNewLine(
                      checkLangAvailableDatabase(collections[item].description)
                    ).trim()
                  )}
                </DescriptionWrapper>
                <LinkWrapper>
                  <ul>
                    <li
                      className={"primary"}
                      onClick={
                        collections[item].collection_type_id === "ct_d"
                          ? () => {
                              updateEditCard(0);
                              updateNProducts(1);
                              updateFormList([emptyFormFill]);
                              setTimeout(
                                () => navigateHandler("/gallery"),
                                200
                              );
                            }
                          : collections[item].collection_type_id === "group"
                          ? () => {
                              navigateHandler(
                                "/collection_group/" + collections[item].id
                              );
                            }
                          : () =>
                              handleChangePage({
                                link:
                                  "/intro/" + collections[item].collection_name,
                                ...collections[item],
                              })
                      }
                    >
                      <p>{t("รับสิทธิ์")}</p>
                    </li>
                  </ul>
                </LinkWrapper>
              </TitleContainer>
            </BannerWrapper>
          ) : (
            /*</SectionWrapper>*/
            <></>
          )
        )}
      {voucherReachLimit && <LimitRedemption />}
      {collections && !voucherReachLimit && <ContactLogoComponent />}
    </>
  );
};

export default RedeemLandingPage;
