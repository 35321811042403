import styled from "styled-components";
import fluidText from "../../utils/fluidText";
import { Default } from "react-spinners-css";
import { handleFontFamily } from "../../utils/utils";

export const PreviewImage = styled.img`
  width: 100%;
  height: 100%;
  //margin: 0.75vw;
  opacity: ${(props) => (props.isSelected ? "30%" : "100%")};
  pointer-events: none;
  border-radius: 15px;

  box-shadow: 0px 3px 12px rgba(92, 92, 92, 0.2);
  @media (min-width: 600px) {
    width: 100%;
    height: 100%;
    min-width: 120px;
    //max-height: 40vh;
    //margin: 0.25vw;
  }
`;

export const PreviewImageWrapper = styled.div`
  position: relative;
  //height: 100%;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  /* margin-bottom: 4vw;
margin-left: 4vw; */
  width: 100%;
  height: 100%;
  border-radius: 15px;
  /* @media (min-width: 600px) {
  margin-bottom: 2vw;
  margin-left: 2vw;
} */
`;

export const LargePreviewImageWrapper = styled.img`
  width: 100%;
  height: 100%;
  max-height:${props=>props.IS_IOS?'80vh':'90vh'} ;
  margin: auto;
  pointer-events: ${props=>props.isDownload?'auto':'none'};
  object-fit: contain;
`;

export const LargePreviewWrapper = styled.div`
  width: 100%;
  height: 100vh;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: 9;
`;

export const HeaderWrapper = styled.div`
  height : 10vh;
  width:100%;
  position:fixed;
  background-color:white ;
  display:flex ;
  flex-direction:column ;
  z-index:2;
  @media (min-width: 600px) {
    width: 60vw;
    margin: auto;
    max-width: 600px;
    left: 50%;
    transform: translate(-50%, 0%);
  }
`
export const LineHeader = styled.div`
  //padding-top:10vh ;
  border: 1px solid #D2D2D2;
  width: 100vw ;
  position:fixed ;
  bottom : 0px;
  left:50%;
  transform: translate(-50%, 0%);
`

export const HeadTextStyled = styled.div`
  //width:88.46vw;
  //margin: 2vh auto 2vh auto;
  margin:auto ;
  ${fluidText(24, 28)}
  //font-size: 1.5rem; 
    ${handleFontFamily()}
  font-style: normal;
  font-weight: 300;
  color: black;
  text-align: center;
  @media (min-width: 600px) {
    //width: 38vw;
    //margin: 3vh auto 3vh auto;
  }
`;

export const TipsTextStyled = styled.div`
  //font-size: 0.9rem;
  ${fluidText(12, 16)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: "black";
  text-align: left;
  align-items: center;
  display: flex;
  @media (min-width: 600px) {
    //height: 3vh;
    //font-size: 1rem;
  }
  //margin-left : 5.1vw;
`;

export const TipsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  margin: 0 auto 1vh auto;
  @media (min-width: 600px) {
    width: 100%;
    /* min-width: 450px; */
    /* margin: 0 auto 4vh auto; */
  }
`;

export const PreviewButtonWrapper = styled.div`
  position: absolute;
  bottom: 3vh;
  left: 50%;
  transform: translate(-50%, 0%);
`;

export const CancelIconStyled = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 9vw;
  pointer-events: auto;
  cursor: pointer;
  @media (min-width: 600px) {
    width: 3vw;
  }
`;

export const FilterWrapperStyled = styled.div`
  /* position: absolute; */
  /* top: 3.5vh;
  right: 10px; */
  flex-direction: column;
  pointer-events: auto;
  cursor: pointer;
  position: absolute;
  top: 50%;
  right : 10%;
  transform: translate(0%, -50%);
  div {
    color: #828282;
    transition: color 0.2s;
    svg {
      path {
        transition: fill 0.2s;
      }
    }
  }

  :hover {
    div {
      color: #e4202a;
      svg {
        path {
          fill: #e4202a;
        }
      }
    }
  }
`;

export const FilterIconStyled = styled.div`
  display: flex;
  margin: auto;
  width: 1.5rem;
  @media (min-width: 600px) {
    width: 2rem;
  }
  /* svg {
    path {
      transition: fill 0.2s;
    }
  }
  :hover {
    svg {
      path {
        fill: #e4202a;
      }
    }
  } */
`;

export const FilterTextedStyled = styled.div`
  ${fluidText(12, 16)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
`;

export const Gradient = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: linear-gradient(to bottom, #000000 0%, rgba(0, 0, 0, 0) 50%);
  opacity: 0.65;
  border-radius: 15px;

  @media (min-width: 600px) {
    width: 100%;
    min-width: 118.42px;
  }
`;

export const ContentWrapper = styled.div`
  padding-top:10.5vh ;
  position: relative;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  pointer-events: ${(props) => (props.isPreview ? "none" : "auto")};
  @media (min-width: 600px) {
    width: 60vw;
    margin: auto;
    max-width: 500px;
  }
  //align-items:center;
`;

export const CheckCircleStyled = styled.img`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  width: 6.15vw;
  left: 50%;
  opacity: 100%;
  z-index: 2;
  @media (min-width: 600px) {
    width: 2.5vw;
  }
`;

export const TagNumber = styled.div`
  position: absolute;
  top: 2vw;
  left: 2vw;
  font-size: 0.5rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: white;
  z-index: 1;
  @media (min-width: 600px) {
    font-size: 0.8rem;
    top: 1vw;
    left: 1vw;
  }
`;

export const PriceNumber = styled.div`
  font-size: 0.8rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;

  margin-top: 0.4rem;
  margin-bottom: 0.6rem;
  display:flex ;
  flex-direction:row ;
  @media (min-width: 600px) {
    font-size: 1rem;
  }
`;

export const ImageAreaWrapper = styled.div`
  position: relative;
  height: ${(props) => `${props.vh}px`};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const PositionImageWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.useWidth ? `90vw` : `${props.vh * 0.518}px`)};
  height: ${(props) => (props.useWidth ? `calc(90vw / 0.518)` : `${props.vh}px`)};

  display: flex;
  justify-content: center;
  align-items: center;
`;

export const TipImage = styled.img`
  width: 9.2vw;
  margin-left: 3.5vw;
  margin-right: 3.5vw;
  @media (min-width: 600px) {
    width: 6vh;
    height: 6vh;
    margin-left: 1vw;
    margin-right: 1vw;
  }
`;
export const SpinnerWrapper = styled(Default)`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

export const SubHeadTextStyled = styled.div`
  width: 80vw;
  margin: 1vh auto 0vh auto;
  ${fluidText(16, 18)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  text-align: left;
  @media (min-width: 600px) {
    //width: 40vw;
    min-width: 500px;
  }
`;
