import React, { useContext, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { HeadTextStyled } from "../HomePage/styles";
import { state as stateAtom, formTypeDatabase } from "../../global/atom";
import TextFieldCard from "../../components/TextFieldCard";
import FooterComponent from "../../components/FooterComponent";
import { IS_ANDROID } from "../../utils/support";
import NotificationCard from "../../components/NotificationCard";
import { useNavigate } from "react-router";
import { UserContext } from "../../utils/Context/userContext";
import { createSubscriberInfo } from "../../utils/IndexedDBStore";
import {
  session_subscription_user,
  useSessionStorage,
} from "../../utils/sessionStorage";
import useNavigationHandling from "../../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";

const ContentWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  /* height: ${(props) => `${props.vh}px`}; */
  margin-top: 3.2rem;
  height: auto;
  overflow-x: hidden;
  padding-bottom: 10vh;
`;

const SubscriptionPersonalInfoPage = () => {
  const [state, updateState] = useRecoilState(stateAtom);
  const [subscriptionUser, updateSubscriptionUser] = useSessionStorage(
    session_subscription_user.key,
    session_subscription_user.default
  );
  const TextFieldCardRef = useRef();
  const formType = "type_2";
  const [form_selector, update_form_selector] = useState();
  const form_type_database = useRecoilValue(formTypeDatabase);
  const [dateError, setDateError] = useState();
  const [showNoti, setShowNoti] = useState();
  const [showNextPage, setShowNextPage] = useState(false);
  const [height] = useState(window.innerHeight);
  const [hide, updateHide] = useState(false);
  const [vh, updateVh] = useState(window.innerHeight);
  const { subscriberInfo, updateSubscriberInfo } = useContext(UserContext);
  const { navigateHandler } = useNavigationHandling();
  const { t } = useTranslation();

  useEffect(() => {
    if (!subscriptionUser || !subscriptionUser.phone_number)
      navigateHandler("/ais/login");
    updateState("SubscriptionPersonalInfo");
    window.addEventListener("resize", checkWindowHeight);

    // hidden scroll
    let body = document.body;
    body.style.overflow = "hidden";
    let root = document.getElementById("root");
    root.style.overflow = "hidden";

    return function cleanup() {
      window.removeEventListener("resize", checkWindowHeight);

      // enable scroll
      let body = document.body;
      body.style.overflow = "unset";
      let root = document.getElementById("root");
      root.style.overflow = "unset";
    };
  }, []);

  const checkWindowHeight = () => {
    let vh = window.innerHeight;
    updateVh(vh);
    if (IS_ANDROID) {
      if (parseInt(window.innerHeight) < 0.8 * height) updateHide(true);
      else updateHide(false);
    }
  };

  useEffect(() => {
    if (subscriberInfo && showNextPage) {
      createSubscriberInfo(subscriberInfo, subscriptionUser.phone_number);
      navigateHandler("/ais/user");
    }
  }, [subscriberInfo]);

  useEffect(() => {
    if (formType) {
      for (var x = 0; x < form_type_database.length; x++) {
        if (formType == form_type_database[x].id)
          update_form_selector(form_type_database[x]);
      }
    }
  }, [formType, form_type_database]);
  return (
    <>
      <ContentWrapper vh={vh}>
        <HeadTextStyled>
          {t("กรอกข้อมูลเพื่อดาวน์โหลด")}
          <br />
          {t("ลัคกี้วอลเปเปอร์เฉพาะคุณ")}
        </HeadTextStyled>
        <TextFieldCard
          setDateError={(e) => setDateError(e)}
          setShowNoti={(e) => setShowNoti(e)}
          ref={TextFieldCardRef}
          type="type_3"
          type_selector={form_selector}
          state={state}
        />
      </ContentWrapper>

      <FooterComponent
        addOnClickNextFunction={() => {
          TextFieldCardRef.current.setSubscriber();
          setShowNextPage(true);
          //navigateHandler('/subscription/user')
        }}
        hide={hide}
        isDisableNextButton={dateError}
      />
    </>
  );
};

export default SubscriptionPersonalInfoPage;
