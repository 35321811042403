import React, { useState, useEffect, useRef } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from 'styled-components';
import { getPurchasedImages } from "../utils/IndexedDBStore";
import DoseartButton from "../components/DoseartButton";
import CancelIcon from "../assets/gallery/cancelButton.svg";
import GalleryList from "../components/GalleryListComponent";
import fluidText from "../utils/fluidText";
import FooterComponent from "../components/FooterComponent";
import { state as stateAtom } from "../global/atom"
import DownloadComponent from "../components/DownloadComponent";
import { session_state, useSessionStorage } from "../utils/sessionStorage";
import { useTranslation } from "react-i18next";
import { handleFontFamily } from "../utils/utils";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  pointer-events: ${(props) => (props.isPreview ? "none" : "auto")};
  @media (min-width: 600px) {
    width: 40vw;
    margin: auto;
    min-width: 500px;
  }
  //align-items:center;
`;

const GalleryCardWrapper = styled.div`
    //background-color:#F9F9F9;
    width:88.46vw;
    //border-radius:15px;
    margin: 0vh auto 12vh auto;
    //box-shadow: 4px 4px 10px #D1D9E6;
    //border-radius: 15px;
    display: flex;
    pointer-events: ${props => props.isPreview ? 'none' : 'auto'};
    @media(min-width:600px){
        width: 38vw;
        min-width: 450px;
        margin: 0vh auto 15vh auto;
    }
`;

const GalleryWrapper = styled.div`
    display: inline-flex;
    //align-items: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin : 4vw auto 0 auto;
    width:88.46vw;
    //justify-content: space-evenly;
    //padding : 0.75vw;
    @media(min-width:600px){
        width: 38vw;
        min-width: 430px;
        margin : 0vw auto 0 auto;
        //padding : 0.25vw;
    }
`

const PreviewImage = styled.img`
  width: 24vw;
  //height: 100%;
  //margin: 0.75vw;
  opacity: ${(props) => (props.isSelected ? "30%" : "100%")};
  pointer-events: none;
  border-radius: 15px;
  @media (min-width: 600px) {
    width: 10vw;
    min-width: 118.42px;
    //margin: 0.25vw;
  }
`;

const PreviewImageWrapper = styled.div`
  position: relative;
  //height: 100%;
  display: inline-flex;
  cursor: pointer;
  margin-bottom: 4vw;
  margin-left: 4vw;
  @media (min-width: 600px) {
    margin-bottom: 2vw;
    margin-left: 2vw;
  }
`;

const LargePreviewWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: 2;
`;

const ImageAreaWrapper = styled.div`
  position: relative;
  height: ${(props) => `${props.vh}px`};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PositionImageWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.useWidth ? `90vw` : `${props.vh * 0.518}px`)};
  height: ${(props) =>
        props.useWidth ? `calc(90vw / 0.518)` : `${props.vh}px`};

  display: flex;
  justify-content: center;
  align-items: center;
`;


const LargePreviewImageWrapper = styled.img`
  width: 100%;
  height: 100%;
  margin: auto;
  pointer-events: none;
  object-fit: contain;
`;

const CancelIconStyled = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 9vw;
  pointer-events: auto;
  cursor: pointer;
  @media (min-width: 600px) {
    width: 3vw;
  }
`;

const PreviewButtonWrapper = styled.div`
  position: absolute;
  bottom: 3vh;
  left: 50%;
  transform: translate(-50%, 0%);
`;

const HeadTextStyled = styled.div`
    width:82vw;
    margin: 3vh auto 0vh auto;
    ${fluidText(20, 25)}
    ${handleFontFamily()}
    font-style: normal;
    font-weight: 400;
    color : black;
    text-align:left;
    @media(min-width:600px){
        width: 35vw;
        min-width: 350px;
    }
`;

const isTouchEvent = (event) => {
    return "touches" in event;
};

const preventDefault = (event) => {
    if (!isTouchEvent(event)) return;

    if (event.touches.length < 2 && event.preventDefault) {
        event.preventDefault();
    }
};


const PurchasedGalleryPage = () => {
    const [state, updateState] = useRecoilState(stateAtom)
    //const [state,updateState] = useSessionStorage(session_state.key,session_state.default)
    const [image, updateImage] = useState([])
    const [useWidth, setUseWidth] = useState(true);
    const [width, setWidth] = useState(window.innerWidth);
    const [vh, updateVh] = useState(window.innerHeight);
    const [largeURLImages, updateLargeUrlImages] = useState([]);
    const [previewIndex, updatePreviewIndex] = useState(0);
    const [previewImage, updatePreviewImage] = useState(false);
    const [selectIndex, updateSelectIndex] = useState();

    const {t} = useTranslation();
    
    var isTouchmove = false;

    useEffect(() => {
        updateState('PurchasedHistory')
        getPurchasedImages().then((res) => {
            updateImage(res)
        })
        if (
            (width < 600 && 1.69 * width > window.innerHeight) ||
            (width > 600 && 0.25 * width > window.innerHeight)
        ) {
            setUseWidth(false);
        } else {
        }
        window.addEventListener("resize", handleResize);
        window.addEventListener("touchmove", handleTouchMove);
        window.addEventListener("touchend", handleTouchEnd)
        return () => {
            window.removeEventListener("touchmove", preventDefault, {
                passive: true,
            });
            window.removeEventListener("resize", handleResize);
            window.removeEventListener('touchmove', handleTouchMove)
            window.removeEventListener("touchend", handleTouchEnd)
        };
    }, [])

    const imageList = image.map((value) => {
        return (
            <PreviewImageWrapper>
                <PreviewImage src={URL.createObjectURL(value.blob)} />
            </PreviewImageWrapper>
        )
    })

    const onClickSelectImage = React.useCallback((i) => {
        updateSelectIndex(i);
        updatePreviewImage(false);
        window.removeEventListener("touchmove", preventDefault, {
            passive: true,
        });
    }, [])

    const handleResize = () => {
        let vh = window.innerHeight;
        updateVh(vh);
        let vw = window.innerWidth;
        setWidth(vw);
    };

    const handleTouchMove = () => {
        isTouchmove = true;
    };

    const handleTouchEnd = () => {
        isTouchmove = false
    }

    const onLongPress = (i) => {
    };

    const onClick = (i) => {
        if (!isTouchmove) {
            onClickItem(i);
        }
        isTouchmove = false;
    };

    const onClickItem = React.useCallback((i) => {
        if (!isTouchmove) {
            updatePreviewIndex(i);
            updatePreviewImage(true);
            //setLoading(true);
            window.addEventListener("touchmove", preventDefault, { passive: false });
        }
        isTouchmove = false;
    }, []);


    const onClickClose = () => {
        updatePreviewImage(false);
        window.removeEventListener("touchmove", preventDefault, {
            passive: true,
        });
    }

    return (
        <ContentWrapper>
            <HeadTextStyled>{t("ประวัติการสั่งซื้อ")}</HeadTextStyled>
            <GalleryCardWrapper>
                <GalleryWrapper>
                    <GalleryList
                        items={imageList}
                        onLongPressItem={onLongPress}
                        onClickItem={onClick}
                        isPreview={previewImage}
                    ></GalleryList>
                </GalleryWrapper>
            </GalleryCardWrapper>
            {previewImage ? (
                //<LargePreviewImage />
                <DownloadComponent
                    imgSrc={URL.createObjectURL(image[previewIndex].blob)}
                    onClickClose={onClickClose}
                    width={width}
                    vh={vh}
                />
            ) : <FooterComponent />}

        </ContentWrapper>
    )
}

export default PurchasedGalleryPage;