import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CancelIcon from "../assets/gallery/cancelButton.svg";
import DoseartButton from "../components/DoseartButton";
import demoVDO from "../assets/checkout/demoVDO.mp4";
import { IS_IOS } from "../utils/support";
import { useTranslation } from "react-i18next";
import { handleFontFamily } from "../utils/utils";

const LargePreviewWrapper = styled.div`
  width: 100%;
  height: 100%;
  //pointer-events: none;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  //display: flex;
  z-index: 9;
`;

const ImageAreaWrapper = styled.div`
  position: relative;
  height: ${(props) => `${props.vh}px`};
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PositionImageWrapper = styled.div`
  position: relative;
  width: fit-content;
  /*width: ${(props) => (props.useWidth ? `90vw` : `${props.vh * 0.518}px`)};
  height: ${(props) =>
    props.useWidth ? `calc(90vw / 0.518)` : `${props.vh}px`};*/
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  padding: 5vw;
  //justify-content: center;
  //align-items: center;
  background-color: #f9f9f9;
  border-radius: 15px;
`;

const LargePreviewImageWrapper = styled.img`
  //width: 63vw;
  display: flex;
  //width: 80%;
  height: ${IS_IOS ? "63vh" : "70vh"};
  margin: auto;
  pointer-events: auto;
  //object-fit: contain;
  border-radius: 15px;
  //@media (min-width: 600px) {
  //  width: 24vw;
  //}
`;

const CancelIconStyled = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 9vw;
  pointer-events: auto;
  cursor: pointer;
  @media (min-width: 600px) {
    width: 2.5vw;
  }
`;

const PreviewButtonWrapper = styled.div`
  //position: absolute;
  margin: 1vh auto;
  //bottom: 2vh;
  //left: 50%;
  //transform: translate(-50%, 0%);
`;

const VideoBGWrapper = styled.div`
  width: 100vw;
  height: 100%;
  //align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: 10;
`;

const VideoWrapper = styled.video`
  width: 90vw;
  margin: auto;
`;

const LoaderTextStyled = styled.div`
  //width:71.84vw;
  margin: 0vh auto;
  font-size: 1.2em;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  @media (min-width: 600px) {
    margin: 0vh auto 1vh auto;
    font-size: 1.5rem;
  }
`;

const LoaderTextStyled2 = styled.div`
  //margin: 0 auto;
  font-size: 0.8em;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  @media (min-width: 600px) {
    font-size: 1.2rem;
  }
`;

const DownloadComponent = ({ imgSrc, onClickClose, width, vh }) => {
  const [useWidth, setUseWidth] = useState(true);
  const [showVDO, setShowVDO] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (
      (width < 600 && 1.69 * width > window.innerHeight) ||
      (width > 600 && 0.25 * width > window.innerHeight)
    ) {
      setUseWidth(false);
    }
  }, []);

  const ShowVideo = () => {
    return (
      <VideoBGWrapper>
        <CancelIconStyled
          src={CancelIcon}
          onClick={() => {
            setShowVDO(false);
          }}
        />
        <VideoWrapper src={demoVDO} controls type="video/mp4" />
      </VideoBGWrapper>
    );
  };

  const downloadImage = () => {
    const anchor = document.createElement("a");
    anchor.href = imgSrc;
    anchor.download = `luckydose.jpg`;
    anchor.target = "_blank";
    anchor.click();
  };

  return (
    <LargePreviewWrapper>
      {showVDO ? <ShowVideo></ShowVideo> : <></>}
      <PositionImageWrapper vh={vh * 0.9} useWidth={width / vh < 0.518}>
        <div style={{ margin: "4vh auto 2vh auto" }}>
          <LargePreviewImageWrapper
            isIOS={IS_IOS}
            src={imgSrc}
            useWidth={useWidth}
            vh={vh * 0.9}
          />
        </div>
        <CancelIconStyled src={CancelIcon} onClick={onClickClose} />
        {IS_IOS ? (
          <>
            <LoaderTextStyled>
              <span style={{ color: "#E4202A" }}>
                {t("กดค้างที่ภาพเพื่อดาวน์โหลด")}
              </span>{" "}
            </LoaderTextStyled>
            <div style={{ margin: "0 auto" }}>
              <LoaderTextStyled2>
                {t("หากมีข้อสงสัยเกี่ยวกับวิธีดาวน์โหลดภาพ กด")}{" "}
                <span
                  style={{ textDecoration: "underline" }}
                  onClick={() => {
                    setShowVDO(true);
                  }}
                >
                  {t("ที่นี่")}
                </span>
              </LoaderTextStyled2>
            </div>
          </>
        ) : (
          <PreviewButtonWrapper>
            <DoseartButton
              isDisable={false}
              isPrimary={true}
              text={t("ดาวน์โหลดภาพ")}
              width={parseInt(width) < 600 ? "65vw" : "21vw"}
              handleOnClick={() => downloadImage()}
            />
          </PreviewButtonWrapper>
        )}
      </PositionImageWrapper>
    </LargePreviewWrapper>
  );
};

export default DownloadComponent;
