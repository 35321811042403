export const IS_IOS = /iPad|iPhone|iPod/.test(navigator.userAgent)
  || (navigator.platform === "MacIntel" && navigator.maxTouchPoints > 1);

export const IS_IPhone = /iPhone/i.test(navigator.userAgent);

export const IS_ANDROID = /android/i.test(navigator.userAgent);

//export const IS_SAFARI = /safari/i.test(navigator.userAgent);
export const IS_SAFARI =/^((?!chrome|android).)*safari/i.test(navigator.userAgent) && !navigator.userAgent.match('CriOS');
export const QUICKLOOK_SUPPORTED = (() => {
    const anchorEl = document.createElement("a");
    return anchorEl.relList && anchorEl.relList.supports && anchorEl.relList.supports("ar");
  })();

//export const IS_LINE = /(Line)/i.test(navigator.userAgent);
export const IS_LINE = navigator.userAgent.toLowerCase().indexOf('line') > -1;

export const IS_TIKTOK = navigator.userAgent.toLowerCase().indexOf('tiktok') > -1;

export const IS_FACEBOOK_APP = navigator.userAgent.toLowerCase().indexOf('fbav') > -1 || navigator.userAgent.toLowerCase().indexOf('fbdv') > -1;

export const IS_ANDROID_FIREFOX = navigator.userAgent.toLowerCase().indexOf('firefox') > -1; 

export const IS_IOS_EDGE = navigator.userAgent.toLowerCase().indexOf("edgios") > -1 ;

export const IS_IOS_FIREFOX = navigator.userAgent.toLowerCase().indexOf('fxios') > -1; 

export const IS_INSTAGRAM = navigator.userAgent.toLowerCase().indexOf('instagram') > -1;