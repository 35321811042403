/**
 * The bundles here are configured so that each locale only requires loading a single webpack chunk.
 */

 const bundles = {
    th: () => import(/* webpackChunkName: "th" */ "./locales/th.json"),
    zh: () => import(/* webpackChunkName: "zh" */ "./locales/zh.json"),
    en: () => import(/* webpackChunkName: "zh" */ "./locales/en.json"),
  };
  
  // generate whitelist for i18next
  export const availableLocales = Object.keys(bundles);
  
  export default bundles;
  