import React, { useContext, useEffect, useRef, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  dateDatabase as dateDatabaseAtom,
  state as stateAtom,
} from "../../global/atom";
import styled from "styled-components";
import PreviewCard from "../../components/PreviewCard";
import { UserContext } from "../../utils/Context/userContext";
import fluidText from "../../utils/fluidText";
import CancelIcon from "../../assets/gallery/cancelButton.svg";
import {
  sessionRefID,
  session_subscription_selection_artwork,
  session_subscription_selection_package,
  session_subscription_user,
  useSessionStorage,
} from "../../utils/sessionStorage";
import {
  createSubscriptionArtwork,
  GCLOUDSTORAGEURL_SUBSCRIPTION,
  getDateText,
  getThaiMoonPhase,
  getTimeInQueue,
  getZodiacName,
  handleFontFamily,
} from "../../utils/utils";
import FooterComponent from "../../components/FooterComponent";
import { Default } from "react-spinners-css";
import LoaderComponent from "../../components/LoaderComponent";
import { useNavigate } from "react-router-dom";
import useNavigationHandling from "../../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";

const TipsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  width: 90vw;
  margin: 0 auto 2vh auto;
  @media (min-width: 600px) {
    width: 38vw;
    min-width: 400px;
    margin: 0 auto 4vh auto;
  }
`;

const HeadTextStyled = styled.div`
  //width:88.46vw;
  margin: 2vh auto 2vh auto;
  ${fluidText(24, 28)}
  //font-size: 1.5rem; 
    ${handleFontFamily()}
  font-style: normal;
  font-weight: 300;
  color: black;
  text-align: center;
  @media (min-width: 600px) {
    //width: 38vw;
    margin: 3vh auto 3vh auto;
  }
`;

const TipsRedTextStyled = styled.div`
  ${fluidText(12, 20)}
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: #e4202a;
  text-align: left;
  word-wrap: normal;
  @media (min-width: 600px) {
    //font-size: 1rem;
  }
  //margin-left : 5.1vw;
`;

const LargePreviewImageWrapper = styled.img`
  width: 100%;
  height: 100%;
  margin: auto;
  pointer-events: none;
  object-fit: contain;
`;

const LargePreviewWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: 10;
`;
const ImageAreaWrapper = styled.div`
  position: relative;
  height: ${(props) => `${props.vh}px`};
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  //display: flex;
`;

const PositionImageWrapper = styled.div`
  position: relative;
  width: ${(props) => (props.useWidth ? `90vw` : `${props.vh * 0.518}px`)};
  height: ${(props) =>
    props.useWidth ? `calc(90vw / 0.518)` : `${props.vh}px`};

  display: flex;
  justify-content: center;
  align-items: center;
`;

const CancelIconStyled = styled.img`
  position: absolute;
  top: 10px;
  right: 10px;
  width: 9vw;
  cursor: pointer;
  pointer-events: auto;
  @media (min-width: 600px) {
    width: 2.5vw;
  }
`;
const SpinnerWrapper = styled(Default)`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
`;

const SubscriptionPreviewPage = () => {
  const [state, updateState] = useRecoilState(stateAtom);
  const [referenceID, updateRefID] = useSessionStorage(
    sessionRefID.key,
    sessionRefID.default
  );
  const [circularProgress, updateCircularProgress] = useState(0);
  const [selectedArtwork] = useSessionStorage(
    session_subscription_selection_artwork.key,
    session_subscription_selection_artwork.default
  );
  const [dateDatabase] = useRecoilState(dateDatabaseAtom);
  const [subscriptionUser, updateSubscriptionUser] = useSessionStorage(
    session_subscription_user.key,
    session_subscription_user.default
  );
  const { subscriberInfo, setOnShowPreview } = useContext(UserContext);
  const [selectPackageDetail, updateSelectedPackageDetail] = useSessionStorage(
    session_subscription_selection_package.key,
    session_subscription_selection_package.default
  );
  const [largePreviewLoading, setLoading] = useState(true);
  const [isLoading, updateLoading] = useState(true);
  const [isMessageSending, updateMessageSending] = useState(false);
  const [isPreviewImage, updatePreviewImage] = useState(false);
  const [vh, updateVh] = useState(window.innerHeight);
  const [width, setWidth] = useState(window.innerWidth);
  const [useWidth, setUseWidth] = useState(true);
  const [nextURL, updateNextULR] = useState("");
  const { t } = useTranslation();
  const { navigateHandler } = useNavigationHandling();
  var intervalFunction = useRef();
  var gCloudStorageURL = GCLOUDSTORAGEURL_SUBSCRIPTION;
  var intervalCircularProgressRef = useRef();
  var progressTime = 1;
  var currentTime = 0;

  useEffect(() => {
    if (!subscriptionUser || !subscriptionUser.phone_number)
      navigateHandler("/ais/login");
    if (
      (width < 600 && 1.69 * width > window.innerHeight) ||
      (width > 600 && 0.25 * width > window.innerHeight)
    ) {
      setUseWidth(false);
    }
    updateState("SubscriptionPreview");
    return () => {
      setOnShowPreview(false);
      clearInterval(intervalFunction.current);
      clearInterval(intervalCircularProgressRef.current);
    };
  }, []);

  useEffect(() => {
    if (referenceID != null && referenceID != "" && nextURL == "") {
      getTimeInQueue(1).then((time) => {
        progressTime = time;
        intervalFunction.current = setInterval(checkURLImage, 1000);
        intervalCircularProgressRef.current = setInterval(
          countdownProgress,
          100
        );
      });
    }
  }, [referenceID]);

  useEffect(() => {
    if (isPreviewImage) setOnShowPreview(true);
    else setOnShowPreview(false);
  }, [isPreviewImage]);

  const countdownProgress = () => {
    currentTime += 0.1;
    var progression = Math.round((currentTime / progressTime) * 100);
    if (progression >= 95) {
      clearInterval(intervalCircularProgressRef.current);
      progression = 95;
    }
    updateCircularProgress(progression);
  };

  window.addEventListener("resize", () => {
    let vh = window.innerHeight;
    updateVh(vh);
    let vw = window.innerWidth;
    setWidth(vw);
  });

  function checkURLImage() {
    var http = new XMLHttpRequest();
    var previewImageURL = gCloudStorageURL + referenceID;
    http.onreadystatechange = function () {
      if (this.readyState == 4) {
        // Typical action to be performed when the document is ready:
        if (http.status != 404) {
          updateLoading(false);
          clearInterval(intervalCircularProgressRef.current);
          clearInterval(intervalFunction.current);
        }
      }
    };
    http.open("GET", previewImageURL, false);
    http.send();
  }

  useEffect(() => {
    if (nextURL && referenceID) {
      navigateHandler(nextURL);
    }
  }, [nextURL]);

  const createArtwork = async () => {
    getThaiMoonPhase(
      subscriberInfo.birthYear-543,
      subscriberInfo.birthMonth + 1,
      subscriberInfo.birthDate,
      dateDatabase
    ).then((moonPhase) => {
      var day_of_week = new Date(
        subscriberInfo.birthYear-543,
        subscriberInfo.birthMonth,
        subscriberInfo.birthDate
      ).getDay();
      createSubscriptionArtwork(
        selectedArtwork.filename,
        "false",
        subscriberInfo.name,
        subscriberInfo.surname,
        subscriberInfo.birthDate.toString(),
        subscriberInfo.birthMonth.toString(),
        subscriberInfo.birthYear.toString(),
        subscriberInfo.zodiacId.toString(),
        day_of_week.toString(),
        moonPhase.toString(),
        subscriptionUser.phone_number,
        selectPackageDetail.id,
        [],
        subscriberInfo.chinese_zodiac.toString()
      ).then((returnValue) => {
        if (returnValue.status == "success") {
          updateRefID(returnValue.refID);
          updateNextULR("/ais/generate");
        } else updateMessageSending(false);
        //console.log(returnValue)
      });
    });
  };

  const LargePreviewImage = () => {
    return (
      <LargePreviewWrapper>
        <ImageAreaWrapper vh={vh}>
          <PositionImageWrapper vh={vh * 0.9} useWidth={width / vh < 0.518}>
            {largePreviewLoading ? (
              <SpinnerWrapper color="#E4202A" size={70} />
            ) : (
              <></>
            )}
            <LargePreviewImageWrapper
              src={gCloudStorageURL + referenceID}
              useWidth={useWidth}
              onLoad={() => setLoading(false)}
            ></LargePreviewImageWrapper>
            <CancelIconStyled
              src={CancelIcon}
              onClick={() => updatePreviewImage(false)}
            />
          </PositionImageWrapper>
        </ImageAreaWrapper>
      </LargePreviewWrapper>
    );
  };

  return (
    <div style={{ marginTop: "calc(3.2rem + 2vh)", height: "auto" }}>
      {isMessageSending && <LoaderComponent />}
      {isPreviewImage ? <LargePreviewImage /> : <></>}
      <HeadTextStyled>{t(`วอลเปเปอร์มงคลเฉพาะคุณ`)}</HeadTextStyled>
      <TipsWrapper>
        <TipsRedTextStyled>
          • {t(`กดที่ภาพ เพื่อดูภาพขนาดใหญ่`)}
          <br />• {t(`กรุณาตรวจสอบรายละเอียดของภาพก่อนกด "ยืนยัน"`)}
        </TipsRedTextStyled>
      </TipsWrapper>
      <div style={{ marginBottom: "15vh" }}>
        <PreviewCard
          isLoading={isLoading}
          circularProgress={circularProgress}
          sampleImage={selectedArtwork.thumbnailPath}
          name={subscriberInfo.name + " " + subscriberInfo.surname}
          birthdate={
            subscriberInfo.birthDate != 0
              ? getDateText(
                  subscriberInfo.birthDate,
                  subscriberInfo.birthMonth,
                  subscriberInfo.birthYear,
                  t
                )
              : ""
          }
          isShowDate={true}
          zodiac={getZodiacName(subscriberInfo.zodiacId)}
          addOnClick={() => {
            updatePreviewImage(true);
            setLoading(true);
          }}
          referenceID={referenceID}
          chinese_zodiac=""
          day_of_week=""
          isSubscribe={true}
        />
      </div>
      <FooterComponent
        addOnClickNextFunction={() => {
          //console.log('hello')
          updateMessageSending(true);
          createArtwork();
        }}
      />
    </div>
  );
};

export default SubscriptionPreviewPage;
