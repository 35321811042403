import React, { Suspense } from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { RecoilRoot } from "recoil";
import Div100vh from "react-div-100vh";
import { BrowserRouter } from "react-router-dom";
import { hydrate, render } from "react-dom";
import { UserContextProvider } from "./utils/Context/userContext";
import { I18nextProvider } from "react-i18next";
import i18n from "./i18n";
import LangRouter from "./LangRouter";

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  hydrate(
    <RecoilRoot>
      <React.StrictMode>
        <UserContextProvider>
          <BrowserRouter>
            <Div100vh style={{ overflowX: "hidden" }} id="root-div100">
              <Suspense fallback={<></>}>
                <I18nextProvider i18n={i18n}>
                  <LangRouter />
                </I18nextProvider>
                {/* <App /> */}
              </Suspense>
            </Div100vh>
          </BrowserRouter>
        </UserContextProvider>
      </React.StrictMode>
    </RecoilRoot>,
    rootElement
  );
} else {
  render(
    <RecoilRoot>
      <React.StrictMode>
        <UserContextProvider>
          <BrowserRouter>
            <Div100vh style={{ overflowX: "hidden" }} id="root-div100">
              <Suspense fallback={<></>}>
                <I18nextProvider i18n={i18n}>
                  <LangRouter />
                  {/* <App /> */}
                </I18nextProvider>
              </Suspense>
            </Div100vh>
          </BrowserRouter>
        </UserContextProvider>
      </React.StrictMode>
    </RecoilRoot>,
    rootElement
  );
}
/*ReactDOM.render(
  <RecoilRoot>
    <React.StrictMode>
      <BrowserRouter>
        <Div100vh style={{ overflowX: "hidden" }} id="root-div100">
          <Suspense fallback={<Homepage />}>
            <App />
          </Suspense>
        </Div100vh>
      </BrowserRouter>
    </React.StrictMode>
  </RecoilRoot>,
  document.getElementById("root")
);*/

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
