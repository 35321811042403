import React, {
  useEffect,
  useRef,
  useState,
} from "react";
import styled from "styled-components";
import CancelIcon from "../assets/gallery/cancelButton.svg";
import { IS_ANDROID, IS_IOS } from "../utils/support";
import DoseartButton from "./DoseartButton";
import { useTranslation } from "react-i18next";
import { handleFontFamily } from "../utils/utils";

const PreviewButtonWrapper = styled.div`
  position: absolute;
  margin: 1vh auto;
  bottom: ${(props) => (props.ratio && props.ratio == 1 ? "-20%" : "0%")};
  left: 50%;
  transform: translate(-50%, 0%);
`;

const ContentWrapper = styled.div`
  width: 100vw;
  height: 100%;
  //align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: 10;
`;

const CancelIconStyled = styled.img`
  //height: ${(props) =>
    props.useWidth ? `calc(90vw / 0.518)` : `${props.vh}px`};
  position: absolute;
  top: 10px;
  right: 10px;
  width: 9vw;
  z-index: 11;
  //transform: translate(-50%, -50%);
  pointer-events: auto;
  cursor: pointer;
  @media (min-width: 600px) {
    width: 2.5vw;
  }
`;

const DescripitonText = styled.div`
  font-size: 1rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 300;
  color: white;
  flex-direction: row;
  position: absolute;
  bottom: -2rem;
  width: 100%;
  text-align: center;
  margin: auto;
`;

const CanvasWrapper = styled.div`
  aspect-ratio: ${(props) => (props.ratio ? props.ratio : 1)};
  height: ${(props) =>
    props.ratio && props.ratio == 1 && props.isMobile
      ? "80vw"
      : props.ratio && props.ratio == 1 && !props.isMobile
      ? "50%"
      : "80%"};
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const CanvasComponent = ({
  width,
  height,
  items,
  updateShowStatus,
  previewType,
}) => {
  const canvasRef = useRef(null);
  const [canvasURL, updateCanvasURL] = useState("");

  const { t } = useTranslation();
  useEffect(() => {
    if (items.length > 0) {
      drawImagesInCanvas();
    }
  }, [items]);

  const drawImagesInCanvas = async () => {
    var promises = [];
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    var backgroundImg = new Image();
    backgroundImg.crossOrigin = "anonymous";
    backgroundImg.onload = () => {
      ctx.drawImage(backgroundImg, 0, 0, width, height);
      if (items.length == 1) {
        updateCanvasURL(canvasRef.current.toDataURL("image/jpeg"));
      } else {
        for (var i = 1; i < items.length; i++) {
          promises.push(
            new Promise((res, rej) => {
              var index = i;
              if (index > 0) {
                var image = new Image();
                image.crossOrigin = "anonymous";
                image.onload = () => {
                  ctx.drawImage(image, 0, 0, width, height);
                  res("finish");
                };
                image.src = items[index];
              }
            })
          );
          Promise.all(promises).then(() => {
            updateCanvasURL(canvasRef.current.toDataURL("image/jpeg"));
          });
        }
      }
    };
    backgroundImg.src = items[0];
  };

  const downloadCanvas = () => {
    const canvas = canvasRef.current;
    const link = document.createElement("a");
    link.download = previewType ? `${previewType}.jpg` : "download.jpg";
    link.href = canvas.toDataURL("image/jpeg", 1.0);
    link.click();
  };

  const onClickClose = () => {
    updateShowStatus(false);
  };

  return (
    <ContentWrapper>
      <CanvasWrapper isMobile={IS_ANDROID || IS_IOS} ratio={width / height}>
        {canvasURL && (
          <img
            src={canvasURL}
            style={{
              margin: "auto",
              position: "absolute",
              height: "100%",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}
        <canvas
          width={width}
          height={height}
          ref={canvasRef}
          style={{
            visibility: "hidden",
            margin: "auto",
            position: "absolute",
            height: "100%",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <CancelIconStyled src={CancelIcon} onClick={onClickClose} />
        {IS_IOS ? (
          <DescripitonText>
            {t("PreviewGenerate.กดค้างที่ภาพเพื่อดาวน์โหลด")}
          </DescripitonText>
        ) : (
          <PreviewButtonWrapper ratio={width / height}>
            <DoseartButton
              isDisable={false}
              isPrimary={true}
              text={t("ดาวน์โหลดภาพ")}
              //width={parseInt(width) < 600 ? "65vw" : "21vw"}
              handleOnClick={() => downloadCanvas()}
            />
          </PreviewButtonWrapper>
        )}
      </CanvasWrapper>
    </ContentWrapper>
  );
};

export default CanvasComponent;
