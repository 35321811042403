import React, { useEffect, useState, useRef } from "react";
import { useRecoilValue } from "recoil";

import { useRecoilState } from "recoil";
import styled from "styled-components";
import GalleryList from "../../components/GalleryListComponent";
import DoseartButton from "../../components/DoseartButton";
import CancelIcon from "../../assets/gallery/cancelButton.svg";
import Div100vh from "react-div-100vh";
import {
  state as stateAtom,
  catalogDatabase as catalogDatabaseAtom,
  filterAW as filterAWAtom,
  previewIndex as previewIndexAtom,
  selectedIndex as selectedIndexAtom,
  selectedURL as selectedURLAtom,
} from "../../global/atom";
import { useSessionStorage } from "react-use";
import {
  sessionFormType,
  session_preview_index,
  session_select_index,
  session_select_url,
  session_filter_aw,
  reffer_url,
  session_collection_selection_info,
  session_artwork_database,
  discountCoupon,
  session_select_edit_card,
  session_nProducts,
  session_name_length,
} from "../../utils/sessionStorage";
import FilterComponent from "../../components/FilterComponent";
import DefaultGalleryComponent from "../../components/DefaultGalleryComponent";
import { useNavigate } from "react-router";
import { SubscriptionBannerWrapper } from "../HomePage/styles";
import useNavigationHandling from "../../utils/useNavigationHandling";
import {
  CancelIconStyled,
  CheckCircleStyled,
  GalleryCardWrapper,
  ContentWrapper,
  FilterIconStyled,
  FilterTextedStyled,
  FilterWrapperStyled,
  Gradient,
  HeaderWrapper,
  HeadTextStyled,
  ImageAreaWrapper,
  LargePreviewImageWrapper,
  LargePreviewWrapper,
  LineHeader,
  PositionImageWrapper,
  PreviewButtonWrapper,
  PreviewImage,
  PreviewImageWrapper,
  PriceNumber,
  SpinnerWrapper,
  SubHeadTextStyled,
  TagNumber,
  TipsTextStyled,
  TipsWrapper,
} from "./styles";
import { fi, fil } from "date-fns/locale";
import TagManager from "react-gtm-module";
import { useContext } from "react";
import { UserContext } from "../../utils/Context/userContext";
import { useTranslation } from "react-i18next";

const isTouchEvent = (event) => {
  return "touches" in event;
};

const preventDefault = (event) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

const FilterIconSvg = (
  <svg
    width="25"
    height="25"
    viewBox="0 0 36 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.66361 4.5H29.1636L17.8911 18.675L6.66361 4.5ZM0.476109 3.6225C5.02111 9.45 13.4136 20.25 13.4136 20.25V33.75C13.4136 34.9875 14.4261 36 15.6636 36H20.1636C21.4011 36 22.4136 34.9875 22.4136 33.75V20.25C22.4136 20.25 30.7836 9.45 35.3286 3.6225C36.4761 2.1375 35.4186 0 33.5511 0H2.25361C0.386109 0 -0.671391 2.1375 0.476109 3.6225Z"
      fill="#828282"
    />
  </svg>
);

const emptyFormFill = {
  fill: false,
  name: "",
  surname: "",
  zodiacId: "",
  birthDate: "",
  birthMonth: "",
  birthYear: "",
  showBirthDate: false,
  selectImageId: "",
  selectImage: "",
  id: "",
};

const AlacarteGalleryPage = () => {
  const { contextAWDb, couponProducts, voucher, updateFormList } =
    useContext(UserContext);
  //const [data,] = useSessionStorage(session_artwork_database.key,session_artwork_database.default)
  //const data = useRecoilValue(artworkDatabase);
  const [previewImage, updatePreviewImage] = useState(false);
  const [previewIndex, updatePreviewIndex] = useState(0);
  const [, updateState] = useRecoilState(stateAtom);
  const catalogDatabase = useRecoilValue(catalogDatabaseAtom);

  const [, update_preview_index] = useSessionStorage(
    session_preview_index.key,
    session_preview_index.default
  );
  const [, updateSelectIndex] = useSessionStorage(
    session_select_index.key,
    session_select_index.default
  );
  const [, updateSelectUrl] = useSessionStorage(
    session_select_url.key,
    session_select_url.default
  );
  //const [, updateState] = useSessionStorage(session_state.key,session_state.default);
  const [filterAW, updateFilterAW] = useSessionStorage(
    session_filter_aw.key,
    session_filter_aw.default
  );

  const { t } = useTranslation();

  const [urlImages, updateURLImages] = useState([]);
  const [, updateLargeUrlImages] = useState([]);
  const [vh, updateVh] = useState(window.innerHeight);
  var isTouchmove = false;
  const [width, setWidth] = useState(window.innerWidth);
  const [useWidth, setUseWidth] = useState(true);
  const [largePreviewLoading, setLoading] = useState(true);
  const [showFilter, setupShowFilter] = useState(false);
  const [tagToawSet, updateTagToAwSet] = useState(); // color = [dog_1,dog_2]
  const [awToTagSet, updateAWToTagSet] = useState(); // dog_1 = [color_1,color_2]
  const [catalog, updateCatalog] = useState(); //catalog : 'name'
  const [allGallery, updateAllGallery] = useState(); //all tag
  const defaultGalleryRef = useRef(null);
  const { navigateHandler } = useNavigationHandling();
  const [urlArrayImages_l_r, updateurlArrayImages_l_r] = useState([]);
  const [, update_formType] = useSessionStorage(
    sessionFormType.key,
    sessionFormType.default
  );
  const [nameLength,update_name_length] = useSessionStorage(
    session_name_length.key,
    session_name_length.default,
  )
  const [goToFill, updateGoToFill] = useState(false);
  const [image_select_index, update_image_select_index] = useState();
  const [session_reffer_url, update_session_reffer_url] = useSessionStorage(
    reffer_url.key,
    reffer_url.default
  );
  const [collection_selection_info, update_collection_selection_info] =
    useSessionStorage(
      session_collection_selection_info.key,
      session_collection_selection_info.default
    );
  const [, updateEditCard] = useSessionStorage(
    session_select_edit_card.key,
    session_select_edit_card.default
  );
  const [, updateNProducts] = useSessionStorage(
    session_nProducts.key,
    session_nProducts.default
  );
  const [discount_type] = useSessionStorage(
    discountCoupon.key,
    discountCoupon.default
  );
  const filterPrice = [59, 99, 159, 199, 249, 299];
  const headerRef = useRef();
  useEffect(() => {
    updateState("SelectImage");
    //check width to use height or width
    if (
      (width < 600 && 1.69 * width > window.innerHeight) ||
      (width > 600 && 0.25 * width > window.innerHeight)
    ) {
      setUseWidth(false);
    }
    updateEditCard(0);
    updateNProducts(1);
    // scroll top
    window.scrollTo(0, 0);
    window.addEventListener("resize", handleResize);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);
    return () => {
      window.removeEventListener("touchmove", preventDefault, {
        passive: true,
      });
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);

  const [aw_array, set_aw_array] = useState([]);

  const ItemArray = (urlArray) => {
    let colLeft = [];
    let colRight = [];
    urlArray.map((item, idx) => {
      var total_amount = item.price;
      if (voucher != "") {
        if (discount_type.type == "AMOUNT") {
          var discount_amount = (discount_type.amount_off / 100).toFixed(2);
          if (item.sale_price != "")
            total_amount = (item.sale_price - discount_amount).toFixed(2);
          else total_amount = (total_amount - discount_amount).toFixed(2);
        }
        //percent
        else if (discount_type.type == "PERCENT") {
          if (item.sale_price != "") {
            var discount_amount = (
              (discount_type.percent_off / 100) *
              item.sale_price
            ).toFixed(2);
            total_amount = (item.sale_price - discount_amount).toFixed(2);
          } else {
            var discount_amount = (
              (discount_type.percent_off / 100) *
              total_amount
            ).toFixed(2);
            total_amount = (total_amount - discount_amount).toFixed(2);
          }
        }
        if (total_amount < 0) total_amount = 0.0;
      }
      if (idx % 2 === 0) {
        var isItemSelected = false;
        colLeft = [
          ...colLeft,
          <PreviewImageWrapper>
            <PreviewImage src={item.path} isSelected={isItemSelected} />
            {voucher != "" && item.sale_price == "" ? (
              <PriceNumber>
                <div style={{ color: "black", textDecoration: "line-through" }}>
                  {item.price}{" "}
                </div>
                <div style={{ color: "red" }}>
                  &nbsp;{total_amount} {t("บาท")}
                </div>
              </PriceNumber>
            ) : voucher != "" && item.sale_price != "" ? (
              <PriceNumber>
                <div style={{ color: "black", textDecoration: "line-through" }}>
                  {item.price}{" "}
                </div>
                &nbsp;
                <div style={{ color: "red", textDecoration: "line-through" }}>
                  {item.sale_price}
                </div>
                <div style={{ color: "red" }}>
                  &nbsp;{total_amount} {t("บาท")}
                </div>
              </PriceNumber>
            ) : item.sale_price != "" ? (
              <PriceNumber>
                <div style={{ color: "black", textDecoration: "line-through" }}>
                  {item.price}{" "}
                </div>
                <div style={{ color: "red" }}>
                  &nbsp;{item.sale_price} {t("บาท")}
                </div>
              </PriceNumber>
            ) : (
              <PriceNumber>
                {item.price} {t("บาท")}
              </PriceNumber>
            )}
          </PreviewImageWrapper>,
        ];
      } else {
        var isItemSelected = false;
        colRight = [
          ...colRight,
          <PreviewImageWrapper>
            <PreviewImage src={item.path} isSelected={isItemSelected} />
            {voucher != "" && item.sale_price == "" ? (
              <PriceNumber>
                <div style={{ color: "black", textDecoration: "line-through" }}>
                  {item.price}{" "}
                </div>
                <div style={{ color: "red" }}>
                  &nbsp;{total_amount} {t("บาท")}
                </div>
              </PriceNumber>
            ) : voucher != "" && item.sale_price != "" ? (
              <PriceNumber>
                <div style={{ color: "black", textDecoration: "line-through" }}>
                  {item.price}{" "}
                </div>
                &nbsp;
                <div style={{ color: "red", textDecoration: "line-through" }}>
                  {item.sale_price}
                </div>
                <div style={{ color: "red" }}>
                  &nbsp;{total_amount} {t("บาท")}
                </div>
              </PriceNumber>
            ) : item.sale_price != "" ? (
              <PriceNumber>
                <div style={{ color: "black", textDecoration: "line-through" }}>
                  {item.price}{" "}
                </div>
                <div style={{ color: "red" }}>
                  &nbsp;{item.sale_price} {t("บาท")}
                </div>
              </PriceNumber>
            ) : (
              <PriceNumber>
                {item.price} {t("บาท")}
              </PriceNumber>
            )}
          </PreviewImageWrapper>,
        ];
      }
    });
    return [colLeft, colRight];
  };

  // component did mount
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "view_item",
        collection_id: collection_selection_info.collection_name
          ? collection_selection_info.collection_name
          : "",
        refID: session_reffer_url,
      },
    });
    return function cleanup() {
      enableScroll();
    };
  }, []);

  useEffect(() => {
    var awtags = {};
    var tagsaw = {};
    if (contextAWDb && catalogDatabase && allGallery) {
      allGallery.forEach((element) => {
        tagsaw[element] = new Set();
      });
      contextAWDb.forEach((e) => {
        awtags[e.id] = new Set();
        e.tags.forEach((tag) => {
          awtags[e.id].add(tag);
          tagsaw[tag].add(e.id);
        });
      });
      updateAWToTagSet(awtags);
      updateTagToAwSet(tagsaw);
    }
  }, [contextAWDb, catalog, allGallery]);

  useEffect(() => {
    if (urlImages) {
      var r = [];
      var l = [];
      urlImages.map((item, index) => {
        if (index % 2 == 0) l.push(item);
        else r.push(item);
      });
      updateurlArrayImages_l_r([l, r]);
    }
  }, [urlImages]);

  useEffect(() => {
    if (catalogDatabase) {
      var catalogtags = {};
      var g = [];
      //catalogDatabase[0].tag.forEach((tag) => {
      Object.entries(catalogDatabase[0].tag).forEach(([key, value]) => {
        catalogtags[key] = value;
        g.push(key);
      });
      //});
      updateAllGallery(g);
      updateCatalog(catalogtags);
    }
  }, [catalogDatabase]);

  useEffect(() => {
    if (filterAW && contextAWDb) {
      var urlArray = [];
      contextAWDb.forEach((dataValue, index) => {
        if (
          dataValue.attribute_name == "" &&
          (!("power_user" in dataValue) ||
            ("power_user" in dataValue &&
              dataValue.power_user == true &&
              filterPrice.includes(parseInt(dataValue.price))))
        ) {
          if (
            !filterAW instanceof Set ||
            filterAW.size === 0 ||
            filterAW.size === undefined
          ) {
            urlArray.push({
              path: dataValue.thumbnailPath,
              index: index,
              price: dataValue.price,
              sale_price:
                dataValue.price == "59"
                  ? "49"
                  : dataValue.price == "99"
                  ? "79"
                  : dataValue.price == "159"
                  ? "129"
                  : dataValue.price == "199"
                  ? "159"
                  : dataValue.price == "249"
                  ? "199"
                  : dataValue.price == "299"
                  ? "239"
                  : "",
            });
          } else if (filterAW.size !== 0) {
            filterAW.forEach((awValue) => {
              if (dataValue.id == awValue) {
                urlArray.push({
                  path: dataValue.thumbnailPath,
                  index: index,
                  price: dataValue.price,
                  sale_price:
                    dataValue.price == "59"
                      ? "49"
                      : dataValue.price == "99"
                      ? "79"
                      : dataValue.price == "159"
                      ? "129"
                      : dataValue.price == "199"
                      ? "159"
                      : dataValue.price == "249"
                      ? "199"
                      : dataValue.price == "299"
                      ? "239"
                      : "",
                });
              }
            });
          }
        }
      });
      updateURLImages(urlArray);
      let align_item = ItemArray(urlArray);
      set_aw_array(align_item);
    }
  }, [filterAW, contextAWDb]);

  const onClickItem = React.useCallback((i) => {
    if (!isTouchmove) {
      updatePreviewIndex(i);
      updatePreviewImage(true);
      setLoading(true);
      window.addEventListener("touchmove", preventDefault, { passive: false });

      disableScroll();
    }
    isTouchmove = false;
  }, []);

  const onClickItemDefault = React.useCallback((i) => {
    if (!isTouchmove) {
      updatePreviewIndex(i);
      updatePreviewImage(true);
      setLoading(true);
      window.addEventListener("touchmove", preventDefault, { passive: false });
    }
    isTouchmove = false;
  }, []);

  const onClickSelectImage = React.useCallback((i) => {
    updateSelectIndex(i);
    updatePreviewImage(false);
    setImageInFormList(i);
    window.removeEventListener("touchmove", preventDefault, {
      passive: true,
    });
    enableScroll();
  }, []);

  useEffect(() => {
    if (contextAWDb && goToFill) {
      updateSelectUrl(contextAWDb[image_select_index].thumbnailPath);
      update_formType(contextAWDb[image_select_index].form_type);
      let imageNameLength={
        first_name:contextAWDb[image_select_index].firstNameLength,
        last_name:contextAWDb[image_select_index].lastNameLength,
      }
      update_name_length(imageNameLength)
      update_preview_index(previewIndex);
      setTimeout(() => navigateHandler("/aisalc/fill"), 200);
    }
  }, [contextAWDb, goToFill]);

  const setImageInFormList = (index) => {
    update_image_select_index(index);
    updateGoToFill(true);
  };

  const handleResize = () => {
    let vh = window.innerHeight;
    updateVh(vh);
    let vw = window.innerWidth;
    setWidth(vw);
  };

  const handleTouchMove = () => {
    isTouchmove = true;
  };

  const handleTouchEnd = () => {
    isTouchmove = false;
  };

  const disableScroll = () => {
    let root = document.getElementById("root-div100");
    root.style.overflow = "hidden";
  };
  const enableScroll = () => {
    // window.onscroll = function () {};

    let root = document.getElementById("root-div100");
    root.style.overflow = "scroll";
  };

  const LargePreviewImage = () => {
    return (
      <LargePreviewWrapper>
        <ImageAreaWrapper vh={vh}>
          <PositionImageWrapper vh={vh * 0.9} useWidth={width / vh < 0.518}>
            <LargePreviewImageWrapper
              src={contextAWDb[previewIndex].largeThumbnailPath}
              useWidth={useWidth}
              onLoad={() => setLoading(false)}
            />
            {largePreviewLoading ? (
              <SpinnerWrapper color="#E4202A" size={70} />
            ) : (
              <>
                <CancelIconStyled
                  src={CancelIcon}
                  onClick={() => {
                    updatePreviewImage(false);
                    //window.removeEventListener("scroll", preventMotion,true);
                    //window.removeEventListener("touchmove", preventMotion,true);
                    window.removeEventListener("touchmove", preventDefault, {
                      passive: true,
                    });
                    enableScroll();
                    //var htmlElement = document.querySelector("html");
                    //htmlElement.style.overflowY = 'auto';
                  }}
                />
                <PreviewButtonWrapper>
                  <DoseartButton
                    isDisable={false}
                    isPrimary={true}
                    text={t("GalleryPage.เลือกภาพนี้")}
                    width={parseInt(width) < 600 ? "42vw" : "12.5vw"}
                    handleOnClick={() => onClickSelectImage(previewIndex)}
                  />
                </PreviewButtonWrapper>
              </>
            )}
          </PositionImageWrapper>
        </ImageAreaWrapper>
      </LargePreviewWrapper>
    );
  };

  const items = urlImages.map((item) => {
    //var isItemSelected = item.index == selectIndex;
    var isItemSelected = false;
    return (
      <PreviewImageWrapper>
        <PreviewImage src={item.path} isSelected={isItemSelected} />
        <PriceNumber>
          {item.price} {t("บาท")}
        </PriceNumber>
      </PreviewImageWrapper>
    );
  });

  return (
    <Div100vh id="div-100-gallery-page" style={{ paddingTop: "3rem" }}>
      <SubscriptionBannerWrapper
        state="aisalc"
        jpg_small={
          "https://storage.googleapis.com/doseart_banner/aisalc%20/aisalc_inside_banner_small.jpg"
        }
        jpg_med={
          "https://storage.googleapis.com/doseart_banner/aisalc%20/aisalc_inside_banner_medium.jpg"
        }
        jpg_large={
          "https://storage.googleapis.com/doseart_banner/aisalc%20/aisalc_inside_banner_large.jpg"
        }
      />
      <HeaderWrapper>
        <div style={{ display: "flex", flexDirection: "row", margin: "auto" }}>
          <HeadTextStyled>{t("GalleryPage.เลือกภาพที่ต้องการ")}</HeadTextStyled>
          <FilterWrapperStyled
            onClick={() => {
              disableScroll();
              setupShowFilter(true);
              // updatePreviewImage(true);
              // window.addEventListener("touchmove", preventDefault, {
              //   passive: false,
              // });
            }}
          >
            <FilterIconStyled>{FilterIconSvg}</FilterIconStyled>
          </FilterWrapperStyled>
        </div>
      </HeaderWrapper>

      <ContentWrapper isPreview={previewImage}>
        {showFilter ? (
          <FilterComponent
            onClickClose={() => {
              enableScroll();
              setupShowFilter(false);
              // updatePreviewImage(false);
              // window.removeEventListener("touchmove", preventDefault, {
              //   passive: true,
              // });
            }}
            updateFilterAW={updateFilterAW}
            allGallery={allGallery}
            catalog={catalog}
            awToTagSet={awToTagSet}
            tagToawSet={tagToawSet}
            catalogDatabase={catalogDatabase}
          />
        ) : (
          <></>
        )}
        {
          /*contextAWDb.length == urlImages.length*/ false ? (
            <DefaultGalleryComponent
              data={contextAWDb}
              catalogDatabase={catalogDatabase}
              tagToawSet={tagToawSet}
              onClickItem={onClickItemDefault}
              ref={defaultGalleryRef}
            />
          ) : (
            <></>
          )
        }

        {/* <SubHeadTextStyled>คลังรูปภาพ</SubHeadTextStyled> */}
        {/* <GalleryFilter
          updatePreview={onClickItem}
          items={items}
          urlImages={urlImages}
          previewImage={previewImage}
        /> */}
        <GalleryCardWrapper>
          <GalleryFilter
            updatePreview={onClickItem}
            items={aw_array[0]}
            urlImages={urlArrayImages_l_r[0]}
            previewImage={previewImage}
            position={"left"}
          />
          <GalleryFilter
            updatePreview={onClickItem}
            items={aw_array[1]}
            urlImages={urlArrayImages_l_r[1]}
            previewImage={previewImage}
            position={"right"}
          />
        </GalleryCardWrapper>
        {previewImage ? <LargePreviewImage /> : <></>}
      </ContentWrapper>
    </Div100vh>
  );
};

export const GalleryListWrapper = styled.div`
  margin-right: ${(props) => (props.position === "right" ? "0" : "0.5rem")};
  margin-left: ${(props) => (props.position === "right" ? "0.5rem" : "0")};
  width: 100%;
  @media (min-width: 600px) {
    width: 100%;
    margin: 0;
  }
`;

export const GalleryFilter = ({
  updatePreview,
  items,
  urlImages,
  previewImage,
  position,
}) => {
  //const [previewImage, updatePreviewImage] = useState(false);
  var isTouchmove = false;

  const onLongPress = (i) => {};

  const onClick = (i) => {
    if (!isTouchmove) {
      updatePreview(urlImages[i].index);
    }
    isTouchmove = false;
  };

  return (
    <GalleryListWrapper position={position}>
      <GalleryList
        items={items}
        onLongPressItem={onLongPress}
        onClickItem={onClick}
        isPreview={previewImage}
        position={position}
      ></GalleryList>
    </GalleryListWrapper>
  );
};

export default AlacarteGalleryPage;
