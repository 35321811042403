import React, { useState, useEffect,useRef } from "react";
import { useNavigate } from "react-router";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from 'styled-components';
import ValidateCoupon from "../components/ValidateCouponComponent";
import {
    state as stateAtom,
  } from "../global/atom";
import useNavigationHandling from "../utils/useNavigationHandling";
const ContentWrapper = styled.div`
    display :flex;
    flex-direction: column;
    @media(min-width:600px){
        width: 40vw;
        margin:auto;
        min-width: 500px;
    }
`;

const CouponPage = ()=>{
    const { navigateHandler } = useNavigationHandling()
    const [state, updateState] = useRecoilState(stateAtom);
    const [codeParams,setCodeParams] = useState()
    useEffect(()=>{
        updateState("home");
        let searchParams = new URLSearchParams(window.location.search);
        if(searchParams.has('code')){
            setCodeParams(searchParams.get('code'))
        }
    },[])
    return(
        <ContentWrapper>
            <ValidateCoupon 
                code ={codeParams}
                amount={59}
                closeVoucherPopup = {()=>{navigateHandler('/home')}}
            />
        </ContentWrapper>
    )
}

export default CouponPage;