import React, { useEffect, useMemo, useState, useRef, useContext } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState, useRecoilValue } from "recoil";
import styled from "styled-components";
import { use100vh } from "react-div-100vh";
import ReactPixel from "react-facebook-pixel";
import { fbc_cookies, fbp_cookies } from "../../global/atom";
import {
  SectionWrapper,
  TitleWrapper,
  DescriptionWrapper,
  ActionButtonWrapper,
  DetailSectionWrapper,
  DetailImage,
  DetailSectionBg,
  DetailImageWrapper,
} from "./styles";
import {
  collection_selection_info as collection_selection_info_atom,
  state as stateAtom,
} from "../../global/atom";
import {
  checkLangAvailableDatabase,
  checkRefferURL,
  COLLECTION_API_URL,
  DEPLOY_VERSION,
  fetchAndHandleData,
  handleFontFamily,
  handleNewLine,
  sendConversionAPI,
  sendEvent,
} from "../../utils/utils";
import {
  filterCheck,
  package_name_detail,
  reffer_url,
  sessionClientID,
  sessionReferenceOrder,
  session_artwork_show_birthdate,
  session_bundle_tag_price,
  session_collection_selection_info,
  session_edit_card_state,
  session_fbc_cookies,
  session_fbp_cookies,
  session_filter_aw,
  session_multiple_index_selection,
  session_multiple_url_selection,
  session_multple_attribute_selection,
  session_nProducts,
  session_order_type_id,
  session_package_id_selection_atom,
  session_prev_bundle_tag_price,
  session_prev_form_list,
  session_select_index,
  useSessionStorage,
} from "../../utils/sessionStorage";
import LazyLoad from "react-lazy-load";
import TagManager from "react-gtm-module";
import gtag, { install } from "ga-gtag";
import { UserContext } from "../../utils/Context/userContext";
import useNavigationHandling from "../../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";

const IndexDetail = styled.div`
  position: fixed;
  background-color: rgba(196, 196, 196, 0.65);
  color: white;
  padding: 10px 17px;
  margin-top: 1vh;
  right: 10px;
  @media (min-width: 600px) {
    //width: 38vw;
    right: 30px;
  }
  border-radius: 52px;
  font-size: 0.7rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: normal;
`;
const emptyFormFill = {
  fill: false,
  name: "",
  surname: "",
  zodiacId: "",
  birthDate: "",
  birthMonth: "",
  birthYear: "",
  showBirthDate: false,
  selectImageId: "",
  selectImage: "",
  id: "",
};

const IntroPage = () => {
  const { navigateHandler } = useNavigationHandling();
  //const collection_selection_info = useRecoilValue(collection_selection_info_atom);
  const [collection_selection_info, update_collection_selection_info] =
    useSessionStorage(
      session_collection_selection_info.key,
      session_collection_selection_info.default
    );
  const { collection_id,partner_id } = useParams();
  //const fbc= useRecoilValue(fbc_cookies)
  //const fbp = useRecoilValue(fbp_cookies)
  const [fbp] = useSessionStorage(
    session_fbp_cookies.key,
    session_fbp_cookies.default
  );
  const [fbc] = useSessionStorage(
    session_fbc_cookies.key,
    session_fbc_cookies.default
  );
  const [detailPath, updateDetailPath] = useState();
  const [imageArr, set_imageArr] = useState([]);
  const [nextPage, updateNextPage] = useState("");
  const imageListRef = useRef([]);
  const [imageIntersect, updateImageIntersect] = useState(0);
  const height = use100vh();
  const { updateFormList } = useContext(UserContext);
  const [state, updateState] = useRecoilState(stateAtom);
  const [session_reffer_url, update_session_reffer_url] = useSessionStorage(
    reffer_url.key,
    reffer_url.default
  );
  const [, updateNProducts] = useSessionStorage(
    session_nProducts.key,
    session_nProducts.default
  );
  const [, updateBundleTagPrice] = useSessionStorage(
    session_bundle_tag_price.key,
    session_bundle_tag_price.default
  );
  const [, update_buffer_bundleTagPrice] = useSessionStorage(
    session_prev_bundle_tag_price.key,
    session_prev_bundle_tag_price.default
  );
  const [, updateMultipleAttribute] = useSessionStorage(
    session_multple_attribute_selection.key,
    session_multple_attribute_selection.default
  );
  const [, update_edit_card_state] = useSessionStorage(
    session_edit_card_state.key,
    session_edit_card_state.default
  );
  const [, updateSelectedIndex] = useSessionStorage(
    session_select_index.key,
    session_select_index.default
  );
  const [, update_oreder_type_id] = useSessionStorage(
    session_order_type_id.key,
    session_order_type_id.default
  );
  const [, update_package_id_selection] = useSessionStorage(
    session_package_id_selection_atom.key,
    session_package_id_selection_atom.default
  );
  const [, updateMutipleIndexSelection] = useSessionStorage(
    session_multiple_index_selection.key,
    session_multiple_index_selection.default
  );
  const [, updatePrevList] = useSessionStorage(
    session_prev_form_list.key,
    session_prev_form_list.default
  );
  const [, updateURLSelection] = useSessionStorage(
    session_multiple_url_selection.key,
    session_multiple_url_selection.default
  );
  const [, update_artwork_show_birthDate] = useSessionStorage(
    session_artwork_show_birthdate.key,
    session_artwork_show_birthdate.default
  );
  const [, updateReferenceOrder] = useSessionStorage(
    sessionReferenceOrder.key,
    sessionReferenceOrder.default
  );
  const [, update_package_name_detail] = useSessionStorage(
    package_name_detail.key,
    package_name_detail.default
  );
  const [, updateFilter] = useSessionStorage(
    filterCheck.key,
    filterCheck.default
  );
  const [, updateFilterAW] = useSessionStorage(
    session_filter_aw.key,
    session_filter_aw.default
  );
  const [, update_session_client_id] = useSessionStorage(
    sessionClientID.key,
    sessionClientID.default
  );

  const { t,i18n } = useTranslation();

  const handleChangePage = (input) => {
    // enable scroll
    const root = document.getElementById("root");
    root.style.overflow = "unset";
    updateNextPage(input);
    //navigateHandler(input);
  };

  const resetState = () => {
    // clear state
    updateState("home");
    updateNProducts("");
    updateBundleTagPrice("");
    update_buffer_bundleTagPrice("");
    updateMultipleAttribute([]);
    update_edit_card_state("");
    updateSelectedIndex("");
    update_oreder_type_id("");
    update_package_id_selection("");
    updateMutipleIndexSelection([]);
    updateFormList([emptyFormFill]);
    updatePrevList([emptyFormFill]);
    updateURLSelection([]);
    updateMultipleAttribute([]);
    update_artwork_show_birthDate("");
    updateReferenceOrder("");
    update_package_name_detail({
      package_name: "",
      icon: "",
    });
    updateFilter("");
    updateFilterAW(new Set());
  };

  useEffect(() => {
    
    resetState();
    var MEASUREMENT_ID = "G-5TM9EK429P";
    if (DEPLOY_VERSION == "PROD") MEASUREMENT_ID = "G-2PCQ99MS9K";
    install(MEASUREMENT_ID);

    gtag("get", MEASUREMENT_ID, "client_id", (field) => {
      update_session_client_id(field);
    });

    updateState("home");
    fetchCollection();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    if (urlParams.has("refID")) {
      var ref_url = urlParams.get("refID");
      checkRefferURL(ref_url).then((res) => {
        if (res.result) {
          if (session_reffer_url == "") {
            if (ref_url == "BbJ4VowBI3pLuupSP45n") {
              TagManager.dataLayer({
                dataLayer: {
                  event: "true_pageview",
                },
              });
            }
            sendEvent(ref_url, "page_view");
          }
          update_session_reffer_url(ref_url);
        }
      });
    }
    if(urlParams.has("partner")){
      var ref_url = urlParams.get("partner");
      checkRefferURL(ref_url).then((res) => {
        if (res.result) {
          update_session_reffer_url(ref_url);
        }
      });
      TagManager.dataLayer({
        dataLayer: {
          event: "pageview",
          refID: ref_url,
        },
        dataLayer: {
          event: "view_content",
          collection_id: collection_id,
          refID: ref_url,
        },
      });
    }
    else{
      TagManager.dataLayer({
        dataLayer: {
          event: "view_content",
          collection_id: collection_id,
          refID: session_reffer_url,
        },
      });
    }
    if (DEPLOY_VERSION == "PROD") {
      ReactPixel.init("973339143546259");
      ReactPixel.track("ViewContent");
      sendConversionAPI("ViewContent", fbc, fbp);
    }
    //check partner link
    if(partner_id){
      checkRefferURL(partner_id).then((res)=>{
        if(res.result){
          update_session_reffer_url(partner_id)
        }
      })
    }
  }, []);

  useEffect(() => {
    if (nextPage !== "") {
      if(window.location.href.includes("redeem"))
      navigateHandler("/redeem/package");
      else navigateHandler("/package");
    }
  }, [nextPage]);

  const options = {
    root: null,
    rootMargin: "0px",
    threshold: 0.7,
  };

  useEffect(() => {
    if (
      imageListRef.current &&
      imageArr.length > 0 &&
      "IntersectionObserver" in window
    ) {
      imageListRef.current = imageListRef.current.slice(0, imageArr.length);
      var observers = [];
      imageListRef.current.map((imageRef, index) => {
        var observer = new IntersectionObserver(
          (entries) => callbackFn(entries, index),
          options
        );
        observer.observe(imageRef);
        observers.push(observer);
      });
      setTimeout(() => updateImageIntersect(0), 500);
      return () => {
        for (var i = 0; i < observers.length; i++) {
          //observers[i].unobserve(imageListRef.current[i])
          observers[i].disconnect();
        }
      };
    }
  }, [imageListRef, imageArr]);

  const fetchCollection = () => {
    fetchAndHandleData(
      COLLECTION_API_URL + "/collections/" + collection_id
    ).then((res_collection) => {
      if (res_collection.data) {
        update_collection_selection_info(res_collection.data);
      } else {
        navigateHandler("/home");
      }
    });
  };

  const callbackFn = (entries, index) => {
    const [entry] = entries;
    if (entry.isIntersecting) {
      updateImageIntersect(index);
    }
  };

  // use detail_image_count, collection_name
  useEffect(() => {
    if (
      collection_selection_info &&
      collection_selection_info.collection_name
    ) {
      //check 
      var imageURL = `https://storage.googleapis.com/doseart_banner/${collection_selection_info.collection_name}/${i18n.language}/Mobile/1.png`;
      const checkDetailUrl = new Promise((resolve,reject)=>{
        const image = new Image();
        image.src = imageURL
        image.onload = () =>{
          var path = `https://storage.googleapis.com/doseart_banner/${collection_selection_info.collection_name}/${i18n.language}/`
          resolve(path)
        };
        image.onerror = ()=>{
          var path =`https://storage.googleapis.com/doseart_banner/${collection_selection_info.collection_name}/`
          resolve(path)
        }
      })
      checkDetailUrl.then((detail_path)=>{
        //TODO i18n for intro page to select each detail page
      if (window.innerWidth < 420) {
        updateDetailPath(
          detail_path+"Mobile/"
        );
      } else {
        //updateDetailPath("/images/detail/" +collection_selection_info.collection_name+'/Desktop/')
        updateDetailPath(
          detail_path+"Desktop/"
        );
      }

      let arrCount = Array.from(
        Array(parseInt(checkLangAvailableDatabase(collection_selection_info.detail_image_count))).keys()
      );
      imageListRef.current = imageListRef.current.slice(0, arrCount.length);
      set_imageArr(arrCount);
      })
    }
    // disable scroll
    const root = document.getElementById("root");
    root.style.overflow = "hidden";
    return function cleanup() {
      const root = document.getElementById("root");
      root.style.overflow = "unset";
    };
  }, [collection_selection_info]);

  return (
    <DetailSectionWrapper height={height}>
      {"IntersectionObserver" in window && (
        <IndexDetail>{imageIntersect + 1 + "/" + imageArr.length}</IndexDetail>
      )}
      <DetailSectionBg
        bg_large={
          "https://storage.googleapis.com/doseart_banner/" +
          collection_selection_info.collection_name +
          "/Desktop/large.jpg"
        }
        bg_medium={
          "https://storage.googleapis.com/doseart_banner/" +
          collection_selection_info.collection_name +
          "/Desktop/medium.jpg"
        }
        bg_small={
          "https://storage.googleapis.com/doseart_banner/" +
          collection_selection_info.collection_name +
          "/Mobile/small.jpg"
        }
      />
      {/* loop image over images/detail/{name}/{index} */}
      <DetailImageWrapper height={height}>
        {collection_selection_info &&
          imageArr.map((count) => {
            return (
              <DetailImage
                key={count}
                isLast={imageArr.length === count + 1}
                height={height}
              >
                <img
                  style={{
                    pointerEvents: "none",
                    zIndex: "1",
                  }}
                  ref={(el) => (imageListRef.current[count] = el)}
                  src={detailPath + (count + 1) + ".png"}
                  loading="lazy"
                />
              </DetailImage>
            );
          })}
      </DetailImageWrapper>
      <ActionButtonWrapper>
        <button onClick={() => handleChangePage("/package")}>
          {t("IntroPage.ต่อไป")}
        </button>
      </ActionButtonWrapper>
    </DetailSectionWrapper>
  );
};

export default IntroPage;
