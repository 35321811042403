import { fluidRange } from "polished"

export default function fluidText(from, to) {
	return fluidRange({
		prop: 'fontSize',
		fromSize: `${from}px`,
		toSize: `${to ? to : from * 3}px`
	},
		'400px',
		'1000px'
	)
}