import ReactDOM from 'react-dom'
import React, { Component } from 'react'

import { createReactEditorJS } from 'react-editor-js'

import { EDITOR_JS_TOOLS } from '../utils/tools'
import { Button } from '@material-ui/core'

const ReactEditorJS = createReactEditorJS()

class ReactEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      count: 0,
    };
    this.editorRef = React.createRef()
  }
  render() {
    const {defaultValue,readOnly} = this.props

    const onSaved = ((async()=>{
      const savedData = await this.editorRef.current.save()
      downloadJson(JSON.stringify(savedData))
    }))
    const handleInitialize = ((instance) => {
      this.editorRef.current = instance
    })

    const downloadJson = (data)=>{
      var a = document.createElement('a')
      var file = new Blob([data],{type:'text/plain'})
      a.href = URL.createObjectURL(file)
      a.download = 'json.txt'
      a.click()
    }

    return (
      <>
      {!readOnly&&(<Button onClick={onSaved}>Saved</Button>)}
      <ReactEditorJS
        readOnly={readOnly}
        tools={EDITOR_JS_TOOLS}
        defaultValue={defaultValue}
        onInitialize={handleInitialize}
        style={{paddingBottom:'100px'}}
      />
      </>
    )
  }
}

export default ReactEditor

/*ReactDOM.render(<ReactEditor />, document.getElementById('app'))*/