import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  @media (min-width: 600px) {
    width: 88.46vw;
    margin: auto;
    min-width: 450px;
    padding: 0;
  }
  //align-items:center;
`;

const GradientBottom = styled.div`
  position: fixed;
  width: 100vw;
  height: 12vh;
  bottom: 0px;

  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 63.02%
  );
  border-radius: 0px;
`;

const BackToTopWrapper = styled.div`
  height: 12vh;
  //position: fixed;
  width: 100vw;
  //bottom: 0px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-size: 1.2em;
`;

const RefundPage = () => {
  const { t } = useTranslation();
  const ref = useRef();

  const RefundCondition = () => {
    return (
      <>
        <h4>
          <span class="c14 c22">Refund Policy</span>
        </h4>
        <p class="c5">
          <span class="c14 c7">
            If the manufacturing company is found to have provided incorrect
            products and failed to rectify the delayed delivery after
            verification, customers are eligible to request a refund. However,
            refunds due to a change of mind by the buyer will not be accepted,
            unless otherwise specified in this refund policy.
          </span>
        </p>
        <h4>
          <span class="c14 c22">{t("นโยบายการคืนเงิน")} </span>
        </h4>
        {t(
          "หากตรวจสอบแล้วบริษัทผลิตสินค้าให้ผิดและแก้ไขจัดส่งไม่เป็นไปตามเวลากำหนด"
        )}
        {t("สามารถยื่นคำขอคืนเงินได้แต่หากเกิดจากการเปลี่ยนใจ")}
        {t("ของผู้ซื้อไม่อาจยื่นคำร้องขอคืนเงินได้")}
        {t("เว้นแต่จะระบุไว้ในนโยบายการคืนเงินนี้")}
      </>
    );
  };

  return (
    <ContentWrapper ref={ref}>
      <RefundCondition />
      <GradientBottom />
    </ContentWrapper>
  );
};

export default RefundPage;
