import React, { useEffect, useState } from "react";
import {
  HeadTextStyled,
  TipsWrapper,
  TipImage,
  TipsTextStyled,
  ImageWrapper,
  Image,
  GalleryCardWrapper,
  GalleryWrapper,
  PreviewImageWrapper,
  PreviewImage,
  CloseButton,
  ActionButton,
  BGDark,
  GalleryLeft,
  GalleryRight,
  ActionButtonWrapper,
  PreviewImage_,
} from "./styles";
import {
  artworkDatabase,
  isPreviewImage,
  selectedURL as selectedURLAtom,
  selectedIndex as selectedIndexAtom,
  multiple_index_selection,
  multiple_url_selection,
  multple_attribute_selection,
} from "../../global/atom";
import { useRecoilState, useRecoilValue } from "recoil";
import tipImg from "../../assets/cart/tips.svg";
import useLongPress from "../../utils/useLongPress";
import CancelIcon from "../../assets/gallery/cancelButton.svg";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import SELECT_ICON from "../../assets/select.png";

import { Check } from "@material-ui/icons";
import {
  package_name_detail,
  sessionFormType,
  session_artwork_database,
  session_collection_selection_info,
  session_is_preview_image,
  session_multiple_index_selection,
  session_multiple_url_selection,
  session_multple_attribute_selection,
  session_name_length,
  session_select_index,
  session_select_url,
  useSessionStorage,
} from "../../utils/sessionStorage";
import { checkLangAvailableDatabase, handleNewLine } from "../../utils/utils";
import { useContext } from "react";
import { UserContext } from "../../utils/Context/userContext";
import useNavigationHandling from "../../utils/useNavigationHandling";
import { useTranslation } from "react-i18next";

let isTouchmove = false;
const isTouchEvent = (event) => {
  return "touches" in event;
};
const preventDefault = (event) => {
  if (!isTouchEvent(event)) return;

  if (event.touches.length < 2 && event.preventDefault) {
    event.preventDefault();
  }
};

const NotationSelection = styled.div`
  position: fixed;
  z-index: 10;
  bottom: 13%;
  width: 50%;
  height: 5%;
  left: 50%;
  transform: translate(-50%, 0);

  background: rgba(196, 196, 196, 0.65);
  border-radius: 52px;

  display: flex;
  justify-content: center;
  align-items: center;

  color: white;

  @media (min-width: 600px) {
    width: 30vw;
  }
`;

// FIXME: preview large image render previous image before load new src
// FIXME: select same picture

const GalleryPageMultipleSelection = ({
  filter_topic_id,
  max_image_selection,
}) => {
  const { contextAWDb } = useContext(UserContext);
  //const [artwork_db,] = useSessionStorage(session_artwork_database.key,session_artwork_database.default)
  //const artwork_db = useRecoilValue(artworkDatabase); // data
  const [filtered_artwork_db, update_filtered_artwork_db] = useState("");
  const [filtered_item_id, update_filtered_item_id] = useState("");
  const [preview, updatePreview] = useState("");
  const [, updateFormType] = useSessionStorage(
    sessionFormType.key,
    sessionFormType.default
  );
  const [nameLength,update_name_length] = useSessionStorage(
    session_name_length.key,
    session_name_length.default,
  )
  // handle touch move
  const [width, setWidth] = useState(window.innerWidth);
  const [, setHeight] = useState(window.innerHeight);
  const [, setUseWidth] = useState(true);

  /*const [isPreview, set_isPreview] = useRecoilState(isPreviewImage);
  const [selectedURL, updateSelectUrl] = useRecoilState(selectedURLAtom);
  const [selectedIndex, updateSelectedIndex] = useRecoilState(selectedIndexAtom);
  const [multipleSelectedUrl, updateMultipleSelectedUrl] = useRecoilState(multiple_url_selection);
  const [multipleAttribute, updateMultipleAttribute] = useRecoilState(multple_attribute_selection);
  const [multipleSelectedIndex, updateMultipleSelectedIndex] =
    useRecoilState(multiple_index_selection);*/

  const [isPreview, set_isPreview] = useSessionStorage(
    session_is_preview_image.key,
    session_is_preview_image.default
  );
  const [package_name, update_package_name_detail] = useSessionStorage(
    package_name_detail.key,
    package_name_detail.default
  );
  const [multipleSelectedUrl, updateMultipleSelectedUrl] = useSessionStorage(
    session_multiple_url_selection.key,
    session_multiple_url_selection.default
  );
  const [multipleAttribute, updateMultipleAttribute] = useSessionStorage(
    session_multple_attribute_selection.key,
    session_multple_attribute_selection.default
  );
  const [multipleSelectedIndex, updateMultipleSelectedIndex] =
    useSessionStorage(
      session_multiple_index_selection.key,
      session_multiple_index_selection.default
    );

  const [collection_selection_info] = useSessionStorage(
    session_collection_selection_info.key,
    session_collection_selection_info.default
  );

  const [ready2go, set_ready2go] = useState(false);
  const [mulImageSelect, updateMulImageSelect] = useState([]);
  const { navigateHandler } = useNavigationHandling();

  const { t } = useTranslation();

  useEffect(() => {
    if (
      contextAWDb &&
      !filtered_artwork_db &&
      filter_topic_id &&
      !filtered_item_id
    ) {
      let filtered_artwork = [],
        filtered_id = [];
      contextAWDb.map((item, idx) => {
        if (item.tags.includes(filter_topic_id)) {
          filtered_artwork.push(item);
          filtered_id.push(idx);
        }
      });
      update_filtered_artwork_db(filtered_artwork);
      update_filtered_item_id(filtered_id);
    }
  });
  useEffect(() => {
    if (preview) {
      set_isPreview(true);
    } else set_isPreview(false);
  }, [preview]);
  // start handle touch move
  useEffect(() => {
    if (
      (width < 600 && 1.69 * width > window.innerHeight) ||
      (width > 600 && 0.25 * width > window.innerHeight)
    ) {
      setUseWidth(false);
    } else {
    }
    window.addEventListener("resize", handleResize);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);
    return () => {
      window.removeEventListener("touchmove", preventDefault, {
        passive: true,
      });
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, []);
  const handleResize = () => {
    let vh = window.innerHeight;
    setHeight(vh);
    let vw = window.innerWidth;
    setWidth(vw);
  };
  const handleTouchMove = () => {
    isTouchmove = true;
  };
  const handleTouchEnd = () => {
    isTouchmove = false;
  };
  // end handle touch move

  const handleSelectImage = (idx, select) => {
    if (select) {
      if (mulImageSelect.length < max_image_selection) {
        updateMulImageSelect((arr) => [...arr, idx]);
        updateMultipleSelectedIndex((arr) => [...arr, filtered_item_id[idx]]);
        updateMultipleSelectedUrl((arr) => [
          ...arr,
          filtered_artwork_db[idx].thumbnailPath,
        ]);
        set_isPreview(false);
      } else {
        return;
      }
    } else {
      updateMulImageSelect((arr) => arr.filter((item) => item !== idx));
      updateMultipleSelectedIndex((arr) =>
        arr.filter((item) => item !== filtered_item_id[idx])
      );
      updateMultipleSelectedUrl((arr) =>
        arr.filter((item) => item !== filtered_artwork_db[idx].thumbnailPath)
      );
    }
  };

  useEffect(() => {
    if (multipleSelectedIndex.length == max_image_selection) {
      set_ready2go(true);
    } else {
      set_ready2go(false);
    }
  }, [multipleSelectedIndex, multipleSelectedUrl]);

  const goToFillInformation = () => {
    var temp = [];
    updateFormType(contextAWDb[multipleSelectedIndex[0]].form_type);
    let imageNameLength={
      first_name:contextAWDb[multipleSelectedIndex[0]].firstNameLength,
      last_name:contextAWDb[multipleSelectedIndex[0]].lastNameLength,
    }
    update_name_length(imageNameLength)
    for (var x = 0; x < multipleSelectedIndex.length; x++) {
      temp.push(contextAWDb[multipleSelectedIndex[x]].attribute_name);
    }
    updateMultipleAttribute(temp);
    setTimeout(() => {
    if(window.location.href.includes('redeem')) navigateHandler("/redeem/filled")
    else navigateHandler("/filled")
  }, 100);
  };

  const ItemArray = () => {
    let colLeft = [];
    let colRight = [];
    filtered_artwork_db.map((item, idx) => {
      if (idx % 2 === 0) {
        colLeft = [
          ...colLeft,
          <ImageWrapperComponent
            updatePreview={updatePreview}
            item={item}
            idx={idx}
            key={"image_id" + idx}
            handleSelectImage={handleSelectImage}
            selected={mulImageSelect.includes(idx)}
            set_isPreview={set_isPreview}
            preview={preview}
          />,
        ];
      } else {
        colRight = [
          ...colRight,
          <ImageWrapperComponent
            updatePreview={updatePreview}
            item={item}
            idx={idx}
            key={"image_id" + idx}
            handleSelectImage={handleSelectImage}
            selected={mulImageSelect.includes(idx)}
            set_isPreview={set_isPreview}
            preview={preview}
          />,
        ];
      }
    });

    return [colLeft, colRight];
  };
  return (
    <>
      <HeadTextStyled>
        {t(handleNewLine(checkLangAvailableDatabase(package_name.title)))}
      </HeadTextStyled>
      <TipsWrapper>
        <TipsTextStyled>
          {`• ${t("GalleryPage.กดเลือกบริเวณกลางภาพ เพื่อดูภาพขนาดใหญ่")}\n• ${t(
            "GalleryPage.กดเลือกที่มุมขวาล่าง เพื่อเลือกภาพให้ครบตามรูปแบบที่เลือก"
          )}\n• ${t(
            "GalleryPage.ภาพที่เหมาะกับพื้นดวงของท่าน จะแสดงหลังกรอกข้อมูลเสร็จเรียบร้อยในหน้ารายการสั่งซื้อ"
          )}`}
        </TipsTextStyled>
      </TipsWrapper>
      <GalleryCardWrapper>
        <GalleryWrapper>
          {filtered_artwork_db && (
            <>
              <GalleryLeft>{ItemArray()[0]}</GalleryLeft>
              <GalleryRight>{ItemArray()[1]}</GalleryRight>
            </>
          )}
        </GalleryWrapper>
      </GalleryCardWrapper>
      <NotationSelection>
        {t("GalleryPage.เลือกแล้ว")} {multipleSelectedIndex.length} {t("GalleryPage.ภาพ")}
      </NotationSelection>
      <ActionButtonWrapper>
        <button disabled={!ready2go} onClick={goToFillInformation}>
          {t("ต่อไป")}
        </button>
      </ActionButtonWrapper>
      <PreviewImageWrapper isPreview={isPreview}>
        <BGDark isPreview={isPreview} />
        <PreviewImage_
          bgUrl={preview && checkLangAvailableDatabase( filtered_artwork_db[preview - 1].largeThumbnailPath)}
          isPreview={isPreview}
        >
          <CloseButton
            src={CancelIcon}
            onClick={() => set_isPreview(false)}
            isPreview={isPreview}
          />
          <ActionButton
            disable={mulImageSelect.length >= max_image_selection}
            primary={true}
            width={parseInt(width) < 600 ? "42vw" : "12.5vw"}
            isPreview={isPreview}
            onClick={() =>
              handleSelectImage(
                preview - 1,
                !mulImageSelect.includes(preview - 1)
              )
            }
          >
            {t("GalleryPage.เลือกภาพนี้")}
          </ActionButton>
        </PreviewImage_>
        {/* <PreviewImage
          src={preview && filtered_artwork_db[preview - 1].largeThumbnailPath}
          isPreview={isPreview}
        />
        <CloseButton src={CancelIcon} onClick={() => set_isPreview(false)} isPreview={isPreview} />
        <ActionButton
          disable={mulImageSelect.length >= max_image_selection}
          primary={true}
          width={parseInt(width) < 600 ? "42vw" : "12.5vw"}
          isPreview={isPreview}
          onClick={() => handleSelectImage(preview - 1, !mulImageSelect.includes(preview - 1))}
        >
          เลือกภาพนี้
        </ActionButton> */}
      </PreviewImageWrapper>
    </>
  );
};

const CirlcleSelection = styled.div`
  width: 2rem;
  height: 2rem;
  box-sizing: border-box;
  border: ${(props) => (props.selected ? "none" : "solid white 1px")};
  border-radius: 50%;
  position: absolute;
  right: 10px;
  bottom: 10px;
  /* z-index: 99; */
  background-color: ${(props) => (props.selected ? "#3EBA2B" : "none")};
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageWrapperComponent = ({
  updatePreview,
  idx,
  item,
  handleSelectImage,
  selected,
  set_isPreview,
  preview,
}) => {
  const defaultOptions = {
    shouldPreventDefault: true,
    delay: 500,
  };

  const onLongPress = (i) => {};

  const onClick = (i) => {
    if (!isTouchmove) {
      updatePreview(idx + 1);
      if (idx + 1 === preview) {
        set_isPreview(true);
      }
    }
    isTouchmove = false;
  };

  const longpress = useLongPress(onLongPress, onClick, idx, defaultOptions);
  return (
    <ImageWrapper>
      <Image src={item && checkLangAvailableDatabase(item.thumbnailPath)} {...longpress} />
      <CirlcleSelection
        onClick={() => handleSelectImage(idx, !selected)}
        selected={selected}
      >
        {selected && <Check />}
      </CirlcleSelection>
    </ImageWrapper>
  );
};

export default GalleryPageMultipleSelection;
