import React, { useState } from "react";
import styled from "styled-components";
import PhoneSVG from "../assets/home/phoneSVG.svg";
import facebookLogo from "../assets/home/facebookLogo.svg";
import Qrcode from "../assets/home/qr-code.png";
import ChromeSVG from "../assets/home/Chrome.svg";
import SafariSVG from "../assets/home/Safari.svg";
import { fluidRange } from "polished";
import { useTranslation } from "react-i18next";
import { handleFontFamily } from "../utils/utils";

const ContentWrapper = styled.div`
  width: 100vw;
  height: 100%;
  //align-items: center;
  background-color: rgba(0, 0, 0, 0.85);
  position: fixed;
  left: 0px;
  top: 0px;
  display: flex;
  z-index: 11;
`;

const CardWrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  height: min-content;
  transform: translate(-50%, -50%);
  background-color: #f9f9f9;
  bottom: 0;
  border-radius: 15px;
  //padding : 0 10px;
  flex-direction: column;
  display: flex;
  padding: 3.5vh 2.5vw 4vh 2.5vw;
`;

const PhoneImageStyled = styled.img`
  width: 3vw;
  height: auto;
  margin: 0 auto;
`;

const HeaderTextStyled = styled.div`
  margin: 2vh auto 2vh auto;
  //font-size: 1.4rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 600;
  color: black;
  text-align: center;
  ${fluidRange(
    {
      prop: "font-size",
      fromSize: "10px",
      toSize: "24px",
    },

    "400px",
    "1200px"
  )}
`;

const QRCodeWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const QRCodeImg = styled.img`
  width: 9.75vw;
  height: 9.75vw;
  margin: auto 2vw auto 0;
`;

const SafariImg = styled.img`
  width: 3.75vw;
`;
const ChromeImg = styled.img`
  width: 3.33vw;
  height: 3.33vw;
  margin: auto;
`;

const WebWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TextStyled = styled.p`
  //font-size: 1.2rem;
  ${handleFontFamily()}
  font-style: normal;
  font-weight: 400;
  color: black;
  ${fluidRange(
    {
      prop: "font-size",
      fromSize: "8px",
      toSize: "20px",
    },
    "400px",
    "1200px"
  )}

  margin:0.7vh auto 0.7vh auto;
  @media (min-width: 1200px) {
    margin: 0.9vh auto;
  }
`;

const HandleDesktop = () => {
  const { t } = useTranslation();
  return (
    <ContentWrapper>
      <CardWrapper>
        <PhoneImageStyled src={facebookLogo} />
        <HeaderTextStyled>
          {t("กรุณาสแกน QR เพื่อเข้าสู่เว็บไซต์")}
          <br />
          {t("ด้วยสมาร์ทโฟน")}
        </HeaderTextStyled>
        <QRCodeWrapper>
          <QRCodeImg src={Qrcode} />
          <WebWrapper>
            <div style={{ display: "flex", alignItems: "center" }}>
              <SafariImg src={SafariSVG} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "1vw",
                }}
              >
                <TextStyled>
                  {t("สำหรับ")}{" "}
                  <a style={{ color: "red", fontWeight: "600" }}>{t("iOS")}</a>
                  <br />
                  {t("กรุณาเปิดด้วย")}{" "}
                  <a style={{ fontWeight: "600" }}>{t("Safari")}</a>
                </TextStyled>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{ display: "flex", width: "3.75vw", height: "3.75vw" }}
              >
                <ChromeImg src={ChromeSVG} />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  marginLeft: "1vw",
                }}
              >
                <TextStyled>
                  {t("สำหรับ")}{" "}
                  <a style={{ color: "red", fontWeight: "600" }}>
                    {t("Android")}
                  </a>
                  <br />
                  {t("กรุณาเปิดด้วย")}{" "}
                  <a style={{ fontWeight: "600" }}>{t("Chrome")}</a>
                </TextStyled>
              </div>
            </div>
          </WebWrapper>
        </QRCodeWrapper>
      </CardWrapper>
    </ContentWrapper>
  );
};

export default HandleDesktop;
