import React, { useEffect, useState  } from "react";

export const UserContext = React.createContext();

export const UserContextProvider = ({children})=>{
    const [contextAWDb,setContextAWDb] = useState(
        JSON.parse(sessionStorage.getItem('artwork_database_context'))||[]
    );
    const [voucher,update_voucher] = useState(
        sessionStorage.getItem('session_voucher')||'')

    const [couponCollection,updateCouponCollection] = useState(
        JSON.parse(sessionStorage.getItem('coupon_collection'))||new Array())

    const [couponProducts,updateCouponProducts] = useState(
        JSON.parse(sessionStorage.getItem('coupon_products'))||new Array())

    const [fetchArtwork,updateFetchArtwork] = useState(
        JSON.parse(sessionStorage.getItem('fetch_artwork'))||[]
    );
    const [formList,updateFormList] = useState(
        JSON.parse(sessionStorage.getItem('sessionFormList'))||[{
            fill: false,
        name: "",
        surname: "",
        zodiacId: "",
        birthDate: "",
        birthMonth: "",
        birthYear: "",
        showBirthDate: false,
        selectImageId: "",
        selectImage: "",
        id: "",
        }])
    const [subscriberInfo,updateSubscriberInfo] = useState(
        JSON.parse(sessionStorage.getItem('session_subscription_user_info'))||{
            name:"",
            surname:"",
            birthDate:29,
            birthMonth:11,//month 0-11
            birthYear: 1994,
            zodiacId: 0,
            chinese_zodiac:'',
            day_of_week:'',
        })

    const [onShowPreview,setOnShowPreview] = useState(false)

    useEffect(()=>{
        sessionStorage.setItem('artwork_database_context',JSON.stringify(contextAWDb))
    },[contextAWDb])

    useEffect(()=>{
        sessionStorage.setItem('session_voucher',voucher)
    },[voucher])

    useEffect(()=>{
        sessionStorage.setItem('sessionFormList',JSON.stringify(formList))
    },[formList])

    useEffect(()=>{
        sessionStorage.setItem('coupon_collection',JSON.stringify(couponCollection))
    },[couponCollection])

    useEffect(()=>{
        sessionStorage.setItem('coupon_products',JSON.stringify(couponProducts))
    },[couponProducts])

    useEffect(()=>{
        sessionStorage.setItem('fetch_artwork',JSON.stringify(fetchArtwork))
    },[fetchArtwork])

    useEffect(()=>{
        sessionStorage.setItem('session_subscription_user_info',JSON.stringify(subscriberInfo))
    },[subscriberInfo])

    return(
        <UserContext.Provider value={({
            contextAWDb,setContextAWDb,
            voucher,update_voucher,
            couponCollection,updateCouponCollection,
            couponProducts,updateCouponProducts,
            fetchArtwork,updateFetchArtwork,
            subscriberInfo,updateSubscriberInfo,
            onShowPreview,setOnShowPreview,
            formList,updateFormList,
            })}>
            {children}
        </UserContext.Provider>
    )
}

