import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Routes,
  Route,
  Redirect,
  Navigate,
} from "react-router-dom";
import LoginPage from "./LoginPage";
import SubscriptionPersonalInfoPage from "./PersonalInformationPage";
import SelectPackagePage from "./SelectPackagePage";
import SubscriberPackage from "./SubscriberPackage";
import SubscriptionArtworkPage from "./SubscriptionArtworkPage";
import SubscriptionPreviewPage from "./SubscriptionPreviewPage";

const LuckySubscriptionPage = () => {
  return (
    <Routes>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/package" element={<SelectPackagePage />} />
      <Route path="/information" element={<SubscriptionPersonalInfoPage />} />
      <Route path="/user" element={<SubscriberPackage />} />
      <Route path="/preview" element={<SubscriptionPreviewPage />} />
      <Route path="/generate" element={<SubscriptionArtworkPage />} />
      <Route path="/" element={<LoginPage />} />
    </Routes>
  );
};

export default LuckySubscriptionPage;
