import React, { useState, useEffect, useMemo, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";
import { checkRefferURL } from "../utils/utils";
import HomePage from "./HomePage/HomePage";

const PartnerPage = () => {
    const { partner_id } = useParams();
    return(
        <HomePage partner_link={partner_id}/>
    )
}

export default PartnerPage;
