import "./App.css";
import Main from "./pages/Test";
import React, { useState, useEffect, Fragment } from "react";
import {
  BrowserRouter,
  Route,
  useNavigate,
  useLocation,
  Navigate,
  Routes,
} from "react-router-dom";
import PaymentPage from "./pages/PaymentPage";
import Message from "./pages/Message";
// the hoc
//import { useTranslation, I18nextProvider } from 'react-i18next';
import FillInformationPage from "./pages/FillInformationPage";
import DownloadPage from "./pages/DownloadPage";
import Homepage from "./pages/HomePage";
import PackageSelectPage from "./pages/PackageSelectPage";
import TermsAndConditionPage from "./pages/TermsAndConditionPage";
import CartPage from "./pages/CartPage";
import "./i18n";
import {
  getDateDatabase,
  API_URL,
  getCatalog,
  checkServer,
  getFormType,
  sendConversionAPI,
  DEPLOY_VERSION,
} from "./utils/utils";
import {
  artworkDatabase as artworkDatabaseAtom,
  dateDatabase as dateDatabaseAtom,
  state as stateAtom,
  catalogDatabase as catalogDatabaseAtom,
  serverStatus as serverStatusAtom,
  formTypeDatabase,
  fbc_cookies,
  fbp_cookies,
} from "./global/atom";
import { useRecoilState, useResetRecoilState } from "recoil";
import GalleryPage from "./pages/GalleryPage/GalleryPage";
import CouponPage from "./pages/CouponPage";
import Div100vh from "react-div-100vh";
import CheckoutPage from "./pages/CheckoutPage";
import SubscriptionPage from "./pages/SubscriptionPage/index";
import GlobalStyle from "./global/globalStyle";
import { use100vh } from "react-div-100vh";
import OrientationPopup from "./components/OrientationPopup";
import {
  IS_ANDROID,
  IS_ANDROID_FIREFOX,
  IS_FACEBOOK_APP,
  IS_INSTAGRAM,
  IS_IOS,
  IS_IPhone,
  IS_LINE,
  IS_SAFARI,
  IS_TIKTOK,
} from "./utils/support";
import HandleDesktop from "./components/HandleDesktop";
import HandleMobile from "./components/HandleMobile";
import QRNotify from "./pages/QrNotify";
import CardNotify from "./pages/CardNotify";
import CardCallback from "./pages/CardCallback";
import { useSessionStorage } from "react-use";
import {
  sessionDateDatabase,
  sessionState,
  session_state,
  session_artwork_database,
  session_fetch_artwork,
  session_fbc_cookies,
  session_fbp_cookies,
} from "./utils/sessionStorage";
import PurchasedGalleryPage from "./pages/PurchasedGalleryPage";
import TagManager from "react-gtm-module";
import PreviewGeneratePage from "./pages/PreviewGenerate";
import ThankyouPage from "./pages/ThankyouPage";
import MaintenancePage from "./pages/MaintenancePage";
import NavLogo from "./components/NavLogo";
import GalleryMultipleSelectionPage from "./pages/GalleryMultipleSelectionPage";
import PartnerPage from "./pages/PartnerPage";
import AgentPage from "./pages/AgentPage";
import IntroPage from "./pages/HomePage/IntroPage";
import Test from "./pages/Test";
import PackagePage from "./pages/HomePage/PackagePage";
import CollectionGroup from "./pages/HomePage/CollectionGroup";
import { checkHasPurchasedHistory } from "./utils/IndexedDBStore";
import ReactPixel from "react-facebook-pixel";
import BlogsPages from "./pages/BlogsPage/BlogsPage";
import BlogsContentPage from "./pages/BlogsPage/BlogsContentPage";
import { useContext } from "react";
import { UserContext } from "./utils/Context/userContext";
import AlacartePage from "./pages/Alacarte";
import AisCollectionGroup from "./pages/HomePage/AisCollectionGroup";
import LuckySubscriptionPage from "./pages/LuckySubscriptionPage";
import RefundPage from "./pages/RefundPage";
import { useTranslation } from "react-i18next";
import RedeemLandingPage from "./pages/RedeemPage/RedeemLandingPage.";
import RedeemPage from "./pages/RedeemPage";

function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return () => setValue((value) => value + 1); // update the state to force render
}
export const APP_VERSION = "1.6.2";

const App = (props) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const height = use100vh();
  let initialIndex;
  //i18n.language === "zh" ? (initialIndex = 0) : (initialIndex = 1);
  //const [fbc,setFbc] = useRecoilState(fbc_cookies)
  const [fbc, setFbc] = useSessionStorage(
    session_fbc_cookies.key,
    session_fbc_cookies.default
  );
  //const [fbp,setFbp] = useRecoilState(fbp_cookies)
  const [fbp, setFbp] = useSessionStorage(
    session_fbp_cookies.key,
    session_fbp_cookies.default
  );
  const { contextAWDb, setContextAWDb, fetchArtwork, updateFetchArtwork } =
    useContext(UserContext);
  //const [fetchArtwork,updateFetchArtwork] = useSessionStorage(session_fetch_artwork.key,session_fetch_artwork.default)
  const [, updateFormTypeDatabase] = useRecoilState(formTypeDatabase);
  const [isLandscape, setIsLandscape] = useState(false);
  const [dateDatabase, updateDateDatabase] = useRecoilState(dateDatabaseAtom);
  const [catalogDatabase, updateCatalogDatabase] =
    useRecoilState(catalogDatabaseAtom);
  const [sessionStorageState, updateSessionState] = useSessionStorage(
    sessionState.key,
    sessionState.default
  );
  const [state, updateState] = useRecoilState(stateAtom);
  const [serverStatus, updateServerStatus] = useState("");

  const forceUpdate = useForceUpdate();

  /*const handleLang = (e, index) => {
    if (index === 0) {
      const lang = "zh";
      i18n.changeLanguage(lang);
    } else {
      const lang = "en";
      i18n.changeLanguage(lang);
    }
    setSelectedIndex(index);
    setAnchorEl(null);
  };*/

  //const FETCHURL = "http://192.168.0.109:8080/getDatabase";
  const FETCHURL = API_URL + "/getDatabase";
  //const FETCHURL = "https://asia-southeast1-doseart-304207.cloudfunctions.net/luckydose_api/getDatabase";

  const VersionText = () => {
    return (
      <div
        style={{
          position: "fixed",
          top: "10px",
          right: "10px",
          color: "red",
          zIndex: "10",
        }}
      >
        {"version " + APP_VERSION}
      </div>
    );
  };

  const handleContextMenu = (e) => {
    e.preventDefault();
  };

  const tagManagerArgs = {
    gtmId: "GTM-MCK8G6S",
  };

  var initViewport = function () {
    var metaEl = document.querySelector("#viewportMeta");
    var content =
      "height=" +
      window.innerHeight +
      ",width=" +
      window.innerWidth +
      ",initial-scale=1.0, maximum-scale=1.0, user-scalable=no,user-scalable=0";
    metaEl.setAttribute("name", "viewport");
    metaEl.setAttribute("content", content);
  };
  initViewport();

  useEffect(() => {
    
    if(DEPLOY_VERSION=="PROD"){
      ReactPixel.init("973339143546259");
      ReactPixel.grantConsent();
      ReactPixel.pageView();
    }
    checkHasPurchasedHistory();
    updateState(sessionStorageState);

    const fetchData = async () => {
      var status = await checkServer();
      if (status) updateServerStatus("online");
      else updateServerStatus("maintain");

      if (fetchArtwork.length == 0) {
        const res = await fetch(FETCHURL);
        const result = await res.json();
        var array = [];
        result.forEach((value) => {
          if (value.flag) {
            array.push(value);
          }
        });
        updateFetchArtwork(array);
        setContextAWDb(array);
      }

      var catalog = await getCatalog();
      updateCatalogDatabase(catalog);
    };

    fetchData();

    if (window.orientation != 0 && (IS_ANDROID || IS_IOS)) setIsLandscape(true);
    if (
      (IS_ANDROID && IS_LINE) ||
      (IS_ANDROID && IS_ANDROID_FIREFOX) ||
      (IS_ANDROID && IS_FACEBOOK_APP) ||
      (IS_ANDROID && IS_INSTAGRAM) ||
      (IS_ANDROID && IS_TIKTOK)
    ) {
      var url =  window.location.host + window.location.pathname + window.location.search
      window.location.href =
        "intent://" +
        url +
        "#Intent;scheme=https;package=com.android.chrome;action=android.intent.action.VIEW;end;";
      window.location.target = "_blank";
    } else if (IS_IOS && IS_LINE) {
      var url = window.location.href;
      window.location = url.includes("?")
        ? url + "&openExternalBrowser=1"
        : url + "?openExternalBrowser=1";
      //window.location = window.location.origin+"?openExternalBrowser=1"
    }

    // window.addEventListener("orientationchange", handleOrientationChange, false);

    const getDatabase = async () => {
      var yearDatabase = await getDateDatabase();
      updateDateDatabase(yearDatabase);
      var form_type = await getFormType();
      updateFormTypeDatabase(form_type);
    };

    getDatabase();

    TagManager.initialize(tagManagerArgs);
    getFbCookies();

    const rootElement = document.getElementById("root-div100");
    //disable right click on website
    rootElement.addEventListener("contextmenu", handleContextMenu);

    return () => {
      rootElement.addEventListener("contextmenu", handleContextMenu);
      // window.removeEventListener("orientationchange", handleOrientationChange);
    };
  }, []);

  const getFbCookies = () => {
    //React facebook pixel
    var fbc_name = "_fbc";
    var fbp_name = "_fbp";
    const value = `; ${document.cookie}`;
    //fpc
    var parts = value.split(`; ${fbc_name}=`);
    const params = new URLSearchParams(window.location.search);
    let t_fbp = ''
    let t_fbc =''
    if (parts.length === 2) {
      var part_value = parts.pop().split(";").shift();
      t_fbc = part_value
      //sendConversionAPI('PageView',fbc,fbp)
      setFbc(part_value);
    } else if (params.get("fbclid")) {
      var _fbc = "fb.1." + Date.now() + "." + params.get("fbclid");
      t_fbc = _fbc
      setFbc(_fbc);
    }
    //fbp
    parts = value.split(`; ${fbp_name}=`);
    if (parts.length === 2) {
      var part_value = parts.pop().split(";").shift();
      t_fbp = part_value
      //sendConversionAPI('PageView',fbc,fbp)
      setFbp(part_value);
    }
    if(DEPLOY_VERSION=="PROD"){

      sendConversionAPI("PageView",t_fbc,t_fbp)
    }
  };

  /*useEffect(() => {
    if (fbc != "" && fbp != "" && DEPLOY_VERSION == "PROD")
      sendConversionAPI("PageView", fbc, fbp);
  }, [fbc, fbp]);*/

  useEffect(() => {
    updateSessionState(state);
  }, [state]);

  const HandleMobileMode = () => {
    if (IS_IOS || IS_ANDROID) {
      if (
        (IS_IOS && IS_LINE) ||
        (IS_ANDROID && IS_ANDROID_FIREFOX) ||
        (IS_ANDROID && IS_LINE) ||
        (IS_ANDROID && IS_INSTAGRAM) ||
        (IS_ANDROID && IS_TIKTOK) ||
        (IS_ANDROID && IS_FACEBOOK_APP)
      )
        return <HandleMobile />;
      else {
        return <></>;
      }
    } else return <></>;
  };

  return (
    <Fragment>
      <GlobalStyle locale={i18n.language} />
      <HandleMobileMode />
      <OrientationPopup />

      <NavLogo
        isHome={
          location.pathname.includes("home") ||
          location.pathname.includes("gallery") ||
          location.pathname.includes("collection_group") ||
          location.pathname.includes("blogs") ||
          (location.pathname.includes("ais") &&
            !location.pathname.includes("aisalc"))
        }
      />

      {serverStatus == "maintain" ? (
        <>
          <MaintenancePage/>
        </>
      ) : serverStatus == "online" ? (
        state == "" ? (
          <>
            <Routes>
              <Route path="/checkout-complete" element={<CheckoutPage />} />
              <Route path="/thankyou" element={<ThankyouPage />} />
              <Route path="/coupon" element={<CouponPage />} />
              <Route path="/payment" element={<PaymentPage />} />
              <Route
                path="/termsandcondition"
                element={<TermsAndConditionPage />}
              />
              <Route path="/refundCondition" element={<RefundPage />} />
              <Route path="/partner/:partner_id" element={<PartnerPage />} />
              <Route path="/agent/:agent_id" element={<AgentPage />} />
              <Route path="/intro/:collection_id" element={<IntroPage />} />
              <Route path="/intro/:collection_id/:partner_id" element={<IntroPage />} />
              <Route
                path="/collection_group/:collection_tag"
                element={<CollectionGroup />}
              />
              <Route exact path="/home" element={<Homepage />} />
              <Route path="/version" element={<Test />} />
              <Route path="/blogs/:content_id" element={<BlogsContentPage />} />
              <Route path="/blogs" element={<BlogsPages />} />
              <Route path="/ais_collection" element={<AisCollectionGroup />} />
              <Route path="/aisalc/*" element={<AlacartePage />} />
              <Route path="/aispostpaid/*" element={<LuckySubscriptionPage />} />
              <Route path="/ais/*" element={<SubscriptionPage />} />
              <Route path="/redeem/*" element={<RedeemPage/>}/>
              <Route
                path="/"
                render={() => {
                  return <Navigate to="/home" replace />;
                }}
              />
            </Routes>
          </>
        ) : (
          <>
            <Routes>
              <Route path="/payment" element={<PaymentPage />} />
              <Route path="/filled" element={<FillInformationPage />} />
              <Route path="/cart" element={<CartPage />} />
              <Route path="/gallery" element={<GalleryPage />} />
              <Route path="/checkout-complete" element={<CheckoutPage />} />
              <Route
                path="/termsandcondition"
                element={<TermsAndConditionPage />}
              />
              <Route path="/refundCondition" element={<RefundPage />} />
              <Route
                path="/purchasedHistory"
                element={<PurchasedGalleryPage />}
              />
              <Route path="/coupon" element={<CouponPage />} />
              <Route path="/preview" element={<PreviewGeneratePage />} />
              <Route path="/thankyou" element={<ThankyouPage />} />
              <Route path="/partner/:partner_id" element={<PartnerPage />} />
              <Route path="/agent/:agent_id" element={<AgentPage />} />
              <Route path="/intro/:collection_id" element={<IntroPage />} />
              <Route path="/intro/:collection_id/:partner_id" element={<IntroPage />} />
              <Route path="/package" element={<PackagePage />} />
              <Route
                path="/gallery-selection/:topic_id/:aw_price?"
                element={<GalleryMultipleSelectionPage />}
              />
              <Route
                path="/collection_group/:collection_tag"
                element={<CollectionGroup />}
              />
              <Route path="/version" element={<Test />} />
              <Route path="/blogs/:content_id" element={<BlogsContentPage />} />
              <Route path="/blogs" element={<BlogsPages />} />
              <Route path="/ais_collection" element={<AisCollectionGroup />} />
              <Route path="/aisalc/*" element={<AlacartePage />} />
              <Route path="/aispostpaid/*" element={<LuckySubscriptionPage />} />
              <Route path="/ais/*" element={<SubscriptionPage />} />
              <Route path="/redeem/*" element={<RedeemPage/>}/>
              <Route
                exact
                path="/"
                render={() => {
                  return <Navigate to="/home" replace />;
                }}
              />

              <Route path="/home/*" element={<Homepage />} />
            </Routes>
          </>
        )
      ) : (
        <></>
      )}
    </Fragment>
  );
};

export default App;
